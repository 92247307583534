import { getUserInfo,me } from "../../../utils/users";
class ActionSummaryOverTimeController {
  constructor(
      API,
      ToastService,
      i18nService,
      $auth,
      $timeout,
      $state,
      DialogService,
      UserService,
      $stateParams,
      $mdMenu,
      $rootScope,
      $filter,
      $q,
      $location,
      $scope,
      $window,
      $mdBottomSheet,
      TabActionService
  ) {
    "ngInject";
    this.UserService = UserService;
    this.API = API;
    this.$auth = $auth;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.TabActionService = TabActionService;
    this.$scope = $scope;
    this.window = $window;
    this.$mdBottomSheet = $mdBottomSheet;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$mdMenu = $mdMenu;

    this.per_page = 25;
    this.pageNumber = 1;
    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;

    this.ciroEndDate = new Date()
    this.ciroStartDate = new Date()

    this.lastCiroEndDate = new Date()
    this.lastCiroStartDate = new Date()
  }
  $onInit() {
    this.getActionSummary();
  }

  getActionSummary(){
    let parent = this;
    this.API.all("action/summary")
        .customGET(null, {
          ciroStartDate: parent.ciroStartDate,
          ciroEndDate: parent.ciroEndDate,
          lastCiroStartDate: parent.lastCiroStartDate,
          lastCiroEndDate: parent.lastCiroEndDate
        })
        .then((response) => {
          this.ciroList = response.data.ciroList
          this.ciroListNotes = response.data.ciroListNotes
        });
  }

}
ActionSummaryOverTimeController.$inject = ["API", "ToastService", "i18nService", "$auth", "$timeout", "$state", "DialogService", "UserService", "$stateParams", "$mdMenu", "$rootScope", "$filter", "$q", "$location","$scope","$window","$mdBottomSheet","TabActionService"];
const ActionSummaryOverTimeComponent = {
  templateUrl: "./views/angular/app/components/action-summary-over-time/action-summary-over-time.component.html",
  controller: ActionSummaryOverTimeController,
  controllerAs: "vm",
  bindings: {},
};
export {ActionSummaryOverTimeComponent,ActionSummaryOverTimeController}
