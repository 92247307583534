import {parseToken} from "../utils/users";

class TokenService {
  constructor($rootScope) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.token = window.localStorage.satellizer_token;
    this.permissions = [];
    this.loadPermissions();
  }

  setToken(token) {
    this.token = token;
    // Token güncellendiğinde, permissions'ı güncelle
    this.loadPermissions();
  }

  getToken() {
    return this.token;
  }

  loadPermissions() {
    if (this.token) {
      try {
        let t = parseToken(this.token);
        this.permissions = t.permissions || [];
        // Permissions'ı $rootScope'a yükle
        this.$rootScope.permissions = this.permissions;
      } catch (e) {
        console.error("Invalid token:", e);
      }
    }
  }

  getPermissions() {
    return this.permissions;
  }
}

TokenService.$inject = ["$rootScope"];

export {TokenService};
