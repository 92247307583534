import {getUserInfo} from "../../../utils/users";

class ProductEntryController {
    constructor(API, ToastService, $translate, $mdBottomSheet, $scope, DialogService, $state, $location, $anchorScroll, $filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$filter = $filter;
        this.translate = this.$filter('translate');

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

        this.dropdownModelsTranslationsText = {
            buttonDefaultText: this.$filter('translate')('MODEL_SELECT'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            dynamicButtonTextSuffix: this.$filter('translate')('MODEL_SELECTED'),
        };
        this.dropdownModelsSettings = {
            enableSearch: true, selectionLimit: 1, scrollableHeight: '400px', scrollable: true,
            smartButtonMaxItems: 1,
            smartButtonTextConverter: function (itemText, originalItem) {
                return itemText;
            }
        };

        this.models = [];
        this.stockUnits = [];
        this.product = {
            productCode: null,
            productName: null,
            supplierCode: null,
            barcode: null,
            model: null,
            stockUnit: null,
            stockUnitId: null,
            with: null,
            depth: null,
            height: null,
            weight: null,
            m3: null,
            combine: true,
            productType: 'product'
        }

        this.quantityValue = 'weight'
        //     this.autoM3Calc = false;

    }

    $onInit() {
        this.getModels()
        this.getStockUnit()
    }

    $onChanges(obj) {
        if (this.autoM3Calc && obj.cbm) {
            this.product.width = obj.cbm.currentValue?.width
            this.product.depth = obj.cbm.currentValue?.depth
            this.product.height = obj.cbm.currentValue?.height
            this.product.weight = obj.cbm.currentValue?.weight
            this.product.m3 = obj.cbm.currentValue?.m3
        }
        if (obj.stockUnit) {
            this.product.stockUnit = obj.stockUnit.currentValue
        }
    }


    clearFilter() {
        this.product = {
            productCode: null,
            productName: null,
            supplierCode: null,
            barcode: null,
            model: null,
            stockUnit: null,
            stockUnitId: null,
            with: null,
            depth: null,
            height: null,
            weight: null,
            m3: null,
            combine: true,
            productType: 'product'
        }
    }


    getModels() {
        this.API.all("model/getList")
            .post()
            .then((response) => {
                this.models = response.data.modelArray;
            });
    }

    getStockUnit() {
        this.API.all("stockUnit/getList")
            .post()
            .then((response) => {
                this.stockUnits = response.data.stockUnit;
            });
    }

    save() {
        if (this.quantityValue === 'weight') {
            this.product.quantity = this.product.weight
        }
        this.API.all("stock/save")
            .post(this.product)
            .then((response) => {
                if (response.response.status === 200) {
                    this.ToastService.showHtml('success', this.product.productName + ' başarıyla oluşturuldu')
                    this.clearFilter()
                }
            });
    }

}

ProductEntryController.$inject = ["API", "ToastService", "$translate", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$anchorScroll", "$filter"];
const ProductEntryComponent = {
    templateUrl: "./views/angular/app/components/combine-stock-entry/partials/product-entry.html",
    controller: ProductEntryController,
    controllerAs: "vm",
    bindings: {
        with: '<',
        height: '<',
        depth: '<',
        weight: '<',
        autoM3Calc: '<',
        cbm: '<',
        product: '=?',
        stockUnit: '<',
        productType: '<',
        component: '<',
    },
};

export {ProductEntryComponent, ProductEntryController}
