import "./login-form.scss"
class LoginFormController {
	constructor($auth, $translate, $state, $location, $rootScope, UserService, ToastService, TokenService) {
		'ngInject';

		this.$auth = $auth;
		this.$translate = $translate;
		this.$state = $state;
		this.$location = $location
		this.$rootScope = $rootScope;
		this.UserService = UserService;
		this.ToastService = ToastService;
		this.TokenService = TokenService;
	}

    $onInit(){
    	if(this.$auth.getToken()!=null) {
    		this.$state.go('app.dashboard');
    	}
    	this.email = '';
			this.password = '';
			this.inputType = 'password';
			this.showPassword = false;
			const d = new Date();
			this.timezoneOffset = -new Date().getTimezoneOffset()/60;
    }

	login() {
		let user = {
			email: this.email,
			password: this.password,
			companyName: window.__env.companyName
		};

		this.$auth.login(user)
			.then((response) => {
				this.$auth.setToken(response.data);
				this.TokenService.setToken(response.data.data.token);

				this.UserService.getUserObject().then(r => {
					this.ToastService.success(this.$translate.instant('SUCC_LOGGED'));
					this.$location.path('/dashboard');
					this.$state.go('app.dashboard');
				});
			})
			.catch(this.failedLogin.bind(this));
	}


	failedLogin(response) {
		if (response.status === 422) {
			for (let error in response.data.errors) {
				return this.ToastService.error(response.data.errors[error][0]);
			}
		}

		if (response.status === 401) {
			for (let error in response.data.errors) {
				return this.ToastService.error(response.data.errors[error][0]);
			}
		}

		this.ToastService.error(response.statusText);
	}

	togglePasswordVisibility(){
		this.showPassword = !this.showPassword;
		this.inputType = this.showPassword ? 'text' : 'password';
	};
}

LoginFormController.$inject = ["$auth", "$translate", "$state", "$location", "$rootScope", "UserService", "ToastService", "TokenService"];
export const LoginFormComponent = {
	templateUrl: './views/angular/app/components/login-form/login-form.component.html',
	controller: LoginFormController,
	controllerAs: 'vm',
	bindings: {}
}
