import "./stock-organization.scss";

class StockOrganizationController {
  constructor(API, ToastService, $translate, $location, $state) {
    "ngInject";

    this.API = API;
    this.ToastService = ToastService;
    this.$translate = $translate;
    this.subLocationId = 0;
    this.$location = $location;
    this.$state = $state;
    this.tags = null;
    this.tagCategories = null;
  }

  $onInit() {
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;

    this.getCategories();
    this.getBrands();
    this.getTags();

    this.brands = {};
    this.per_page = 10;
    this.filterArr = {};
    this.pageNumber = 1;

    /*this.data = [{
        'id': 1,
        'title': 'node1',
        'nodes': [
          {
            'id': 11,
            'title': 'node1.1',
            'nodes': [
              {
                'id': 111,
                'title': 'node1.1.1',
                'nodes': []
              }
            ]
          },
          {
            'id': 12,
            'title': 'node1.2',
            'nodes': []
          }
        ]
      }, {
        'id': 2,
        'title': 'node2',
        'nodrop': true, // An arbitrary property to check in custom template for nodrop-enabled
        'nodes': [
          {
            'id': 21,
            'title': 'node2.1',
            'nodes': []
          },
          {
            'id': 22,
            'title': 'node2.2',
            'nodes': []
          }
        ]
      }, {
        'id': 3,
        'title': 'node3',
        'nodes': [
          {
            'id': 31,
            'title': 'node3.1',
            'nodes': []
          }
        ]
      }];*/
  }

  onSelectTab(obj) {
    this.$location.search(obj);
    this.video = undefined;
    /*if (obj.tab == 0) {
        this.video = 'location-management';
    }
    if (obj.tab == 1) {
        this.video = 'locationShelf';
    }
    if (obj.tab == 2) {
        this.video = 'locationProductShelf';
    }*/
  }


  getCategories(parentIds = '') {
    this.API.all("category/getCategories")
      .post({parentIds: parentIds})
      .then((response) => {
        this.data = response.data.categories;
      });
  }


  getBrands(pageNumber = this.pageNumber,
            filterArr = this.filterArr,
            per_page = this.per_page,
            brandId = undefined) {

    var url = 'brand',
      parent = this;

    if (brandId) {
      url += '/' + brandId;
    }

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let customGet = {
      per_page: this.per_page,
      page: this.pageNumber,
      brand_name: this.searchBrandName
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;
    reqObject.page = this.pageNumber;

    this.API.all(url)
      .customGET('', reqObject)
      .then((response) => {
        if (!brandId) {
          parent.paginationDataGenerator(parent, response);
        } else {
          parent.brandId = brandId;
          parent.createBrandName = response.data.list.brand_name;
          parent.createBrandLink = response.data.list.brand_link;
        }
      });
  }

  paginationDataGenerator(parent, response) {
    parent.brands = response.data.list;
    parent.total = response.data.list.total;
    parent.current_page = response.data.list.current_page;
    parent.per_page = response.data.list.per_page;
    parent.last_page = response.data.list.last_page;
    parent.prev_page_url = response.data.list.prev_page_url;
    parent.next_page_url = response.data.list.next_page_url;

    parent.brandPagesArray = response.data.brandPagesArray;

    parent.prev_index = response.data.prevIndex;
    parent.next_index = response.data.nextIndex;
  }

  paginationDataGeneratorTag(parent, response) {
    parent.tags = response.data.list;
    parent.total = response.data.list.total;
    parent.current_page = response.data.list.current_page;
    parent.per_page = response.data.list.per_page;
    parent.last_page = response.data.list.last_page;
    parent.prev_page_url = response.data.list.prev_page_url;
    parent.next_page_url = response.data.list.next_page_url;

    parent.brandPagesArray = response.data.brandPagesArray;
    parent.tagCategories = response.data.tagCategories;

    parent.prev_index = response.data.prevIndex;
    parent.next_index = response.data.nextIndex;
  }


  clearBrandForm() {
    this.createBrandName = null;
    this.createBrandLink = null;
  }

  createBrand() {
    var reqData = {
      brandName: this.createBrandName,
      //product_id: JSON.stringify(this.createLotProductId),
      brandLink: this.createBrandLink
    };

    var parent = this;

    this.API.all("brand")
      .post(reqData)
      .then((response) => {
        if (207 === response.response.status) {
          this.ToastService.success(this.$translate.instant('BRAND_ADDED_SUCC'))
          this.clearBrandForm();
          this.getBrands();
        }
      });
  }

  updateBrand() {
    var reqData = {
      brandName: this.createBrandName,
      //product_id: JSON.stringify(this.createLotProductId),
      brandLink: this.createBrandLink
    };

    var parent = this;

    this.API.all("brand/" + this.brandId)
      .post(reqData)
      .then((response) => {
        if (207 === response.response.status) {
          this.ToastService.success(this.$translate.instant('BRAND_UPDATED_SUCC'))
          this.clearBrandForm();
          this.getBrands();
        }
      });
  }

  saveBrand() {
    if (!this.brandId) {
      this.createBrand();
    } else {
      this.updateBrand();
    }
  }

  deleteBrand(id) {
    this.API.all('brand/' + id)
      .customDELETE()
      .then((response) => {
        if (204 === response.response.status) {
          this.getBrands()
          return true
        }
        this.ToastService.error(this.$translate.instant('AN_ERROR_DELETING'))
      });
  }

  editBrand(id) {
    this.getBrands('', '', '', id);
  }


  getTags(pageNumber = this.pageNumber,
            filterArr = this.filterArr,
            per_page = this.per_page,
            tagId = undefined) {

    var url = 'tag',
      parent = this;

    if (tagId) {
      url += '/' + tagId;
    }

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let customGet = {
      per_page: this.per_page,
      page: this.pageNumber,
      category: this.tagCategory,
      name: this.tagName
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;
    reqObject.page = this.pageNumber;

    this.API.all(url)
      .customGET('', reqObject)
      .then((response) => {
        if (!tagId) {
          parent.paginationDataGeneratorTag(parent, response);
        } else {
          parent.tagId = tagId;
          parent.tags = response.data.list;
          parent.tagCategories = response.data.tagCategories;
        }
      });
  }


  clearTagForm() {
    this.tagCategory = null;
    this.tagName = null;
  }

  createTag() {
    let reqData = {
      category: this.tagCategory,
      name: this.tagName
    };

    var parent = this;

    this.API.all("tag")
      .post(reqData)
      .then((response) => {
        if (207 === response.response.status) {
          this.ToastService.success(this.$translate.instant('TAG_ADDED_SUCC'))
          //this.clearTagForm();
          //this.tagCategory
          this.getTags();
        }
      });
  }

  updateTag() {
    var reqData = {
      brandName: this.createBrandName,
      //product_id: JSON.stringify(this.createLotProductId),
      brandLink: this.createBrandLink
    };

    var parent = this;

    this.API.all("tag/" + this.tagId)
      .post(reqData)
      .then((response) => {
        if (207 === response.response.status) {
          this.ToastService.success(this.$translate.instant('BRAND_UPDATED_SUCC'))
          this.clearTagForm();
          this.getTags();
        }
      });
  }

  saveTag() {
    if (!this.brandId) {
      this.createTag();
    } else {
      this.updateTag();
    }
  }

  deleteTag(id) {
    this.API.all('tag/' + id)
      .customDELETE()
      .then((response) => {
        if (204 === response.response.status) {
          this.getTags()
          return true
        }
        this.ToastService.error(this.$translate.instant('AN_ERROR_DELETING'))
      });
  }

  editTag(id) {
    this.getTags('', '', '', id);
  }

  editCategory(node) {
    node.showInput = true;
    node.inputValue = node.name;
    node.showAddInput = false;
  };

  addCategory(node) {
    node.showAddInput = true;
    node.newCategoryName = '';
    node.showInput = false;
  };

  saveCategory(node) {
    node.showInput = false;
    this.API.all("category/"+node.id)
        .customPUT(node)
        .then((response) => {
          if (207 === response.response.status) {
            this.ToastService.success(this.$translate.instant('CATEGORY_UPDATE_SUCC'))
            this.getCategories();
          }
        });
  };

  addNewCategory(node) {
    node.showAddInput = false;
    this.API.all("category")
        .post({category: node})
        .then((response) => {
          if (207 === response.response.status) {
            this.ToastService.success(this.$translate.instant('CATEGORY_CREATE_SUCC'))
            this.getCategories();
          }
        });
  };
  addNewMainCategory() {
    this.showMainCategoryInput = false;
    const node = {
      newCategoryName: this.newMainCategoryName,
      mainCategory: true,
    };
    this.API.all("category")
        .post({category: node})
        .then((response) => {
          if (207 === response.response.status) {
            this.ToastService.success(this.$translate.instant('MAIN_CATEGORY_CREATE_SUCC'))
            this.getCategories();
          }
        });
  };
  deleteCategory(node) {
    this.API.all("category/"+node.id)
        .customDELETE()
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.success(this.$translate.instant('CATEGORY_DELETE_SUCC'))
            this.getCategories();
          }
        });
  };

}

StockOrganizationController.$inject = ["API", "ToastService", "$translate", "$location", "$state"];
export const StockOrganizationComponent = {
  templateUrl:
    "./views/angular/app/components/stock-organization/stock-organization.component.html",
  controller: StockOrganizationController,
  controllerAs: "vm",
  bindings: {},
};
