class FocusInputController{
    constructor(){
        'ngInject';

        //
    }
}

export function FocusInputDirective(){
    return {
        controller: FocusInputController,
        restrict: 'A',
        priority:600,
        link: function(scope, element, attrs, controllers) {
            if(attrs.focusclause>0)
                element.focus();
        } 
    }
}
