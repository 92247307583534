var MenuEs = {
  SETTINGS: "Configuración",
  HELP: "Ayuda",
  QUICK_HELP: "Ayuda Rápida",
  SUPPORT: "Soporte",
  DARK_MODE: "Modo Oscuro",
  MY_SHORTCUT: "Mis Atajos"
};

var accountSettingsEs = {
  PERSONAL_INFORMATION: "Información Personal",
  TURKISH: "Turco",
  ENGLISH: "Inglés",
  CURRENT_PASSWORD: "Contraseña Actual",
  NEW_PASSWORD: "Nueva Contraseña",
  REPEAT_NEW_PASSWORD: "Repetir Nueva Contraseña",
  UPDATE: "Actualizar",
  CHANGE_PASSWORD: "Cambiar Contraseña",
  LOCALIZATION_SETTINGS: "Configuraciones de Localización",
  SCAN_PRIORITY: "Prioridad de Escaneo",
  PRINTER_SETTINGS: "Configuraciones de Impresora",
  PRINTER_NAME: "Nombre de la Impresora",
  PRINTER_CONNECTION_PROTOCOL: "Protocolo de Conexión de la Impresora",
  PRINTER_IP_ADDRESS: "Dirección IP de la Impresora",
  PRINTER_PORT_ADDRESS: "Dirección del Puerto de la Impresora",
  PRINT_TEST_PAGE: "Imprimir Página de Prueba",
  FIRM_LOGO: "Logotipo de la Empresa",
  CONTROLLER: "Controlador",
  QUALITY_CONTROL: "Control de Calidad",
  PACKER: "Empaquetador",
  SHOW: "Mostrar",
  HIDE: "Ocultar",
  CONFIRM_OFFER: "Confirmar Oferta",
  COMPLETE_OFFER: "Completar Oferta",
  CLOSE_OFFER: "Cerrar Oferta",
  CANCEL_ORDER: "Cancelar Pedido",
  CANCEL_OFFER: "Cancelar Oferta",
  CONFIRM_QUICK: "Confirmar Rápidamente",
  CREATE_PERMISSION_GROUP: "Crear Grupo de Permisos"
};

var cargoEs = {
  SEARCH_ORDER: "Buscar Pedido",
  UPDATE_ORDER_STATUS: "Actualizar Estado del Pedido",
  ORDER_COUNT: "Cantidad de Pedidos",
  CARGO_INFO1: "Para actualizar los estados de los pedidos en el canal seleccionado, debe seleccionar al menos un pedido.",
  ORDER_NU: "N.º de Pedido",
  PREPARE_IN_CARGO: "Pedidos en Carga",
  OFFER_NU: "N.º de Oferta",
  USERNAME: "Nombre de Usuario",
  CUSTOMER_NAME: "Nombre del Cliente",
  ADD_NEW_CUSTOMER: "Añadir Nuevo Cliente",
  CARGO_PROCESSES: "Procesos de Carga",
  ACTIONS: "Acciones",
  ACTION: "Acción",
  REMOVE_THE_TABLE: "Eliminar de la Tabla",
  ERP_NUMBER: "N.º de ERP",
  SHIP_IT: "Enviar",
  CREATED_DATE: "Fecha de Creación",
  AMOUNT: "Cantidad",
  CREATOR: "Creador",
  PRINT_CARGO_BARCODE: "Imprimir Código de Barras de Carga",
  CREATE_CARGO_PDF: "Crear PDF de Carga",
  SELECT: "Seleccionar",
  PRINT_CARGO_REPORT: "Imprimir Registro de Carga",
  SELECT_DATE_GIVE_CARGO_FIRM: "Fecha de Envío",
  CARGO_STATUS: "Estado de la Carga",
  CARGO_MESSAGE: "Mensaje de Carga",
  CARGO_INFO: "Sin Información de Carga",
  ORDER_ID: "ID de Pedido",
  SELECT_CARGO_FIRM: "Seleccionar Empresa de Carga",
  CARGO_FIRM: "Empresa de Carga",
  CARGO_PRICE: "Precio de Carga",
  SERVICE_PRICE: "Precio del Servicio",
  DELIVER: "Entregar",
  DELIVER_TO_SELECTED: "Entregar a los Seleccionados",
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "No hay Pedidos en Proceso de Empaque",
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "Puede crear un nuevo pedido desde la sección de Movimiento de Stock",
  QUALITY_CONTROL_INFO_TITLE: "Control de Calidad",
  QUALITY_CONTROL_INFO_BODY: "Ingrese el código de barras del pedido que desea verificar",
  INVOICES_INFO_TITLE: "Sección de Búsqueda de Pedidos",
  INVOICES_INFO_BODY: "Puede continuar su proceso con el N.º de Pedido",
  CHANGE_SHELF_INFO_TITLE: "Sección de Cambio de Estante",
  CHANGE_SHELF_INFO_BODY: "Puede continuar seleccionando en qué almacén operará",
  PRINT_UNIQUE_CARGO_BARCODE: "Imprimir Código de Barras Único de Carga",
  PRODUCT_ERP_CODE: "Código ERP",
  START_ADD_PRODUCT_WITH_CAMERA: "Añadir Producto con Cámara",
  SEARCH_PRODUCT_WITH_CAMERA: "Buscar Producto con Cámara",
  SEARCH_PRODUCT_WITH_BARCODE: "Buscar Producto con Código de Barras",
  NOT_EXIST_CONTROL_OF_STEP: "Aún no existe control de este paso"
};

var customersEs = {
  CUSTOMER: "Cliente",
  CREATE_CUSTOMER: "Crear Cliente",
  CUSTOMER_CODE: "Código de Cliente",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Nombre y Apellido / Etiqueta de Empresa",
  EMAIL: "Correo Electrónico",
  PHONE_NUMBER: "Teléfono",
  INVOICE_ADDRESS: "Dirección de Facturación",
  ADDRESS_TYPE: "Tipo de Dirección",
  PERSONAL: "Personal",
  CORPORATE: "Corporativa",
  IDENTITY_NU: "N.º de Identidad",
  TAX_NU: "N.º de Impuesto",
  NO_MISSING_PRODUCTS_TITLE: "No hay Productos Faltantes",
  NO_MISSING_PRODUCTS_BODY: "Actualmente no hay productos faltantes. Puede añadir productos desde la sección de Añadir Producto.",
  TAX_DEP: "Departamento de Impuestos",
  TRADE_REGISTRATION_ID: "ID de Registro Comercial",
  COMPANY_LABEL: "Etiqueta de Empresa",
  NAME_SURNAME: "Nombre y Apellido",
  GSM_NU: "Teléfono Móvil",
  ADDRESS: "Dirección",
  CITY: "Ciudad",
  TOWN: "Municipio",
  NEIGHBOURHOOD: "Barrio",
  ZIP_CODE: "Código Postal",
  SAME_ADDRESS_INFO: "Mi dirección de entrega es la misma que la dirección de facturación",
  SAVE: "Guardar",
  SAVE_RECOMMENDED_LOCATIONS: "Añadir Ubicaciones Recomendadas",
  DELIVERY_ADDRESS: "Dirección de Entrega",
  CUSTOMERS: "Clientes",
  CLEAR: "Limpiar",
  CLEAR_UPPERCASE: "LIMPIAR",
  SEARCH: "Buscar",
  CUSTOMER_NAME: "Nombre del Cliente",
  PRINT: "Imprimir",
  ACTIONS: "Acciones",
  CONTACT: "Contacto",
  FOOTER_MESSAGE: "Mensaje de Pie de Página",
  SHOW_MISSING_PRODUCTS: "Mostrar Productos Faltantes",
  EDIT: "Editar",
  DELETE: "Eliminar",
  CUSTOMER_INFO1: "Usado para buscar, añadir y editar cuentas de clientes",
  CUSTOMER_INFO2: "Busca por código de cliente, nombre de usuario, nombre del cliente",
  CUSTOMER_EMPTY_SEARCH_MSG: "No se ha encontrado información del cliente para la búsqueda.",
  NO_RECORD_INFO: "No hay registros.",
  RETURN_TRANSACTION: "Crear Devolución",
  CANCEL_TRANSACTION: "Crear Reembolso",
  MISSING_QUANTITY_REPORT: "Informar Cantidad Faltante",
  SAME_QUANTITY: "Misma Cantidad",
  OFFER_INFO_TITLE: "Crear Oferta",
  OFFER_INFO_BODY: "Puede crear su oferta con la información ingresada arriba"
};

var dashboardIndexEs = {
  TOTAL_ORDERS: "Total de Pedidos",
  TOTAL_ORDER_QTY: "Cantidad Total de Pedidos",
  PREPARATION_STEP: "Paso de Preparación",
  CONTROL_STEP: "Paso de Control",
  CARGO_PROCESSES: "Procesos de Carga",
  ORDER_ITEM_QUANTITY: "Cantidad de Artículos del Pedido",
  GENERAL_VIEW: "Vista General",
  STOCK_TURNOVER_RATE: "Rotación de Stock",
  TURNOVER_RATE: "Tasa de Rotación",
  MOST_ACTIVE_PRODUCTS: "Productos Más Movidos",
  DATA_NOT_READY_YET: "Los datos de este día aún no están listos",
  NOT_ENOUGH_DATA_FOR_REPORT: "No tienes suficientes datos para el informe",
  THE_MOST_MOVING_PRODUCTS: "Productos Más Movidos",
  REPORT_CREATE_TIME: "Hora de Generación del Informe",
  REPORT_BEING_PREPARED: "Tu informe está siendo preparado. Se mostrará aquí cuando esté listo",
  CLICK_GENERATE_REPORT_NOW: "Haz clic para generar el informe ahora",
  HIGHEST: "Mayor",
  LOWEST: "Menor",
  DAILY: "Diario",
  DAILY_REVENUE: "Ingresos Diarios",
  MONTH: "Mes",
  WEEK: "Semana",
  DAY: "Día",
  YEAR: "Año",
  NOW_YEAR: "Este Año",
  ALL_TIME: "Todo el Tiempo",
  BRANCH_REVENUE: "Ingresos por Sucursal",
  DATE: "Fecha",
  BRANCH: "Sucursal",
  PAYMENT_TYPE: "Tipo de Pago",
  PREPARED_PRODUCT_COUNT: "Cantidad de Productos Preparados",
  PREPARED_PRODUCT_QTY: "Cantidad de Productos Preparados",
  SERVICE: "Servicio",
  STATUS: "Estado",
  ELASTIC_SEARCH_ENGINE: "Motor de Búsqueda Elástico",
  ACTIVE: "Activo",
  COMPLETE: "Completado",
  PASSIVE: "Pasivo",
  RECORD_COUNT: "número de registros",
  WHOLESALES: "Ventas al por Mayor",
  SELECT_MONTH: "Seleccionar Mes",
  JAN: "Enero",
  FEB: "Febrero",
  MARCH: "Marzo",
  APR: "Abril",
  MAY: "Mayo",
  JUN: "Junio",
  JULY: "Julio",
  AUGUST: "Agosto",
  SEPTEMBER: "Septiembre",
  OCTOBER: "Octubre",
  NOVEMBER: "Noviembre",
  DECEMBER: "Diciembre",
  TOTAL_AMOUNT: "Cantidad Total",
  SEARCH_ENGINE: "Motor de Búsqueda",
};

var integrationPageEs = {
  SIPARISLER: "Pedidos",
  INTEGRATION_FILTER_1: "Fecha de Inicio de la Tarea",
  SAAT_SECINIZ: "Seleccionar Hora",
  INTEGRATION_FILTER_2: "Fecha de Finalización de la Tarea",
  SELECT_CHANNEL: "Seleccionar Canal",
  ORDER_UPDATED_START_DATE_ENTER: "Ingresar Fecha de Inicio de Actualización",
  ORDER_UPDATED_END_DATE_ENTER: "Ingresar Fecha de Fin de Actualización",
  SELECT_ORDER_STATUS: "Seleccionar Estado del Pedido",
  SALES_CHANNEL: "Canal de Ventas",
  USER: "Usuario",
  ORDER_START_DATE: "Fecha de Inicio",
  ORDER_END_DATE: "Fecha de Finalización",
  TRANSFERRED_ORDER_COUNT: "Cantidad de Pedidos Transferidos",
  ORDER_STATUSSES: "Estados del Pedido",
  WORKING_TIME: "Horario de Trabajo",
  WORKING_ELAPSED_TIME: "Tiempo Transcurrido de la Operación",
  KILL_PROCESS: "Terminar Proceso",
  STOCK: "Stock",
  SELECT_DATE: "Seleccionar Fecha",
  SELECT_OPERATOR: "Seleccionar Operador",
  PREV_SELECT_DATE: "Antes de la Fecha Seleccionada",
  PREV: "Anterior",
  AFTER: "Siguiente",
  NEXT_SELECT_DATE: "Después de la Fecha Seleccionada",
  SELECT_CURRENT_DATE: "Fecha Seleccionada",
  SELECT_DATE_FIELD_PROPERTIES: "Propiedad de la Fecha",
  BY_CREATED_DATE: "Por Fecha de Creación",
  BY_UPDATE_DATE: "Por Fecha de Actualización",
  BY_STOCK_UPDATE_DATE: "Por Fecha de Actualización del Stock",
  BY_PRICE_UPDATE_DATE: "Por Fecha de Actualización de Precio",
  STOCK_START_DATE: "Fecha de Inicio",
  STOCK_END_DATE: "Fecha de Fin",
  OPERATOR: "Operador",
  DATE_PROPERTIES_FIELD: "Tipo de Fecha",
  SESSION_ID: "ID de Sesión",
  TRANSFERRED_PRODUCT_COUNT: "Cantidad de Stock Transferido",
  UPDATE_CURRENCY: "Actualizar Moneda",
  CURRENCY: "Moneda",
  EXCHANGE_RATE: "Tasa de Cambio",
  CURRENCY_DECIMAL: "Decimal de la Moneda",
  INTEGRATION_INFORMATION: "Información de Integración",
  PROVIDER_PRODUCT_ID: "ID de Integración",
  TRANSFER_MESSAGE: "Resultado de la Transferencia",
  PREPARER: "Preparador",
  MARKET_PLACE: "Marketplace",
  LAST_STATUS: "Último Estado",
};

var batchOperationsEs = {
  IMPORT_TRANSACTION: "Importar Movimientos de Stock",
  IMPORT_PRODUCT_SHELF_RELATION: "Importar Relación Producto-Estante",
  IMPORT_SHELF: "Importar Estante",
  UPLOAD: "Cargar",
  BROWSE: "Examinar",
  SELECTED_FILE: "Archivo Seleccionado",
  PLEASE_WAIT_FOR_PROCESS: "Por favor espere... Se está procesando...",
  DOWNLOAD_IMPORT_REPORT: "Haga clic en el nombre del archivo para descargar los informes de importación.",
  DOWNLOAD_PREVIEW_REPORT: "Por favor haga clic en el nombre del archivo para ver el análisis del archivo cargado.",
  IMPORT_SHELF_INFO1: "En esta sección, se pueden definir sububicaciones en el sistema con excel.",
  CLICK: "Clic",
  IMPORT_SHELF_INFO2: "En esta sección, se pueden definir las relaciones entre sububicaciones y stocks en el sistema con excel.",
  IMPORT_SHELF_INFO3: "En esta sección, se crea una lista de procesos utilizando excel.",
  DOWNLOAD_SAMPLE_EXCEL_FILE: "Descargar Archivo de Excel de Muestra",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT: "transacción creada. Haga clic en el nombre del archivo para descargar los informes de importación.",
  SAMPLE_DATA_SET: "Conjunto de Datos de Muestra",
  DATA_PATTERN: "Patrón de Datos",
};

var invoicesEs = {
  ORDERS: "Pedidos",
  CHANNEL_ORDERS: "Pedidos de Canal",
  STATISTICS: "Estadísticas",
  CHOOSE_USER: "Seleccionar Usuario",
  SHOW_STATISTIC: "Ver Estadística",
  TRANSFER_EXCEL_BUTTON_NAME: "Exportar pedidos con stock faltante a excel",
  TRANSFER_EXCEL: "Transferir a Excel",
  ORDER_STATUS_HISTORY: "Historial de Estado de Pedido",
  NOT_TRANSFERRED_ERP: "No Transferido al ERP",
  MANUAL_TRANSFER_ERP: "Transferencia Manual al ERP",
  BALANCE_PRODUCT_ORDERS: "Pedidos con Stock Faltante",
  SELECT_STATUSSES_ORDER: "Seleccionar Estados del Pedido",
  SEARCH_STATUS: "Buscar Estado",
  REMOVE_STATUS: "Eliminar",
  SELECT_ALL: "Seleccionar Todo",
  TAKE_ALL: "Tomar Todo",
  WAS_SELECT_STATUS: "Estado Seleccionado",
  ALL: "Todo",
  TRANSFER_TO_ERP: "Transferir al ERP",
  ACCOUNTING: "Contabilidad",
  ACCOUNTING_TRANSFERS: "Transferencias Contables",
  SHIPMENT_TRANSFERS: "Transferencias de Envíos",
  TAKE_TO_ACCOUNTING: "Enviar a Contabilidad",
  ACTION: "Acción",
  ORDER_LINE_COUNT: "Cantidad de Líneas del Pedido",
  TRANSFER: "Transferir",
  ORDER_STATUS: "Estado del Pedido",
  ORDER_DATE: "Fecha del Pedido",
  GENERAL_TOTAL: "Total General",
  EMAIL_NATIVE: "Correo Electrónico",
  ORDER_TYPE: "Tipo de Pedido",
  BALANCE_PRODUCT_NOTIFY: "Notificación de Stock Faltante",
  CREATE_STOCK_ACTION: "Crear Movimiento de Stock",
  ORDER_TRANSFERED_ERP: "El pedido ha sido transferido al ERP.",
  ORDER_NOT_TRANSFERED_ERP: "El pedido no pudo ser transferido al ERP.",
  CONNECTION_LOST: "No se pudo conectar al servidor",
  DOWNLOADING: "Descargando",
  INVOICES_INFO: "Puede consultar y gestionar sus pedidos transferidos con el formulario anterior. Por favor, amplíe sus criterios de búsqueda.",
};

var langEs = angular.merge(
  MenuEs,
  accountSettingsEs,
  cargoEs,
  customersEs,
  dashboardIndexEs,
  integrationPageEs,
  batchOperationsEs,
  invoicesEs/*,
  locationEs,
  loginEs,
  batchOperationsForTsoftEs,
  actionEs,
  notificationEs,
  ordersPrepareEs,
  reportsEs,
  commonEs,
  paginationEs,
  offerTypeEs,*/
);
export {langEs};
