import "./invoices.scss";

class EditController{
    constructor(API, ToastService, uiGridConstants, $timeout, i18nService, $auth, $state, $stateParams, $log){
        'ngInject';

        this.API = API;
        this.ToastService = ToastService;
        this.uiGridConstants = uiGridConstants;
        this.$timeout = $timeout;
        this.i18nService = i18nService;
        this.state = $state;
        this.$auth = $auth;
        this.$stateParams = $stateParams;
        this.$log = $log;
    }

    $onInit(){
        this.sipNo = this.$stateParams.sipNo;
        this.getCustomerData(this.sipNo);

    }

    getCustomerData (sipNo) {
        this.API.all('order/detail').post({
                                sip_no: sipNo
                            }).then((response) => {
                                response = response.data;

                                this.ts_delivery_name = response.orderDetail.ts_delivery_name;
                                this.ts_delivery_mobile = response.orderDetail.ts_delivery_mobile;
                                this.ts_delivery_address = response.orderDetail.ts_delivery_address;
                                this.ts_delivery_city = response.orderDetail.ts_delivery_city;
                                this.ts_delivery_town = response.orderDetail.ts_delivery_town;
                                this.ts_delivery_neighbourhood = response.orderDetail.ts_delivery_neighbourhood;

                                this.ts_invoice_name = response.orderDetail.ts_invoice_name;
                                this.ts_invoice_mobile = response.orderDetail.ts_invoice_mobile;
                                this.ts_invoice_address = response.orderDetail.ts_invoice_address;
                                this.ts_invoice_city = response.orderDetail.ts_invoice_city;
                                this.ts_invoice_town = response.orderDetail.ts_invoice_town;
                                this.ts_invoice_neighbourhood = response.orderDetail.ts_invoice_neighbourhood;

                                this.cargo_price = response.orderDetail.cargo_price;
                                this.cargo_firm = response.orderDetail.cargo_firm;

                                this.ts_invoice_company = response.orderDetail.ts_invoice_company;


                                this.products = response.orderDetail.products;

                                this.updated_addresses = response.orderDetail.updated_addresses;

                            });
    }

    blink () {
        this.colorVal = '';
        this.colorVal = 'orangeBg';
        var parent = this;

        this.$timeout(function(){
            parent.colorVal = 'whiteBg';
        }, 500);
    }

    updateAddress(){
        let updateData = {
            company_name: this.ts_invoice_company,
            delivery_name: this.ts_delivery_name,
            invoice_name: this.ts_invoice_name,
            delivery_address: this.ts_delivery_address,
            delivery_neighbourhood: this.ts_delivery_neighbourhood,
            delivery_town:this.ts_delivery_town,
            delivery_city:this.ts_delivery_city,
            invoice_address:this.ts_invoice_address,
            invoice_neighbourhood:this.ts_invoice_neighbourhood,
            invoice_town: this.ts_invoice_town,
            invoice_city: this.ts_invoice_city,
            sip_no: this.sipNo,
        };


        this.API
        .all('order/saveAddress')
        .post(updateData).then((response) => {
            this.updated_addresses = response;
            alert("Adres eklendi ve aktif hale getirildi. Sipariş aktarım için hazır.");
        });

    }

    activateAddress(addressId) {
        let activateAddressData = {
            sip_no : this.sipNo,
            address_id : addressId
        };

        this.API
        .all('order/activateAddress')
        .post(activateAddressData).then((response) => {

            this.updated_addresses = response;
        });
    }
}
EditController.$inject = ["API", "ToastService", "uiGridConstants", "$timeout", "i18nService", "$auth", "$state", "$stateParams", "$log"];
export const EditComponent = {
    templateUrl: './views/angular/app/components/invoices/partials/edit.component.html',
    controller: EditController,
    controllerAs: 'vm',
    bindings: {}
}
