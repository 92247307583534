import "./orders-prepare.scss";
import {introductionVideos, openIntroductionVideo} from "../../../utils/introduction-videos";
import {ToastService} from "../../../services/toast.service";
import moment from "moment";

class MultipleQuantityController{
    constructor(
        DialogService,
        ToastService,
        $translate,
        $window
    ) {
        "ngInject";
        this.DialogService = DialogService;
        this.ToastService = ToastService
        this.$translate = $translate
        this.window = $window

        this.remainingQuantity = null;
        this.productBarcode = null;
        this.terminalEvent = null;
        this.shelfCodeDisable=true;
        this.showShelf = false
        this.beepSound = new Audio('./files/success.wav');
    }

    $onInit(){
        this.readBarcode()
      //  this.stocktakingShelfCode = this.actionPrepareProductListSelect.shelves[0].barcode;
    }

    quantityPlus(){
        if(this.productQuantity < this.remainingQuantity - 1){
            this.productQuantity += 1;
        }else if(this.productQuantity === this.remainingQuantity - 1){
            this.productQuantity += 1;
            this.DialogService.hide({productQuantity: this.productQuantity,stocktakingShelfCode: this.stocktakingShelfCode});
        }else{
            this.ToastService.error(this.$translate.instant('CANNOT_ADD_MORE_PRODUCTS'))
        }
    }

    readBarcode(){
        return 1;
        let parent = this;
        let barcode = '';
        let interval;
        this.terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.key == 'Enter' && barcode) {
                parent.barcodeCheck(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown',this.terminalEvent  ,true)
    }

    barcodeCheck(barcode){
        let parent = this;
        let success = false;
        if(parent.productBarcode === barcode){
                parent.quantityPlus()
                success = true
        }else{
            this.actionPrepareProductListSelect.shelves.map(shelf => {
                if(shelf.name === barcode){
                    this.stocktakingShelfCode = barcode
                    success = true
                }
            })
            if(!success){
                parent.stocktakingShelfCode = null
            }
        }
    }

    accept(){
        if (this.productQuantity > this.actionPrepareProductListSelect.remainingQuantity) {
            this.ToastService.error(this.$translate.instant('ENOUGH_PRODUCTS', { quantity: this.productQuantity - this.actionPrepareProductListSelect.remainingQuantity }))
            return;
        }
        this.DialogService.hide({productQuantity: this.productQuantity, stocktakingShelfCode: this.stocktakingShelfCode});
    }

    acceptAll(){
        this.DialogService.hide({productQuantity: this.remainingQuantity, stocktakingShelfCode: this.stocktakingShelfCode});
    }

    cancelDialog(){
        this.DialogService.hide({message:this.$translate.instant('PRODUCT_APPROVAL_CANCELED')});
    }

    shelfCodeDisabled(){
        if(this.shelfCodeDisable){
            this.shelfCodeDisable = false;
        }
      //  this.actionPrepareProductListSelect.changePrepareDefault = !this.actionPrepareProductListSelect.changePrepareDefault;
        this.actionPrepareProductListSelect.shelves.map((shelf, index) => {
            if (0 === index) return;
            // else if (!shelf.show)  shelf.show = this.actionPrepareProductListSelect.changePrepareDefault
        this.showShelf = !this.showShelf
        })
        if (this.actionPrepareProductListSelect.changePrepareDefault){
            this.stocktakingShelfCode = null
            if (this.actionPrepareProductListSelect.stockType !== 'preStock'){
                this.productBarcode       = null
            }
        }  if (!this.actionPrepareProductListSelect.changePrepareDefault) {
            if (this.actionPrepareProductListSelect.stockType === 'reservedStock'){
                this.stocktakingShelfCode = this.actionPrepareProductListSelect.shelves[0].barcode
            }
            if (this.actionPrepareProductListSelect.stockType === 'preStock'){
                this.productBarcode = this.actionPrepareProductListSelect['barcode']
            }
            this.productQuantity      = this.actionPrepareProductListSelect['remainingQuantity']
            this.stockType            = this.actionPrepareProductListSelect['stockType']
        }
    }

}


MultipleQuantityController.$inject = ["DialogService","ToastService", "$translate", "$window"];

class ProductDialogController{
    constructor(DialogService, ToastService, $translate, $window) {
        "ngInject";

        this.DialogService = DialogService
        this.ToastService = ToastService
        this.$translate = $translate
        this.window = $window
        this.stocktakingShelfCode = null;
        this.remainingQuantity = null;
        this.terminalEvent = null
        this.monthFormat = this.buildLocaleProvider("MM-YYYY")
        this.beepSound = new Audio('./files/success.wav');;

    }


    $onInit(){
        this.readBarcode()
    }

    shelfFill(shelfName){
        this.stocktakingShelfCode = shelfName
    }

    quantityPlus(){
        if(this.productQuantity < this.remainingQuantity - 1){
            this.productQuantity += 1;
        }else if(this.productQuantity === this.remainingQuantity - 1){
            this.productQuantity += 1;
            this.acceptAll()
        }else{
            this.ToastService.error(this.$translate.instant('CANNOT_ADD_MORE_PRODUCTS'))
        }
    }

    readBarcode(){
        return 1;
        let parent = this;
        let barcode = '';
        let interval;
        this.terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.key == 'Enter' && barcode) {
                parent.barcodeCheck(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown',this.terminalEvent  ,true)
    }

    barcodeCheck(barcode){
        let parent = this;
        if(parent.actionPrepareProductListSelect.barcode === barcode){
            if(!parent.stocktakingShelfCode){
                parent.ToastService.error(this.$translate.instant('MUST_ENTER_SHELF_CODE'))
                return;

            }else{
                parent.quantityPlus()
            }

        }else if(parent.actionPrepareProductListSelect.shelves.includes(barcode)){
            parent.stocktakingShelfCode = barcode
        }else{
            parent.stocktakingShelfCode = null
        }
    }

    accept(){
        if(!this.stocktakingShelfCode){
            this.ToastService.error(this.$translate.instant('MUST_ENTER_SHELF_CODE'))
            return;
        }
        this.DialogService.hide({productQuantity: this.productQuantity, stocktakingShelfCode: this.stocktakingShelfCode,serialNumber: this.serialNumber,expireDate: this.expireDate});
    }
    acceptAll(){
        if(!this.stocktakingShelfCode){
            this.ToastService.error(this.$translate.instant('MUST_ENTER_SHELF_CODE'))
            return;
        }
        this.DialogService.hide({productQuantity: this.remainingQuantity, stocktakingShelfCode: this.stocktakingShelfCode,serialNumber: this.serialNumber,expireDate: this.expireDate});
    }

    cancelDialog(){
        this.DialogService.hide({
            message: this.$translate.instant('RECEIVING_PROCESS_STOPPED')
        })
    }

    quantityRemove(){
        if(this.productQuantity > 1){
            this.productQuantity -= 1;
        }
    }
    buildLocaleProvider(formatString) {
        return {
            formatDate: function (date) {
                if (date) return moment(date).format(formatString);
                else return null;
            },
            parseDate: function (dateString) {
                if (dateString) {
                    var m = moment(dateString, formatString, true);
                    return m.isValid() ? m.toDate() : new Date(NaN);
                }
                else return null;
            }
        };
    }
}
ProductDialogController.$inject = ["DialogService", "ToastService", "$translate", "$window"]

class PreparePackageMultipleController{
    constructor(
        DialogService,
        ToastService,
        $translate,
        $window
    ){
        "ngInject";
        this.DialogService = DialogService
        this.ToastService = ToastService
        this.$translate = $translate
        this.window = $window
        this.productQuantity = 1
        this.remainingQuantity = null
        this.productBarcode = null
        this.beepSound = new Audio('./files/success.wav');
    }

    $onInit(){
        this.readBarcode()
    }

    readBarcode(){
        return true;
        let parent = this;
        let barcode = '';
        let interval;
        this.terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.key == 'Enter' && barcode) {
                parent.checkBarcode(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown',this.terminalEvent  ,true)
    }

    checkBarcode(barcode){
        if(this.productBarcode === barcode){
            this.quantityPlus()
        }else{

        }
    }

    quantityPlus(){
        if(this.productQuantity < this.remainingQuantity - 1){
            this.productQuantity += 1;
        }else if(this.productQuantity === this.remainingQuantity - 1){
            this.productQuantity += 1;
            this.DialogService.hide({productQuantity: this.productQuantity});
        }else{
            this.ToastService.error(this.$translate.instant('CANNOT_ADD_MORE_PRODUCTS'))
            this.DialogService.hide({productQuantity: this.productQuantity});
        }
    }

    accept(){
        this.DialogService.hide({productQuantity: this.productQuantity})
    }

    acceptAll(){
        this.DialogService.hide({productQuantity: this.remainingQuantity})
    }

    cancelDialog(){
        this.DialogService.hide({message: this.$translate.instant('PACKAGING_PROCESS_STOPPED')})
    }

}

PreparePackageMultipleController.$inject = ["DialogService", "ToastService", "$translate","$window"]

export {MultipleQuantityController, ProductDialogController, PreparePackageMultipleController}
