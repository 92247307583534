class BatchOperationsForTsoftByexcelController{
    constructor(FileUploader, $window, ToastService, API){
        'ngInject';
        this.$window = $window;
        this.fileUploader = FileUploader;
        this.ToastService = ToastService;
        this.fileUploaderDisabled = true;
        this.API = API;

        this.uploader = new FileUploader({
            url: $window.__env.apiUrl+'/api/batch-operations/product-filter-excel-for-tsoft-upload',
            headers: {
                Authorization: 'Bearer ' + this.$window.localStorage.satellizer_token
            },
            formData: [
                { "mode": parent.mode, "channel_id": parent.channelId }
            ]
        });
    }

    $onInit() {
        this.API.all('settings/get-channels').post().then((response) => {
            this.channels = response.data.channels;
        });
    }

    createFileUploader() {
        if((this.mode==1 || this.mode==2) && this.channelId!=undefined) {
            this.fileUploaderDisabled = false;
        } else{
            this.fileUploaderDisabled = true;
        }
        var parent = this;
        this.uploader.destroy();
        this.uploader.formData =  [{ "mode": this.mode, "channel_id": this.channelId }];
        this.loading = false;
        this.result = false;

        this.uploader.onErrorItem = function(fileItem, response, status, headers) {
            parent.ToastService.error(response.errors.message[0]);
        };

        this.uploader.onAfterAddingAll = function(item) {
            parent.addedFileName = item[0].file.name;
        };

        this.uploader.onBeforeUploadItem = function() {
            parent.loading = true;
        }

        this.uploader.onCompleteItem = function(item, response, status, headers) {
            parent.loading = false;
            //console.info('onCompleteAll', response);
            if(response.data!==undefined) {
                parent.exportReportFilename = response.data.exportReportFilename;

                angular.forEach(response.data.exportReportFilename, function (fileName, key) {
                    window.open(window.__env.apiUrl+"/api/action/exportExcel/"+fileName+"?token="+window.localStorage.satellizer_token, '_blank');
                });

                parent.result = true;
                parent.mode = response.data.mode;
            }

            parent.uploader.destroy();
        };
    }

    selectTab(tab) {
        if(tab == "pf") {
            var parent = this;
            this.loading = false;
            this.result = false;

            this.uploader.onErrorItem = function(fileItem, response, status, headers) {
                parent.ToastService.error(response.errors.message[0]);
            };

            this.uploader.onAfterAddingAll = function(item) {
                parent.addedFileName = item[0].file.name;
            };

            this.uploader.onBeforeUploadItem = function() {
                parent.loading = true;
            }

            this.uploader.onCompleteItem = function(item, response, status, headers) {
                parent.loading = false;
                //console.info('onCompleteAll', response);
                if(response.data!==undefined) {
                    parent.exportReportFilename = response.data.exportReportFilename;

                    angular.forEach(response.data.exportReportFilename, function (fileName, key) {
                        window.open(window.__env.apiUrl+"/api/action/exportExcel/"+fileName+"?token="+window.localStorage.satellizer_token, '_blank');
                    });

                    parent.result = true;
                    parent.mode = response.data.mode;
                }

                parent.uploader.destroy();
            };
        }
    }
}
BatchOperationsForTsoftByexcelController.$inject = ["FileUploader", "$window", "ToastService", "API"];
export const BatchOperationsForTsoftByexcelComponent = {
    templateUrl: './views/angular/app/components/batch-operations-for-tsoft-byexcel/batch-operations-for-tsoft-byexcel.component.html',
    controller: BatchOperationsForTsoftByexcelController,
    controllerAs: 'vm',
    bindings: {}
};
