import { openIntroductionVideo } from "../../../utils/introduction-videos";

class CargoController {
  constructor(
    API,
    $auth,
    ToastService,
    $state,
    $timeout,
    $translate,
    $window,
    $location,
    DialogService,
    $scope,
    $mdBottomSheet
  ) {
    "ngInject";

    this.API = API;
    this.$auth = $auth;
    this.ToastService = ToastService;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$translate = $translate;
    this.window = $window;
    this.disabled = false;
    this.$location = $location;
    this.video = undefined;
    this.DialogService = DialogService;
    this.$scope = $scope;
    this.$mdBottomSheet = $mdBottomSheet;

    this.API.all("settings/get-channels")
      .post(null, {})
      .then((response) => {
        this.channels = response.data.channels;
      });

    this.API.all("cargo/get-companies")
      .post(null, {})
      .then((response) => {
        this.cargoCompanies = response.data.cargoCompanies;
      });

    this.inputChangedPromise = "";
  }

  $onInit() {

    let parent = this;
    parent.$scope.$on('prepareChildEvent', function(event, data) {
      document.querySelector('md-toolbar').hidden = false;
      document.querySelector('md-tabs-wrapper').hidden = false;
      if (data.count){
        Object.keys(data.count).map(c =>{
          parent[c + 'Count'] = data.count[c]

        })
      }
    });

    this.disabled = false;
    this.label_data = { id: 0, functions: {} };
    this.label_func = {};
    this.incLabelNum = 0;
    this.infoMessageForQuickPrinter = false;
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;


    this.API.all("user/get-printer-settings")
      .post(null, {})
      .then((response) => {
        if (response?.data?.printerSettings){
          parent.printer_name = response.data.printerSettings.printer_logical_name;
          parent.printer_ip_address = response.data.printerSettings.ip_address;
          parent.printer_conn_protocol = response.data.printerSettings.conn_protocol;
          parent.printer_port = response.data.printerSettings.port;
        }
      });
  }
  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 1) {
      this.video = 'cargoBarcode';
    }
    if (obj.tab == 2) {
      this.video = 'cargoReport';
    }
  }
  quickPrintCargoBarcode(orderNu, channelId) {
    if (orderNu) {
      if (this.inputChangedPromise) {
        this.$timeout.cancel(this.inputChangedPromise);
      }
      this.printCompletedCargoOrder(orderNu, channelId);
    }
  }

  printCompletedCargoOrder(orderNu, channelId) {
    var filter = {
      sip_no: orderNu,
      channel_id: channelId,
      completed_cargo: true, // suanda deaktif müşterinin istegine göre kargo entegrasyonu tamamlanan siparisleri basacagız
      per_page: 10,
    };
    var parent = this;

    this.API.all("order/detail")
      .post(filter)
      .then(function (response) {
        var editedAddress = response.data.orderDetail.ts_delivery_address.replace(
          /\r/g,
          " "
        );
        editedAddress = editedAddress.replace(/\n/g, " ");
        var content =
          "ALICI: " +
          response.data.orderDetail.ts_delivery_name +
          " " +
          editedAddress +
          " " +
          response.data.orderDetail.ts_delivery_town +
          "/" +
          response.data.orderDetail.ts_delivery_city +
          " TEL:" +
          response.data.orderDetail.ts_delivery_mobile;
        parent.printBarcode(orderNu, content, response.data.orderDetail.cargo_firm);
      });
  }

  printBarcode(barCodeData, content, cargo_firm) {
    //barcode_data TS0407529666

    var barCodeSymbol = 1;
    var barCodeHeight = 100;
    var print_HRI = 1;

    var barCodeHri = 1;
    var icerik = content;
    var barWidth = 8;
    var nBarWidth = 4;
    var issueID = 1;

    this.setLabelId(issueID);

    this.checkLabelStatus();
    this.clearBuffer();

    this.setWidth(576);

    this.setCharacterset(0, 12);
    this.drawDeviceFont(icerik, 0, 0, "2", 1, 1, 0, 0, 0, 0);
    this.drawDeviceFont(
      "______________________________________________________________",
      0,
      110,
      "0",
      1,
      1,
      0,
      0,
      0,
      0
    );
    this.drawDeviceFont(cargo_firm, 100, 150, "2", 2, 2, 0, 0, 1, 0);
    /*drawTrueTypeFont(icerik, 0, 5, "0", 1, 1, 0, 0, 1, 0);*/
    this.draw1DBarcode(
      barCodeData,
      10,
      225,
      barCodeSymbol,
      nBarWidth,
      barWidth,
      barCodeHeight,
      0,
      1
    );
    this.printBuffer();

    var strSubmit = this.getLabelData();

    issueID++;
    this.requestPrint(this.printer_name, strSubmit);
  }

  selectAllContent($event) {
    $event.target.select();
  }

  findElement(array, id) {
    var returnKey = -1;
    angular.forEach(array, function (value, key) {
      if (id == value.id) {
        returnKey = parseInt(key);
      }
    });

    return returnKey;
  }

  printCargoBarcodesV2() {
      var data = [];
      this.disabled = true;
      data.push(this.firstUrl);
      data.push(this.secondUrl);
      data.push(this.thirdUrl);
      data.push(this.fourthUrl);
      data.push(this.fivethUrl);
      data.push(this.sixthUrl);
      data.push(this.seventhUrl);
      data.push(this.eighthUrl);


      var options = {
        controller        : 'PrintPreviewDialogController',
        controllerAs      : 'vm',
        //  scope             : this.$scope,
        locals            : {
          data: data,
          channelId: this.printCargoChannelId
        },
        bindToController  : true,
        multiple          : true,
        onShowing         : function (scope, element, options, controller) {

          controller.printCargoBarcodes(controller.data, controller.channelId);
        },
      };

      var customerDialogPromise = this.DialogService.fromTemplateV2(
          './views/angular/dialogs/printPreview/printPreview.dialog.html',
          options
      );
      customerDialogPromise.catch(err=>{
        console.log(err)
      });

      this.disabled = false;
  }

  exportCargoRecord() {
    var reqData = {
      cargoDate: this.cargoDate,
      cargoCompany: this.cargoCompany,
    };
    var parent = this;
    this.exportCargoButtonDisabled = true;
    this.API.all("cargo/export-cargo-record")
      .post(reqData)
      .then((response) => {
        if (response.data.fileName !== false) {
          parent.window.open(parent.window.__env.apiUrl+"/api/action/exportExcel/"+response.data.fileName+"?token="+parent.window.localStorage.satellizer_token, '_blank');
          parent.ToastService.success(this.$translate.instant('DOWNLOADING_STOCK_FILE'));
          parent.exportCargoButtonDisabled = false;
        } else {
          parent.ToastService.error(this.$translate.instant('NO_RECORD_FOUND'));
          parent.exportCargoButtonDisabled = false;
        }
      });
  }

  setLabelId(setId) {
    this.label_data.id = setId;
  }

  checkLabelStatus() {
    this._a = { checkLabelStatus: [] };
    this.label_func["func" + this.incLabelNum] = this._a;
    this.incLabelNum++;
  }

  clearBuffer() {
    this._a = { clearBuffer: [] };
    this.label_func["func" + this.incLabelNum] = this._a;
    this.incLabelNum++;
  }

  setWidth(width) {
    this._a = { setWidth: [width] };
    this.label_func["func" + this.incLabelNum] = this._a;
    this.incLabelNum++;
  }

  setCharacterset(ics, charset) {
    this._a = { setCharacterset: [ics, charset] };
    this.label_func["func" + this.incLabelNum] = this._a;
    this.incLabelNum++;
  }

  drawDeviceFont(
    text,
    x,
    y,
    fontType,
    widthEnlarge,
    heightEnlarge,
    rotation,
    invert,
    bold,
    alignment
  ) {
    var _a = {
      drawDeviceFont: [
        text,
        x,
        y,
        fontType,
        widthEnlarge,
        heightEnlarge,
        rotation,
        invert,
        bold,
        alignment,
      ],
    };
    this.label_func["func" + this.incLabelNum] = _a;
    this.incLabelNum++;
  }

  draw1DBarcode(data, x, y, symbol, narrowbar, widebar, height, rotation, hri) {
    var _a = {
      draw1DBarcode: [
        data,
        x,
        y,
        symbol,
        narrowbar,
        widebar,
        height,
        rotation,
        hri,
      ],
    };
    this.label_func["func" + this.incLabelNum] = _a;
    this.incLabelNum++;
  }

  printBuffer() {
    switch (arguments.length) {
      case 0:
        var _a = { printBuffer: [] };
        this.label_func["func" + this.incLabelNum] = _a;
        this.incLabelNum++;
        break;
      case 1:
        var _a = { printBuffer: [arguments[0]] };
        this.label_func["func" + this.incLabelNum] = _a;
        this.incLabelNum++;
        break;
    }
  }

  getLabelData() {
    this.label_data.functions = this.label_func;
    this.label_func = {};
    this.incLabelNum = 0;

    return JSON.stringify(this.label_data);
  }

  getPrinterFullAddres() {
    var fullUri = "";

    if (this.printer_conn_protocol == 1) {
      fullUri = "http://";
    } else {
      fullUri = "ws://";
    }

    fullUri += this.printer_ip_address + ":" + this.printer_port;

    return fullUri;
  }

  checkResult(method, strPrinterName, requestId, responseId, _callback) {
    var serverURL = this.getPrinterFullAddres() + "/WebPrintSDK/";
    var requestURL = serverURL + strPrinterName + "/checkStatus";
    var inquiryData = this.makeResultInquiryData(requestId, responseId, 30);

    var xmlHttpCheck = false;
    if (window.XMLHttpRequest) {
      xmlHttpCheck = new XMLHttpRequest();
    }

    xmlHttpCheck.open(method, requestURL, true);
    xmlHttpCheck.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    xmlHttpCheck.send(inquiryData);
    var parent = this;
    xmlHttpCheck.onreadystatechange = function () {
      if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
        var res = JSON.parse(xmlHttpCheck.responseText);
        var ret = res.Result;

        if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
          parent.checkResult(
            method,
            strPrinterName,
            requestId,
            responseId,
            _callback
          );
        } else {
          if (res.Result == "success") {
            parent.printInfoMsgForPrinter(
              this.$translate.instant('ORDER_REFERENCE_REMOVED', {
                orderNumber: parent.searchOrderNumberForCargoBarcode,
                printerName: strPrinterName
              })
            );
            parent.searchOrderNumberForCargoBarcode = null;
          } else if (res.Result == "error") {
            parent.printInfoMsgForPrinter(
                this.$translate.instant('ERROR_CONNECT_TERMINAL'),
              true
            );
          }
        }
      } else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
        parent.printInfoMsgForPrinter(
        this.$translate.instant('PRINTER_NOT_DEFINED', { printerName: strPrinterName }),
        );
      } else if (xmlHttpCheck.readyState == 4) {
        parent.printInfoMsgForPrinter(this.$translate.instant('ERROR_CONNECT_TERMINAL'));
      }
    };
  }

  printInfoMsgForPrinter(message, error = false) {
    this.infoMessageForQuickPrinter = true;
    this.message = message;

    if (error == true) {
      this.notifyClass = "alert alert-danger";
    } else {
      this.notifyClass = "alert alert-success";
    }
  }

  makeResultInquiryData(requestId, responseId, timeout) {
    return (
      '{"RequestID":' +
      requestId +
      ',"ResponseID":"' +
      responseId +
      '","Timeout":' +
      timeout +
      "}"
    );
  }

  requestPrint(strPrinterName, strSubmit) {
    var serverURL = this.getPrinterFullAddres() + "/WebPrintSDK/";

    var requestURL = serverURL + strPrinterName;
    var xmlHttpReq = false;
    if (window.XMLHttpRequest) {
      xmlHttpReq = new XMLHttpRequest();
    }

    xmlHttpReq.open("POST", requestURL, true);
    xmlHttpReq.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    xmlHttpReq.send(strSubmit);
    var parent = this;
    xmlHttpReq.onreadystatechange = function () {
      if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 200) {
        var res = JSON.parse(xmlHttpReq.responseText);
        var ret = res.Result;

        if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
          if (res.Result == "success") {
            parent.printInfoMsgForPrinter(
              this.$translate.instant('ORDER_REFERENCE_REMOVED', {
                orderNumber: parent.searchOrderNumberForCargoBarcode,
                printerName: strPrinterName
              })
            );
            parent.searchOrderNumberForCargoBarcode = null;
          } else if (res.Result == "error") {
            parent.printInfoMsgForPrinter(
                this.$translate.instant('ERROR_CONNECT_TERMINAL'),
              true
            );
          }
          parent.checkResult(
            "POST",
            strPrinterName,
            res.RequestID,
            res.ResponseID,
            alert
          );
        } else if (ret.search("duplicated") >= 0) {
          parent.printInfoMsgForPrinter(this.$translate.instant('ERROR_CONNECT_TERMINAL'), true);
        }
      } else if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 404) {
        parent.printInfoMsgForPrinter(
            this.$translate.instant('PRINTER_NOT_DEFINED', { printerName: strPrinterName }),
          true
        );
      } else if (xmlHttpReq.readyState == 4) {
        parent.printInfoMsgForPrinter(this.$translate.instant('ERROR_CONNECT_TERMINAL'), true);
      }
    };
  }

  drawTrueTypeFont(
    text,
    x,
    y,
    fontname,
    fontsize,
    rotation,
    italic,
    bold,
    underline,
    compression
  ) {
    var _a = {
      drawTrueTypeFont: [
        text,
        x,
        y,
        fontname,
        fontsize,
        rotation,
        italic,
        bold,
        underline,
        compression,
      ],
    };
    this.label_func["func" + this.incLabelNum] = _a;
    this.incLabelNum++;
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  clearCargoBarcode() {
    this.searchChannelIdForCargoBarcode = null;
    this.searchOrderNumberForCargoBarcode = null;
  }

  clearCargoRecord() {
    this.cargoDate = null;
    this.cargoCompany = null;
  }

  clearPDFForm() {
    this.printCargoChannelId = null;
    this.firstUrl = null;
    this.secondUrl = null;
    this.thirdUrl = null;
    this.fourthUrl = null;
    this.fivethUrl = null;
    this.sixthUrl = null;
    this.seventhUrl = null;
    this.eighthUrl = null;
  }



  shipmentProcessList(approve =null,transactionId= null){
    this.shipmentList = [];
    this.shipmentListCount = 0;

    let query = {};

    if (approve !== null) {
      query['approve'] = approve
    }

    if (transactionId){
      query['transactionId'] = transactionId
    }

    this.API.all("action-process/shipment")
        .doGET(null,query)
        .then((response) => {
          if (response.response.status !== 200) return;
          this.scanText = null
          this.shipmentList = response.data.list.shipment.map(s=>{

            s.show = s.provider === 'polypus';
            s.actions.map(i=>{i.selected = true})

            return s;
          })
          this.shipmentListCount = response.data.list.total
        });
  }


  approveShipment(provider, sendSelected = false) {
    let data = {
      provider: provider,
    };

    if (sendSelected) {
      const selectedActions = this.shipmentList
          .find(s => s.provider === provider)
          ?.actions
          .filter(a => a.selected)
          .map(a => a.actionId)
          .filter(f => f);

      if (selectedActions.length === 0) {
        this.ToastService.showHtml('error', this.$translate.instant('DIDNT_CHOOSE'))
        return;
      }
      data['processIds'] = selectedActions
    }


    this.API.all(`action-process/shipment/_/approve`)
        .customPUT(data)
        .then(response => {
          this.shipmentProcessList(0)
        })
  }


  tryAgain(process, processId) {
    this.API.all(`action-process/${process}/${processId}/try`)
        .customPUT()
        .then(response => {
          if (process ===  'shipment'){
            this.shipmentProcessList()
          }else if (process === 'accounting'){
            this.accountingProcessList()
          }
        })
  }

  shippingProcessList() {
    this.API.all("action-process/shipment")
        .doGET(null, {
          counting: 1
        })
        .then((response) => {
          if (response.response.status !== 200) {
            this.shippingList = [];
            this.$scope.$emit('prepareChildEvent', {count: {shippingCounter: 0}});
            return;
          }
          this.shippingList = response.data.list;
          this.shippingListCount = response.data.count
          this.$scope.$emit('prepareChildEvent', {count: {shippingCounter: response.data.count}});
        });
  }
  showCameraView() {
    var parent = this;
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
      controller: "BottomCameraController",
      controllerAs: "vm",
      bindToController: true,
      locals: {}
    }).then(response => {
      parent.scanText = response;
    })
        .catch(function (error) {
          console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
        });
  }
}
CargoController.$inject = ["API", "$auth", "ToastService", "$state", "$timeout", "$translate", "$window", "$location", "DialogService", "$scope", "$mdBottomSheet"];
export const CargoComponent = {
  templateUrl: "./views/angular/app/components/cargo/cargo.component.html",
  controller: CargoController,
  controllerAs: "vm",
  bindings: {},
};
