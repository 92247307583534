export function CanFilter(){
    'ngInject';

    return function( input ){
    	//var perms = JSON.parse(sessionStorage.getItem('perms'));
    	
    	
        //return perms;
    }
}
