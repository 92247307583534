import "./pagination.scss";
class PaginationController {
  parentController;
  parentFunction;
  parentTotal;
  parentLastPage;
  parentPerPage;
  parentCache;
  parentCacheDate;
  parentTotalCount;
  parentPeriodTotalCount;
  parentCacheUpdateFunction;

  constructor($window, $state) {
    'ngInject';
    this.$window = $window;
    this.state = $state;
    this.pagesArray = [
      {"pageNumber":1},
      {"pageNumber":2},
      {"pageNumber":3}
    ];
  }

  $onInit() {
    this.year = new Date().getUTCFullYear();
    this.totalCountInfo = 'normal'
    if (this.parentCache) this.totalCountInfo = 'cache'
    if (this.parentCache && !this.parentCacheDate) this.totalCountInfo = 'waiting'
  }

  getPaginationAction(pageNumber, filterArr, per_page) {
    this.parentController[this.parentFunction](pageNumber, filterArr, per_page);
    this.parentCurrentPage = pageNumber;
    let start = this.parentCurrentPage - 1;
    if (start < 1) {
      start = 1
    }
    let end =  this.parentCurrentPage+3; //this.parentLastPage;
    let pagesArray = [];
    for (start; start < end+1 ; start++) {
      pagesArray.push({"pageNumber":start})
    }
    this.pagesArray = pagesArray;
    if ('waiting' === this.totalCountInfo && this.parentCache) this.totalCountInfo = 'cache'
  }

  refreshTotalInfo() {
    this.parentController[this.parentCacheUpdateFunction]();
    this.totalCountInfo = 'waiting'
  }

}

PaginationController.$inject = ["$window", "$state"]
export const PaginationComponent = {
  templateUrl: './views/angular/app/components/pagination/pagination.component.html',
  controller: PaginationController,
  controllerAs: 'pg',
  bindings: {
    parentTotal:"<",
    parentLastPage:"<",

    parentPerPage:"<",
    parentCurrentPage:"<",
    parentFilterArr:"<",
    parentPrevIndex:"<",
    parentNextIndex:"<",
    parentPagesArray:"<",

    parentController: "<",
    parentFunction: "@",
    parentCache:"<",
    parentCacheDate:"<",
    parentTotalCount:"=",
    parentPeriodTotalCount:"<",
    parentCacheUpdateFunction:"@"
  },
}
