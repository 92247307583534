import angular from "angular";

import {RoutesConfig} from './config/routes.config';
import {LoadingBarConfig} from './config/loading_bar.config';
import {ThemeConfig} from './config/theme.config';
import {SatellizerConfig} from './config/satellizer.config';
import {DatepickerConfig} from './config/datepicker.config';
//import {EnvironmentConfig} from './config/environment.config';

angular.module('app.config')
//	.config(EnvironmentConfig)
    .config(RoutesConfig)
	.config(LoadingBarConfig)
	.config(ThemeConfig)
	.config(DatepickerConfig)
	.config(SatellizerConfig);

