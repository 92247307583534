class PasteEventController{
    constructor(){
        'ngInject';

        //
    }
}

export function PasteEventDirective(){
    return {
        controller: PasteEventController,
        restrict: 'A',
        priority:1000,
        link: function(scope, element, attrs) {
            var pasteFunction = attrs.ppPaste
            element.on('paste', function(event) {
                var pastedText = (event.clipboardData || window.clipboardData).getData('text');
                let x = scope.$eval(pasteFunction, {barcode:pastedText.trim()});
                scope.$apply();
            });
        }
    }
}
