class UserService {
	constructor(API, $http, $timeout, $q,$rootScope) {
		'ngInject';

		this.$q = $q;
		this.$timeout = $timeout;
		this.API=API;
		this.userData = {};
		this.userName = '';
		this.$http = $http;
		this.perm = {};
		this.$rootScope = $rootScope;
		this.permProm = false;
		this.roles = {};
	}

	setUserPermbyHttpReq() {
		if(this.permProm==false) {
			var parent = this;
			this.permProm = this.$q.defer();

	        this.$http.get(window.__env.apiUrl+'/api/user/perm/get').then(function(values) {
	            parent.permProm.resolve(values.data.data.data);
	            parent.setPermObject(values.data.data.data);
	        });
	   }

	   return this.permProm.promise;
	}

	getUserObject() {
		var self_ = this;
		var deferred = this.$q.defer();

		this.API.all('user/me').doGET().then(function(response){
			localStorage.setItem('polypus-me',JSON.stringify(response.data))
			deferred.resolve(response.data);
		});

		return deferred.promise;
	}

	setPermObject(data) {
		this.permData = data;
		//this.$rootScope.$broadcast('perm:set',data); //event ı aşağıya scope yollar
	}

	getUserPerm() {
		return this.permData;
	}

	getUserPermPromise() {
		var self_ = this;
		var deferred = this.$q.defer();

		this.API.all('user/perm/get').doGET().then(function(response){
			deferred.resolve(response.data);
		});

		return deferred.promise;
	}

	getResponsibleUsers(role_id) {
		var data = null;


		data = this.API.all('user/getResponsible').getList({
                                role_id: role_id
                            }).$object;

		return data;
	}

	getAllUsers(role_id) {
		var data = null;

		data = this.API.all('user/all').getList({
			role_id: role_id
		}).$object;

		return data;
	}

	setPerms(perms) {
		this.perm = perms;
	}

	getPerms() {
		return this.perm;
	}

	getCustomers(query = "") {
		var data = null;

		data = this.API.all('user/customers').getList({
                                query: query
                            }).$object;

		return data;
	}

}
UserService.$inject = ["API", "$http", "$timeout", "$q","$rootScope"];
export {UserService};
