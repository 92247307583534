import {openIntroductionVideo} from "../../../utils/introduction-videos";
import {getUserInfo} from "../../../utils/users";

class ReturnManagementController {
  constructor(API,ToastService,$mdBottomSheet,$scope,$rootScope,DialogService,$location) {
    "ngInject";
    this.API = API;
    this.ToastService = ToastService;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$scope = $scope;
    this.DialogService = DialogService;
    this.$rootScope = $rootScope;
    this.$location = $location;
  }

  $onInit() {
    let parent = this;

    this.$scope.$on('returnEvent', function (event, data) {
      if (data.screen === 'return') {}
    })
    this.API.all("shipment")
        .customGET()
        .then((response) => {
          this.shipmentProviders = response.data.list
        })
  }

  onSelectTab(obj) {
    this.$rootScope.$broadcast('returnEvent', obj)
    this.$location.search(obj);
/*
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 0) {
      this.video = 'return-management';
    }
    if (obj.tab == 1) {
      this.video = 'locationShelf';
    }
    if (obj.tab == 2) {
      this.video = 'locationProductShelf';
    }
    if (obj.tab == 4) {
      this.focus_shelf_change_barcode = true;
    }

 */
  }
}

ReturnManagementController.$inject = ["API", "ToastService","$mdBottomSheet", "$scope", "$rootScope", "DialogService","$location"];
const ReturnManagementComponent = {
  templateUrl:
    "./views/angular/app/components/return-management/return-management.component.html",
  controller: ReturnManagementController,
  controllerAs: "vm",
  bindings: {},
};

export {ReturnManagementComponent, ReturnManagementController}
