import {getUserInfo} from "../../../utils/users";

class BundleEntryController {
    constructor(API, ToastService, $translate, $mdBottomSheet, $scope, DialogService, $state, $location, $anchorScroll, $filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$filter = $filter;
        this.translate = this.$filter('translate');

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;
        this.searchTerm = null;
        this.selectedRawMaterial = null;
        this.bundleStockUnit = 'Adet'
        this.bundleItems = [];

        this.autoM3Calc = true
        this.product = {
            productType:'bundle'
        }

        this.selectedRecipeQuantityValue = 'weight'

    }

    $onChanges(obj) {
    }

    $onInit() {
        this.getStockUnit()

    }

    clearFilter() {
        this.product = {
            productType:'bundle'
        }
        this.selectedRawMaterial = null;
        this.bundleItems = [];
        this.searchTerm = null;

    }

    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }

    searchRawMaterialProduct(term) {
        let data = {
            term: term,
            last: term ? 0 : 1,
            limit: term ? 20 : 5,
            productType:'rawMaterial'
        }
        return this.API.all('search/product')
            .customGET(null, data)
            .then((response) => {

                return response.data.list
            })
    }

    selectedItemChange(item) {
        console.log(this.product)
    }

    addBundleItem() {
        this.bundleItems.push({
            productName: null,
            weight: null,
            height: null,
            width: null,
            quantity: 1,
            stockUnit: this.bundleStockUnit,
        })
    }

    deleteBundleItem(index) {
        this.bundleItems.splice(index, 1);
    }

    getStockUnit() {
        this.API.all("stockUnit/getList")
            .post()
            .then((response) => {
                this.stockUnits = response.data.stockUnit;
            });

    }

    calcM3() {
        this.cbm = {
            width: 0,
            height: 0,
            depth: 0,
            weight: 0,
            m3: 0,
        }

        this.bundleItems.forEach(item => {
            this.cbm.width += item.width ? +item.width : 1;
            this.cbm.height += item.height ? +item.height : 1;
            this.cbm.depth += item.depth ? +item.depth : 1;
            this.cbm.weight += item.weight ? +item.weight : 1;
        })

        this.cbm.m3 = this.cbm.width * this.cbm.height * this.cbm.depth
        //  document.getElementById('fake').click()

    }

    stockUnitLabelChange(item) {
        item.unitText = this.stockUnits.find(s => s.id === item.unit).name
    }

    save() {
        if (!this.selectedRawMaterial) {
            this.ToastService.showHtml('error', this.$translate.instant('Hammede Seçmelisiniz'))
            return;
        }

        if (this.bundleItems.length === 0) {
            this.ToastService.showHtml('error', this.$translate.instant('En  az 1 bundle içeriği seçmelisiniz'))
            return;
        }
        this.product.bundleItems = this.bundleItems.map(b => {
            b.m3 =  b.width * b.height * b.depth
            if (this.selectedRecipeQuantityValue === 'weight') {
                b.recipeQuantity = b.weight
            }
            return b
        });

        this.product.recipeProductId = this.selectedRawMaterial.productId;

        this.product.productType = 'bundle';
        this.product.combine = true;
        this.product.cbm = this.product.m3
        this.API.all("stock/save")
            .post(this.product)
            .then((response) => {
                if (response.response.status===200){
                    this.ToastService.showHtml('success',this.product.productName+' başarıyla oluşturuldu');
                    this.clearFilter()
                }
            });
    }
}

BundleEntryController.$inject = ["API", "ToastService", "$translate", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$anchorScroll", "$filter"];
const BundleEntryComponent = {
    templateUrl: "./views/angular/app/components/combine-stock-entry/partials/bundle-entry.html",
    controller: BundleEntryController,
    controllerAs: "vm",
    bindings: {},
};

export {BundleEntryComponent, BundleEntryController}
