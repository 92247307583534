import "./recipe.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class RecipePageController {
    constructor($auth,$window, $state, $timeout, $location,$rootScope,$stateParams,$scope) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$scope = $scope;

        this.productId = null;
        this.recipeId = null;
        this.selectBarcode = null;
        this.offerTab ={
            sales:0,
            purchase:1,
        }
        this.missingProductList = [];
    }

    $onInit() {
        this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
        let parent = this;
        this.$scope.$on('recipe', function (event, data) {
            parent.onSelectTab(data)
        });
    }

    onSelectTab(obj) {

        if ( this.$location.search()?.productId){
            obj.productId = this.$location.search()?.productId;
        }
        if ( this.$location.search()?.recipeId){
            obj.recipeId = this.$location.search()?.recipeId;
        }

        this.selectedTabIndex = obj.tab
        this.$location.search(obj);
        this.video = undefined;
        if (obj.tab ===1){
            this.$timeout(() => {
                this.$rootScope.$broadcast('recipeDetailEvent', obj);
            }, 0);
        }

    }

}



RecipePageController.$inject = ["$auth", "$window", "$state", "$timeout", "$location","$rootScope","$stateParams","$scope"];

const RecipePageComponent = {
    templateUrl: './views/angular/app/components/recipe/recipe-page.component.html',
    controller: RecipePageController,
    controllerAs: 'vm',
    bindings: {}
};


export {RecipePageComponent, RecipePageController}
