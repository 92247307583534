import { openIntroductionVideo } from "../utils/introduction-videos";

function RoutesRun($state, $transitions, $auth) {
  'ngInject';

  let requiresAuthCriteria = {
    to: ($state) => $state.data && $state.data.auth,
  };

  let redirectToLogin = () => {
    "ngInject";
    if (!$auth.isAuthenticated()) {
      return $state.target("app.login", undefined, { location: false });
    }
  };

  let onSuccessRouteChange = () => {
    /*
    setTimeout(() => {
      const url = window.location.href.split("/#!/")[1];
      openIntroductionVideo(url);
    }, 3000);
     */
  };

  $transitions.onBefore(requiresAuthCriteria, redirectToLogin, {
    priority: 10,
  });
  $transitions.onSuccess(requiresAuthCriteria, onSuccessRouteChange, {
    priority: 10,
  });
}
RoutesRun.$inject = ["$state", "$transitions", "$auth"]
export {RoutesRun};
