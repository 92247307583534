//import "./orders-prepare.scss";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {list} from "postcss";
import {getUserInfo, getUserSetting} from "../../../utils/users";

class StocktakingController {
  constructor(API, ToastService, DialogService, $state, $translate, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
    "ngInject";

    this.API = API;
    this.window = $window;
    this.$scope = $scope;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$state = $state;
    this.$translate = $translate;
    this.$location = $location;
    this.sce = $sce
    this.$timeout = $timeout
    this.$rootScope = $rootScope;
      this.$mdBottomSheet = $mdBottomSheet;
    this.falseBeep = new Audio('./files/error.wav');
    this.trueBeep = new Audio('./files/success.wav');

    this.priority = {
      1: 'normal',
      2: 'high',
      3: 'very-high',
      4: 'urgent',
    }

    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;

    this.stocktakingId = null;
    this.stocktakingShelf = null;
    this.stocktakingProduct = null;


    this.detailOfStocktaking = false;
    this.stocktakingShelfCode = null;//stocktaking.component
    this.stocktakings = null;//stocktaking.component

    this.stocktakingDetail = null;
    this.screen = 'stocktakingList';

    this.stocktakingSameShelfCode = null;
    this.sameShelfName = null;
    this.userLoction = getUserSetting('userLocation')

  }

  $onInit() {
    /*
    let parent = this;
    parent.getPrepareProcess()
    parent.$scope.$on('prepareEvent', function (event, data) {
      if (data.screen === 'shelfUp') {
        parent.getPrepareProcess()
      }else {
        document.querySelector('md-toolbar').hidden = false;
        document.querySelector('md-tabs-wrapper').hidden = false;
      }
    })
     */
    this.getStockLists();
    console.log(this.userInfo);
  }

  getStockLists(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page,
  ) {
    var parent = this;

    this.per_page = per_page;
    this.page = pageNumber;

    let reqObject = {
      page: pageNumber,
      per_page: per_page
    }
    this.API.all("stocktaking")
      .customGET('', reqObject)
      .then((response) => {
        if (response.response.status !== 204){
          parent.stocktakings = response.data.data;

          parent.stocktakingsTotal = response.data.total;
          parent.stocktakingsPageNumber = response.data.current_page;
          parent.stocktakingsPerPage = response.data.per_page;
          parent.stocktakingslast_page = response.data.last_page;
          parent.stocktakingsPrev_page_url = response.data.prev_page_url;
          parent.stocktakingsNext_page_url = response.data.next_page_url;

          parent.stocktakingsPagesArray = response.data.dataPagesArray;

          parent.stocktakingsPrev_index = response.data.prevIndex;
          parent.stocktakingsNext_index = response.data.nextIndex;
        }

          this.API.all("location")
              .customGET()
              .then((response) => {
                  if (response.errors === false) {
                      this.locations = response.data.location;
                      this.locationId = this.locations[0].id;
                      this.locationId = null;
                  }
              });
      });
  }

  stocktakingStart(list) {
    this.screen = 'stocktakingMake';
    this.stocktakingId = list.stocktakingId
    this.stocktakingDetail = list;
    this.locationId = list.locationId;
  }
  selectStockTakingDetail(selectedStocktaking) {
    this.selectedStocktaking = selectedStocktaking;
    if (selectedStocktaking.stocktakingId) {
      this.stocktakingId = selectedStocktaking.stocktakingId;
    }
    this.getStockTakingDetail();
  }
  getStockTakingDetailFilter()
  {
    this.stocktakingDetailPage = 1;
    this.getStockTakingDetail();
  }
  getStockTakingDetail(
    stocktakingDetailPage = this.stocktakingDetailPage,
    filterArr = this.filterArr,
    stocktakingDetailPerPage = this.stocktakingDetailPerPage,
    excelReport = false
  ) {
    var parent = this;

    this.stocktakingDetailPerPage = stocktakingDetailPerPage;
    this.stocktakingDetailPage = stocktakingDetailPage;

    this.API.all("stocktaking/")
      .customGET(this.stocktakingId, {per_page:stocktakingDetailPerPage, page:stocktakingDetailPage, productCode: parent.searchingProduct, excelReport: excelReport})
      .then((response) => {
        if (response.response.status === 204) {
          this.ToastService.error(this.$translate.instant('RELATED_STOCK_LIST_NOT_LOADED'));
          return;
        }
        if (response.data.fileName){
            window.location.assign(window.__env.apiUrl+"/api/stock/exportExcel/"+response.data.fileName+"?token="+window.localStorage.satellizer_token);
        }
        parent.stocktakingDetail               = parent.selectedStocktaking;
        parent.stocktakingDetail.stocktakingId = parent.selectedStocktaking.stocktakingId;
        parent.stocktakingDetail.items         = response.data.data;

        parent.stocktakingDetail_Total         = response.data.total;
        parent.stocktakingDetail_CurrentPage   = response.data.current_page;
        parent.stocktakingDetail_PerPage       = response.data.per_page;
        parent.stocktakingDetail_Last_page     = response.data.last_page;
        parent.stocktakingDetail_Prev_index    = response.data.prevIndex;
        parent.stocktakingDetail_Next_index    = response.data.nextIndex;
        parent.stocktakingDetail_PagesArray    = response.data.dataPagesArray;


        parent.stocktakingDetailPrev_page_url  = response.data.prev_page_url;
        parent.stocktakingDetailNext_page_url  = response.data.next_page_url;

        this.screen = 'stocktakingDetail';
        this.selectedTabIndex = 6
      });
  }

  createStockTaking(name, description, location_id) {
    if (!name) {
      this.ToastService.error(this.$translate.instant('FILL_ALL_FIELDS'));
      return
    }
    this.API.all('stocktaking')
      .post({name, description, location_id})
      .then((response) => {
        let data = response.data.list
        if (response.response.status === 207) {
          this.stockTakingName = null
          this.stockTakingDescriotion = null
          this.getStockLists()
          // this.stocktakings = response.data.list
        }
      });
  }

  /** Ürün için sayımı onayla */
  stocktakingAcceptItem() {
    this.same_shelf_code = this.focus_shelf_code;
    let shelfQuantity = 0;
    this.stocktakingProduct?.location?.shelves?.map(list =>{
      if (list.shelfId === this.stocktakingShelf.shelfId){
        shelfQuantity = list.quantity;
      }
    })
    if (shelfQuantity !== this.stocktakingProduct.quantity){
      var options = {
        controller: "StocktakingController",
        controllerAs: 'vm',
      };
      var dialogPromise = this.DialogService.fromTemplateV2(
          './views/angular/app/components/location-management/partials/warehouse-counting-confirm.component.html',
          options
      );
      dialogPromise.then(shelf=>{
        this.API.all('stocktaking/' + this.stocktakingId)
            .customPOST({
              locationId: this.locationId,
              shelfId: this.stocktakingShelf.shelfId,
              productId: this.stocktakingProduct.productId,
              productType: this.stocktakingProduct.productType,
              mainProductId: this.stocktakingProduct.mainProductId,
              quantity: this.stocktakingProduct.quantity,
              lotId: this.stocktakingProduct.lotId,
              mode: this.stockTakingMode
            })
            .then((response) => {
              if (response.response.status === 204) {
                this.ToastService.success(this.$translate.instant('ADDED_TO_COUNT'));
                this.stockTakingMode = null;
                this.clearStocktakingRead()
                this.stocktakingShelfRead()

              }
            })
      })
      dialogPromise.catch(err=>{
        this.stocktakingProduct.quantity = 0;
      })
    } else {
      this.API.all('stocktaking/' + this.stocktakingId)
          .customPOST({
            locationId: this.locationId,
            shelfId: this.stocktakingShelf.shelfId,
            productId: this.stocktakingProduct.productId,
            productType: this.stocktakingProduct.productType,
            mainProductId: this.stocktakingProduct.mainProductId,
            quantity: this.stocktakingProduct.quantity,
            lotId: this.stocktakingProduct.lotId,
            mode: this.stockTakingMode
          })
          .then((response) => {
            if (response.response.status === 204) {
              this.ToastService.success(this.$translate.instant('ADDED_TO_COUNT'));
              this.stockTakingMode = null;
              this.clearStocktakingRead()
              this.stocktakingShelfRead()
            }
          })
    }

  }
  countingConfirm() {
    this.DialogService.hide(true);
  }

  stocktakingShelfRead() {
    this.focus_shelf_barcode = true;
    this.stocktakingSameShelfCode = this.stocktakingShelfCode;
    this.checkShelf(this.locationId, this.stocktakingShelfCode)
  }

  stocktakingProductRead() {
    this.focus_shelf_barcode = true;
    this.checkProduct(this.locationId, this.stocktakingBarcode)
  }

  /** ilgili sayım için o lokasyonda raf barkoduyla rafı sorgularız. */
  checkShelf(locationId, barcode) {
    var parent = this;
    this.API.all('stocktaking/' + this.stocktakingId+'/search-shelf?barcode=' +barcode+ '&locationId=' + this.locationId)
      .customGET()
      .then((response) => {
        if (response.response.status === 200) {
          this.readBarcode = true;
          this.ToastService.success(this.$translate.instant('SHELF_VERIFIED'));
          parent.stocktakingShelf = response.data;
        }
        if (response.response.status === 204) {
          this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
          this.shelfOperations = false;
        }
        let shelf_barcode = parent.$window.document.getElementById('shelf_barcode');
        shelf_barcode.focus();

      })
      .then((response) => {
      })
  }

  checkProduct(locationId, barcode) {
    if (barcode === '' || barcode === undefined) {
      this.ToastService.error(this.$translate.instant('WRITE_PRODUCT_BARCODE'));
    } else {
      this.API.all('stocktaking/' + this.stocktakingId + '/search-product?barcode=' + barcode + '&locationId=' + this.locationId)
        .customGET()
        .then((response) => {
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_PRODUCT_WITH_BARCODE'));
          } else {
            this.stocktakingProduct = response.data;
          }
        })
    }
  }

  stocktakingSelect(item) {
    let barcode = item.productCode;
    if (item.subProductId>0) {
      barcode = item.subProductBarcode;
    }
    this.stocktakingBarcode = barcode;
    this.stocktakingProductRead();
  }

  continueSameShelf() {
  //  this.readBarcode = false;
  //  this.stocktakingShelfCode = this.stocktakingSameShelfCode;
    this.stocktakingShelfRead();
    //this.focus_shelf_code = true;
    this.stocktakingShelf = null;
    this.stocktakingProduct = null;

    this.stocktakingBarcode = this.shelfChangeProduct = null;
  }
  clearStocktakingRead() {
  //  this.readBarcode = false;
  //  this.stocktakingShelfCode = null;
    this.focus_shelf_code = true;
    this.stocktakingShelf = null;
    this.stocktakingProduct = null;

    this.stocktakingBarcode = this.shelfChangeProduct = null;
  }
    otherStocktakingRead() {
        this.readBarcode = false;
        this.stocktakingShelfCode = null;
        this.focus_shelf_code = true;
        this.stocktakingShelf = null;
        this.stocktakingProduct = null;

        this.stocktakingBarcode = this.shelfChangeProduct = null;
    }
  removeStocktakingTab() {
    this.detailOfStocktaking = false
    this.stocktakingDetail = null;
    this.$state.go('app.location_management',{tab: 5}, {reload:false});
  }


  showStocktakingList() {
    this.stocktakingListPage = true;
  }

  stocktakingComplete() {
    this.API.all('stocktaking/' + this.stocktakingDetail.stocktakingId + '/complete')
      .customPOST()
      .then((response) => {
        if (response.response.status === 204) {
          this.ToastService.success(this.$translate.instant('COUNTING_LIST_INFO'));
          this.removeStocktakingTab()
            window.location.reload();
        }

      })
  }
    showCameraView(type) {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            if (type === 'shelf'){
                parent.stocktakingShelfCode = response;
                parent.stocktakingSameShelfCode = response;
            }
            if (type === 'product'){
                parent.stocktakingBarcode = response;
            }
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }

}

StocktakingController.$inject = ["API", "ToastService", "DialogService", "$state", "$translate", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const StocktakingComponent = {
  templateUrl: "./views/angular/app/components/location-management/partials/stocktaking.component.html",
  controller: StocktakingController,
  controllerAs: "vm",
  bindings: {},
};
export {StocktakingComponent, StocktakingController}
