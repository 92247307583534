/**
 * @deprecated
 */
class AttachTransporterController{
    parentController;
    constructor(API, $auth, ToastService){
        return null;
        "ngInject";

        this.API = API;
        this.$auth = $auth;
        this.ToastService = ToastService;
        this.transportersForSelectbox = {};
        this.sections = {};
    }

    $onInit() {
        this.API.all("transporter/get")
            .post()
            .then((response) => {
                if (response.errors === false) {
                    this.transportersForSelectbox = response.data.transporters;
                }
            });
    }

    getSections(transporterId) {
        this.API.all("get-section-order-prepare")
            .post({transporterId: transporterId})
            .then((response) => {
                this.clearForm();
                this.sections = response.data.sections.data;
            });
    }

    /**
     * @deprecated uç kaldırırldı
     */
    attachSection() {
        var keys = Object.keys(this.parentController.selectedActions);
        var reqSelectedActions = [];
        for (var i = 0; i < keys.length; i++) {
            if (this.parentController.selectedActions[keys[i]]) {
                reqSelectedActions.push(keys[i]);
            }
        }
        var reqObj =  {
            transporter_id: this.transporterId,
            section_name: this.sectionNameForRelationCreate,
            selected_actions: JSON.stringify(reqSelectedActions)
        };

        this.API.all("section-transaction-relation/create")
            .post(reqObj)
            .then((response) => {
                this.clearForm();
                let html = ''
                response.data.list.detail.map(res=>{
                    let icon = 'done'
                    if (!res.status) icon = 'close'
                    html += '<li><md-icon style="color: white">'+icon+'</md-icon>'+res.message+'</li>'
                })
                this.ToastService.showHtml('success','<ul style="list-style-type: none;">'+html+'</ul>');
            });
    }

    clearForm() {
        this.sectionNameForRelationCreate = null;
    }
}
AttachTransporterController.$inject = ["API", "$auth", "ToastService"];
export const AttachTransporterComponent = {
    templateUrl: './views/angular/app/components/attach-transporter/attach-transporter.component.html',
    controller: AttachTransporterController,
    controllerAs: 'vm',
    bindings: {
        parentController: "<"
    }
}