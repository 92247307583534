import "./test.scss";

class TestController {
  constructor(
    $auth, ToastService, API, $window, $state, $location) {
    'ngInject';

    this.$auth = $auth;
    this.$window = $window;
    this.ToastService = ToastService;
    this.API = API;
    this.users = {};
    this.$state = $state;
    this.$location = $location;
    this.video = undefined;
  }

  $onInit() {
    this.testQueue = {};
    this.result = '';
    this.per_page = 10;
    this.filterArr = {};
    this.per_page = 10;
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
  }

  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
  }

  sendTestQueue(){
    let filter = {};

    var parent = this;

    this.API.all("test/test-queue")
      .post(filter)
      .then((response) => {
        parent.testQueue = response.data;
        parent.result = response.data;
      });
  }

}

TestController.$inject = ["$auth", "ToastService", "API", "$window", "$state", "$location"];

export const TestComponent = {
  templateUrl: './views/angular/app/components/test/test.component.html',
  controller: TestController,
  controllerAs: 'vm',
  bindings: {}
};
