import moment from "moment";
function DatepickerConfig($mdDateLocaleProvider) {
	'ngInject';
	/* For more info, visit https://material.angularjs.org/#/Theming/01_introduction */
	$mdDateLocaleProvider.months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
	$mdDateLocaleProvider.shortMonths = ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'];
	$mdDateLocaleProvider.days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
	$mdDateLocaleProvider.shortDays = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'];
/*
	todo : burada çalıştırırsak global etkiliyor
	$mdDateLocaleProvider.formatDate = function (date) {
		if (date) return moment(date).format(formatString);
		else return null;
	};
	$mdDateLocaleProvider.parseDate= function (dateString) {
		if (dateString) {
			var m = moment(dateString, formatString, true);
			return m.isValid() ? m.toDate() : new Date(NaN);
		}
		else return null;
	}
 */
}
DatepickerConfig.$inject = ["$mdDateLocaleProvider"];
export {DatepickerConfig};
