//import "./camera.scss";
import Cookies from "js-cookie";

const CAMERA_COOKIE_KEY = 'camera-id'
class BottomCameraController{
  constructor(API, $scope, $rootScope, $state, $translate, $mdBottomSheet, $auth, $location, ToastService, $window) {
    "ngInject";
    this.API = API
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$state = $state
    this.$translate = $translate
    this.$mdBottomSheet = $mdBottomSheet;
    this.$auth = $auth
    this.$location = $location;
    this.ToastService = ToastService;
    this.$window = $window;

    this.randId = (Math.random() + 1).toString(36).substring(7)
    this.API = API;
    this.ToastService = ToastService;
    this.$mdBottomSheet = $mdBottomSheet;
    this.$scope = $scope;
    this.barcodeScanner = $window.BarcodeScanner;
    this.cameraId = null;
    this.cameraState = 'resume'
    this.cameraEvent = null
    this.searchCallBack = this.$scope.searchCallBack;

    this.isShowCamera = false;
    var parent = this;

  }
  $onInit(){
    var parent = this;
    this.startScreen()
  }

  $onDestroy() {
    this.barcodeScanner = null
    this.cameraStop()
  }

  startScreen() {
    this.cameraId = Cookies.get(CAMERA_COOKIE_KEY)
    this.getCameraInfo()
  }

  completeCallback = (decodedText, decodedResult) => {
    this.cameraPause()
    this.$mdBottomSheet.hide(decodedText)
  }

  setSelectedCamera() {
    Cookies.set(CAMERA_COOKIE_KEY, this.cameraId)
    this.cameraEvent?.stop()
    this.cameraStart()
  }

  cameraStart() {
    this.cameraState = 'resume'
    this.cameraEvent = new this.barcodeScanner(this.randId);
    // telefon için {width: 200, height: 150} ideal
    // pc için {width: 250, height: 250}
    //ekrana göre ayar yapmak lazım
    const config = {
      fps: 2,
      qrbox: {width: 300, height: 150},
      experimentalFeatures: {useBarCodeDetectorIfSupported: true}
    }
    this.cameraEvent.start(
      this.cameraId,
      config,
      this.completeCallback,
    ).catch((err) => {
      console.log(err);
    });
  }

  cameraStop() {
    this.cameraEvent?.stop()
    //this.$mdBottomSheet.hide()
  }

  cameraPause() {
    this.cameraEvent.pause()
    this.cameraState = 'pause'
  }

  cameraResume() {
    this.cameraEvent.resume()
    this.cameraState = 'resume'
  }

  getCameraInfo() {
    let parent = this
    this.barcodeScanner.getCameras().then(devices => {
      if (0 === devices.length) {
        parent.ToastService.error(this.$translate.instant('NO_AVAILABLE_CAMERA_FOUND'))
        //parent.$mdBottomSheet.hide(false)
      }
      if (1 === devices.length) {
        parent.cameraId = devices[0].id
      }
      parent.cameraDevices = devices

    }).then(() => {
      parent.cameraStart()
    }).catch(err => {
      parent.ToastService.error(this.$translate.instant('PLEASE_ENABLE_BROWSER_PERMISSION')+err)
      //parent.$mdBottomSheet.hide(false)
    })
  }

  showCamera(){
    this.isShowCamera = !this.isShowCamera;
  }

  closeQuickView(){
    this.$mdBottomSheet.hide();
  }

}

BottomCameraController.$inject = ["API", "$scope", "$rootScope", "$state", "$translate", "$mdBottomSheet", "$location", "$auth", "ToastService","$window"];

const BottomCameraComponent = {
  templateUrl:
    "./views/angular/app/components/bottom-view/bottom-view.component.html",
  controller: BottomCameraController,
  controllerAs: "vm",
  bindings: {},
};
export {BottomCameraComponent, BottomCameraController}
