
function MarkdownFilter ($sce, markdownService){
  'ngInject';

  return function(input) {
    input = markdownService.md.render(input);
    //input = $sce.trustAsHtml(input);
    return input;
  }
}
MarkdownFilter.$inject = ["$sce", "markdownService"];
export {MarkdownFilter};
