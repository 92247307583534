var MenuRu = {
  SETTINGS: "Настройки",
  HELP: "Помощь",
  QUICK_HELP: "Быстрая помощь",
  SUPPORT: "Поддержка",
  DARK_MODE: "Тёмный режим",
  MY_SHORTCUT: "Мои ярлыки",
  CREATE_SHORTCUT: "Создать ярлык для этой страницы",
  RIGHTBAR_INFO: "Примечание: Видеоуроки относятся к предыдущей версии Polypus. Видео для новой версии скоро будут доступны.",
  SHOW_VIDEO_HELP: "Показать видео-помощь",
  SCREEN_VIEW: "Просмотр экрана",
  VIEW: "Просмотр",
};

var accountSettingsRu = {
  PERSONEL_INFORMATION: "Личная информация",
  TURKISH: "Турецкий",
  ENGLISH: "Английский",
  CURRENT_PASSWORD: "Текущий пароль",
  NEW_PASSWORD: "Новый пароль",
  REPEAT_NEW_PASSWORD: "Повторите новый пароль",
  UPDATE: "Обновить",
  CHANGE_PASSWORD: "Изменить пароль",
  LOCALIZATION_SETTINGS: "Настройки локализации",
  SCAN_PRIORITY: "Приоритет сканирования",
  PRINTER_SETTINGS: "Настройки принтера",
  PRINTER_NAME: "Имя принтера",
  PRINTER_CONNECTION_PROTOCOL: "Протокол подключения принтера",
  PRINTER_IP_ADDRESS: "IP-адрес принтера",
  PRINTER_PORT_ADDRESS: "Порт принтера",
  PRINT_TEST_PAGE: "Печать тестовой страницы",
  FIRM_LOGO: "Здесь вы можете обновить логотип компании.",
  CONTROLLER: "Контроллер",
  QUALITY_CONTROL: "Контроль качества",
  PACKER: "Упаковщик",
  SHOW: "Показать",
  HIDE: "Скрыть",
  CONFIRM_OFFER: "Подтвердить предложение",
  COMPLETE_OFFER: "Завершить предложение",
  CLOSE_OFFER: "Закрыть предложение",
  CANCEL_ORDER: "Отменить заказ",
  CANCEL_OFFER: "Отменить предложение",
  CONFIRM_QUICK: "Быстро закрыть предложение",
  CREATE_PERMISSION_GROUP: "Создать группу разрешений",

  OPEN_REQUEST: "Открытые запросы",

  TRANSACTION_STATUS_INFO1: "Когда заказ попадает в Polypus",
  TRANSACTION_STATUS_INFO2: "Когда заказ назначен пользователю",
  TRANSACTION_STATUS_INFO3: "Когда сбор заказа завершен",
  TRANSACTION_STATUS_INFO4: "Когда контроль заказа завершен",
  TRANSACTION_STATUS_INFO5: "Когда упаковка заказа завершена",
  TRANSACTION_STATUS_INFO6: "Когда доставка заказа завершена (Курьер или Самовывоз)",
  TRANSACTION_STATUS_INFO7: "Когда заказ удален Polypus",
  TRANSACTION_STATUS_INFO8: "Сообщение о нехватке",
  TRANSACTION_STATUS_INFO9: "возврат",
};

var cargoRu = {
  SEARCH_ORDER: "Поиск заказа",
  UPDATE_ORDER_STATUS: "Обновить статус заказа",
  ORDER_COUNT: "Количество заказов",
  CARGO_INFO1: "Чтобы обновить статус заказов на выбранном канале, нужно выбрать как минимум один заказ",
  ORDER_NU: "Номер заказа",
  ORDER_NOTE: "Заметка к заказу",
  PACKAGE_CODE: "Код пакета",
  PREPARE_IN_CARGO: "Заказы в процессе отправки",
  SUCCESSFUL: "Успешно",
  UNSUCCESSFUL: "Неуспешно",
  PRODUCT_NOT_ADDED_INFO: "Товар не добавлен, пожалуйста, проверьте информацию.",
  OFFER_NU: "Номер предложения",
  USERNAME: "Имя пользователя",
  BASIC_INFORMATION: "Основная информация",
  CUSTOMER_NAME: "Имя клиента",
  ADD_NEW_CUSTOMER: "Добавить нового клиента",
  CARGO_PROCESSES: "Процессы доставки",
  ACTIONS: "Действия",
  ACTION: "Действие",
  ACT: "Действие",
  ACT_NO: "Номер действия",
  REMOVE_THE_TABLE: "Убрать со стола",
  ERP_NUMBER: "Номер ERP",
  SHIP_IT: "Отправить",
  CREATED_DATE: "Дата создания",
  AMOUNT: "Сумма",
  CREATOR: "Создатель",
  PRINT_CARGO_BARCODE: "Печать нескольких штрих-кодов",
  CREATE_CARGO_PDF: "Создать PDF",
  SELECT: "Выберите",
  PRINT_CARGO_REPORT: "Отчет о доставке",
  SELECT_DATE_GIVE_CARGO_FIRM: "Дата передачи в службу доставки",
  CARGO_STATUS: "Статус доставки",
  CARGO_MESSAGE: "Сообщение службы доставки",
  CARGO_INFO: "Сообщений от службы доставки нет",
  ORDER_ID: "Номер заказа",
  SELECT_CARGO_FIRM: "Выберите службу доставки",
  CARGO_FIRM: "Служба доставки",
  CARGO_PRICE: "Стоимость доставки",
  EARNINGS: "Доход в рублях",
  SCAN_TYPE_MANUAL: "Вручную",
  SCAN_TYPE_TERMINAL: "Терминал",
  SCAN_TYPE_CAMERA: "Камера",
  CARGO_MESSAGES: "Сообщения от службы доставки",
  NO_MESSAGES: "Сообщений нет",
  SERVICE_PRICE: "Стоимость услуги",
  DELIVER: "Доставить",
  UPDATE_NOW: "Обновлено сейчас",
  UPDATE_SECOND_AGO: "Обновлено {{second}} секунд назад",
  UPDATED_MINUTE_SECOND: "Обновлено {{minutes}} минут {{seconds}} секунд назад",
  DAYS_AGO: "{{days}} дней назад",
  DAYS_HOURS_AGO: "{{days}} дней, {{hours}} часов назад",
  HOURS_AGO: "{{hours}} часов назад",
  HOURS_MINUTES_AGO: "{{hours}} часов, {{minutes}} минут назад",
  MINUTES_AGO: "{{minutes}} минут назад",
  MINUTES_SECONDS_AGO: "{{minutes}} минут, {{seconds}} секунд назад",
  SECONDS_AGO: "{{seconds}} секунд назад",
  DELIVER_TO_SELECTED: "Доставить выбранные",
  NO_ORDER_IN_PACKAGE_PROCESS_TITLE: "Заказы в процессе упаковки отсутствуют",
  NO_ORDER_IN_PACKAGE_PROCESS_BODY: "Вы можете создать новый заказ в разделе Движение запасов",
  QUALITY_CONTROL_INFO_TITLE: "Контроль качества",
  QUALITY_CONTROL_INFO_BODY: "Введите штрих-код заказа для проверки",
  INVOICES_INFO_TITLE: "Раздел поиска заказов",
  INVOICES_INFO_BODY: "Вы можете продолжить, введя номер заказа",
  CHANGE_SHELF_INFO_TITLE: "Раздел смены полки",
  CHANGE_SHELF_INFO_BODY: "Выберите склад, на котором хотите работать, и продолжайте",
  PRINT_UNIQUE_CARGO_BARCODE: "Печать одного штрих-кода доставки",
  PRODUCT_ERP_CODE: "Код ERP",
  START_ADD_PRODUCT_WITH_CAMERA: "Добавить товар с камеры",
  SEARCH_PRODUCT_WITH_CAMERA: "Искать товар с камеры",
  SEARCH_PRODUCT_WITH_BARCODE: "Искать товар по штрих-коду",
  NOT_EXIST_CONTROL_OF_STEP: "На этапе обработки заказов нет",
};

var customersRu = {
  CUSTOMER: "Клиент",
  DEFAULT_COMPANY_CUSTOMER: "Клиент компании по умолчанию",
  ONLY_ACCOUNTING_INTEGRATIONS: "Только для бухгалтерских интеграций",
  CREATE_CUSTOMER: "Создать клиента",
  CUSTOMER_NOT_SELECTED: "Клиент не выбран",
  SELECT_CUSTOMER: "Выбрать клиента",
  CUSTOMER_CODE: "Код клиента",
  CUSTOMER_NAME_OR_FIRM_LABEL: "Имя или название компании",
  EMAIL: "Электронная почта",
  PHONE_NUMBER: "Номер телефона",
  INVOICE_ADDRESS: "Адрес для счета",
  ADDRESS_TYPE: "Тип адреса",
  ADDRESS_DETAILS: "Детали адреса",
  ADDRESS_DESCRIPTION: "Описание адреса",
  PERSONAL: "Личный",
  CORPORATE: "Корпоративный",
  IDENTITY_NU: "Номер ИНН",
  TAX_NU: "Налоговый номер",
  PREPARING_STREAM: "Поток подготовки",
  GETTING_READY: "Подготовка",
  NO_MISSING_PRODUCTS_TITLE: "Нет отсутствующих товаров",
  PRODUCT_NOT_FOUND: "Товар не найден",
  PRODUCT_NOT_FOUND_INFO: "Нет товаров на выбранной полке. Вы можете добавить товары в разделе Управление полками.",
  NO_MISSING_PRODUCTS_BODY: "На данный момент отсутствующие товары не обнаружены. Вы можете начать добавление товаров.",
  TAX_DEP: "Налоговое управление",
  TRADE_REGISTRATION_ID: "Регистрационный номер",
  COMPANY_LABEL: "Название компании",
  NAME_SURNAME: "Имя и фамилия",
  GSM_NU: "Мобильный телефон",
  ADDRESS: "Адрес",
  ACTIVATE: "Активировать",
  MAKE_ACTIVE_PASSIVE: "Сделать активным/пассивным",
  CITY: "Город",
  TOWN: "Район",
  COUNTRY: "Страна",
  NEIGHBOURHOOD: "Район",
  ZIP_CODE: "Почтовый индекс",
  SAME_ADDRESS_INFO: "Адрес доставки совпадает с адресом для счета",
  SAVE: "Сохранить",
  SAVE_CHANGES: "Сохранить изменения",
  UNDO_CHANGES: "Отменить изменения",
  SAVE_RECOMMENDED_LOCATIONS: "Сохранить предложенные склады",
  DELIVERY_ADDRESS: "Адрес доставки",
  ENTER_NUMBER_ONLY: "Введите только цифры",
  DELIVERY: "Доставка",
  ETC: "и т.д.",
  CUSTOMERS: "Клиенты",
  CLEAR: "Очистить",
  CLEAR_UPPERCASE: "ОЧИСТИТЬ",
  SEARCH: "Поиск",
  CUSTOMER_NAME: "Имя клиента",
  CUSTOMER_EMAIL: "Электронная почта клиента",
  PRINT: "Печать",
  ACTIONS: "Действия",
  CONTACT: "Контакт",
  FOOTER_MESSAGE: "Сообщение в нижнем колонтитуле",
  SHOW_MISSING_PRODUCTS: "Показать отсутствующие товары",
  EDIT: "Редактировать",
  DELETE: "Удалить",
  CUSTOMER_INFO1: "Используется для поиска, добавления и редактирования учетной записи клиента",
  CUSTOMER_INFO2: "Поиск по коду клиента, имени пользователя, названию клиента",
  CUSTOMER_EMPTY_SEARCH_MSG: "Нет информации о клиенте, соответствующей запросу",
  NO_RECORD_INFO: "Нет записей для отображения",
  RETURN_TRANSACTION: "Создать отмену",
  CANCEL_TRANSACTION: "Создать возврат",
  RETURN_PROCESS: "Процесс возврата",
  MISSING_QUANTITY_REPORT: "Сообщить о нехватке количества",
  MISSING_QUANTITY: "Недостающее количество",
  SAME_QUANTITY: "То же количество",
  OFFER_INFO_TITLE: "Создание предложения",
  OFFER_INFO_BODY: "Вы можете создать предложение, указав информацию выше",
  NO_PRODUCT_TABLE: "На столе нет товаров",
  GALLERY: "Галерея",
  SELECTED_PHOTO: "Выбранное фото",
  CUSTOMER_TAX_OFFICE: "Налоговое управление",
  TAX_ID: "ИНН",
};

var dashboardIndexRu = {
  TOTAL_ORDERS: "Всего заказов",
  TOTAL_ORDER_QTY: "Общее количество заказов",
  COLLECTION: "Общее количество заказов",
  PREPARATION_STEP: "Этап подготовки",
  CONTROL_STEP: "Этап контроля",
  CARGO_PROCESSES: "Процессы доставки",
  PROCESSES: "Процессы",
  ORDER_ITEM_QUANTITY: "Количество позиций в заказе",
  ITEM_QUANTITY: "Количество позиций",
  GENERAL_VIEW: "Общий обзор",
  STOCK_TURNOVER_RATE: "Скорость оборота запасов",
  TURNOVER_RATE: "Скорость оборота",
  MOST_ACTIVE_PRODUCTS: "Самые активные товары",
  DATA_NOT_READY_YET: "Данные за сегодняшний день еще не готовы",
  NOT_ENOUGH_DATA_FOR_REPORT: "Недостаточно данных для отчета",
  THE_MOST_MOVING_PRODUCTS: "Самые активные товары",
  REPORT_CREATE_TIME: "Время создания отчета",
  REPORT_BEING_PREPARED: "Ваш отчет готовится. После завершения он будет отображен здесь.",
  CLICK_GENERATE_REPORT_NOW: "Нажмите, чтобы создать отчет прямо сейчас",
  HIGHEST: "Наибольший",
  LOWEST: "Наименьший",
  DAILY: "Ежедневный",
  DAILY_REVENUE: "Ежедневная общая выручка",
  REVENUE: "Выручка",
  MONTH: "Месяц",
  WEEK: "Неделя",
  DAY: "День",
  YEAR: "Год",
  NOW_YEAR: "Этот год",
  PREVIOUS_DAYS: "Прошлые дни",
  TODAY: "Сегодня",
  YESTERDAY: "Вчера",
  RATE_OF_CHANGE: "Темп изменения",
  NO_REPORT_DATA_YET: "Данных для отчета пока нет",
  BETWEEN_DATES: "Между датами",
  LAST_DAY: "Последний день",
  LAST_WEEK: "Последняя неделя",
  THIS_WEEK: "Эта неделя",
  LAST_MONTH: "Последний месяц",
  THIS_MONTH: "Этот месяц",
  ALL_TIME: "Все время",
  BRANCH_REVENUE: "Выручка филиалов",
  DATE: "Дата",
  BRANCH: "Филиал",
  PAYMENT_TYPE: "Тип оплаты",
  EXAMPLE_PAYMENT_TYPE: "tip_oplaty",
  PREPARED_PRODUCT_COUNT: "Количество подготовленных товаров",
  PREPARED_PRODUCT_QTY: "Количество подготовленных товаров",
  SERVICE: "Сервис",
  STATUS: "Статус",
  ELASTIC_SEARCH_ENGINE: "Поисковая система Elastic",
  ACTIVE: "Активный",
  COMPLETE: "Завершено",
  PASSIVE: "Пассивный",
  STOCK_ENTRY: "Вход на склад",
  STOCK_EXIT: "Выход со склада",
  PREVIEW: "Предпросмотр",
  OPEN_NEW_PAGE: "Открыть на новой странице",
  EXPORT_TSOFT: "Экспорт в T-Soft",
  SELECT_CHANNEL: "Пожалуйста, выберите канал...",
  SELECT_TRANSACTION_MODE: "Выберите режим транзакции",
  RECORD_COUNT: " количество записей",
  RETAIL_SALE: "Розничная продажа",
  WHOLESALES: "Оптовая продажа",
  SELECT_MONTH: "Выберите месяц",
  JAN: "Январь",
  FEB: "Февраль",
  MARCH: "Март",
  APR: "Апрель",
  MAY: "Май",
  JUN: "Июнь",
  JULY: "Июль",
  AUGUST: "Август",
  SEPTEMBER: "Сентябрь",
  OCTOBER: "Октябрь",
  NOVEMBER: "Ноябрь",
  DECEMBER: "Декабрь",
  TOTAL_AMOUNT: "Общая сумма",
  SEARCH_ENGINE: "Поисковая система",
  COUNT_PERIOD: "Период подсчета",
  COUNT_PERIOD_NOT_DEFINED: "Период подсчета не определен",
  ONCE_A_DAY: "раз в день",
  STOCK_INFO: "Пока нет данных о запасах",
};
var integrationPageRu = {
  SIPARISLER: "Заказы",
  INTEGRATION_FILTER_1: "Дата начала выполнения задачи",
  SAAT_SECINIZ: "Выберите время",
  INTEGRATION_FILTER_2: "Дата окончания выполнения задачи",
  SELECT_CHANNEL: "Выберите канал",
  ORDER_UPDATED_START_DATE_ENTER: "Дата начала обновления",
  ORDER_UPDATED_END_DATE_ENTER: "Дата окончания обновления",
  SELECT_ORDER_STATUS: "Выберите статус заказа",
  SALES_CHANNEL: "Канал продаж",
  NAME: "Имя",
  USER: "Пользователь",
  OFFICIAL: "Ответственный",
  ORDER_START_DATE: "Дата начала",
  BEGINNING: "Начало",
  FINISH: "Конец",
  ORDER_END_DATE: "Дата окончания",
  TRANSFERRED_ORDER_COUNT: "Количество переданных заказов",
  ORDER_STATUSSES: "Статусы заказов",
  WORKING_TIME: "Время работы",
  WORKING_ELAPSED_TIME: "Прошедшее время работы",
  KILL_PROCESS: "Прервать процесс",
  STOCK: "Склад",
  B2C_TRANSFER: "Передача B2C",
  PRODUCT_IDS: "Идентификаторы продуктов",
  FILTER_PARAMS: "Параметры фильтра",
  SELECT_DATE: "Выберите дату",
  SELECT_OPERATOR: "Выберите оператора",
  PREV_SELECT_DATE: "до выбранной даты",
  PREV: "Предыдущий",
  AFTER: "Следующий",
  PREV_PRODUCT: "Предыдущий товар",
  AFTER_PRODUCT: "Следующий товар",
  MINIMUM_AMOUNT: "Минимальная сумма",
  MAXIMUM_AMOUNT: "Максимальная сумма",
  NEXT_SELECT_DATE: "после выбранной даты",
  SELECT_CURRENT_DATE: "выбранная дата",
  SELECT_DATE_FIELD_PROPERTIES: "Свойства поля даты",
  BY_CREATED_DATE: "По дате создания",
  BY_UPDATE_DATE: "По дате обновления",
  BY_STOCK_UPDATE_DATE: "По дате обновления склада",
  BY_PRICE_UPDATE_DATE: "По дате обновления цены",
  STOCK_START_DATE: "Дата начала",
  STOCK_END_DATE: "Дата окончания",
  OPERATOR: "Оператор",
  DATE_PROPERTIES_FIELD: "Тип даты",
  SESSION_ID: "ID сессии",
  TRANSFERRED_PRODUCT_COUNT: "Количество переданных товаров",
  UPDATE_CURRENCY: "Обновить валютную информацию",
  CURRENCY: "Валюта",
  EXCHANGE_RATE: "Валютный курс",
  CURRENCY_DECIMAL: "Десятичные знаки валюты",
  INTEGRATION_INFORMATION: "Информация об интеграции",
  PROVIDER_PRODUCT_ID: "ID интеграции",
  TRANSFER_MESSAGE: "Результат передачи",
  PREPARER: "Подготовил",
  MARKET_PLACE: "Торговая площадка",
  LAST_STATUS: "Последний статус",
};
var batchOperationsRu = {
  IMPORT_TRANSACTION: "Импортировать движение склада",
  IMPORT_PRODUCT_SHELF_RELATION: "Импортировать связь продукт-полка",
  IMPORT_SHELF: "Импортировать полку",
  SHELF_AND_QUANTITY: "Полка и количество",
  RETURN_TO_SHELF: "Вернуть на полку",
  UPLOAD: "Загрузить",
  BROWSE: "Просмотреть",
  UPLOADED: "Загружено",
  SELECTED_FILE: "Выбранный файл",
  PLEASE_WAIT_FOR_PROCESS: "Пожалуйста, подождите... Ваш файл обрабатывается...",
  DOWNLOAD_IMPORT_REPORT: "Нажмите на имя файла, чтобы скачать отчет об импорте.",
  DOWNLOAD_PREVIEW_REPORT: "Нажмите на имя файла, чтобы просмотреть анализ загруженного файла.",
  IMPORT_SHELF_INFO1: "В этом разделе можно добавить коды полок в систему через Excel.",
  CLICK: "нажмите",
  IMPORT_SHELF_INFO2: "В этом разделе можно добавить связи между полками и продуктами через Excel.",
  IMPORT_SHELF_INFO3: "В этом разделе можно создать список операций через Excel.",
  DOWNLOAD_SAMPLE_EXCEL_FILE: "Чтобы скачать пример файла Excel,",
  UPLOAD_COMPLETED_DOWNLOAD_REPORT: "операция создана. Нажмите на имя файла, чтобы скачать отчет об импорте.",
  SAMPLE_DATA_SET: "Пример набора данных",
  SAMPLE_DATA: "Пример данных",
  DATA_PATTERN: "Шаблон данных",
};
var invoicesRu = {
  ORDERS: "Заказы",
  CHANNEL_ORDERS: "Заказы канала",
  STATISTICS: "Статистика",
  CHOOSE_USER: "Выберите пользователя",
  SHOW_STATISTIC: "Показать статистику",
  TRANSFER_EXCEL_BUTTON_NAME: "С отсутствующими продуктами",
  TRANSFER_EXCEL: "Excel",
  CSV: "CSV",
  ORDER_STATUS_HISTORY: "История статусов заказа",
  NOT_TRANSFERRED_ERP: "Не перенесено в бухгалтерию",
  MANUAL_TRANSFER_ERP: "Ручная передача в бухгалтерию",
  BALANCE_PRODUCT_ORDERS: "Заказы с отсутствующими продуктами",

  ORDER_CURRENCY: "Валюта заказа",
  SELECTED_CURRENCY: "Выбранная валюта",

  SELECT_STATUSSES_ORDER: "Статусы заказов",
  SEARCH_STATUS: "Поиск статуса",
  REMOVE_STATUS: "Снять выбор",
  SELECT_ALL: "Выбрать все",
  TAKE_ALL: "Взять все",
  WAS_SELECT_STATUS: "статус выбран",
  ALL: "Все",
  PRODUCT_DEFINED_FULL_SHELF: "Полка с определенным продуктом (полная)",
  PRODUCT_DEFINED_EMPTY_SHELF: "Полка с определенным продуктом (пустая)",
  PRODUCT_UNDEFINED_SHELF: "Полка с неопределенным продуктом",
  TRANSFER_TO_ERP: "Передать в ERP",
  ACCOUNTING: "Бухгалтерия",
  ACCOUNTING_TRANSFERS: "Передача в ERP",
  SHIPMENT_TRANSFERS: "Передача доставки",
  TAKE_TO_ACCOUNTING: "Отправить в бухгалтерию",
  ACTION: "Действие",
  ORDER_LINE_COUNT: "Количество позиций в заказе",
  TRANSFER: "Передача",
  ORDER_STATUS: "Статус заказа",
  ORDER_DATE: "Дата заказа",
  GENERAL_TOTAL: "Общая сумма",
  EMAIL_NATIVE: "Электронная почта",
  ORDER_TYPE: "Тип заказа",
  BALANCE_PRODUCT_NOTIFY: "Уведомление о недостающих продуктах",
  BALANCE_PRODUCT_NOTIFY_INFO: "Для неподходящих количеств в списке будет создана запись, и неподходящие заказы будут удалены из списка.",
  BALANCE_PRODUCT_NOTIFY_INFO2: "В системе достаточно продуктов на полках, чтобы удовлетворить потребности в списке. Поэтому созданное уведомление также превратится в автоматический документ учета.",

  NUMBER_OF_LIST_PRODUCT: "Количество продуктов в списке",
  QUANTITY_ON_SHELVES: "Количество на полках",
  CREATE_STOCK_ACTION: "Создать движение запаса",
  ORDER_TRANSFERED_ERP: "Заказ передан в бухгалтерскую программу.",
  ACCOUNTING_MANUAL_TRANSFER_APPROVAL: "Подтверждение ручной передачи в бухгалтерию",
  STOCKMOVEMENT_CONFIRM_INFO: "Движение запасов будет передано в бухгалтерию. Вы уверены?",
  ORDER_NOT_TRANSFERED_ERP: "Заказ не передан в бухгалтерскую программу.",
  ERP_INFO: "Показывает необработанные данные из системы ERP",
  ERP: "ERP",
  SUPPLIER_PRODUCT_CODE: "Код продукта поставщика",
  CONNECTION_LOST: "Не удается подключиться к серверу",
  DOWNLOADING: "Загрузка",
  INVOICES_INFO: "Через форму выше вы можете запросить и управлять переданными заказами. Пожалуйста, расширьте ваши критерии поиска.",
};

var locationRu = {
  LOCATIONS: "Склады",
  CREATE_LOCATION: "Создать склад",
  LOCATION_NAME: "Название склада",
  EXAMPLE_LOCATION_NAME: "название_склада",
  LOCATION_TYPE: "Тип склада",
  SELECT_LOCATION_TYPE: "Тип склада",
  SELECT_REMOVAL_TYPE: "Тип удаления",
  REMOVAL_TYPE: "Тип удаления",
  NOT_FOLLOWING: "Не отслеживается.",
  CHOOSE_USER: "Выберите пользователя",
  SHELF_CODE: "Код полки",
  SHELF: "Полка",
  SHELF_NAME: "Название полки",
  WAREHOUSE_NAME: "Название склада",
  EMPTY_SHELF: "Пустая полка",
  USED_SHELF: "Используемая полка",
  NO_PRODUCT_IN_SHELF: "Полка без продукта",
  SUB_LOCATION_STATUS: "Статус полки",
  SEARCH: "Поиск",
  SAVE: "Сохранить",
  CANCEL: "Отменить",
  ORDER_CANCEL: "Отмена",
  SHELF_ORDER_NU: "Порядковый номер полки",
  SHELVES: "Полки",
  ALTERNATIVE_SHELVES: "Альтернативные полки",
  NO_SUITABLE_SHELF_FOUND: "Подходящая полка не найдена",
  NOT_SHELVES: "Полки не определены",
  ORDER_INFO_MESSAGE: "Ваши заказы запланированы в разделе задач. Здесь можно обновлять вручную при необходимости.",
  ORDER_INFO_MESSAGE2: "Ваши запасы запланированы в разделе задач. Здесь можно обновлять вручную при необходимости.",
  SCHEDULED_TASKS: "Запланированные задачи",
  SHELF_DETAIL: "Детали полки",
  CREATE_SHELF: "Создать полку",
  SHELF_OPERATION: "Операции с полкой",
  PRODUCT_INFO: "Информация о продукте",
  PRODUCT_CODE: "Код продукта",
  ENTER_CRITERIA_SEARCH_SHELF: "Введите критерий поиска полки",
  PRODUCT_VARIATION: "Вариант продукта",
  ENTER_CRITERIA_SEARCH_PRODUCT: "Введите критерий поиска продукта",
  FILTER: "Фильтр",
  PRODUCT_SHELF_RELATION: "Связь продукта и полки",
  TRANSPORTERS: "Перевозчики",
  TRANSPORTER_SECTIONS: "Разделы перевозчика",
  TRANSPORTER_NAME: "Имя перевозчика",
  SECTION_NAME: "Название раздела",
  SELECT_TRANSPORTER: "Выберите перевозчика",
  VIEW_TRANSACTION: "Просмотр операции",
  WAREHOUSE: "Склад",
  WAREHOUSES: "Склады",
  WAREHOUSE_UPDATED: "Склад обновлен.",
  LOT_SERIAL_WAREHOUSE_NOT_SELECTED: "Лот/серийный номер не выбран",
  IN_STOCK: "В наличии",
  CREATE_NEW_LOT: "Создать новый лот",
  SHELF_UPDATED: "Код полки обновлен.",
  SHELF_CREATED: "Полка создана.",
  PENDING_SUBMISSION_CONFIRMATION: "Ожидает подтверждения отправки",
  SHIPMENT_CONFIRMED: "Отправка подтверждена",
  CONFIRM: "Подтвердить",
  CONFIRM_ALL: "Подтвердить все",
  DELIVER_ALL: "Доставить все",
  NOT_CONFIRM: "Не подтверждать",
  CONFIRM_MESSAGE: "Сообщение о подтверждении",
  CONFIRM_MESSAGE_INFO: "При обновлении приема товаров вход для других пользователей будет деактивирован, подтверждаете?",
  CONFIRM_MESSAGE_BODY: "Вы уверены, что хотите удалить эту строку?",
  WANT_TO_DELETE_SHELF: "Вы уверены, что хотите удалить эту полку?",
  WANT_TO_DELETE_PRODUCT: "Вы уверены, что хотите удалить этот продукт?",
  WANT_TO_DELETE_CHANNEL: "Вы уверены, что хотите удалить этот канал?",
  PRODUCT_APPROVAL_CANCELED: "Подтверждение продукта отменено",
  PACKAGING_PROCESS_STOPPED: "Процесс упаковки остановлен",
  WANT_TO_DELETE_AUTOMATIC_TRANSFER: "Вы уверены, что хотите удалить настройку автоматической передачи в бухгалтерию?",
  WANT_TO_DELETE_B2C_SITE: "Вы уверены, что хотите удалить настройку автоматической передачи запасов на B2C сайт?",
  WANT_TO_DELETE_CUSTOMER: "Вы уверены, что хотите удалить этого клиента?",
  WANT_TO_DELETE_OFFER: "Вы уверены, что хотите удалить предложение?",
  PRODUCT_NOT_FOUND_SYSTEM: "Продукт не найден в системе, хотите добавить?",
  AUTOMATICALLY_APPROVE: "Вы уверены, что хотите автоматически подтвердить все операции?",
  ARE_YOU_SURE: "Вы уверены?",
  ONCE_YOU_CONFIRM_THIS_TRANSACTION: "После подтверждения нельзя будет отменить. Вы уверены?",
  WANT_TO_DELETE_TRANSACTION: "Вы уверены, что хотите удалить эту операцию?",
  PLACE_COLLECT_ITEMS_BACK_SHELF: "Вернуть собранные товары на полку",
  CANNOT_BE_UNDONE: "Это действие нельзя отменить. При подтверждении будет создано задание для возврата товаров на полку.",
  START_RETURN_PROCESS_INFO: "Вы собираетесь начать процесс возврата заказа",
  SHELF_DELETED_MESSAGE: "Полка удалена.",
  SHELF_PRODUCT_MATCH_MESSAGE: "Полка и продукт связаны.",
  SHELF_PRODUCT_RELATION_DELETE: "Связь полки и продукта удалена.",
  SELECT_ONE_CRITERIA: "Выберите как минимум один критерий.",
  SECTION_DEFINED: "Раздел определен",
  SECTION_DELETED: "Раздел удален.",
  ORDER_PREPARATION_TOOL: "Инструмент подготовки заказа",
  GET_PACKED: "Начать упаковку",
  PACKED_INFO: "Нет заказов с завершенным процессом упаковки.",
  TRANSPORTER_DEFINED: "Определено средство подготовки заказа.",
  TRANSPORTER_DELETED: "Средство подготовки заказа удалено.",
  REPORT_NAME: "Название отчета",
  SHELF_TYPE: "Тип полки",
  PACKAGING: "Упаковка",
  PACKAGING_DETAIL: "Детали упаковки",
  LOCATION: "Склад",
  UNDEFINED: "Неопределено",
  BARCODE: "Штрих-код",
  PROCESS: "Процесс",
  PROCESSED: "Обработано",
  LAST_PROCESS: "Последний процесс",
  WIDTH: "Ширина",
  HEIGHT: "Высота",
  DEPTH: "Глубина",
  WEIGHT: "Вес",
  DOWNLOAD: "Скачать",
  PRODUCT_COUNT: "Количество товаров",
  VOLUME: "Объем (Ш x В x Г)",
  WAREHOUSE_LAYOUT_GUIDE: "Руководство по размещению склада",
  CHANNEL: "Канал",
  CREATE_GUIDE_REQUEST: "Создать запрос на руководство",
  WHATIS_LOCATION_GUIDE: "Что такое руководство по размещению склада?",
  LOCATION_GUIDE_INFO: "Инструмент для анализа заказов и организации склада. Выберите канал для создания запроса.",
  REQUEST_DATE: "Дата запроса",
  RESPONSE_DATE: "Дата ответа",
  TRANSPORTER_ORDER_PREP_MSG: "Выбор пользователя не обязателен",
  TRANSPORTER_INFO_MSG: "Невозможно использовать это поле, так как к перевозчику привязан заказ.",
  PACKING_SHELF: "Постоянная (упаковочная полка)",
  PACKING_SHELF_INFO1: "Имя полки должно быть штрих-кодом",
  PRODUCT: "Продукт",
  PRODUCT_DEFINE_METHOD: "Метод определения продукта",
  PRODUCT_DEFINE_TO_SHELVE: "Назначить продукт на полку",
  BARCODE_NU: "Штрих-код",
  GET: "Получить",
  PRODUCT_ID: "ID продукта",
  VARIATION_ID: "ID варианта",
  PRODUCT_NAME: "Название продукта",
  VARIATION: "Вариант",
  SERIAL_NUMBER: "Серийный номер",
  NOTHING: "Ничего",
  LOCATION_MSG: "Полки отображаются, даже если нет запасов",
  WAREHOUSE_ANALYSIS: "Анализ соответствия продукта складу",
  RESPONSE_ITEM_COUNT: "Количество единиц",
  RESPONSE_ENOUGH: "Соответствие",
  REQUEST_QUANTITY: "Количество запроса",
  RESPONSE_QUANTITY: "Количество ответа",
  PRIORITY: "Приоритет",
  RESPONSE_COUNT: "Количество ответов",
  REQUIRED: "Обязательно",
  NOT_REQUIRED: "Не обязательно",
  PRODUCT_SEARCH: "Поиск продукта",
  NOT_FOUND_RESULT: "Результаты по запросу не найдены",
  APPROVE: 'Одобрить',
  APPROVED: 'Одобренный',
  TOTAL_COUNTED: 'Общее количество',
  THOSE_AWAITING_APPROVAL: 'Ожидающие одобрения',
  WAITING: 'Ожидающий',
  TRANSFERRED: 'Перенесенный',
  NOT_QUEUED: 'Не в очереди',
  TRANSFERRED_MANUALLY: 'Ожидающий ручного переноса',
  MANUALLY_TRANSFERRED: 'Ручной перенос',
  MISTAKE: 'Произошла ошибка',
  FAULT: 'Ошибка',
  DETAIL_VIEW: 'Подробный просмотр',
  PRODUCTS_DEFINED: 'Необходимо определить продукты',
  ORDER_WAREHOUSE: "склад_заказов",
  TRIAL_PROCESS: "процесс_теста",
  DOMAIN_NAME: "Алан Ади"
}

var loginRu = {
  COPYRIGHT_RESERVED: "Все права защищены.",
  ENTER: "Войти",
  PASSWORD: "Пароль",
  EMAIL: "Эл. почта",
  LOGIN: "Вход",

  USER_CREATE: "Создать пользователя",
  TOTAL_NUMBER_RECORDS: "Общее количество записей",
  TOTAL_NUMBER_PAGES: "Общее количество страниц",
  NUMBER_RECORD_PAGES: "Количество записей на странице",
  POWERS: "Полномочия",
  CREATE_AUTHORIZATION_GROUP: "Создать группу полномочий",
  AUTHORITY_NAME: "Название полномочия"

}

var batchOperationsForTsoftRu = {
  IMPORT_PRODUCTS_AND_FILTERS: "Добавить фильтры к продуктам",
  IMPORT_PRODUCT_FILTER_FOR_TSOFT_INFO1: "В этом разделе с помощью Excel можно определить фильтры продуктов для выбранного T-Soft B2C канала.",
  IMPORT_EXCEL: "Массовые операции с Excel",
  STEP_FILE_IMPORT: "Загрузить файл",
  STEP_SERVICE_SELECT: "Выбрать сервис",
  STEP_PREPARE_TEMPLATE: "Подготовить данные",
  STEP_UPLOAD_DATA: "Загрузить данные",
  STEP_SERVICE_FILE_HEADER: "Есть заголовок в файле",
  STEP_SELECT_TEMPLATE: "Выбрать шаблон",
  TEMPLATE_EDITING: "Редактирование шаблона",
  TEMPLATE: "Шаблон",
  STEP_NEW_TEMPLATE: "Новый шаблон",
  COLUMN: "Колонка",
  TEMPLATE_NAME: "Имя шаблона",
  SAMPLE_LINES_WITHOUT_LOADING: "Примеры строк без загрузки",
  TOTAL_NUMBER_OF_ROWS: "Общее количество строк",
  NUMBER_OF_ROWS: "Количество строк",
  SAVE_TEMPLATE: "Сохранить шаблон",
  ESTIMATED_DELIVERY_DATE: "Дата доставки",
  UPDATE_TEMPLATE: "Обновить шаблон",
  PROGRESS_SUMMERY: "Сводка загрузки",
  DOWNLOAD_CENTER: "Центр загрузок",
  STEP_SELECT_EXTRA_FIELD: "Дополнительные поля",
  CHOOSE_CARGO_FIRM: "Выберите грузовую компанию",
  STEP_SELECT_EXTRA_FIELD_INFO: "Дополнительные поля показывают другие нужные поля в зависимости от выбранного.",
  NOT_YET_SAVED: "Не сохранено",
  MULTIPLE: "Множественный",
  OTHER_FILTERS: "Другие фильтры",
  QUICK_PROCESS_FILTERS: "Фильтры быстрого процесса",
  PROCESS_FILTERS: "Фильтры процесса",
  CUSTOMER_CONTACT: "Уведомление клиента",
  RECEIVING_ACCEPT: "Статус принятия возврата",
  FIRST_PRICE_CONTROL: "Подтверждение цены предложения",
  FIRST_QUANTITY_CONTROL: "Подтверждение количества предложения",
  DOCUMENT_PRICE_CONTROL: "Подтверждение цены документа",
  DOCUMENT_QUANTITY_CONTROL: "Подтверждение количества документа",
  LAST_PRICE_CONTROL: "Подтверждение итоговой цены",
  LAST_QUANTITY_CONTROL: "Подтверждение итогового количества",
  FREE_QUANTITY_CONTROL: "Подтверждение бесплатного продукта",
  CUSTOMER_CONTROL: "Подтверждение клиента",
  DISCOUNT_CONTROL: "Подтверждение скидки",
  SAVE_SELECTED_SYSTEM: "Сохранить выбранные в системе",
  REMOVE_SELECTED: "Удалить выбранные",
  BACK: "Назад",
  SEND: "Отправить",
  NEXT: "Далее",
  MY_UPLOADS: "Мои загрузки",
  SHOW_ALL: "Показать все",
  SHOW_WAITING_ACTIONS: "Показать только ожидающие действий",
  FILE_NAME: "Имя файла",
  TITLE_INFORMATION: "Информация о заголовке",
  TITLE: "Заголовок",
  ERROR_MESSAGE: "Детали ошибки",
  ROW_MATCH: "Соответствие в системе"

}

var actionRu = {
  ISSUE: "Заявка",
  ISSUE_STATUS: "Статус заявки",
  ONLY_ANOMALY_ISSUE_STATUS: "Только списки с другим статусом",
  ISSUE_NO: "Номер заявки",
  ISSUE_CREATE: "Создать заявку",
  SHELF_EXIT_ISSUE_CREATE: "Создать заявку на выход с полки",
  SHELF_ENTRY_ISSUE_CREATE: "Создать заявку на вход на полку",
  FAULTY_ISSUE_CREATE: "Создать заявку на неисправность",
  ISSUE_DETAIL: "Детали заявки",
  ISSUES: "Заявки",
  ORDER_PREPARE_LIST: "Заявка",
  ORDER_PREPARE_LIST_CREATE: "Создать заявку",
  ORDER_PREPARE_LIST_NAME: "Заявка",
  LIST_TYPE: "Тип заявки",
  LIST_CONTENT: "Содержание заявки",
  MARKETPLACE: "Маркетплейсы",
  ACTON_TYPE: "Тип заказа",
  OFFER_LIST: "Предложения",
  SALES_OFFER: "Предложения по продажам",
  RECEIVING: "Приемка",
  RECEIVING_TYPE: "Тип приемки",
  RECEIVING_CONFLICT: "Конфликт количества",
  RECEIVING_REJECT_QUANTITY: "Отклоненные товары",
  RECEIVING_COMPLETED: "Процесс приемки завершен",
  RECEIVING_COMPLETED_INFO: "Процесс приемки завершен, но сервисные действия еще продолжаются. Выберите товар по штрих-коду для замены. Поскольку тип приемки <b>Свободный</b>, можно подтвердить и завершить процесс.",
  OFFER: "Предложение",
  REGULATION_STOCK_ENTRY: "Вход по регулированию запаса",
  REGULATION_STOCK_EXIT: "Выход по регулированию запаса",
  SHOULD_BE_ENTERED1: "Следует вводить как USD/EUR/Руб.",
  SHOULD_BE_ENTERED2: "Следует вводить как -1/-2/-3 (-1: банковский перевод, -2: кредитная карта, -3: наличные)",
  CART: "Корзина",
  NEW_CART: "Новая корзина",
  PRODUCT_CURRENT_HISTORY: "Текущая история товара (в этом году)",
  TOTAL_SALES: "Общая продажа",
  TOTAL_QUANTITY: "Общее количество",
  AVAILABLE_BASKETS: "Доступные корзины",
  OFFER_ID: "Номер предложения",
  OFFER_TYPE: "Тип предложения",
  OFFER_STATUS: "Статус предложения",
  OFFER_EXPIRE_STATUS: "Срок действия предложения",
  TIME_PERIOD: "Период",
  ACTUAL_STATUS: "Текущий статус",
  QTY_OFFER_NOT_YET_FINAL: "Предложения с неокончательными количествами",
  EXPIRED: "Истек",
  DONE: "Завершено",

  SALES_STATISTICS: "Статистика продаж",
  PURCHASING_STATISTICS: "Статистика покупок",

  LAST_SALES_QTY: "Количество продаж за последние {{months}} месяцев",
  LAST_ORDER_QTY: "Количество заказов за последние {{months}} месяцев",
  EDIT_ORDER: "Изменить заказ №{{sipNo}}",

  COMPLETE2: "Завершить",
  REJECT2: "Отклонить",
  NOT_PURCHASED2: "Не приобретено",

  COMPLETE_ALL: "Завершить (все)",
  REJECT_ALL: "Отклонить (все)",
  NOT_PURCHASED_ALL: "Не приобретено (все)",
  ASSOCIATED_OFFER: "Связанное предложение",
  PRODUCT_TOTAL_OF_ASSOCIATED_OFFER: "Общая стоимость связанных предложений",
  COUNT_OF_PURCHASE_OFFER: "Количество предложений на покупку",

  DEMAND_NUMBER: "Номер запроса",
  DEMAND_TYPE: "Тип запроса",
  DEMAND_STATUS: "Статус запроса",
  DEMAND_DATE: "Дата запроса",
  DEMANDER: "Запросивший",

  REJECT: "Отклонено",
  CREATED_BY: "Создатель",
  CREATE_AN_OFFER: "Создать предложение",
  CREATE_STOCK_MOVEMENTS: "Создать движение запасов",
  MOVEMENTS: "Движения",
  STOCK_MOVEMENT: "Движение запаса",
  STOCK_MOVEMENTS: "Движения запасов",
  STOCK_MOVEMENT_DETAIL: "Детали движения запаса",
  CUSTOMER_CODE: "Код клиента",
  EXAMPLE_CUSTOMER_CODE: "код_клиента",
  WAREHOUSE_FOR_ACTION: "Склад для действий",
  WAREHOUSE_FOR_ACTION2: "Второй склад",
  STOCKTAKINGS_NOT_FOUND_TITLE: "Инвентаризация не найдена",
  STOCKTAKINGS_NOT_FOUND_BODY: "Нажмите на 'Новый', чтобы открыть форму и создать новую инвентаризацию",
  ACTION_TYPE: "Тип действия",
  EXAMPLE_ACTION_TYPE: "тип_действия",
  EXAMPLE_ACTION_TYPE_INFO: "Возможные типы действий перечислены ниже.",
  NEW_ACTION_TYPE: "Новый тип действия",
  CHOOSE_ACTION_TYPE: "Выберите тип действия",
  CHOOSE_REFUND_TYPE: "Выберите причину возврата",
  INVOICE_NU: "Номер счета",
  INVOICE_DATE: "Дата счета",
  CHOOSE_DUE_DATE: "Выберите срок",
  DUE_DATE: "Срок",
  DAY: "День",
  CREATOR_USER: "Создатель",
  SELECT_CREATOR_USER: "Выберите создателя",
  ORDER_PREPARE_USER: "Подготавливающий пользователь",
  SELECT_ORDER_PREPARE_USER: "Выберите подготавливающего пользователя",
  TRANSACTION_NOTE: "Заметка о транзакции",
  EXAMPLE_TRANSACTION_NOTE: "заметка_о_транзакции",
  SHIPPING_TYPE: "Тип доставки",
  CARGO_TYPE: "Тип груза",
  CARGO: "Груз",
  SHIPPING_TYPE1: "Груз",
  SHIPPING_TYPE2: "Самовывоз",
  TAX_TYPE: "Тип налога",
  ACTION_INFO1: "Должно рассчитываться на основе общей суммы с НДС.",
  ACTION_INFO2: "Чтобы изменить записи, приостановите ввод товаров и внесите изменения.",
  CONTINUE: "Продолжить",
  SELECTED_PRODUCTS: "Выбранные товары",
  SELECTED_PRODUCT: "Выбранный товар",
  SELECTED: "Выбран",
  SELECTED_ISSUES: "Выбранные заявки",
  ISSUE_NAME: "Заявка",
  BASKET: "Корзина",
  BASKET_AMOUNTS: "Сумма корзины",
  BASKET_DISCOUNT: "Скидка на корзину",
  VAT_INCLUDED: "С НДС",
  NET_VAT_INCLUDED: "Чистая сумма с НДС",
  VAT: "НДС",
  VAT_NOT_INCLUDED: "Без НДС",
  NET_VAT_NOT_INCLUDED: "Чистая сумма без НДС",
  NUMBER_OF_MISSING_PRODUCT: "Количество недостающих товаров",
  BUY_PRODUCT_INFO: "Вы уверены, что хотите купить {{productQuantity}} товаров?",
  CLEAR_LIST: "Очистить список",
  NOTE: "Заметка",
  STOCK_CODE: "Код товара",
  DISCOUNT: "Скидка",
  DISCOUNT_PERCENT: "Процент скидки",
  RECOMMENDED_SHELF: "Рекомендуемая полка",
  PRICE: "Цена",
  NEW_PRICE: "Новая цена",
  QUANTITY: "Количество",
  QTY: "Кол.",
  ORDER_QUANTITY: "Количество заказа",
  QUANTITY_DIFF: "Изменение количества",
  LAST_BUYING: "Последняя покупка",
  FREE: "Бесплатно",
  ADDITION_FREE_QUANTITY: "Доп. количество бесплатно",
  RETURNABLE_QUANTITY: "Возвратное количество",
  HOW_MANY_ARE_FREE: "Сколько бесплатно",
  WILL_BE_SENT_LATER: "Будет отправлено позже",

  BBD_LOT: "BBD / LOT",
  FROM_LAST: "С последнего",
  LOT_SERIAL: "Лот/серийный номер",
  ENTER_LOT_SERIAL: "Введите лот/серийный номер",
  CHOOSE_A_LOT_SERIAL: "Выберите лот/серийный номер",
  LOT_SERIAL_SELECTION: "Выбор лота/серийного номера",
  LEAD_TIME_DESC: "Описание срока поставки",
  OPEN: "Открыто",

  SEARCH_RESULT: "Результаты поиска",
  PRODUCT_QUANTITY: "Количество товара",
  NUMBER_OF_TRANSACTION: "Количество транзакций",
  PLEASE_SEARCH_TO_SELECT_LOT_SERIAL: "Пожалуйста, выполните поиск для выбора лота/серийного номера.",
  LOT_SERIAL_NOT_DEFINED_INFO: "Лот/серийный номер не указан на выбранном складе",
  PRODUCT_FOLLOW: "Отслеживание запасов",
  SUB_PRODUCT_FEATURE: "Характеристика подтовара",
  STOCK_QTY: "Количество на складе",
  WAREHOUSES_QTY: "Распределение по складам",
  WITH_DISCOUNT: "Со скидкой",
  DISCOUNT_TYPE: "Тип скидки",
  ADD: "Добавить",
  BULK_ADD: "Добавить массово",
  BULK_ADD_INFO: "Для массового добавления клиентов воспользуйтесь возможностями интеграции.",
  ACTION_INFO3: "Нет записей для отображения",
  TRANSPORTER_OR_SECTION: "Транспорт/Секция",
  PREPARING_USER: "Подготавливающий пользователь",
  CARGO_DATE: "Дата отправки",
  COMPLETE_TIME: "Время завершения",
  INCOMPLETE: "Не завершено",
  DOCUMENT: "Документ",
  DOCUMENT_STATUS: "Статус документа",
  DEPTOR: "Должник",
  NO_DEPT: "Без долгов",
  CREDITOR: 'Кредитор',
  SELECTED_SHELF: 'Выбранная Полка',
  MORE_SHELVES: 'больше полок',
  MORE: 'больше',
  LESS: 'меньше',
  DAY_HAS_PASSED: 'дней прошло',
  BBD: 'СГ',
  ENTER_QUANTITY_WITH_BBD: 'Введите Количество с СГ',
  SENT: 'Отправлено',
  BBD_ENTRANCE: 'Вход СГ',
  FAULTY_INPUT: 'Ошибочный Ввод',
  WAREHOUSE_COUNTING_CONFIRM: 'Подтверждение Инвентаризации',
  WAREHOUSE_COUNTING_CONFIRM_INFO: 'Количество введенных и продуктов на полке не совпадает. Хотите подтвердить?',
  ACTION_LINE_COUNT: 'Количество Позиций',
  MOVEMENT_TYPE: 'Тип Движения',
  MOVEMENT: 'Движение',
  LAST_MOVEMENT: 'Последнее Движение',
  LAST: 'Последний',
  VISITED_SHELF_QUANTITY: 'Количество Посещенных Полок',
  VISITED_SHELF: 'Посещенная Полка',
  CHANNEL_TYPE: 'Название Канала',
  CUSTOMER_INFO: 'Информация о Клиенте',
  NOT_ASSIGNED_ORDER: 'Заказы без Пользователя',
  ORDERS_IN_PACKAGE_PROCESS: 'В Процессе Упаковки',
  PREPARED_ORDERS: 'Подготовленные Заказы',
  NOT_PREPARED_ORDERS: 'Неподготовленные Заказы',
  NOT_ASSIGNED_TRANSPORTER: 'Не Назначенные Перевозчики',
  ORDERS_HAS_ONE_PRODUCTS: 'С одним Товаром',
  MIXED: 'Смешанный',
  SINGLE_PRODUCT: 'Один Товар',
  SINGLE_QTY: 'Единичное Количество',
  SINGLE_PROD_OR_QTY: 'Один Товар или Количество',
  UNDER_DECLARED_ORDERS: 'Не Полностью Указанные Заказы',
  TRANSACTION_ID: 'Номер Транзакции',
  STATUS_FILTERS: 'Фильтры по Статусам',
  FILTER_OPTIONS: 'Опции Фильтрации',
  SHIPPING_DATE: 'Дата Отгрузки',
  SELECT_WAREHOUSE: 'Выберите Склад',
  USER_WAREHOUSE: 'Склады Пользователя',
  MAIN_WAREHOUSE: 'Основной Склад',
  USER_PASSWORD_INFO: 'Внимание! Заполнив поле пароля, вы измените пароль пользователя.',
  SELECT_PAYMENT_TYPE: 'Выберите тип оплаты',
  CARE: 'ВНИМАНИЕ',
  SHELF_CODE_INFO: 'Этот товар привязан к другому коду полки. Подтвердить размещение на новой полке?',
  FIELD: 'Поле',
  SHIPMENT_CODE: 'Код Отгрузки',
  VALUE: 'Значение',
  SELECT_FIELD: 'Выберите Поле',
  NOT_PREPARED_INVOICE: 'Не Созданные Счета',
  ACTION_NOT_IN_VAT: 'Без НДС',
  DELETED_ACTIONS: 'Удаленные Операции',
  ARCHIVE: 'Архив',
  ACTION_LINES_QTY_TRANSFER_EXCEL: 'Перенести Общий Товарный Объем',
  ACTIONS_TRANSFER_EXCEL: 'Перенести Операции',
  TRANSFER_TO_PDF: 'Экспортировать в PDF',
  TRANSFER_PDF_OFFER_MODE: 'Подготовить PDF как Предложение',
  REPREPARE_ACTION: 'Отменить Подготовку Заказа',
  COPY_ACTION: 'Копировать Складское Движение',
  SHOW_ERP_INTEGRATION: 'Показать ERP Интеграцию',
  EDIT_ACTION_INFO1: 'Редактирование возможно в течение 24 часов после создания складского движения.',
  EDIT_ACTION_INFO3: 'Пользователя можно изменить в списке.',
  EDIT_ACTION_INFO4: 'Чтобы изменения по товарным позициям отобразились в разделе заказов, нажмите «Обновить».',
  PRINT: 'Печать',
  PRODUCTS: 'Продукты',
  INTERNATIONAL_PRICE: 'Цена в Валюте',
  LOCAL_PRICE: 'Цена в Рублях',
  CASH_AMOUNT: 'Сумма Наличными',
  CREDIT_CARD_AMOUNT: 'Сумма по Карте',
  TRANSFER_AMOUNT: 'Сумма Перевода',

  ORDER_LIST_INFO1: 'Редактирование возможно только для типа предложения, если прошло 24 часа.',
  ORDER_LIST_INFO2: 'Редактирование недоступно через 24 часа после создания складского движения.',
  ENTER_TRANSACTION_NOTE: 'И Введите Заметку о Транзакции',

  TOTAL_STOCK_QTY: 'Общее Количество на Складе',
  TOTAL: 'Итого',
  SUB_TOTAL: 'Промежуточный Итого',
  SHOWING: 'Показано',
  GO_ACTION: 'Перейти к Операции',
  PAGE: 'Страница',
  PREPARE_LIST_INFO1: 'Создать новый список для выбранных заказов',
  PREPARE_LIST_INFO2: 'Список Ожидающих Подготовки',
  PREPARE_LIST_INFO3: 'Сейчас нет активного списка для подготовки',

  PREPARE_LIST_INFO4: 'Мои Списки',
  PREPARE_LIST_INFO5: 'Нет активных списков',

  SHIPMENT_LIST_INFO1: 'Процессы Доставки',
  SHIPMENT_LIST_INFO2: 'Процесс интеграции доставки и отслеживание возможных ошибок',

  SHIPMENT_LIST_INFO3: 'Список Проверки перед Отправкой',
  SHIPMENT_LIST_INFO4: 'Список для окончательной проверки перед отправкой заказов.',

  SHIPMENT_LIST_INFO5: 'Передать в Доставку',
  SHIPMENT_LIST_INFO6: 'Список для передачи заказов с завершенной проверкой',

  CART_INFO1: 'КОРЗИНА',
  CART_INFO2: 'Корзина пуста',

  REMOVE_PRODUCT_CART: 'Удалить Товар из Корзины',
  COMPLETE_CART: 'Завершить Корзину',

  MISSING_ITEM_INFO1: 'Сообщенные Недостающие Товары',
  MISSING_ITEM_INFO2: 'Нет данных о недостающих товарах',

  ACCOUNTING_LIST_INFO1: 'Бухгалтерские Переводы',
  ACCOUNTING_LIST_INFO2: 'Процесс создания счетов и отслеживание возможных ошибок',
  ACCOUNTING_LIST_INFO3: 'Заказ еще не на стадии выставления счета',

  SHIPMENT_STATUS: 'Статус Доставки',
  SHIPMENT_INFO1: 'Заказы для Доставки',
  SHIPMENT_INFO2: 'Нет ожидающих доставки заказов',

  ISSUE_LIST_INFO1: 'Список Рабочих Заказов',
  ISSUE_LIST_INFO2: 'По заданным критериям рабочие заказы не найдены. Попробуйте изменить фильтры.',

  ISSUE_CREATE_ACTION_LIST_INFO1: 'Заказы для Добавления в Рабочие Заказы',
  ISSUE_CREATE_ACTION_LIST_INFO2: 'Список для поиска заказов для добавления в рабочие заказы.',

  TRY_AGAIN: 'Повторить Попытку',

  CREATE_LIST: 'Создать Рабочий Заказ',
  UPDATE_LIST: 'Обновить Рабочий Заказ',
  SINGULAR: 'Единственное',
  ADD_ORDER: 'Добавить Заказ',
  CHANGE_USER: 'Сменить Пользователя',
  GO_PRODUCT: 'Перейти к Товару',
  PENDING_ISSUES: 'Ожидающие Рабочие Заказы',
  OWN_ISSUES: 'Мои Рабочие Заказы',
  STOCK_MOVEMENT_FOR_NOT_CREATED_ISSUE: 'Движения Товара без Созданного или Завершенного Рабочего Заказа',

  SEARCH_RESULT_MATCH: 'Результат поиска найден с "Точным Соответствием"',
  SEARCH_RESULT_SIMILAR: 'Результат поиска найден с "Похожими Соответствиями"',

  SELECT_IMAGE_UPLOAD_FORMAT: 'Выберите Формат Загрузки Изображения',

  STOCK_MOVEMENT_INFO1: 'Это движение товара еще не обработано',
  STOCK_MOVEMENT_INFO2: 'Процессы для этого движения товара еще не завершены',

  TRIGGERING_STAGE_YET: 'Не достигнута стадия активации',
  PROCESS_RUN_PARALLEL: 'Процесс работает параллельно',
  PLACE: 'На Очереди',
  ACCOUNTING_MUST_APPROVE: 'Необходимо одобрение бухгалтерии.',

}

var notificationRu = {
  NOTIFICATIONS: "Notifications",
  NOTIFICATION_INFO1: "You can set which levels the notification alert will be visible at.",
  NOTIFICATION_INFO2: "Notifications older than the last 24 hours are automatically deleted.",
  NO_NEW_NOTIFICATIONS: "You have no notifications yet.",
  NOTIFICATION_SETTINGS: "Notification Settings",
  GENERAL: "General",
  IN_COMPANY: "In Company",
  PERSONALITY: "Personal",
  CLEAR_NOTIFICATIONS: "Clear Notifications",
  MESSAGE: "Message",
  WHO: "To Whom",
  NOTIFICATION_DOWNLOAD_INFO1: "Download links for the services you've requested to download.",
  NOTIFICATION_DOWNLOAD_INFO2: "",
  DOWNLOAD_NOTIFICATION: "Download Links",

}
var ordersPrepareRu = {
  ORDER_PREPARING: "Подготовка заказа",
  ORDER_TRANSPORTER_RELATION: "Отношения заказа и перевозчика",
  TRANSPORTER: "Перевозчик",
  SECTION: "Секция",
  EMPTY_SECTION: "Пустая секция",
  ORDERS_FOR_WAITING_PRODUCTS: "Заказы с ожидающими продуктами",
  ORDERS_SECTION_INFOMSG1: "Не сопоставлен ни один заказ с секцией",
  ORDERS_SECTION_INFOMSG2: "Не сопоставлен ни один заказ с перевозчиком.",
  ORDERS_SECTION_INFOMSG5: "Нет подготовленных заказов",
  ORDERS_SECTION_INFOMSG6: "Нет продуктов, которые нужно подготовить для заказов",
  ORDERS_SECTION_INFOMSG7: "Пожалуйста, отсканируйте код товара, который вы помещаете в коробку заказа",
  ORDERS_SECTION_INFOMSG4: "Все полки в упаковке заняты. Вы можете добавить новые полки через <a href=\"/#!/location-management?tab=5\"><b>этот линк</b></a>.",
  ORDERS_SECTION_INFOMSG3: "Полка для упаковки не была определена. Вы можете определить её через <a href=\"/#!/location-management?tab=5\"><b>этот линк</b></a>.",
  ORDERS_PREPARING_FOR_TRANSPORTER: "Подготовка заказа для перевозчика",
  ORDERS_PREPARING_FOR_LIST: "Подготовка заказа по списку",
  ORDERS_RECEIVING: "Прием товара",
  ORDERS_PICKING: "Сборка заказа",
  HANDLING: "Обработка",
  CONTROL: "Контроль",
  DONE_PROCESS: "Доставка",
  SHIPPING_ITEMS: "Подтверждение отправки",
  PREPARED_ORDERS: "Подготовленные заказы",
  ORDERS_IN_PACKAGING_PROCESS: "Заказы в процессе упаковки",
  PACKAGING: "Упаковка",
  PACKAGING_INFO: "Ваши списки на этапе упаковки будут отображаться здесь. В настоящее время у вас нет списка на этапе упаковки.",
  WILL_PREPARE_PRODUCT_FOR_ORDERS: "Продукты, которые будут подготовлены для заказов",
  CLOSE_CONTINUE_READ: "Закрыть и продолжить чтение",
  CONTINUE_READ: "Продолжить чтение",
  RETURN_SHELF: "Вернуть на полку",
  RETURN: "Возврат",
  RETURN_RECEIVING: "Прием возврата товара",
  PARTIAL_RETURN: "Частичный возврат",
  SAME_SHELF: "Та же полка",
  NEXT_SHELF: "Следующая полка",
  DEFINED_SHELF: "Товары на определенной полке",
  MOST_LESS: "Минимум",
  MOST_PLUS: "Максимум",
  PLUS_PREPARING: "СОБРАТЬ",
  SELECT_PREPARING_METHOD: "Выберите метод сбора",
  CAMERA: "Камера",
  HAND_TERMINAL: "Ручной терминал",
  HAND: "Ручной сбор",
  SELECT_CAMERA: "Выберите камеру",
  START_NEXT_ISSUE: "Начать следующий заказ",
  ENTER_ISSUES: "Введенные заказы",
  NOT_ENTER_ISSUES: "Не введенные заказы",
  NO_RESULT: "Нет результатов",
  START: "Начать",
  LIST: "Список",
  LIST_NO: "Номер списка",
  LIST_STATUS: "Статус списка",
  RECEIVING_ISSUES: "Прием заказов",
  SHELF_UP: "Положить на полку",
  SHELF_ENTRY: "Вход на полку",
  SHELF_UNDEFINED: "Полка не определена!",
  PLACE_PRODUCTS_WITHOUT_BARCODE: "Разместить без штрих-кода",
  FAULTY: "Неисправный",
  FAULTY_PRODUCT: "Неисправный товар",
  REJECTED_QUANTITY: "Отклоненное количество",
  PICKING_PREVIOUS_ISSUES: "Заказы на сборку",
  PREPARING: "Подготавливающий",
  TOTAL_PRODUCT_COUNT: "Общее количество товаров",
  TOTAL_PRODUCT_QUANTITY: "Общее количество продуктов",
  TOTAL_PRODUCT_SORT: "Общее количество видов продуктов",
  PRODUCT_ORDER_RELATION: "Информация о товаре / заказе",
  LOT_DETAIL_QTY: "Детали партии / Количество",
  LOT_DETAIL: "Детали партии",
  LOT_LIST: "Список партий",
  READY: "Готово",
  REVERT: "Отменить",
  UNCONFIRM: "Отменить подтверждение",
  TRANSPORTER_SECTION: "Секция перевозчика",
  STOP: "Остановить",
  STOP_CAMERA: "Остановить камеру",
  STOPS_THE_CAMERA: "Останавливает камеру",
  PACKAGING_AREA_DETAIL: "Детали зоны упаковки",
  DETAIL: "Детали",
  TRANSPORTER_DETAIL: "Детали перевозчика",
  PRODUCT_LIST: "Список продуктов",
  PRODUCT_APPROVAL: "Одобрение продукта",
  SHIPPING_READY: "Готово к отправке",
  SHOW_ACTION_DETAIL: "Показать детали действия",
  SELECT_ORDER: "Выберите заказ",
  PRODUCT_BARCODE: "Штрих-код продукта",
  ACCEPT: "Принять",
  ACCEPT_ALL: "Принять все",
  SHIPPING_INFO: "Вы можете использовать номер заказа, номер действия, код упаковки и код отправления. Когда вы вставляете штрих-код или сканируете его с помощью терминала, процесс отправки заказа будет подтвержден.",
  STOCKTAKING_PRODUCT_INFO: "Продукт уже был отсканирован в этом инвентаре. Выберите тип обновления",
  REFRESH_COUNT: "Обновить счет",
  ADD_TO_COUNT: "Добавить в счет",
  GROUP_NAME: "Название группы",
  PAST_SKT_INFO1: "На этой полке есть продукт с истекшим сроком годности",
  PAST_SKT_INFO2: "дней назад.",
  EXPIRE_DATE_WARNING: "На этой полке есть продукт с истекшим сроком годности {{days}} дней назад.",
  SYSTEM_WAITING_TIME: "Время ожидания системы",
  ACCEPTED: "Принято",
  REJECTED: "Отклонено",
  PURCHASE_REQUEST_FAILED: "Не удалось приобрести",
  CUSTOMER_CONTACTED: "Связались с клиентом",
  CUSTOMER_NOT_CONTACTED: "Не связывались с клиентом",
  OUTSIDE_RETURN_ACCEPT_STATUS: "Статус принятия возврата",
  OUTSIDE_RETURN_ACCEPT_STATUS_ACCEPT: "Принять возврат",
  OUTSIDE_RETURN_ACCEPT_STATUS_REJECT: "Отклонить возврат",
  OUTSIDE_RETURN_ACCEPT_STATUS_ONLY_REJECT: "Отклонить только поврежденные",

  MAIN_PRODUCT_CODE: "Основной код продукта",
  CONNECTED_MAIN_PRODUCT: "Подключенный основной продукт",
  LOT_SERIAL_ENTERED: "Общее количество партий/серий выбранных продуктов должно быть {{must_qty}}.",
  PRODUCT_SHOULD_BE_PLACED_SECTION: "Продукт должен быть размещен в разделе {{section_name}}.",
  PRODUCT_SHOULD_BE_PIECES: "Продукт должен быть {{qty}} штук.",
  PRODUCT_PLACED_IN_SECTION: "Продукт {{product_code}} размещен в разделе.",
  ORDER_TRANSFERRED: "Переведено {{order_count}} заказов.",
  ENTER_QTY_OR_SCAN_BARCODE: "Введите количество или отсканируйте штрих-код",
};

var reportsRu = {
  REPORTS: "Отчеты",
  REPORTS_INFOMSG1: "В рамках вашей полномочий не найдено отчетов для отображения",
  REPORTS_INFOMSG2: "Отчет, созданный в",
  FILE_TYPE: "Тип файла",
  CREATE_REPORT: "Создать отчет",
  UPDATE_STATUS: "Обновить статус",
  REQUEST_PENDING: "Запрос в ожидании",
  REQUEST_QUEUED: "Запрос поставлен в очередь",
  REPORT_PRODUCED: "Запрашиваемый отчет генерируется",
};

var commonRu = {
  HELP_PAGE: "Страница помощи",
  HELP_WITH_VIDEO: "Помощь с видео",
  INSTALL_WIZARD: "Мастер установки",
  SYSTEM_SETTINGS: "Настройки системы",
  MOVEMENT_SYSTEM: "Внутреннее движение системы",
  CHANNEL_SETTINGS: "Настройки канала",
  PROVIDER: "Поставщик",
  CHANNEL_NAME: "Название канала",
  CHANNEL_NAME_LABEL: "Название вашего рынка на поставщике",
  DEFAULT_CURRENCY: "Основная валюта",
  EXAMPLE_RATE: "курс",
  TAX_STATUS: "Статус налога",
  SET_AS_DEFAULT: "Установить как по умолчанию",
  SPECIAL_ELECTION: "Специальный выбор",
  HAS: "Есть",
  NOT_HAS: "Нет",
  PROTOCOL: "Протокол",
  WEB_SERVICE_URL: "URL веб-сервиса (например; www.xyz.com)",
  API_URL: "URL API (например; https://www.xyz.com)",
  API_KEY: "API ключ",
  API_SECRET: "API секрет",
  WEB_SERVICE_USERNAME: "Имя пользователя веб-сервиса",
  WEB_SERVICE_PASSWORD: "Пароль пользователя веб-сервиса",
  SETTINGS_INFOMSG1: "Вы можете определить информацию о веб-сервисах для ваших сайтов T-Soft b2c.<br>\n" +
    "                                Вы можете обновить \"состояния заказов\" и \"типы оплаты\" с помощью кнопки Обновить настройки.",
  SETTINGS_INFOMSG2: "Для выбранного канала настройки состояния заказов не загружены. Пожалуйста, нажмите.",
  SETTINGS_INFOMSG3: "В разделе интеграции после выбора канала\n" +
    "                                        вы можете автоматически настроить, какие состояния заказов будут выбраны.\n" +
    "                                        <br/>Также необходимо настроить состояния заказов, которые вы хотите, чтобы автоматически\n" +
    "                                        загружались в систему, как по умолчанию.",
  SETTINGS_INFOMSG4: "Чтобы просмотреть типы оплаты, пожалуйста, выберите канал в верхней части.",
  SETTINGS_INFOMSG5: "В этом разделе вы можете определить, с какого склада будут списываться заказы с вашего сайта B2C в зависимости от их состояния.",
  SETTINGS_INFOMSG6: "Отразить общий объем запасов.",
  SETTINGS_INFOMSG7: "Если установлено в \"Да\", то \"Общий объем запасов\" будет отправлен на выбранный\n" +
    "                                            канал.<br/>\n" +
    "                                            Выбранный склад будет; если не выбран, то склад для мануальной передачи будет выбран\n" +
    "                                            в следующем разделе в зависимости от состояния заказа и типа движения.",
  SETTINGS_INFOMSG8: "Вы можете определить соответствие складов для количества запасов, которое будет отправлено на ваши сайты B2C.\n" +
    "                                    Также, вы можете отразить общий объем запасов на вашем сайте.",
  SETTINGS_INFOMSG9: "Вы можете определить критерии для передачи заказов в бухгалтерскую программу.",
  SETTINGS_INFOMSG10: "Вы можете вручную запустить автоматически выполняющиеся запланированные задачи.",
  SETTINGS_INFOMSG11: "Вы можете определить адреса электронной почты, на которые будут приходить уведомления о товарах с критически низким количеством.\n" +
    "                                            (Вы можете указать несколько адресов электронной почты, используя разделитель <b>\",\"</b>.)",
  SETTINGS_INFOMSG12: "Вы можете изменить группу пользователей, которая будет готовить заказы, используя приведенную выше форму.",
  SETTINGS_INFOMSG13: "Вы можете изменить группу пользователей, которая будет контролировать подготовленные заказы, используя приведенную выше форму.",
  SETTINGS_INFOMSG14: "Вы можете изменить группу пользователей, которая будет проверять подготовленные заказы, используя приведенную выше форму.",
  SETTINGS_INFOMSG15: "Вы можете изменить изменения состояния заказов в процессе подготовки, используя приведенную выше форму.",
  SETTINGS_INFOMSG16: "Операции, выполняемые в канале, будут применяться ко всем движениям запасов, созданным через Polypus.",
  SETTINGS_INFOMSG17: "Вы можете выбрать поле, которое будет для вас уникальным в карточке товара.",
  UPDATE_PARAMETER: "Обновить параметр",
  PROVIDER_ID: "ID поставщика",
  DEFAULT: "По умолчанию",
  PAYMENT_TYPES: "Типы оплаты",
  FINAL_PAYMENT_ALERT: "Предупреждение о последнем платеже",

  UNPAID_INFO: "У вас есть неоплаченный счет. <br> Если вы не произведете оплату, услуга будет завершена через 12 часов.<br> Стоимость включения/выключения будет отражена в следующем счете",

  SELECT_CHANNEL: "Выберите канал",
  ADDED_DATE: "Дата добавления",
  VALIDITY_DATE: "Дата действия",
  LAST_UPDATE: "Последнее обновление",
  NO_SHELF_CODE: "Нет кода полки",

  PAYMENT_AWAITING: "Ожидается оплата",
  PAYMENT_DOOR_APPROVED: "Оплата при доставке одобрена",
  PRODUCT_BEING_PREPARED: "Продукт готовится",
  POS_APPROVAL: "Одобрение POS",
  ALL_PAYMENT_TYPES: "Все типы оплаты",
  RESERVE_WAREHOUSE: "Резервный склад",
  RETAIL_SALES: "Розничная продажа",
  IF_SELECTED_INFO: "Если выбрано, общий объем запасов будет отправлен в ваш канал",

  AUTOMATIC_TRANSFER_SETTINGS: "Настройки автоматического переноса",
  DEFINE_DYNAMIC_STOCK_ACTION: "Определить динамическое движение запасов",
  SELECT_ALL: "Выбрать все",
  REMOVE_ALL: "Удалить все",
  SUGGEST_DYNAMIC_ACTIONS: "Предложенные динамические действия",
  APPLIED_TRANSACTION_TYPE: "Применяемый тип операции",
  WAREHOUSE_CHANNEL_RELATION: "Связь канала с основным складом",
  IS_SEND_TOTAL_STOCK: "Отправлять общий запас?",
  YES: "Да",
  NO: "Нет",
  ERP_INTEGRATION_SETTINGS: "Настройки интеграции ERP",
  MISSION_NAME: "Название задания",
  COMMAND: "Команда",
  EXPRESSION: "Выражение",
  COMMAND_ARGUMENTS: "Аргументы команды",
  CRON_JOB: "Запланированные задачи",
  RUN: "Запуск",
  COMPANY_SETTINGS: "Настройки компании",
  NOTIFY_EMAIL_SETTING: "Настройки уведомлений по электронной почте",
  EMAIL_ADDRESS: "Адрес электронной почты",
  COMPANY_UPLOAD_LOGO: "Загрузить логотип компании",
  ADD_BANKS: "Добавить банк",
  BANKS: "Банки",
  IBAN: "IBAN",
  BRANCH_BANKS: "Банковская информация компании",
  NEW_BRANCH_TITLE: "Добавить компанию",
  BRANCH_NAME: "Юридическое наименование компании",
  BRANCH_NAME_2: "Наименование",
  DEFAULT_BRANCH: "Установить как основную компанию",
  PACKAGE_DETAIL: "Детали пакета",
  PRODUCT_IMAGE: "Изображение продукта",
  BRANCH_ADDRESS: "Адрес компании",
  BRANCH_LOGO: "Логотип",
  BRANCH_SIGNATURE: "Подпись / Штамп",
  BRANCH_WATERMARK: "Водяной знак",
  SELECTED_BRANCH: "Выберите компанию",
  BRANCH_INFO_TITLE: "Информация о компании",
  BRANCH_INFO_TITLE_INFO: "Здесь можно зарегистрировать дочерние компании. Используется для настройки документов, таких как проформа.",
  BRANCH_FORM_DETAIL: "Характеристики формы",
  BRANCH_FORM_DETAIL_INFO: "Здесь определяются характеристики для форм, таких как проформа и форма заказа",
  ORDER_PREPARE_ROLE: "Группа пользователей для подготовки заказов",
  CURRENT_SELECTED_ROLE: "Текущая выбранная группа",
  SELECT_USER_ROLE: "Выберите группу пользователей",
  CONTROLLER_ROLE: "Группа пользователей для контроля заказов",
  TRANSPORTER_ORGANIZATION: "Организация перевозчика",
  PREPARATION_TYPE: "Тип подготовки",
  BULK_PREPARATION: "Массовая подготовка заказов",
  DIVIDED_PREPARATION: "Закази по разделам сборки",
  MOST_ATTACH_ORDER_TO_TRANSPORTER: "Максимальное количество заказов, привязанных к перевозчику",
  DEFAULT_VALUE: "По умолчанию",
  FREE_TRANSPORTER: "Свободный перевозчик",
  RELATION_WITH_USER: "Связать с пользователем",
  USER_RELATION_HEAD: "Связь с пользователем",
  RELATION: "Отношение",
  ORDER_SYNC: "Синхронизация заказов",
  ACTION_STATUS: "Статус операции",
  DASHBOARD: "Обзор",
  ORDER_PREPARE: "Подготовка заказа",
  STOCK_MANAGEMENT: "Управление запасами",
  SHELF_CHANGE: "Изменить полку",
  SHELF_CHANGE_NAME: "Название изменения полки",
  CHANGE_SHELF_CODE: "Код полки назначения",
  TAKING_SHELF_CODE: "Код забираемой полки",
  SHELF_CHANGE_ALL: "Все",
  SHELF_CHANGE_ACCEPT: "Изменить полку",
  LOCATION_STOCKTAKING: "Инвентаризация местоположения",
  STOCKTAKING: "Инвентаризация",
  COUNTING: "Подсчет",
  STOCKTAKING_ITEM_ACCEPT: "Подтвердить",
  STOCKTAKING_MAKE: "Провести инвентаризацию",
  STOCKTAKING_CREATE: "Создать инвентаризацию",
  STOCKTAKING_LIST_NAME: "Название списка инвентаризации",
  STOCKTAKING_LIST: "Список инвентаризации",
  STOCKTAKING_LISTS: "Списки инвентаризаций",
  STOCKTAKING_DESCRIPTION: "Детали",
  BASKET_SUMMARY: "Итог счета",
  STOCKTAKING_COMPLETE: "Завершить инвентаризацию",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1: "Потенциальные движения",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_1_1: "Полка",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2: "Полки с продуктом",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_3: "Вход",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_4: "Выход",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_5: "Название полки",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_6: "Количество",
  STOCKTAKING_COMPLETED: "Завершено",
  STOCKTAKING_PRODUCT_DETAIL_HEADER_2_1: "Другие полки с продуктом",
  PRODUCTION: "Производство",
  BALANCE: "Баланс",
  PRODUCTION_STREAMS: "Потоки производства",
  PRODUCTION_MOVEMENT: "Ввод производства",
  PRODUCTION_MOVEMENTS: "Движения производства",
  CARGO_OPERATION: "Операции с грузом и транспортировкой",
  CARGO_BARCODE: "Штрихкод груза",
  BULK_ACTIONS: "Массовые операции",
  INTEGRATIONS: "Интеграции",
  LOCATION_MANAGEMENT: "Управление местоположениями и полками",
  RETURN_MANAGEMENT: "Управление возвратами",
  SHELF_MANAGEMENT: "Управление полками",
  CATEGORY_BRAND_MANAGEMENT: "Управление категориями и брендами",
  STOCK_ORGANIZATION: "Организация запасов",
  BRAND_MANAGEMENT: "Управление брендами",
  TAG_MANAGEMENT: "Управление метками",
  TAG_MANAGEMENT_INFO: "Категория метки не обязательна. Для решения вашей проблемы с категорией метки, пожалуйста, обратитесь.",
  BRAND: "Бренд",
  TAG: "Метка",
  TAG_NAME: "Метка",
  TAG_CATEGORY: "Категория метки",
  MODEL: "Модель",
  BRAND_NAME: "Название бренда",
  BRAND_LINK: "Ссылка на бренд",
  SHELF_SELECT: "Выберите полку...",
  SHELF_SELECTED: "полка выбрана...",

  ORDER_STATUS_SELECT: "Выберите статус заказа...",
  ORDER_STATUS_SELECTED: "статус выбран...",

  MODEL_SELECT: "Выберите модель...",
  MODEL_SELECTED: "модель выбрана...",
  CATEGORY_SELECT: "Выберите категорию...",
  CATEGORY_SELECTED: "Категория выбрана",
  CATEGORY_TREE: "Дерево категорий",
  NEW_MAIN_CATEGORY: "Новая основная категория",
  NEW_MAIN_CATEGORY_NAME: "Название новой основной категории",
  CATEGORY_NAME: "Название категории",
  NEW_SUBCATEGORY_NAME: "Название новой подкатегории",
  USER_AND_ROLES: "Пользователи и роли",
  ROLES: "Роли",
  PERSONAL_SETTINGS: "Личные настройки",
  CLOSE: "Закрыть",
  MODULES: "Модули",
  LOGOUT: "Выйти",
  LOGOUT_AND_ALL_DEVICE: "Выйти со всех устройств",
  ADMIN: "Админ",
  GENERAL_INFORMATION: "Общая информация",
  IS_WHOLESALE_PRODUCT: "Это оптовый товар?",
  QUICK_PRODUCT: "Быстрый ввод",
  QUICK_PRODUCT_ADD: "Быстрый ввод продукта",
  ADD_PRODUCT: "Добавить продукт",
  ADD_BULK_PRODUCT_REPORT: "Отчет по массовому добавлению продуктов",
  ADD_BULK_PRODUCT: "Массовое добавление продукта",
  IS_THERE_TITLE: "Есть ли заголовок?",
  COLUMN_SEPARATOR: "Разделитель столбцов",
  LINE_SEPARATOR: "Разделитель строк",
  ADD_PRODUCT_INFO: 'Добавьте продукты сюда',
  PRODUCT_QUEUE: 'Очередь продуктов',
  BARCODE_QTY_PRICE_INFO: 'Должно быть в формате: штрихкод, количество, цена',

  SELECT_SUB_PRODUCT: 'Выберите подпродукт',
  SUPPLIER_CODE: "Код поставщика",
  DESCRIPTION: "Описание",
  PRODUCT_SHORT_DESCRIPTION: "Краткое описание продукта",
  ENTER_PRODUCT_TAG: "Введите тег продукта",
  PRINT_LABEL: "Печать этикетки",
  ADD_TO_MISSING_CART: "Добавить в корзину для отсутствующих товаров",
  REMOVE_FROM_MISSING_CART: "Удалить из корзины для отсутствующих товаров",
  SUPPLIER: "Поставщик",
  STOCK_UNIT: "Единица на складе",
  PRICE_INFORMATION: "Информация о цене",
  SELLING_PRICE_WITH_EXAMPLE: "(Например; 13.34, 1234.5)",
  SELLING_PRICE: "Цена продажи",
  BELOW_SELLING_PRICE: "Ниже цены продажи",
  ABOVE_LAST_PURCHASE_PRICE: "Выше последней покупной цены",
  NUMBER_OF_SOLD: "Количество проданных товаров",
  REMAINING_NUMBER_OF_PRODUCTS: "Оставшееся количество товаров",
  SELLING_PRICE_VAT_INCLUDED: "Цена продажи с НДС",
  DISCOUNTED_SELLING_PRICE: "Цена продажи со скидкой",
  DISCOUNTED_SELLING_PRICE_VAT_INCLUDED: "Цена продажи со скидкой с НДС",
  DISCOUNT_APPLIED: "Скидка применена",
  BUYING_PRICE: "Цена покупки",
  TAX_PERCENT: "Процент НДС",
  CHANGE_TAX_PERCENT: "Изменить процент НДС",
  CHANGE_TAX_PERCENT_OF_THE_OFFER: "Изменить процент НДС предложения",
  CHANGE_THE_PRODUCT_TAX_PERCENT: "Изменить НДС продукта",
  PRODUCT_NOT_COLLECTED: "Товар не собран",
  COLLECTED_PRODUCT: "Собранный товар",
  COLLECTED_QUANTITY: "Собранное количество",
  PREPARATION_TIME: "Время подготовки",
  PRODUCT_DEFECTIVE: "Неисправный товар",
  RESERVED: "Зарезервировано",
  ACTUAL: "Фактическое",
  REMAINDER: "Остаток",
  LOCATION_INFO: "На складах еще нет.",
  CARGO_INFORMATION: "Информация о грузе",
  CARGO_AND_ADD_INFORMATION: "Информация о грузе и дополнительные данные",
  SAME_ALL_CHANNEL: "Все каналы одинаковы",
  SALES: "Продажа",
  PURCHASE: "Покупка",
  PURCHASE_RECEIVING: "Приемка покупки",
  PURCHASE_OFFERS: "Предложения по покупке",
  PURCHASE_REQUESTS: "Запросы на покупку",
  SALES_OFFER_DRAFT: "Черновик предложения по продаже",
  PURCHASE_OFFER_DRAFT: "Черновик предложения по покупке",
  SALES_OFFER: "Предложение по продаже",
  PURCHASE_OFFER: "Предложение по покупке",
  PRODUCTION_OFFER_DRAFT: "Черновик предложения по производству",
  RETURN_OFFER_DRAFT: "Черновик предложения по возврату",

  LOCATION_TRANSFER_ENTRY_RECEIVING: "Приемка трансфера между складами",

  CHANNEL_BASED: "По каналу",
  SEO_SETTINGS: "Настройки SEO",
  SEO_LINK: "SEO ссылка",
  SEO_HEAD: "SEO заголовок",
  SEO_DESCRIPTION: "SEO описание",
  PRODUCT_INFORMATION: "Информация о продукте",
  PRODUCT_DETAIL: "Детали продукта",
  ADDITIONAL_INFO1: "Дополнительная информация 1",
  ADDITIONAL_INFO2: "Дополнительная информация 2",
  ADDITIONAL_INFO3: "Дополнительная информация 3",
  ADDITIONAL_INFO4: "Дополнительная информация 4",
  ADDITIONAL_INFO5: "Дополнительная информация 5",
  MIN_ORDER_QTY: "Мин. количество для заказа (для участников)",
  MAX_ORDER_QTY: "Макс. количество для заказа (для участников)",
  LIST_NU: "Номер списка",
  OPTIONS: "Опции",
  ACTIVE: "Активный",
  NEW_PRODUCT: "Новый продукт",
  ENTRY_STOCK_QTY: "Количество на складе (вход)",
  CRITIC_STOCK_QTY: "Критическое количество на складе",
  C_S: "К.С.",
  V_S: "В.С.",
  VIRTUAL_STOCK_QTY: "Виртуальное количество на складе",
  INVENTORY_INFORMATION: "Информация о запасах",
  INVENTORY: "Инвентаризация",
  FOLLOW_METHOD: "Метод отслеживания",
  NOT_FOLLOW: "Не отслеживается",
  UNIQUE_SERIAL_NU: "Уникальный серийный номер",
  LOT: "Партия",
  SUB_PRODUCT: "Вариант",
  HAS_SUB_PRODUCTS: "Имеет варианты",
  ADD_SUB_PRODUCT: "Добавить вариант",
  PUT_PRODUCTS_ON_SHELF: "Положить продукты на полку",
  RECIPE: "Рецепт продукта",
  RECIPE_CONTENT: "Содержание рецепта",
  CBM: "Куб. м.",
  UPDATE_RECIPE: "Обновить рецепт",
  CHANNEL_INFO: "Для этого продукта отсутствуют канальные ценовые данные",
  RECIPE_RECEIVING: "Приемка рецепта продукта",
  PRODUCTION_RECEIVING: "Приемка производства",
  FREE_ENTRY_RECEIVING: "Свободная приемка",
  PACKAGE_PRODUCT_CONTENT: "Комплектация",
  ADD_PACKAGE_PRODUCT_CONTENT: "Добавить продукт",
  CREATE_STOCK: "Создать склад",
  PROPERTY1: "Свойство 1",
  PROPERTY2: "Свойство 2",
  RECIPE_INFO: "Продукты с рецептом",
  RECIPE_PRODUCT: "Продукт с рецептом",
  RECIPE_INFO2: "Если вы добавите рецепт к продукту, увеличение запасов будет выполнено через раздел 'Производственный рецепт'. Продукты по рецепту будут уменьшены, а этот продукт будет увеличен по рецепту.",
  RECIPE_INFO3: "Рецепт не может быть применен, так как для этого продукта введены данные о комплекте или партии",
  VARIATION_PRODUCT: "Продукт с вариантом",
  VARIATION_PRODUCT_LOT_MESSAGE: "Поскольку для продукта введены данные о варианте, вы должны выбрать информацию о запасах для подпродуктов",
  VARIATION_PRODUCT_PACKAGE_MESSAGE: "Поскольку для продукта введены данные о варианте, упаковка не может быть определена",
  PACKAGE_PRODUCT_VARIATION_MESSAGE: "Поскольку продукт определен как комплект, нельзя добавлять варианты",
  PACKAGE_NOT_UPDATED_MESSAGE: "Этот продукт уже был зарегистрирован, поэтому изменение пакета невозможно",
  LOT_PRODUCT_VARIATION_MESSAGE: "Поскольку для продукта установлен метод отслеживания, нельзя добавлять варианты",
  STOCK_LIST: "Список запасов",
  SEARCH_IN_STOCK: "Поиск на складе",
  SEARCH_IN_TAG: "Поиск по тегу",
  SELECT_BRAND: "Выберите марку",
  SELECTED_BRAND: "Марка выбрана",
  SEARCH_BRAND: "Поиск по марке",
  CRITICAL_STOCK: "Критический запас",
  OUT_OF_STOCK: "Нет в наличии",
  MINUS_STOCK: "Отрицательный запас",
  VIRTUAL_STOCK: "Виртуальный запас",
  ACTIVE_PRODUCT: "Активный продукт",
  PROCUREMENT: "Закупка",
  NOT_SUPPLIED: "Не поставлено",
  PACKAGE_PRODUCT: "Продукт в комплекте",
  EMPTY_STOCK: "Закончено",
  NEARLY_EMPTY_STOCK: "Заканчивается",
  ENOUGH_STOCK: "Достаточно",
  SHOW_PRODUCT_CARD: "Показать карточку товара",
  SHOW_PRODUCT_VARIATION: "Показать вариации товара",
  SHOW_PRODUCT_ACTION: "Показать действия со складом товара",
  UPDATE_PRODUCT_CLICK: "Обновить продукт",
  CLONE_STOCK: "Клонировать карточку товара",
  SHOW_MAIN_PRODUCT_BARCODE: "Основной штрихкод товара",
  PRINT_PRODUCT_BARCODE: "Печать штрихкода товара",
  PRINT_ACTION_BARCODE: "Печать штрихкода действия",
  DELETE_PRODUCT_CLICK: "Нажмите, чтобы удалить продукт",
  PASSIVE_PRODUCT_CLICK: "Нажмите, чтобы сделать продукт неактивным",
  SYNC_PRODUCT_CLICK: "Обновить цели продукта",
  STOCK_INFOMSG1: "Отправить в очередь для загрузки в Excel",
  STOCK_INFOMSG2: "Для любого продукта не указаны номера партии/серии",
  STOCK_INFOMSG3: "Для этого продукта отсутствуют данные о запасах вариаций",
  STOCK_INFOMSG4: "Для этого склада нет данных о движении",
  STOCK_INFOMSG5: "Нет данных о движении товара",
  STOCK_INFOMSG6: "Для любого продукта не видны номера партии/серии",
  STOCK_INFOMSG7: "Для этого продукта не отслеживаются партии/серии.",
  STOCK_INFOMSG8: "Для этого продукта отсутствует рецепт.",
  STOCK_INFOMSG9: "Для товара, по которому не создан заказ, нет движения на складе.",
  SHOW_PRODUCT_LOT: "Показать номера партии/серии товара",
  CREATE_LOT_SERIAL: "Создать партию/серийный номер",
  LOT_SERIAL_NUMBERS: "Номера партии/серии",
  SELECTED_LOT_SERIAL_NUMBERS: "Выбранные номера партии и серии",
  WITHOUT_WAREHOUSE_INFO: "Показать партии/серии без складской привязки.",
  LOT_SERIAL_IN_STOCK: "Показать партии и серийные номера на складе.",
  SERIAL_LOT_BY_LOCATION: "Состояние партии/серии по местоположению",
  LOT_SERIAL_NUMBER: "Lot/Serial Number",
  ESTIMATED_DATE: "Estimated Date",
  ENTER_BBD: "Enter Expiry Date",
  VARIATION_DISTRIBUTION: "Variation Distribution",
  STOCK_CARD: "Stock Card",
  STOCK_CODE: "Stock Code",
  STOCK_NAME: "Stock Name",
  SUB_PRODUCT_CODE: "Sub Product Code",
  PROPERTIES1: "Property 1",
  PROPERTIES2: "Property 2",
  PRODUCT_ACTIONS: "Product Stock Movements",
  MOVEMENT_DIRECTION: "Movement Direction",
  STATISTIC_REPORT: "Statistic Report",
  SELECT_VARIATION: "Select Variation",
  UNIT_PRICE: "Unit Price",
  PRODUCT_PROPERTIES: "Product Properties",
  REAL_STOCK_QUANTITY: "Real Stock Quantity",
  PRODUCT_DELETED: "Product Deleted",
  TAG_ORDER: "Tag Order",
  CATEGORY_TAG_CODE: "Category Tag Code",
  CREATE: "Create",
  ROLE: "Role",
  ROLE_LIST: "Role List",
  CREATE_ROLE_GROUP: "Create New Role",
  ROLE_NAME: "Role Name",
  PERMISSIONS: "Permissions",
  SELECT_NATIVE: "Select",
  ADD_NEW_CURRENCY: "Add New Currency",
  SELECT_CURRENCY: "Select Currency",
  SELECT_FOREIGN_CURRENCY: "Select Foreign Currency",
  CURRENCY_INFO: "All defined currency types are added.",
  ORDER_LIST_ACCEPTING: "Order List Approval",
  START_PREPARING_ORDER: "Start Preparing Order",
  PREPARATION_STARTED_ON: "Preparation started on",
  PREPARATION_COMPLETED: "Preparation process completed",
  PAYMENT_INFO: "Payment Information",
  ORDER_NATIVE: "Order",
  PRICE_WITH_VAT: "Price (Including VAT)",
  VIRTUAL_STOCK_QUANTITY: "Virtual Stock Quantity",
  STOCK_QUANTITY: "Stock Quantity",
  PRODUCT_TAG: "Product Tag",
  PRODUCT_TAGS: "Product Tags",
  CUSTOMER_NOTE: "Customer Note",
  MISSING_QTY: "Missing Quantity",
  MISSING_REPORT: "Missing Report",
  MISSING_REPORT_CONFIRM: "Missing Report Confirmation",
  SEND_TO_CUSTOMER: "Send to Customer",
  PLACED_ON_THE_SHELF_AGAIN: "Will Be Placed on the Shelf Again",
  RESERVE_WILL_BE_REMOVED: "Customer Reservation Will Be Removed",
  SENT_TO_CUSTOMER_LATER: "Sent to Customer Later",
  IS_IN_STOCK: "Товар в наличии",
  POSSIBLE_STOCK_ERROR: "Возможная ошибка на складе",
  POSSIBLE_STOCK_ERROR_INFO: "Товар в наличии больше, чем заказано, но указан с дефицитом.",
  COMPLETE: "Завершить",
  MISSING_PRODUCT_LIST: "Список отсутствующих товаров",
  MISSING_PRODUCTS: "Отсутствующие товары",
  MISSING_PRODUCT: "Отсутствующий товар",
  CREATE_ORDER: "Создать заказ",
  REVERT_ACTION_NATIVE: "Отменить действие",
  INVOICE_INFOMSG1: "Информация об отсутствующих товарах для заказа не введена",
  INVOICE_INFOMSG2: "Можно создать заказ с движением склада",
  ARRIVAL_PERSONAL: "Лицо, принимающее товар",
  INVOICE_NAME: "Имя для счета",
  ORDER: "Заказ",
  ORDER_DETAIL: "Детали заказа",
  CRITICAL_STOCK_LEVEL: "Критический уровень запасов",
  WAREHOUSE_MANAGEMENT: "Управление складом",
  WAITING: "Ожидание",
  WAITING_COMPLETED: "Ожидание / Завершено",
  AWAITED: "Ожидается",
  MOBILE: "Мобильный",
  NET: "Чистый",
  CARGO_READY: "Груз готов",
  CARGO_TRACKING: "Отслеживание груза",
  WAITING_FOR_CARGO: "Ожидает груз",
  NOT_YET_ACCESSIBLE: "Еще недоступно",
  CARGO_COUNTED: "Посчитано",
  DELIVERED_CARGO_COMPANY: "Передано транспортной компании",
  IN_WAITING: "Ожидает",
  IN_READY: "Готово",
  NEEDED: "Необходимо",
  IN_COMPLETED: "Завершено",
  NOT_PURCHASED: "Не приобретено",
  IN_TRANSFER: "Передано",
  COMBINE_SHELVES: "Объединить полки",
  AVAILABLE_FROM_SHELF_QUANTITY: "Доступное количество с полки",
  CREATE_OFFER_FROM_SELECTED_PRODUCTS: "Создать предложение из выбранных товаров",

  DEMAND_QUANTITY: "Количество запроса",
  NUMBER_OF_OPEN_DEMAND: "Количество открытых запросов",
  MATCHED_PURCHASE: "Совпавшая покупка",
  FIRST_DEMAND_DATE: "Дата первого запроса",
  LAST_DEMAND_DATE: "Дата последнего запроса",
  PURCHASE_OFFER_QUANTITY: "Количество предложения по закупке",
  START_PURCHASE_INFO: "Для этого товара начата закупка, но предложения не привязаны к запросу",
  ASSOCIATE_TO_DEMAND: "Привязать к запросу",
  NOT_PURCHASE_INFO: "Нет ожидающих назначений предложения по закупке",
  DEMANDS: "Запросы",


  STATUS_WAIT: "Процесс в ходе",
  IN_PROGRESS: "В процессе",
  LAST_COMPLETED: "Последнее завершение",
  STATUS_OFFER_APPROVE: "Предложение одобрено",
  APPROVED: "Одобрено",
  STATUS_OFFER_CREATE_ORDER: "Создается заказ",
  STATUS_OFFER_DONE: "Процесс предложения завершен",
  STATUS_OFFER_CANCEL: "Отменено",
  RETURNED: "Возвращено",
  PAYMENT_MADE: "Оплата произведена",
  DELIVERED: "Доставлено",
  SHIPPED: "Отправлено",
  PRODUCT_WAREHOUSE: "Склад товара",
  NEUTRAL: "Нейтрально",
  TASK_ENTRY: "Ввод задачи",
  LOG: "Журнал",
  MINUTE: "Минута",
  PLATFORM: "Платформа",
  STOP_ADD_PRODUCT: "Остановить добавление товара",
  START_ADD_PRODUCT: "Начать добавление товара",
  FILTER_DETAIL: "Подробные фильтры",
  OK: "ОК",
  ATTACH: "Прикрепить",
  BULK_PRINT: "Печать нескольких",
  SELECT_PREPARE_USER: "Выберите подготовителя",
  SELECT_ASSIGN_ACTION_LIST: "Списки",
  NEW_ACTION_LIST: "Новый список",
  ACTION_LIST_NAME: "Название списка",
  NEW_ACTION_LIST_PRIORITY_NORMAL: "Обычный",
  NEW_ACTION_LIST_PRIORITY_HIGH: "Высокий приоритет",
  PRIORITY_SHELF: "Приоритетная полка",
  NEW_ACTION_LIST_PRIORITY_VERY_HIGH: "Очень высокий приоритет",
  NEW_ACTION_LIST_PRIORITY_URGENT: "Срочный",
  USERS: "Пользователи",
  ENTER_INVENTORY_SEARCH_CRITERIA: "Нажмите F3 для поиска",
  SHOW_CHANNEL_DETAIL: "Детали канала",
  PROFILE_SETTINGS: "Настройки профиля",
  ACCOUNT_SETTINGS: "Настройки учетной записи",
  TIMEZONE: "Часовой пояс",
  SELECT_TIMEZONE: "Выберите часовой пояс",
  LANGUAGE: "Язык",
  SELECT_LANGUAGE: "Выберите язык",
  STOCK_SELECT: "Выберите склад...",
  GENERAL_SETTINGS: "Общие настройки",
  RELEASE_NOTES: "Примечания к версии",
  SUPPORT: "Поддержка",
  ABOUT_US: "О нас",
  PREFERRED_CODE: "Предпочитаемый код товара",
  PREFERRED_PRODUCT_CODE: "Предпочитаемый код товара",
  RELOAD: "Перезагрузить",
  LOADING: "Загрузка",
  SHELVE_SEARCH_INFO: "Поиск полки для операции",
  SHELVE_SEARCH_INFO2: "Вы можете найти полку с помощью нашего поиска по коду полки",
  OFFER_SEARCH_INFO: "Поиск предложений",
  OFFER_SEARCH_INFO2: "Используйте критерии поиска для нахождения предложений",

  STOCKTAKING_INFO: "Инвентаризация",
  STOCKTAKING_INFO2: "Инвентаризация",
  STOCKTAKING_PRODUCT_INFO_EMPTY: "Для этого товара еще не создано инвентаризационное задание",

  OFFER_PRODUCT_INFO: "Количество товаров в активных предложениях",
  OFFER_PRODUCT_INFO2: "Количество товара в предложениях, которые еще не подтверждены",

  RECEIVING_INFO: "Прием товара",
  RECEIVING_INFO2: "Нет ожидающих приемок товара",

  RETURN_OFFER_SCREEN_INFO1: 'Создание возврата',
  RETURN_OFFER_SCREEN_INFO2: 'Вы можете искать по номеру заказа, номеру груза и идентификатору заказа',
  SORTING: "Сортировка",
  CHANGE: "Изменить",
  CHANGE_LANGUAGE: "Сменить язык",
  CHANGE_SORTING: "Изменить сортировку",
  PRICE_DIFFERENCES: "Разница в цене",
  QUANTITY_DIFFERENCES: "Разница в количестве",
  DEFECTIVE_PIECES: "Бракованные изделия",
  PRODUCT_IMAGE_PATH: "URL изображения товара",
  IMAGE_FROM_PATH: "Загрузить изображение по URL",
  TAKE_PHOTO: "Сделать фото",
  NO_DOCUMENT_SELECTED_YET: "Документ еще не выбран",
  DEFAULT_PRODUCT_IMAGE_UPLOAD: "Путь к изображению по умолчанию",
  ANALYTICS: "АНАЛИЗ",
  WITH_VAT: "С НДС",
  VAT_TABLE: "Таблица НДС",
  PRODUCT_PRICE_NO_DISCOUNT: "Цена товара без скидки",
  ANALYTICS_EMPTY_INFO: "Нет данных",
  ANALYTICS_EMPTY_INFO2: "Еще нет данных для анализа",
  NEW: "Новый",
  EXISTS: "Существует",
  MATCH: "Совпадение",
  IMAGE: "Изображение",
  COUNTED: "Посчитано",
  RECEIVING_MESSAGE_INFO: "Срок годности и информация о серийном номере..",
  RECEIVING_MESSAGE_INFO2: "В случаях, когда форма товаров повреждена, визуальное доказательство",
  PRODUCT_EXPIRE_DATE: "Срок годности",
  SELECT_PRODUCT: "Выберите товар",
  STOCK_MOVEMENT_SUMMARY: "Сводка движения товаров",
  PRODUCT_SALES_REPORT: "Отчет по продажам товаров",
  TURNOVER_REPORT: "Отчет о обороте",
  SUMMARY_LIST_TYPE: "Тип сводного списка",
  SUMMARY_LIST_TYPE_MARKETPLACE: "Рынки как столбцы",
  SUMMARY_LIST_TYPE_DEFAULT: "По умолчанию",
  VENDOR_CODE: "Код поставщика",
  SELECT_VENDOR: "Информация о поставщике",
  VENDOR: "Поставщик",
  EXCHANGE_RATE_INFO: "Обмен валюты",
  EXCHANGE_RATE_INFO2: "Здесь вы можете увидеть и отредактировать обменные курсы валют. Для валют, для которых вы не ввели значения, будет использовано значение 1",

  EMPTY_PURCHASE_REQUEST_INFO: "Запрос на покупку",
  EMPTY_PURCHASE_REQUEST_INFO2: "Товары по вашим критериям не найдены. Попробуйте изменить критерии поиска и повторите попытку.",

  EMPTY_RECIPE_INFO: "Рецепты",
  EMPTY_RECIPE_INFO2: "Товары по вашим критериям не найдены. Для создания товара с рецептом",
  EMPTY_RECIPE_ITEM_INFO: "Товары рецепта",
  EMPTY_RECIPE_ITEM_INFO2: "Товара нет в рецепте",
  NEW_RECIPE: 'Новый рецепт',
  NEW_RECIPE_NAME: 'Название нового рецепта',
  PRODUCT_RECIPES: 'Рецепты товара',
  STATUS: 'Статус',
  TOTAL_PRODUCT_COUNT: 'Общее количество товаров',
  TOTAL_RECIPE_COUNT: 'Общее количество рецептов',
  SHOW_RECIPE: 'Показать рецепт',

}

var paginationRu = {
  TOTAL_RECORD_COUNT: "Общее количество записей",
  TOTAL_PAGE_COUNT: "Общее количество страниц",
  PAGINATION_RECORD_COUNT: "Пагинация",
  PERIOD: "Период",
  CALCULATING: "Вычисляется",
  UPDATED_DATE: "Дата обновления"
};


var offerTypeRu = {
  sales: 'Продажа',
  purchase: 'Покупка',
  return_inside: 'Возврат изнутри',
  return_outside: 'Возврат снаружи',
  missing_action: 'Неполное уведомление',
  REPORT: 'Отчет',
  OFFER_RECIPE: 'Предложение на производство',

  PRODUCTION_RECIPE: 'Рецепт производства',
  NEW_PRODUCTION_RECIPE: 'Новый рецепт производства',
  NEW_PRODUCTION_PLAN: 'Новый производственный план',
  PRODUCTION_PLANNING: 'Планирование производства',

  PRODUCTION_OFFER: 'Черновик предложения на производство',
  NEW_PRODUCTION_OFFER: 'Новый черновик предложения на производство',
  PURCHASE_PROCESS: 'Процесс закупки'

}

var toastServiceMessages = {
  OFFER_SAVED: 'Предложение сохранено.',
  SEARCH_DATA_NOT_PRODUCT: 'Поисковые данные не относятся к продукту',
  RETURN_NUMBER_REQUIRED: 'Необходим номер возврата',
  STOCK_CARD_UPDATED: 'Карточка товара обновлена',
  FILE_UPLOADED: 'Файл загружен...',
  ENTER_THE_VALUE_SEARCH: 'Введите значение для поиска',
  SEARCHED_VALUE_WAS_NOT_FOUND: 'Не найдены заказ или номер действия по запрашиваемому значению',
  ENTER_SERIAL_OR_EXPIRATION_DATE: 'Введите серийный номер или дату истечения срока',
  OFFER_COUNTING_COMPLETED: 'Подсчет предложений завершен',
  WAREHOUSE_CREATED_SUCCESSFULLY: 'Склад успешно создан.',
  WAREHOUSE_DELETED: 'Склад удален.',
  SHELF_VERIFIED: 'Полка подтверждена, отсканируйте код продукта',
  NO_SHELF_FOUND: 'Полка не найдена',
  COUNTING_LIST_INFO: 'Действие создано на основе списка инвентаризации',
  ALL_WAREHOUSES_CREATED: 'Все предложенные склады (резервные, продуктовые, дефектные) успешно созданы.',
  GUIDE_REQUEST_CREATED: 'Запрос на руководство создан.',
  GUIDE_DOWNLOADED: 'Руководство загружено.',
  RANK_LEAST_0: 'Ранг может быть не меньше 0',
  WIDTH_LEAST_0: 'Ширина может быть не меньше 0',
  HEIGHT_LEAST_0: 'Высота может быть не меньше 0',
  DEPTH_LEAST_0: 'Глубина может быть не меньше 0',
  SHELF_ADDED_SUCC: 'Полка успешно добавлена',
  AN_ERROR: 'Произошла ошибка',
  UPDATED_SUCC: 'Успешно обновлено',
  AN_ERROR_UPDATING: 'Произошла ошибка при обновлении',
  AN_ERROR_DELETING: 'Произошла ошибка при удалении',
  BARCODED_PRODUCT_ADDED_LIST: 'Товар с баркодом добавлен в список',
  CHECK_SHELF_INFORMATION: 'Проверьте информацию о полке.',
  SHELF_REPLACEMENT_SUCC: 'Замена полки успешна',
  NO_PRODUCT_WITH_BARCODE: 'Товар с таким баркодом не найден',
  MESSAGE_SENT_SUCC: 'Ваше сообщение успешно отправлено.',
  MUST_SELECT_1_ORDER: 'Вы должны выбрать хотя бы один заказ',
  PRODUCT_INCREASED_1: 'Количество товара увеличено на 1.',
  SELECT_PROD_VARIANT: 'Пожалуйста, выберите вариант товара.',
  TRANSACTION_CREATED_SUCC: 'Операция успешно создана.',
  STOCK_MOVEMENT_UPDATED_SUCC: 'Движение товара успешно обновлено.',
  ENTERED_INCORRECT_PRODUCT: 'Вы ввели некорректную информацию о товаре',
  TRANSACTION_COPIED: 'Выбранная операция скопирована.',
  POLYPUS_READY_HANDHELD_TERMINAL: 'Полипус готов к работе с ручным терминалом.',
  NOT_STARTED_READING: 'Вы еще не начали чтение.',
  TOTAL_NUMBERS_INFO: 'Обрабатываются общие числа, они появятся, когда завершится процесс',
  DIDNT_CHOOSE: 'Вы не выбрали',
  APP_READY_OFFLINE: 'Приложение готово к использованию в офлайн-режиме.',
  SEARCHED_VALUE_NOT_FOUND: 'Запрашиваемое значение не найдено',
  CHOOSE_VARIANT_PACKAGE_PRODUCT: 'Вы должны выбрать вариант для пакетированных товаров',
  FILE_SIZE_BE_UP_3MB: 'Размер файла не должен превышать 3 МБ',
  FILE_SIZE_BE_UP_500KB: 'Размер файла не должен превышать 500 КБ',
  PRODUCT_INCREASED: 'Количество товара увеличено',
  PRODUCT_ADDED: 'Товар добавлен',
  UPDATED: 'Обновлено',
  DOWNLOADING_FILE: 'Файл загружается...',
  STOCK_MOVEMENT_DOWNLOADED_EXCEL: 'Движение товара загружается в Excel файл.',
  SUCC_LOGGED: 'Успешный вход в систему.',
  ERROR_CREATING_CURRENT_ACCOUNT: 'Произошла ошибка при создании текущего счета.',
  CURRENT_ACCOUNT_CREATED: 'Текущий счет создан.',
  NOT_DELETE_THIS_CUSTOMER: 'Вы не можете удалить или обновить этого клиента.',
  CURRENT_ACCOUNT_DELETED: 'Текущий счет удален.',
  ERROR_UPDATING_CURRENT_ACCOUNT: 'Произошла ошибка при обновлении текущего счета.',
  CURRENT_ACCOUNT_UPDATING: 'Текущий счет обновлен.',
  MISSING_PRODUCT_RECORDED: 'Отсутствующая информация о товаре записана.',
  CREATED_FOR_ORDER: 'Операция создана для заказа {{orderNo}}.',
  RETURN_CREATED: 'Ваш возврат создан',
  NO_AVAILABLE_CAMERA_FOUND: 'Не найдено доступной камеры.',
  PLEASE_ENABLE_BROWSER_PERMISSION: 'Пожалуйста, включите разрешения для браузера для "Чтения с камерой".',
  FAILED_DELETE_SHORTCUT: 'Не удалось удалить ярлык',
  SHORTCUT_REMOVED: 'Ярлык удален',
  SHORTCUT_NOT_FOUND: 'Указанный ярлык не найден',
  ADDED_THIS_PRODUCT_BEFORE_INFO: 'Вы уже добавили этот товар. Если вам нужно больше, вы можете увеличить количество.',
  USER_CREATED: 'Пользователь создан.',
  TOKEN_NOT_RENEWED: 'Токен не был обновлен',
  SEARCH_DATA_NOT_BELONG_PRODUCT: 'Поисковые данные не относятся к товару',
  PRODUCT_ADD_CART: 'Товар добавлен в корзину',
  NEW_CART_CREATED: 'Создана новая корзина',
  CART_CLEARED: 'Корзина очищена',
  PRODUCT_REMOVED_CART: 'Товар удален из корзины',
  CART_COMPLETE: 'Корзина завершена',
  SUB_PRODUCT_SELECTED: 'Выберите подкатегорию товара',
  DOWNLOADING_STOCK_FILE: 'Файл с товарами загружается.',
  NO_RECORD_FOUND: 'Запись не найдена.',
  SERIAL_EXPIRATION_REQUIRED: 'Необходим серийный номер и дата истечения срока',
  STOCK_CARD_CREATED: 'Карточка товара создана.',
  IMAGE_UPLOAD_SUCC: 'Загрузка изображения успешна.',
  STOCK_EXCEL_DOWNLOADING: 'Файл Excel с товарами загружается.',
  SERIAL_LOT_RECORDED: 'Серийный номер / партия записаны.',
  ACCOUNTING_PROGRAM_INFO: 'Настройки автоматической передачи для бухгалтерской программы введены.',
  DEFAULT_CHANNEL_CANNOT_AGAIN: 'Основной канал не может быть открыт снова',
  WEB_SERVICE_SETTINGS_ENTERED: 'Настройки веб-сервиса для канала продаж введены.',
  PROBLEM_CHANNEL_SETTINGS: 'Есть проблемы с настройками канала продаж.',
  ACTIVE_PASSIVE_SETTING: 'Настройка активного / пассивного состояния канала продаж выполнена.',
  ACTIVE_PASSIVE_SETTING_COULD_NOT: 'Канал продаж не может быть активирован или деактивирован',
  YOU_HAVE_MISSING_FIELDS: 'У вас есть недостающие поля',
  COMMAND_QUEUED: 'Команда поставлена в очередь и будет выполнена позже. Мы сообщим вам.',
  ORDER_STATUS_TRANSFERRED: 'Статусы заказов перенесены.',
  PAYMENT_TYPES_TRANSFERRED: 'Типы оплаты перенесены.',
  COMPANY_LOGO_UPLOADED: 'Логотип компании загружен.',
  LOGO_DELETED: 'Загруженный логотип удален',
  SETTING_SAVED: 'Настройки сохранены',
  COMPANY_INFORMATION_RECORDED: 'Информация о компании записана',
  COMPANY_INFORMATION_UPDATED: 'Информация о компании обновлена',
  COMPANY_NAME_REQUIRED: 'Поле имени компании не может быть пустым',
  COMPANY_ADDRESS_REQUIRED: 'Поле адреса компании не может быть пустым',
  BANK_INFO_MUST_ENTERED: 'Банковская информация должна быть введена полностью',
  COMPANY_INFORMATION_DELETED: 'Информация о компании удалена',
  DEFAULT_COMPANY_CHANGED: 'Основная компания была изменена',
  REPORT_COPIED_CLIPBOARD: 'Результаты отчета скопированы в буфер обмена',
  ERROR_REPORT_COPIED_CLIPBOARD: 'Произошла ошибка при копировании результатов отчета',
  STATUS_UPDATED: "<b>{{label}}</b> &nbsp; статус обновлен",
  PAGE_SHORTCUT_NOT_ELIGIBLE: "Страница не подходит для создания ярлыка",
  SHORTCUT_CREATED: "Ярлык создан",
  PAGE_WAS_ADDED_SHORTCUT: "Эта страница уже добавлена как ярлык",
  SETTINGS_BEEN_CHANGED: "Настройки изменены",
  PASSWORD_BEEN_CHANGED: "Ваш пароль изменен.",
  PRINTER_SETTINGS_DEFINED: "Настройки принтера определены.",
  ROLE_CREATED: "Роль создана.",
  ROLE_UPDATED: " роль обновлена.",
  USER_HAS_BEEN_UPDATED: " пользователь обновлен.",
  APPOINTMENT_PROCESS: "Процесс назначения выполнен, вы перенаправляетесь",
  BARCODE_NOT_DEFINED_SYSTEM: "Этот штрихкод не определен в системе",
  SHELF_REPLACED: "Полка заменена",
  PRODUCT_PREPARATION_COMPLETED: "Подготовка продукта завершена, переход к следующему продукту.",
  NO_PRODUCT_SHELF: "Нет продукта на полке",
  INCORRECT_PRODUCT_QUANTITY_ENTRY: "Неверный ввод количества продукта",
  INCOMPLETE_COMPLETED: "Незавершенная заявка завершена",
  PRODUCTS_SUCC_COMPLETED: "Продукты в этом списке успешно завершены",
  WAS_ADDED_BEFORE: " Уже добавлено",
  ASSIGMENT_SUCC: "Назначение успешно выполнено",
  TRANSACTION_COMPLETED_SUCC: "Операция успешно завершена",
  ORDER_SENT_TO_ACCOUNTING: "Ваш заказ №{{transactionId}} отправлен в бухгалтерию.",
  INFORMATION_COMPLETELY: "Пожалуйста, заполните все поля",
  WRONG_SHELF_CODE: "Вы ввели неправильный код полки",
  LEAST_1: "Может быть хотя бы 1 штука",
  MAX_OF_PIECES: "Максимум {{quantity}} штук.",
  NO_ORDERS_WERE_FOUND: "Не найдено заказов для этого перевозчика",
  ORDERS_IN_TOTAL: "Всего {{ordersPrepared}} заказов.",
  SELECTED_ORDERS_ERROR: "Произошла ошибка при обновлении статуса выбранных заказов",
  NO_PACKAGING_SHELF: "Полка для упаковки не найдена.",
  CART_SECTION_NOT_FOUND: "Раздел корзины не найден",
  ORDER_PREPARED_NOT_FOUND: "Не найден подготовленный заказ для перевозчика",
  PRODUCT_NOT_MATCH: "Этот продукт не соответствует ни одному заказу в корзине",
  CHECKING_STAGE_YET: "Нет списка на стадии проверки",
  ERP_FAILED_AGAIN_LATER: "Не удалось загрузить данные ERP. Пожалуйста, попробуйте позже",
  BRAND_ADDED_SUCC: "Бренд успешно добавлен",
  BRAND_UPDATED_SUCC: "Бренд успешно обновлен",
  TAG_ADDED_SUCC: "Тег успешно добавлен",
  CATEGORY_UPDATE_SUCC: "Категория успешно обновлена.",
  CATEGORY_CREATE_SUCC: "Категория успешно добавлена.",
  MAIN_CATEGORY_CREATE_SUCC: "Основная категория успешно добавлена.",
  CATEGORY_DELETE_SUCC: "Категория успешно удалена.",
  TEXT_COPIED: "\"{{text}}\" текст скопирован.",
  RELATED_STOCK_LIST_NOT_LOADED: "Список связанного товара не загружен",
  FILL_ALL_FIELDS: "Пожалуйста, заполните все поля.",
  ADDED_TO_COUNT: "Добавлено в учет",
  WRITE_PRODUCT_BARCODE: "Пожалуйста, введите штрихкод продукта",
  COPYING_STOCK_CARD: "Карта товара скопирована и создана новая.",
  TRANSACTION_LIST_CREATED: "Список транзакций создан.",
  TEMPLATE_SUCC_REGISTERED: "Шаблон успешно зарегистрирован.",
  SUCC_REMOVED_LIST: "Успешно удален из списка",
  DOWNLOAD_LIST_UPDATED: "Список загрузки обновлен",
  ROW_LIST_UPDATED: ", список строк обновлен",
  PASSWORD_SUCC_CHANGED: "Пароль успешно изменен",
  NOT_FOUND_IN_ERP: "НЕ НАЙДЕНО В ERP",
  SURE_SCANNING_CORRECT_BARCODE: "Убедитесь, что вы отсканировали правильный штрихкод",
  NOT_FOUND_THIS_LIST: " не найдено в этом списке.",
  BARCODE_COMPLETED_FOR_ACTIVE_ORDER: " штрихкод завершен для активного заказа",
  BARCODE_NOT_ACTIVE_ORDER: " штрихкод отсутствует в активном заказе. Переход к следующему заказу не выполнен",
  UNEXPECTED_ERROR_OCCURRED: "Произошла непредвиденная ошибка. Мы скоро займемся этим вопросом. Пожалуйста, попробуйте позже.",
  CANNOT_ADD_MORE_PRODUCTS: "Невозможно добавить больше товаров",
  ENOUGH_PRODUCTS: "Вы выбрали слишком много продуктов — {{quantity}}",
  MUST_ENTER_SHELF_CODE: "Необходимо ввести код полки",
  RECEIVING_PROCESS_STOPPED: "Процесс приема товаров остановлен",
  A_NEW_VERSION: "Вышла новая версия. Пожалуйста, очистите историю браузера.",
  CHOOSE_SHELF_FIRST: "Сначала выберите полку",
  LEAST_1_LOT: "Необходимо выбрать хотя бы 1 партию",
  PRODUCT_CHANGED: "Продукт изменен. Но увеличение количества не выполнено, так как он был уже утвержден",
  NOT_RECEIVING: "Продукт изменен. Но прием товаров не выполнен, так как он был завершен",
  WAS_BBD: "Продукт изменен. Но не может быть принят, так как он просрочен",
  ACTIVE_RECEIVING_NOT_SUPPORT_ADDING_PRODUCTS: "Активный прием товаров не поддерживает добавление товаров.",
  MISTAKE_IMAGE_UPLOAD: "Произошла ошибка при загрузке изображения. Ошибка: ",
  WARNING: "Предупреждение",
  NO_ACTION_TAKEN_YET: "Еще не выполнено действие",
  POLYPUS_STATUTE_UPDATED: "Статус Polypus обновлен",
  COUNTED_QUANTITY_NOT_MATCH: "Количество, посчитанное с серийными номерами, не совпадает. Подтвердить?",
  WANT_TO_DELETE_STATUS: "Вы уверены, что хотите удалить этот статус? Он не будет удален из настроек автоматической передачи.",
  AFTER_THIS_SETTING_DELETED: "После удаления этой настройки заказы с указанным статусом будут автоматически списываться из указанного склада без учета статуса. Вы уверены?",
  APPROVED_PRODUCT_TRACKED_MESSAGE: "Одобренный продукт отслеживается, но номер серии не был введен. Подтвердить?",
  CHANNEL_STATUS_UPDATED: "Статус канала обновлен",
  NOTE_ADDED_TO_CHANNEL_RECORD: "Добавлена заметка в запись канала о статусе заказа",
  SELECTED_ORDERS_WILL_UPDATE: "Статус выбранных заказов будет обновлен. Вы уверены?",
  STATUS_SET_DEFAULT: " статус установлен по умолчанию.",
  STATUS_REMOVED_DEFAULT: " статус удален из предустановленных.",
  PRIORITY_RANKING: "Приоритетное ранжирование",
  MANAGES_YOUR_BUSINESS: "Управляет вашим бизнесом.",
  MOTTO_INFORMATICS: "Мотто Информатика",
  GET_INFORMATION: "Получить информацию",
  SUITABLE: "Подходит",
  NOT_SUITABLE: "Не подходит",
  TRANSACTION_ALREADY_ADDED: "{{transactionId}} Уже добавлен",
  LIST_CREATED: "Список создан. Название списка: {{listName}} Приоритет: {{priority}}",
  SELECT_FILE: "Вы должны выбрать файл",
  PRINTER_NOT_DEFINED: "{{printerName}} принтер не определен.",
  SELECT_SERVICE: "Вы должны выбрать сервис",
  SELECTION_LIST_WILL_EMPTIED: "Список выбора будет очищен. Вы уверены?",
  ERROR_CONNECT_TERMINAL: "Не удалось подключиться к терминалу.",
  ORDER_REFERENCE_REMOVED: "Заказ с номером {{orderNumber}} удален из принтера {{printerName}}.",
  MAIN_PRODUCT_AFFILIATED_WITH: "Основной продукт, связанный с",
  EMPTY_SELECTION_ALERT: "Вы не выбрали ничего, пожалуйста, выберите хотя бы один элемент",
  SEPARATE_CONFIRM_ALERT: "Выбранные заказы будут удалены из списка. Это действие необратимо",
  SEPARATE_SELECTED: "Выбрать выбранные",
  SEPARATE_NEW_ISSUE: "Создать новый рабочий заказ",
  SEPARATE_ONLY_SEPARATE: "Удалить из списка",

  COUNT_COMPLETED_BY: "Подсчет завершен <b>{{name}}</b>. Однако есть различия в количестве.",
  CONFIRM_QTY: "Для подтверждения количества ",
  SEE_UPLOADED_DOCUMENT: "Для просмотра загруженных документов ",
  SEE_SERIAL_EXP_DATE: "Для просмотра серийных номеров и сроков годности ",
  CLICK: "Нажмите",
  DOCUMENT_UPLOADED_COUNT: "Для подсчета загружено <b>{{qty}}</b> документа.",

  REQUEST_CREATED_FOR_MISSING_NOTIFICATION: "Запрос, созданный для уведомления об отсутствии, в данный момент находится в статусе <b>{{status}}</b> и одобренное количество: <b>{{qty}}</b>",
  B2C_SERVICE_COMPLETED: "Перевод запасов с вашего B2C сервиса завершен.",
  CANNOT_PRINT_RESPONSIBLE_USER: "Вы не можете распечатать без назначения ответственного пользователя.",
  CHECK_YOUR_EMAIL_FOR_RESET_PASSWORD: "Пожалуйста, проверьте вашу электронную почту для инструкций по сбросу пароля.",
  PRODUCT_OF_MOTTO: "Polypus — это продукт <a href='{{url}}' target='_blank' class='motto-link'>Motto Bilişim</a>. <br> © 2023 Все права защищены.",
}

var langRu = angular.merge(
  MenuRu,
  accountSettingsRu,
  cargoRu,
  customersRu,
  dashboardIndexRu,
  integrationPageRu,
  batchOperationsRu,
  invoicesRu,
  locationRu,
  loginRu,
  batchOperationsForTsoftRu,
  actionRu,
  notificationRu,
  ordersPrepareRu,
  reportsRu,
  commonRu,
  paginationRu,
  offerTypeRu,
  toastServiceMessages
);

export {langRu};
