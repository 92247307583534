import {getUserInfo} from "../../../utils/users";

class RawMaterialEntryController {
    constructor(API, ToastService, $translate, $mdBottomSheet, $scope, DialogService, $state, $location, $anchorScroll, $filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$filter = $filter;
        this.translate = this.$filter('translate');

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

        this.selectedRecipe = {};
        this.selectedRecipeShowData = {}

        this.newRecipeScreen = false;
        this.newRecipemName = null;
        this.newRecipeQuantity = null;

    }

    $onInit() {
        let parent = this;
        this.$scope.$on('selectProductEvent', function (event, selectProduct) {
            parent.getProductRecipe(selectProduct.id);
        });
    }

    clearFilter() {
    }

    getProductRecipe(productId) {
        this.API.all('recipe/product/' + productId)
            .customGET()
            .then((response) => {
                this.recipeProductDetail = response.data.list
                this.recipeProductDetail?.recipes?.map(recipe => {
                    recipe.recipeProductName = this.recipeProductDetail.productName
                    recipe.productId = this.recipeProductDetail.productId
                    if (this.selectedRecipe[recipe.recipeId]) {
                        recipe.newQuantity = this.selectedRecipe[recipe.recipeId].newQuantity;
                    }
                })

                Object.values(this.selectedRecipe).filter(r => r.new).map(r =>{
                    if (this.recipeProductDetail.productId === r.productId) {
                        this.recipeProductDetail?.recipes.push(r);
                    }
                })

            })
            .catch(function () {
            });
    }

    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }

    selectRecipe(recipe) {
        if (this.selectedRecipe[recipe.recipeId]) {
            delete this.selectedRecipe[recipe.recipeId];
            this.setSelectedRecipeShowData()
            return;
        }
        this.selectedRecipe[recipe.recipeId] = recipe;
        this.setSelectedRecipeShowData()

    }

    setSelectedRecipeShowData() {
        this.selectedRecipeShowData = Object.values(this.selectedRecipe).reduce((result, recipe) => {
            const productId = recipe.productId;

            if (!result[productId]) {
                result[productId] = {
                    recipeProductName: recipe.recipeProductName,
                    productId: recipe.productId,
                    recipes: []
                };
            }

            result[productId].recipes.push(recipe);
            return result;
        }, {});
    }

    addNewRecipe() {
        let index = Object.keys(this.selectedRecipe).length + 1;
        let data = {
            new: true,
            edit: true,
            name: this.newRecipemName+ '-'+index,
            newQuantity: this.newRecipeQuantity,
            recipeId: 'new-'+index,
            recipeProductName: this.recipeProductDetail.productName,
            productId: this.recipeProductDetail.productId
        }
        this.selectRecipe(data)

        this.recipeProductDetail?.recipes.push(data);

        this.newRecipeScreen = false;
        this.newRecipemName = null;
        this.newRecipeQuantity = null;
    }
}

RawMaterialEntryController.$inject = ["API", "ToastService", "$translate", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$anchorScroll", "$filter"];
const RawMaterialEntryComponent = {
    templateUrl: "./views/angular/app/components/combine-stock-entry/partials/raw-material-entry.html",
    controller: RawMaterialEntryController,
    controllerAs: "vm",
    bindings: {},
};

export {RawMaterialEntryComponent, RawMaterialEntryController}
