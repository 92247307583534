import Cookies from "js-cookie";

// url, key ile aynı olduğu takdirde kendisi yakalar. manuel tetiklemek için vm.openIntroductionVideoModal('customersList') şeklinde key ile tetiklenmeli

const introductionVideos = {
  dashboard_0: "https://www.youtube.com/embed/HDIzmyLmMf0",
  invoices_0: "https://www.youtube.com/embed/SH_sZFkHZKI",
  order_lists_0: "https://www.youtube.com/embed/EF4injVVYdI",
  order_lists_1: "https://www.youtube.com/embed/fU7bDAb-Zzo",
  stock_management_0: "https://www.youtube.com/embed/JXn-7ypzvxU",
  stock_management_1: "https://www.youtube.com/embed/F45ulYlc-mo",
  customers_0: "https://www.youtube.com/embed/_q8Qog6Q0N8",
  customers_1: "https://www.youtube.com/embed/4ntL0fDnv2U",
  cargo_0: "https://www.youtube.com/embed/mpiEuqOpfz4",
  "batch-operations-byexcel_0":"https://www.youtube.com/embed/0gPOttYe-ek", // excel raf içeri aktar
  "batch-operations-byexcel_1": "https://www.youtube.com/embed/GZYB9Cg4K3o",// ürün raf ilişkisi içeri aktar
  "batch-operations-byexcel_2":"https://www.youtube.com/embed/3tW7jChm2Ps", // işlem listesi içeri aktar
  "integration_0":"https://www.youtube.com/embed/nGe7VdJPIeA",
  "integration_1": "https://www.youtube.com/embed/qz81T9USKzU",
  "integration_2": "https://www.youtube.com/embed/Ki4YayrPnz4",
  "location_management_0": "https://www.youtube.com/embed/IE68d8TPPNk",
  location_management_1: "https://www.youtube.com/embed/kgKps4KOVr0",
  location_management_2: "https://www.youtube.com/embed/g83haIdZEH0",
  "orders-prepare_6": "https://www.youtube.com/embed/4elAZ6z5eRU",
  users_0: "https://www.youtube.com/embed/vBa9Nwc2NoM",
  settings_0: "https://www.youtube.com/embed/axi5pZQW3BQ",
  settings_3: "https://www.youtube.com/embed/-lczCdaKaBg", // otomatik aktarım ayarları
  settings_4: "https://www.youtube.com/embed/HkKEDMiLxYs", // zamanlanmış görevler
  settings_6: "https://www.youtube.com/embed/JjcBEBqX1DY", // bildirim e posta ayarları
};

const openIntroductionVideo = (video, event) => {
  let iframePath = introductionVideos[video];

  if (!iframePath) {
    let videoBaseArr = video.split("_");
    videoBaseArr[videoBaseArr.length - 1] = "0";
    let videoBase = videoBaseArr.join("_");
    iframePath = introductionVideos[videoBase];
    if (!iframePath) {
      return;
    }
  }

  const modalEl = document.querySelector("#introduction-video");
  const iframeEl = document.querySelector("#introduction-video #iframe");
  const dontShowAgainBtn = document.querySelector("#dontShowAgain");

  modalEl.style.cssText = "display: block";
  iframeEl.src = iframePath;
};

const closeIntroductionVideo = () => {
  const modalEl = document.querySelector("#introduction-video");
  const iframeEl = document.querySelector("#introduction-video #iframe");

  modalEl.style.cssText = "display: none";
  iframeEl.src = "";
};

const dontShowIntroductionVideo = () => {
  const modalEl = document.querySelector("#introduction-video");
  modalEl.style.cssText = "display: none";

  const dontShowAgainBtn = document.querySelector("#dontShowAgain");
  const video = dontShowAgainBtn.dataset.video;

  Cookies.set(`polypus-introduction-${video}`, "dontShowAgain", {
    expires: 365,
  });
};

export {
  introductionVideos,
  openIntroductionVideo,
  closeIntroductionVideo,
  dontShowIntroductionVideo,
};
