class PrintPreviewDialogController {
  constructor(DialogService, API, $scope, $translate, $state, $location, $sce, ToastService) {
    'ngInject';
    this.DialogService = DialogService;
    this.API = API;
    this.$scope = $scope;
    this.$translate = $translate;
    this.$state = $state;
    this.$location = $location;
    this.$sce = $sce;
    this.pdfUrl = '';
    this.ToastService = ToastService;

    this.$scope.cancel = function () {
      DialogService.hide();
      this.selectedLines = [];
    };

    /*
    this.$scope.returnActionDetail = (actionId) => {
      this.$state.go('app.order_lists',{actionNu: actionId, tab: 1});
      DialogService.hide();
    }
    */
    //var parent = this.$scope;

    /*
    this.API.all("action")
      .customGET('filter',{
        searchFieldId: 4,
        searchValue: this.reqData.transaction_id
      })
      .then((response) => {
        parent.actions = response.data.actions.data;
      });
    */
  }



  printStockCart(productId) {
    var parent = this;

    var reqData = {
      product_id: productId,
    };

    this.API.all("stock/print-barcode")
      .post(reqData)
      .then(
        (response) => {
          parent.pdfUrl = window.__env.apiUrl + "/api/action/exportPdf/" + response.data.nativeFileName + "?token=" + window.localStorage.satellizer_token;
          //window.open(url, '_blank');
        }
      );
  }
  printLabel(product) {
    var parent = this;
      var reqData = {
          productId: product.productId,
          productLabels: product.productLabels,
      };

    this.API.all("stock/print-label")
      .post(reqData)
      .then(
        (response) => {
          parent.pdfUrl = window.__env.apiUrl + "/api/action/exportPdf/" + response.data.nativeFileName + "?token=" + window.localStorage.satellizer_token;
        }
      );
  }

  actionBatchPrint(selectedActions) {
    var keys = Object.keys(selectedActions), parent = this, reqSelectedActions = [];

    for (var i = 0; i < keys.length; i++) {
      if (selectedActions[keys[i]]) {
        reqSelectedActions.push(keys[i]);
      }
    }

    var reqData = {
      selectedActions: JSON.stringify(reqSelectedActions),
    };

    this.API.all("action/batch-print")
        .post(reqData)
        .then((response) => {
          //window.open(window.__env.apiUrl+"/api/action/exportPdf/"+response.data.fileName+"?token="+window.localStorage.satellizer_token, '_blank');
          parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/"+response.data.fileName+"?token="+window.localStorage.satellizer_token;
        },function(response){
          parent.DialogService.hide();
        });
  }

  printActionLine(actionLineId) {
    var reqData = {
      actionLineId: actionLineId,
    }, parent = this;

    this.API.all("action/export/product-barcode")
        .post(reqData)
        .then((response) => {
          parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/" + response.fileName+"?token="+window.localStorage.satellizer_token;
        },function(response){
          parent.DialogService.hide();
        });
  }

  printActionLines(selectedLines) {
    var keys = Object.keys(selectedLines);
    var reqSelectedLines = [], parent = this;

    for (var i = 0; i < keys.length; i++) {
      if (selectedLines[keys[i]]) {
        reqSelectedLines.push(keys[i]);
      }
    }

    var reqData = {
      selectedLines: JSON.stringify(reqSelectedLines),
    };

    this.API.all("action/printProductsBarcode")
        .post(reqData)
        .then((response) => {
          this.showToolbarForEdit = false;
          this.selectAllLines = false;
          parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/" + response.fileName+"?token="+window.localStorage.satellizer_token;
        },function(response) {
          parent.DialogService.hide();
        });
  }

  printActionPdf(actionId, type) {
    var parent = this;

    var reqData = {
      actionId: actionId,
      type: type
    };

    this.API.all("action/export/pdf")
      .post(reqData)
      .then((response) => {
        //window.open(window.__env.apiUrl+"/api/action/exportPdf/"+response.fileName+"?token="+window.localStorage.satellizer_token, '_blank');
        parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/"+response.fileName+"?token="+window.localStorage.satellizer_token;
      });
  }

  printCargoBarcode(actionId) {
    var parent = this;

    var reqData = {
      actionId: actionId,
    };

    this.API.all("action/export/cargo-barcode")
      .post(reqData)
      .then((response) => {
        if (response.responsibleUserId != null) {
          parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/" + response.fileName+"?token="+window.localStorage.satellizer_token;
        } else {
          this.ToastService.error(
              this.$translate.instant('CANNOT_PRINT_RESPONSIBLE_USER')
          );
        }
      });
  }

  printCargoBarcodes(data, channelId) {
    var parent = this;
    let reqData = {
      channelId: channelId,
      data: data,
    };

    this.API.all("cargo/getCargoBarcodesFromURL")
        .post(reqData)
        .then((response) => {
          parent.pdfUrl = window.__env.apiUrl+"/api/action/exportPdf/"+response.fileName+"?token="+window.localStorage.satellizer_token;
        })
        .catch(function () {
          parent.DialogService.hide();
        });
  }

    previewBlob(blob){

        this.pdfUrl =URL.createObjectURL(blob);
    }
}

PrintPreviewDialogController.$inject = ["DialogService", "API", "$scope", "$translate", "$state", "$location", "$sce", "ToastService"];
export {PrintPreviewDialogController};
