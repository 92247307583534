class IssuesController {
    constructor($scope, $rootScope,$location) {
        "ngInject";
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;

    }
    $onInit() {
        let parent = this;
        parent.$scope.$on('issueChildEvent', function (event, data) {
            if (data?.tab){
                parent.$state.params.tab =data.tab

            }
            if (data.process === 'addListItem' || data.process === 'cancelAddListItem' || data.process === 'goDetail'){
                parent.onSelectTab(data)
            }
        })
    }
    onSelectTab(obj){
    //    this.listId=null
        this.$location.search(obj);
        this.$rootScope.$broadcast('issueEvent', obj)
    }

}

IssuesController.$inject =  ["$scope", "$rootScope",'$location']
const IssuesComponent = {
    templateUrl: "./views/angular/app/components/issues/issues.component.html",
    controller: IssuesController,
    controllerAs: "vm",
    bindings: {},
};

export {IssuesComponent, IssuesController}
