import "./shortcut.scss";

class ShortcutController {
  constructor($state, $location) {
    "ngInject";

    this.$state = $state;
    this.$location = $location;
    this.originatorEv;
  }

  $onInit() {
    var parent = this;
    setTimeout(() => {
      parent.setLeftMenuActivity();
    }, 0);
  }

  go(Page) {
    this.$state.go(Page);
  }

  openMenu($mdMenu, ev) {
    this.originatorEv = ev;
    $mdMenu.open(ev);
  };

  openUrl(url) {
    window.open(this.$state.href(url), "_blank");
  }

  setLeftMenuActivity() {
    this.activeRoute = window.location.href.split("#!/")[1].trim();
  }
}
ShortcutController.$inject = ["$state", "$location"];

export const ShortcutComponent = {
  templateUrl: "./views/angular/app/components/shortcut/shortcut.component.html",
  controller: ShortcutController,
  controllerAs: "vm",
  bindings: {},
};
