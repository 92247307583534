import "./notification.scss";

class NotificationController {
  constructor(
    $auth, $scope, ToastService, PstorageService, API, $window, $state, $location) {
    'ngInject';

    this.$auth = $auth;
    this.$window = $window;
    this.ToastService = ToastService;
    this.PstorageService = PstorageService;
    this.API = API;
    this.notifications = [];
    this.$state = $state;
    this.$scope = $scope;
    this.$location = $location;
    this.video = undefined;
  }

  $onInit() {
    this.result = '';
    this.per_page = 10;
    this.filterArr = {};
    this.per_page = 10;
    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.PstorageService.$onInit();
    this.notificationsSettings = this.PstorageService.getNotificationsSettings();
    this.PstorageService.viewedNotifications();
  }

  onSelectTab(obj) {
    this.$location.search(obj);
    this.video = undefined;
  }

  clearNotifications(){
    this.PstorageService.clearNotifications();
  }

  changeNotificationSettings(){
    this.PstorageService.updateNotificationsSettings(this.notificationsSettings);
  }

  downloadDocument(index){
    let message = this.$state.downloadableNotification[index]

    const a = document.createElement('a');
    a.href = message.url;
    document.body.appendChild(a);
    a.click();
  }
}

NotificationController.$inject = ["$auth", "$scope", "ToastService", "PstorageService", "API", "$window", "$state", "$location"];

export const NotificationComponent = {
  templateUrl: './views/angular/app/components/notification/notification.component.html',
  controller: NotificationController,
  controllerAs: 'vm',
  bindings: {}
};
