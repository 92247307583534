import {CanFilter} from './filters/can.filter';
import {PriceFormatForDetailFilter} from './filters/priceFormatForDetail.filter';
import {DateFormatFilter} from './filters/dateFormat.filter';
import {PriceFormatFilter} from './filters/priceFormat.filter';
import {CapitalizeFilter} from './filters/capitalize.filter';
import {HumanReadableFilter} from './filters/human_readable.filter';
import {TruncatCharactersFilter} from './filters/truncate_characters.filter';
import {TruncateWordsFilter} from './filters/truncate_words.filter';
import {TrustHtmlFilter} from './filters/trust_html.filter';
import {UcFirstFilter} from './filters/ucfirst.filter';
import {ParsePriceFilter} from './filters/parsePrice.filter';
import {EmptyPartitionFilter} from './filters/empty_partition.filter';
import {CopyTextFilter} from "./filters/copyText.filter";
import {MarkdownFilter} from "./filters/markdown.filter";
import {TimeElapsedFilter} from "./filters/timeElapsed.filter";

angular.module('app.filters')
	.filter('can', CanFilter)
	.filter('priceFormatForDetail', PriceFormatForDetailFilter)
	.filter('dateFormat', DateFormatFilter)
	.filter('timeElapsed', TimeElapsedFilter)
	.filter('priceFormat', PriceFormatFilter)
	.filter('capitalize', CapitalizeFilter)
	.filter('humanReadable', HumanReadableFilter)
	.filter('truncateCharacters', TruncatCharactersFilter)
	.filter('truncateWords', TruncateWordsFilter)
	.filter('trustHtml', TrustHtmlFilter)
	.filter('ucfirst', UcFirstFilter)
	.filter('parsePrice', ParsePriceFilter)
	.filter('emptyPartition',EmptyPartitionFilter)
	.filter('copyText', ['$injector', CopyTextFilter])
  .filter('markdown',MarkdownFilter);
