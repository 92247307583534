import {openIntroductionVideo} from "../../../utils/introduction-videos";
import "./breadcrumb.scss";

class BreadcrumbComponentController {
  constructor($scope, $timeout, $mdSidenav) {
    "ngInject";
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdSidenav = $mdSidenav;
  }

  $onInit() {
    if (this.video !== undefined &&  this.video !== false && this.video != 0) {
      this.animatedPlayButton = true;
      this.animatedPlayButton = "animatedPlayButton";
      var parent = this;
      this.$timeout(function () {
        parent.animatedPlayButton = "nonAnimatedPlayButton";
      }, 5000);
    }

  }
  close(sideNavName = "left") {
    this.$mdSidenav(sideNavName).close();
  }

  open(sideNavName = "left") {
    this.$mdSidenav(sideNavName).open();
  }
  $onChanges() {
    var parent = this;
    if (this.video === undefined) {
      parent.animatedPlayButton = "nonAnimatedPlayButton";
    }
  }

  openIntroductionVideoModal(video, event) {
    openIntroductionVideo(video, event);
  }
}

BreadcrumbComponentController.$inject = ["$scope", "$timeout", "$mdSidenav"];

export const BreadcrumbComponent = {
  templateUrl: "./views/angular/app/components/breadcrumb/breadcrumb.component.html",
  controller: BreadcrumbComponentController,
  controllerAs: "vm",
  bindings: {
    title: "@",
    video: "<",
  },
};
