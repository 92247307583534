function SatellizerConfig($authProvider) {
	'ngInject';

	$authProvider.httpInterceptor = function() {
		return true;
	}

	$authProvider.baseUrl = window.__env.apiUrl;
	$authProvider.loginUrl = window.__env.apiUrl + '/api/auth/login';
	$authProvider.signupUrl = '/api/auth/register';
	$authProvider.tokenRoot = 'data';//compensates success response macro

}
SatellizerConfig.$inject = ["$authProvider"];

export {SatellizerConfig};
