import "./orders-prepare.scss";
import {isEmpty} from "lodash";
import {getUserInfo, getUserSetting, me} from "../../../utils/users";

import {PreparePackageMultipleController} from "./orders-prepare.helpers";
import { timers } from "jquery";
import {list} from "postcss";

class OrdersPackagePrepareController {
    constructor(
        API,
        DialogService,
        ToastService,
        $translate,
        $window,
        $scope,
        $mdBottomSheet
    ) {
        "ngInject";
        this.API = API;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.window = $window;
        this.$scope = $scope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.orderListType = 'picking';
        this.prepareAssignedMe = false;
        this.actionPrepareListSection = 'select';
        this.actionOverLists = [];
        this.maxAction = 0;
        this.maxProduct = 0;
        this.activeActionIndex = 0;
        this.activeProduct = {};
        this.activeProductIndex = 0;
        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.boxShowingSettings = getUserSetting('actionProcessesBoxShowing');
        this.boxShowing = null;
        this.productBarcode = null;
        this.documentPrint = false
        this.productsIndex = []
        this.lastComplete  = null
        this.beepSound = new Audio('./files/success.wav');


        this.priority = {
            1: 'normal',
            2: 'high',
            3: 'very-high',
            4: 'urgent',
        }
    }

    $onInit() {
        let parent = this;
        parent.$scope.$on('prepareEvent', function (event, data) {
            if (data.screen==='package'){
                parent.getList()
                parent.readBarcode();
            }
        })
    }

    readBarcode(){
        return true;
        let parent = this;
        let barcode = '';
        let interval;
        this.terminalEvent = (e) => {
            if (interval) {
                clearInterval(interval)
            }
            if (e.key == 'Enter' && barcode) {
                parent.checkBarcode(barcode)
                barcode = '';
                return true;
            }
            if ('Shift' !== e.key) {
                barcode += e.key
            }
            interval = setInterval(() => barcode = '', 20)

        }
        this.window.document.addEventListener('keydown',this.terminalEvent  ,true)
    }

    getList() {
        this.API.all("action-process/package").doGET(null, {
            listType: this.orderListType
        })
            .then((response) => {

                this.activeAssignedMe = false;
                if (response.response.status === 204) {
                    this.actionOverLists = []
                    this.$scope.$emit('prepareChildEvent', {count: {package: 0}});

                    return
                }

                this.$scope.$emit('prepareChildEvent', {count: {package: response.data.count}});

                this.actionOverLists = response.data.list.map(list => {
                    this.activeAssignedMe = list.prepareAssignedMe
                    list.priorityCode = this.priority[list.priority]
                    return list
                })
            })
    }

    actionDetail(listId) {
        let parent = this;
        let orderNotes = [];
        this.API.all('action-process/package/' + listId)
            .doGET()
            .then((response) => {
                this.listId = listId
                this.maxAction = response.data.list.lines.length;
                this.actionPrepareList = response.data.list;
                this.actionPrepareListSection = 'prepare';
                this.actionPrepareList.lines.map((list,lIndex) =>{
                    list.map((product,pIndex)=>{
                        if (product.actionNote){
                            orderNotes[lIndex] = product;
                        }
                        if (isEmpty(this.activeProduct) && product.preparePackageQuantity < product.quantity){
                            this.activeActionIndex =lIndex
                            this.activeProductIndex =pIndex
                            this.activeProduct = product
                            product.active = true
                        }
                    })
                })
                this.productsIndex  = []
                this.actionPrepareList.lines.map((actionList, actionIndex) => {
                    actionList.map((product, productIndex) => {
                            this.productsIndex.push({
                                actionIndex: actionIndex,
                                productIndex: productIndex,
                                remainingQuantity: product.remainigPackageQuantity,
                                barcode: product.barcode
                            })
                        }
                    )
                })
                if (orderNotes.length > 0){
                    let options = {
                        controller: 'OrdersPrepareController',
                        controllerAs: 'vm',
                        locals: {
                            notes : orderNotes,
                        },
                        bindToController: true,
                    }
                    var msgModal = this.DialogService.fromTemplateV2(
                        './views/angular/app/components/orders-prepare/partials/dialogs/action-message-dialog.html',
                        options
                    );
                    msgModal.catch((response)=>{
                    })
                }
            }).then(()=>{
                this.boxShowing = this.boxShowingSettings[this.activeProduct.locationId]
        });
    }
    docDownload(actionId){
        this.API.all(`action-process/document/${actionId}`)
            .customGET(null,{
                document:'packageDetail'
            })
            .then((response) => {
                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.document);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
            })
    }

    printTemplate(document){
        const printWindow = window.open('', '_blank');
        printWindow.document.write(document);
        printWindow.document.close();
        printWindow.onload = function() {
            printWindow.print();
            printWindow.close();
        };
    }

    nextProduct(){
        this.activeProductIndex = (this.activeProductIndex + 1) % this.actionPrepareList.lines[this.activeActionIndex].length
        if (this.activeProductIndex === 0) {
            this.activeActionIndex = (this.activeActionIndex + 1) % this.maxAction
        }
        this.activeProduct = this.actionPrepareList.lines[this.activeActionIndex][this.activeProductIndex]
        if(this.activeProduct.remainigPackageQuantity === 0){
            this.nextProduct()
        }
    }
    prevProduct(){
        if (this.activeProductIndex === 0) {
            this.activeActionIndex = (this.activeActionIndex === 0) ? this.maxAction - 1 : this.activeActionIndex - 1;
            this.activeProductIndex = this.actionPrepareList.lines[this.activeActionIndex].length-1;
        } else {
            this.activeProductIndex--;
        }
        this.activeProduct = this.actionPrepareList.lines[this.activeActionIndex][this.activeProductIndex]
        if(this.activeProduct.remainigPackageQuantity === 0){
            this.nextProduct()
        }
    }
    targetProduct(actionIndex,productIndex){
        if(this.actionPrepareList.lines[actionIndex][productIndex].remainigPackageQuantity > 0){
            this.activeProductIndex = productIndex;
            this.activeActionIndex = actionIndex;
            this.activeProduct = this.actionPrepareList.lines[actionIndex][productIndex]
        }

    }

    checkBarcode(barcode){
        let quantityBox = this.boxShowing?.quantityBox
        let checked = false;
        if(this.productBarcode === this.activeProduct.barcode || barcode === this.activeProduct.barcode){
            if(this.activeProduct.remainigPackageQuantity > 1 && quantityBox) {
                this.preparePackageMultiple()
            }else{
                this.productQuantity = 1;
                this.toPackListItem()
            }
            checked = true;
            if(!checked){
                this.productBarcode = null;
                return 1
            }
        } else {
            if (this.actionPrepareList.listItemMode === 'singular') {

                let jumpProduct = this.productsIndex.find(indexList => indexList.barcode === this.productBarcode && indexList.remainingQuantity > 0)
                if (!jumpProduct) {
                    this.ToastService.error(this.$translate.instant('SURE_SCANNING_CORRECT_BARCODE'))
                    return
                }

                this.targetProduct(jumpProduct.actionIndex, jumpProduct.productIndex)
                this.checkBarcode(barcode)
            }
            if (this.actionPrepareList.listItemMode === 'mixed') {

                // barcode listesinde var ve aktif sipariş içerisindeyse
                let findActiveAction = this.productsIndex.find(indexList => indexList.actionIndex === this.activeActionIndex && indexList.barcode === this.productBarcode && indexList.remainingQuantity > 0)
                if (findActiveAction){
                    this.targetProduct(this.activeActionIndex, findActiveAction.productIndex)
                    this.checkBarcode(barcode)
                    return 1;
                }

                //barcode listesinde yoksa
                let findOtherAction = this.productsIndex.find(indexList => indexList.actionIndex !== this.activeActionIndex && indexList.barcode === this.productBarcode)
                if (!findOtherAction) {
                    this.ToastService.showHtml('warn', this.productBarcode + this.$translate.instant('NOT_FOUND_THIS_LIST'))
                    this.productBarcode = null;
                    return 1;
                }

                // barcode listede var ama başka aksiyonda
                if (this.activeActionIndex !== findOtherAction.actionIndex) {
                    this.ToastService.showHtml('warn', this.productBarcode + this.$translate.instant('BARCODE_NOT_ACTIVE_ORDER'))
                    this.productBarcode = null;
                    return 1;
                }

                // barcode liste var ama diğer aksiyonlarda ancak hepsi hazırlanmış
                let jumpProduct = this.productsIndex.find(indexList => indexList.actionIndex !== this.activeActionIndex && indexList.barcode === this.productBarcode && indexList.remainingQuantity > 0)
                if (!jumpProduct){
                    this.ToastService.showHtml('warn', this.productBarcode + this.$translate.instant('BARCODE_COMPLETED_FOR_ACTIVE_ORDER'))
                    this.productBarcode = null;
                    return 1;
                }

                this.targetProduct(this.activeActionIndex, jumpProduct.productIndex)
                this.checkBarcode(barcode)
            }
        }
    }

    preparePackageMultiple(){
        var options = {
            controller: PreparePackageMultipleController,
            controllerAs: "vm",
            bindToController: true,
            locals: {
                productBarcode: this.productBarcode,
                remainingQuantity: this.activeProduct.remainigPackageQuantity
            }
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/orders-prepare/partials/prepare-package-multiple.html",options)
            .then((response) => {
                if(response.productQuantity){
                    this.beepSound.play().then().catch()
                    this.productQuantity = response.productQuantity
                    this.toPackListItem();
                }
                if(response.message){
                    this.productBarcode = null;
                    this.ToastService.error(response.message)
                }
            })
            .catch(()=>{
                this.productBarcode = null;
            });
    }
    manuelQuantityCheck(){
        this.productBarcode = this.activeProduct.barcode
        this.preparePackageMultiple()
    }

    toPackListItem(){
        let completeStatisticBox = this.boxShowing?.completeStatisticBox

        let data = {
            barcode: this.productBarcode,
            quantity: this.productQuantity,
            actionId: this.activeProduct.actionId
        }
        this.productBarcode = null;
        this.API.all('action-process/package/' + this.listId)
        .customPOST(data)
        .then((response)=> {
            this.beepSound.play().then().catch()
            this.productBarcode = null;
            let list = response.data.list;
            if (list?.actionComplete){
                this.lastComplete = this.activeProduct.actionId;
            }

            if (list?.document){
                this.printTemplate(list.document)
                this.ToastService.showHtml('success','<p>Onaylanan son siparişin çıktısını almak için <md-button class="highlightClasses" ng-click="toast.resolve()">tıklayın</md-button></p>').then((response)=>{
                    if (response){
                        this.printTemplate(list.document)
                    }
                })
            }

            if (list?.report){

                if (!completeStatisticBox){
                    this.back()
                    return  true;
                }

                var options = {
                    controllerAs      : 'vm',
                    bindToController  : true,
                    onShowing         : function (scope, element, options, controller){
                        scope.reportName          = 'Paketleme';
                        scope.report              = list.report;
                        scope.collapsed           = false;
                    },
                };

                let OPDialogPromise = this.DialogService.fromTemplateV2(
                    './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                    options
                ).then((status) => {
                    this.back()
                }).catch(()=>{
                    this.back()
                })
                return false
            }

            if(response.response.status){
                this.actionPrepareList.lines[this.activeActionIndex][this.activeProductIndex].remainigPackageQuantity -= this.productQuantity
                this.actionPrepareList.lines[this.activeActionIndex][this.activeProductIndex].preparePackageQuantity += this.productQuantity

                this.productsIndex.forEach(indexList => {
                    if (indexList.actionIndex === this.activeActionIndex && indexList.productIndex ===this.activeProductIndex){
                        indexList.remainingQuantity -= this.productQuantity
                    }
                })
                if(response.data.list.message){
                    this.ToastService.success(response.data.list.message)
                }

                //  ürünün ok paket ok sıraki aksiyon
                if(list?.itemComplete && list?.actionComplete){
                    this.nextProduct()
                }

                //  ürünün hazırlığı tamam ama paket tamam değil o yüzden burda kal tamamlanmamış ürüne fokuslan
                if (list?.itemComplete && !list?.actionComplete){
                    let findActiveAction = this.productsIndex.find(indexList => indexList.actionIndex === this.activeActionIndex  && indexList.remainingQuantity > 0)
                    this.targetProduct(this.activeActionIndex, findActiveAction.productIndex)
                }
            }
            this.productBarcode = null;

        })
    }

    listAssignMe(process,listId){
        this.API.all(`action-process/${process}/${listId}/assign`)
            .customPUT()
            .then((response) => {
                this.ToastService.showHtml('success',this.$translate.instant('APPOINTMENT_PROCESS'))
                this.actionDetail(response.data.listId)
            });
    }
    back (){
        this.actionPrepareListSection='select';
        this.productBarcode = null;
        this.actionPrepareList = [];
        this.activeProduct = [];
        this.getList()
    }

    showListItems(){
        let parent = this;
        let list = parent.actionPrepareList.lines.map(l=>{
            let complete = true;
            l.forEach(i=>{
                if (i.remainigPackageQuantity>0){
                    complete = false;
                }
            })

            let meta = l[0]
            return {
                actionId: meta.actionId,
                relation: meta.relation[0],
                itemCount: l.length,
                isComplete:complete
            }
        })
        var options = {
            onShowing         : function (scope, element, options, controller){
                scope.actionLists          = list;
                scope.lastComplete          = parent.lastComplete;
               scope.printTemplate =(actionId) =>{
                   parent.docDownload(actionId)
               }
            },
        };

        let OPDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/orders-prepare/partials/dialogs/order_package_list_detail.html',
            options
        ).then((status) => {

        }).catch(()=>{

        })
    }


    showListDetail(listId) {
        let options = {
            controller: "IssuesDetailController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                listId: listId
            },
            onShowing: function (scope, element, options, controller) {
                controller.getList()
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
            .then((response)=>{
            })
    }
    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.productBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }

}

OrdersPackagePrepareController.$inject = ['API', "DialogService", "ToastService", "$translate", "$window","$scope", "$mdBottomSheet"]
const OrdersPackagePrepareComponent = {
    templateUrl: "./views/angular/app/components/orders-prepare/partials/orders-in-package-process.component.html",
    controller: OrdersPackagePrepareController,
    controllerAs: "vm",
    bindings: {},
};
export {OrdersPackagePrepareComponent, OrdersPackagePrepareController}
