import {openIntroductionVideo} from "../../../utils/introduction-videos";
import "./settings.scss";

class SettingsLogController {
  constructor(API, ToastService, DialogService, FileUploader, $window, $state, $location, $rootScope) {
    "ngInject";

    this.API = API;
    this.ToastService = ToastService;
    this.$window = $window;
    this.$state = $state;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.interval = null 
    this.commandId = '';
    this.schedulerLogs = [];
    this.schedulerMeta = {};
    this.copyX = [];
  }

  $onInit() {
    let arrand = this;
    arrand.interval = setInterval(() => {arrand.getSchedulerLog()},2000)
  }
  
  $onDestroy(){
    clearInterval(this.interval)
  }
 
  getSchedulerLog() {
    this.API.all("settings/scheduler/" + this.commandId + "/log")
      .doGET()
      .then((response) => {
        this.schedulerLogs = (this.schedulerLogs.length > 150) ? [] : [...this.schedulerLogs,...response.data.schedulerLogs] ;                              
        this.schedulerMeta = response.data.schedulerMeta;      
      });
  }

  refreshLogs() {
    this.schedulerLogs = [];
    this.getSchedulerLog();
  }

}

SettingsLogController.$inject = ["API", "ToastService", "DialogService", "FileUploader", "$window", "$state", "$location", "$rootScope"];
const SettingsLogComponent = {
  templateUrl: "./views/angular/app/components/settings/settings-log.component.html",
  controller: SettingsLogController,
  controllerAs: "vm",
  bindings: {},
};

export {SettingsLogComponent, SettingsLogController}
