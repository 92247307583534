class CreateProductionActionController {
  constructor(API, $auth, ToastService) {
    "ngInject";

    this.API = API;
    this.per_page = 10;
    this.$auth = $auth;
    this.ToastService = ToastService;
  }

  $onInit() {
    this.getPaginationAction();
  }

  getPaginationAction(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page
  ) {
    var parent = this;

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let reqObject = {
      per_page: per_page,
      page: this.pageNumber,
      token: this.$auth.getToken(),
      startDate: this.startDate,
      endDate: this.endDate,
    };

    this.API.all("production")
      .customGET("list", reqObject)
      .then((response) => {
        parent.actions = response.data.actions;

        parent.total = response.data.actions.total;
        parent.current_page = response.data.actions.current_page;
        parent.per_page = response.data.actions.per_page;
        parent.last_page = response.data.actions.last_page;
        parent.prev_page_url = response.data.actions.prev_page_url;
        parent.next_page_url = response.data.actions.next_page_url;

        parent.pagesArray = response.data.actionPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
      });
  }

  createAction() {
    var reqObject = {
      ts_supplier_product_code: this.createStockCode,
      qty: this.qty,
      expired_date: this.createExpiredDate,
    };
    var parent = this;
    this.saveProcessing = true;
    this.API.all("production/save")
      .post(reqObject)
      .then(
        (response) => {
          parent.getPaginationAction(1);
          this.saveProcessing = false;
          this.createStockCode = null;
          this.qty = null;
          this.createExpiredDate = null;

          var myEl = document.getElementById("createStockCode");
          var angularEl = angular.element(myEl);
          angularEl.focus();
        },
        (response) => {
          this.saveProcessing = false;
          this.clearCreateActionForm();
        }
      );
  }

  clearCreateActionForm() {
    this.createStockCode = null;
    this.qty = null;
    this.createExpiredDate = null;
  }
}
CreateProductionActionController.$inject = ["API", "$auth", "ToastService"]
export const CreateProductionActionComponent = {
  templateUrl:
    "./views/angular/app/components/create-production-action/create-production-action.component.html",
  controller: CreateProductionActionController,
  controllerAs: "vm",
  bindings: {},
};
