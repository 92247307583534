export function TimeElapsedFilter($translate) {
    'ngInject';

	 return function( input ){

		 const now = new Date();
		 const createdAtDate = new Date(input);
		 const timeDiff = Math.abs(now - createdAtDate);
		 const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

		 const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		 const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
		 const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

		 if (days > 0) {
			 if (hours === 0) {
				 return $translate.instant('DAYS_AGO', { days });
			 } else {
				 return $translate.instant('DAYS_HOURS_AGO', { days, hours });
			 }
		 } else if (hours > 0) {
			 if (minutes === 0) {
				 return $translate.instant('HOURS_AGO', { hours });
			 } else {
				 return $translate.instant('HOURS_MINUTES_AGO', { hours, minutes });
			 }
		 } else if (minutes > 0) {
			 if (seconds === 0) {
				 return $translate.instant('MINUTES_AGO', { minutes });
			 } else {
				 return $translate.instant('MINUTES_SECONDS_AGO', { minutes, seconds });
			 }
		 } else {
			 return $translate.instant('SECONDS_AGO', { seconds });
		 }
	 }
}
TimeElapsedFilter.$inject = ['$translate'];