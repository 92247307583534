class TabActionService {
	constructor($rootScope,$location) {
		'ngInject';
		this.$rootScope = $rootScope;
		this.$location = $location;
		this.status = false;
		this.tabName = 'Tab';
		this.props = {}

		this.returnTab =null;
	}

	show(){
		this.status = true;
	}
	hide(){

		if (this.returnTab){
			this.$location.search({tab:this.returnTab});
		}

		this.status = false;
	}

	setTabName(name){
		this.tabName = name
	}
	setReturnTab(tabId){
		this.returnTab = tabId
	}

	setProps(props){
		this.props = props
	}
	getProp(prop){
		return prop in this.props ? this.props[prop] : null;
	}


}
TabActionService.$inject = ["$rootScope","$location"];
export {TabActionService};
