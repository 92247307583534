import "./offer-recipe.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class RecipePageController {
  constructor($auth,$window, $state, $location,$rootScope,$stateParams) {
    'ngInject';

    this.$auth = $auth;
    this.$window = $window;
    this.$state = $state;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;

    this.offetId = null;
    this.selectBarcode = null;
    this.offerTab ={
      sales:0,
      purchase:1,
    }
    this.selectedTabIndex =0;
  }

  $onInit() {
  }

  onSelectTab(obj) {

    if (this.$stateParams.offerId) {
      this.offerId =  this.$stateParams.offerId;

      if (this.$stateParams.barcode){
        this.selectBarcode = this.$stateParams.barcode
      }
    }
    if (this.$stateParams.offerType) {
      this.selectedTabIndex = this.offerTab[this.$stateParams.offerType]
    }

    //this.$rootScope.$broadcast('offerEvent', obj)
    this.$location.search(obj);
    this.video = undefined;
    this.offerTabScreen = 'list';
  }
}



RecipePageController.$inject = ["$auth", "$window", "$state", "$location","$rootScope","$stateParams"];

const RecipePageComponent = {
  templateUrl: './views/angular/app/components/offer-recipe/recipe-page.component.html',
  controller: RecipePageController,
  controllerAs: 'vm',
  bindings: {}
};


export {RecipePageComponent, RecipePageController}
