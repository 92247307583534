import "./reports.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class ReportsController {
    constructor(
        $auth, ToastService, $translate, API, $window, $state, $location, $filter) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$filter = $filter;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.API = API;
        this.users = {};
        this.$state = $state;
        this.$location = $location;
        this.video = undefined;
        this.reportList = [];
        this.filterData = {};
        this.reportFileType = {};
        this.token = getToken();
        this.permissionAlert = false
    }

    $onInit() {
        this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;

        this.getReportList();
        this.search_selected_categories_1 = [];
        this.search_selected_categories_2 = [];
        this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};
        this.dropdownCategoriesTranslationsText = {
            buttonDefaultText: this.$filter('translate')('CATEGORY_SELECT'),
            checkAll: this.$filter('translate')('SELECT_ALL'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            dynamicButtonTextSuffix: this.$filter('translate')('CATEGORY_SELECTED'),
        };
    }

    onSelectTab(obj) {
        this.$location.search(obj);
        this.video = undefined;
    }

    getReportList() {
        this.API.all('report')
            .doGET()
            .then((response) => {
                if (204 === response.response.status) {
                    this.permissionAlert = true;
                    return;
                }
                this.reportList = response.data.list;
            });
    }

    reportStatus(reportName) {
        this.API.all('report/'+reportName+'/status')
            .doGET()
            .then((response) => {
                this.reportList.map(item=>{
                    if (item.service !== reportName) return;
                    item.status = response.data.result.status
                    item.download = response.data.result.download
                    this.ToastService.showHtml('success',this.$translate.instant('STATUS_UPDATED', { label: item.label }));
                })
            });
    }

    reportRequest(reportName) {
        if (this.search_selected_categories_1.length > 0){
            let dd = [];
            this.reportList.map(item =>{
                if (item.service === reportName){
                    this.search_selected_categories_1.forEach((i, index) => {
                        dd.push(i.key);
                    })
                }
            })
            this.filterData[reportName]['category'] = dd;
        }
        if (this.search_selected_categories_2.length > 0){
            let dd = [];
            this.reportList.map(item =>{
                if (item.service === reportName){
                    this.search_selected_categories_2.forEach((i, index) => {
                        dd.push(i.key);
                    })
                }
            })
            this.filterData[reportName]['categoryIds'] = dd;
        }
        if (typeof this.filterData[reportName] === 'undefined') this.filterData[reportName] = {}
        this.filterData[reportName]['token'] = getToken();

        this.reportList.map(item=>{
            if (item.service !== reportName) return;
            item.status = 'waiting'
        });

        let customFilter = this.filterData[reportName];
        let newCustomFilter = [];
        for (let key in customFilter) {
            if (customFilter.hasOwnProperty(key)) {
                if (Array.isArray(customFilter[key])) {
                    newCustomFilter[key] =  customFilter[key].join('|');
                } else {
                    newCustomFilter[key] =  customFilter[key];
                }
            }
        }

        this.API.oneUrl('report')
            .customGET(reportName, newCustomFilter, {'x-api-output': this.reportFileType[reportName]})
            .then((response) => {
                this.ToastService.success(response.data.message);

                if (this.reportFileType[reportName] ==='json'){

                    navigator.clipboard.writeText(JSON.stringify(response.data)).then(() => {
                        this.ToastService.showHtml('success',this.$translate.instant('REPORT_COPIED_CLIPBOARD'))
                    }).catch(err=>{
                        this.ToastService.showHtml('error',this.$translate.instant('ERROR_REPORT_COPIED_CLIPBOARD'))
                    });

                    this.reportList.map(item=>{
                        if (item.service !== reportName) return;
                        item.status = 'listening'
                    })
                }

            });
    }

    setReportsFilterData(service,filter)
    {
        if (typeof this.filterData[service] === 'undefined') this.filterData[service] = {}
        Object.keys(filter).map((filterKey)=>{
            if (typeof this.filterData[service][filterKey] === 'undefined') this.filterData[service][filterKey] = null;
        })
        this.reportFileType[service]='csv'
    }
    selectAllOptionsByModel(service, key, data) {
        this.filterData[service][key] = data.map(d=>d.key)
    }
    deselectAllOptionsByModel(service, key) {
        this.filterData[service][key] = null;
    }

}

ReportsController.$inject = ["$auth", "ToastService", "$translate", "API", "$window", "$state", "$location", "$filter"];

export const ReportsComponent = {
    templateUrl: './views/angular/app/components/reports/reports.component.html',
    controller: ReportsController,
    controllerAs: 'vm',
    bindings: {}
};
