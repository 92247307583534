import {getUserInfo} from "../../../utils/users";
import "./combine-stock-entry.scss";

class CombineStockEntryPageController {
    constructor(API, ToastService, $translate, $mdBottomSheet, $scope, DialogService, $state, $location, $anchorScroll, $filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$filter = $filter;
        this.translate = this.$filter('translate');

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

        this.screen = 'list';

    }

    $onInit() {}

    clearFilter() {}

    selectScreen(screen){
        this.screen = screen;
    }

}

CombineStockEntryPageController.$inject = ["API", "ToastService", "$translate", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$anchorScroll", "$filter"];
const CombineStockEntryPageComponent = {
    templateUrl: "./views/angular/app/components/combine-stock-entry/combine-stock-entry-page.component.html",
    controller: CombineStockEntryPageController,
    controllerAs: "vm",
    bindings: {},
};

export {CombineStockEntryPageComponent, CombineStockEntryPageController}
