export class ShowScreenForPermissionController{
    constructor(DialogService, $scope, reqData, $window){
        'ngInject';
        $scope.data = reqData;
        $scope.url = $window.__env.apiUrl;
        $scope.cancel = function () {
            DialogService.hide();
        };
    }
}

ShowScreenForPermissionController.$inject = ["DialogService", "$scope", "reqData", "$window"];


