import "./authorize.scss"
class AuthorizeController {
    constructor($auth, API, ToastService, $state, $translate,$stateParams, UserService, TokenService) {
        'ngInject';

        this.$auth = $auth;
        this.API = API;
        this.$state = $state;
        this.$translate = $translate;
        this.ToastService = ToastService;
        this.$stateParams = $stateParams;
        this.UserService = UserService;
        this.TokenService = TokenService;
    }

    $onInit(){
        this.client_id = this.$stateParams.client_id;
        this.token = this.$stateParams.token;
        this.submit();
    }

    submit() {
        this.API.all('auth/authorize').post({
            client_id: this.client_id,
            token: this.token
        }).then((response) => {
            this.$auth.setToken(response.data.token);
            this.TokenService.setToken(response.data.token);
            this.UserService.getUserObject()
              .then(r=>{
                this.ToastService.success(this.$translate.instant('SUCC_LOGGED'));
                this.$state.go('app.dashboard');
                //window.location.reload();
            })
        });
    }
}

AuthorizeController.$inject = ["$auth", "API", "ToastService", "$state", "$translate", "$stateParams", "UserService", "TokenService"];
export const AuthorizeComponent = {
    templateUrl: './views/angular/app/components/authorize/authorize.component.html',
    controller: AuthorizeController,
    controllerAs: 'vm',
    bindings: {}
}
