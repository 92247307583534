import "./recipe.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class RecipeDetailController {
    constructor($auth, $window, $state, $translate, $location, $rootScope, $stateParams, API, $mdBottomSheet, $scope, $filter, DialogService, ToastService) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.API = API;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.$filter = $filter;
        this.DialogService = DialogService;
        this.ToastService = ToastService;

        this.productId = null;
        this.recipeId = null;
        this.barcode = null;

    }

    $onInit() {
        let parent = this;
        this.$scope.$on('selectProductEvent', function (event, selectProduct) {
            if (parent.selectedTabIndex == parent.wrapperTabIndex) {
                parent.addRecipeItem({isBulk: false, productId: selectProduct.id, quantity: 1});
            }
        });

        this.$scope.$on('recipeDetailEvent', function (event, data) {
            if (data.productId) {
                parent.getProduct(data.productId)
                let urlParams = parent.$location.search();
                delete urlParams.productId
                parent.$location.search(urlParams)
            }
            if (data.recipeId){
                parent.recipeId = data.recipeId
            }
        });
    }


    getProduct(productId = '_') {
        let data = {}
        if (productId === '_'){
            data.term = this.barcode
        }
        return  this.API.all('recipe/product/'+productId)
            .customGET(null, data)
            .then((response) => {
                this.productDetail = response.data.list;
                this.recipeDetail = [];
                this.recipeScreen =null;
                this.barcode = null;
                if (this.recipeId){
                    this.getRecipeItems(this.recipeId);
                }
            })
            .catch(function () {
            });
    }

    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }

    setRecipeScreen(screen) {
        this.recipeScreen = screen
    }

    addRecipe({isBulk, productId,itemProductId=null, quantity = null, items = null}) {

        let data = {
            isBulk:isBulk,
            name:this.recipeDetail.newName,
            isActive:this.recipeDetail.active
        }
        if (isBulk) {
            data.items = items
        }
        if (!isBulk) {
            data.itemProductId = itemProductId
            data.quantity = quantity
        }

        this.API.all('recipe/product/'+productId)
            .customPOST(data)
            .then((response) => {
                if (isBulk){
                    this.bulkAddItemResult(response.data.list.message)
                }
                this.getProduct(response.data.list.productId).finally((x) => {
                    this.getRecipeItems(response.data.list.recipeId)
                })
            })
            .catch(function () {
            });
    }

    newRecipe(){
        this.setRecipeScreen('new')
        this.recipeDetail = {
            updateName: true,
       //     name: this.$translate.instant('NEW_RECIPE_NAME'),
            newName:this.$translate.instant('NEW_RECIPE_NAME'),
            active : false,
        }

    }

    bulkAddItem() {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.wait = false
                scope.header = false
                scope.rowSeparator = 'enter'
                scope.colSeparator = 'tab'
                scope.spitData = [];
                scope.data = '';
                scope.DialogService = d;

                scope.addItem = (barcode) => {
                    scope.spitData = [];
                    barcode.split('\n').map((row, index) => {
                        if (scope.header && index === 0) {
                            return true;
                        }
                        let col = row.replace('\r', '').split('\t')

                        scope.spitData.push({
                            barcode: col[0],
                            quantity: col[1] ? col[1].replace(',', '.') : 1,
                            unitPrice: col[2] ? col[2].replace(',', '.') : null,
                        })

                    })
                }

                scope.send = () => {
                    scope.addItem(scope.data)
                    scope.DialogService.$mdDialog.hide(scope.spitData)
                }

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item.html',
            options
        );
        customerDialogPromise.then(resp => {
            if (resp.length > 0) {
                this.addRecipeItem({isBulk: true, items: resp})
            }
        })
            .catch(err => {
                console.log(err)
            })
    }

    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.addProductList = {}
                scope.addProductListCount = 0
                scope.tryErrorRows = false

                scope.listControl = () => {
                    scope.addProductListCount = Object.keys(scope.addProductList).filter(b => scope.addProductList[b]).length
                }

                scope.addProduct = () => {
                    let list = Object.keys(scope.addProductList).filter(b => scope.addProductList[b]).map((barcode) => {
                        let product = scope.report.items.find(r => r.barcode === barcode)
                        return {
                            barcode: barcode,
                            quantity: product.quantity,
                            unitPrice: product.unitPrice,
                            productQuickSave: true
                        }
                    })
                    scope.DialogService.$mdDialog.hide(list)
                }

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        );
        customerDialogPromise.then(resp => {
        }).catch(err => {
            console.log(err)
        })
    }

    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }

    getRecipeItems(recipeId) {
        this.API.all('recipe/' + recipeId)
            .customGET()
            .then((response) => {
                this.prepareRecipeData(response.data.list)
            })
            .catch(function () {
            });
    }

    addRecipeItem({isBulk, productId = null, quantity = null, items = null}) {

        if (this.recipeScreen === 'new'){
            this.addRecipe({
                isBulk:isBulk,
                productId:this.productDetail.productId,
                itemProductId:productId,
                quantity:quantity,
                items:items
            })
            return 1;
        }

        let data = {isBulk:isBulk}
        if (isBulk) {
            data.items = items
        }
        if (!isBulk) {
            data.productId = productId
            data.quantity = quantity
        }

        this.API.all('recipe/' + this.recipeDetail.recipeId)
            .customPUT(data)
            .then((response) => {
                if (response.response.status === 201) {
                    this.ToastService.showHtml('success', response.data.list.message)
                    this.getRecipeItems(this.recipeDetail.recipeId)
                    return true;
                }
                this.bulkAddItemResult(response.data.list)
                this.getRecipeItems(this.recipeDetail.recipeId)
            })
            .catch(function () {
            });
    }

    updateItemQuantity(itemId, quantity) {
        this.API.all('recipe/' + this.recipeDetail.recipeId + '/item/' + itemId + '/quantity')
            .patch({
                quantity: quantity
            })
            .then((response) => {
                this.recipeDetail.items.forEach(i => {
                    if (i.itemId === itemId) {
                        i.quantity = quantity
                        i.quantityUpdate = false
                    }
                })
            })
            .catch(function () {
            });
    }

    recipeNameUpdate() {
        if (this.recipeScreen === 'new'){
            this.recipeDetail.name = this.recipeDetail.newName
            this.recipeDetail.updateName = false;
            return 1;
        }

        this.API.all('recipe/' + this.recipeDetail.recipeId + '/name')
            .patch({
                name: this.recipeDetail.newName
            })
            .then((response) => {
                this.recipeDetail.updateName = false;
                this.recipeDetail.name = this.recipeDetail.newName;
                this.productDetail.recipes.forEach(r=>{
                    if (r.recipeId === this.recipeDetail.recipeId){
                        r.name = this.recipeDetail.newName;
                    }
                })

            })
            .catch(function () {
            });
    }
    recipePermissionUpdate() {
        this.API.all('recipe/' + this.recipeDetail.recipeId + '/permission')
            .patch({
                permission: this.recipeDetail.permission
            })
            .then((response) => {
                this.productDetail.recipes.forEach(r=>{
                    if (r.recipeId === this.recipeDetail.recipeId){
                        r.permission = this.recipeDetail.permission;
                    }
                })
            })
    }

    setRecipeActive(recipeId = null) {
        if (this.recipeScreen === 'new'){
            this.recipeDetail.active = !this.recipeDetail.active
            return 1;
        }

        this.API.all('recipe/' + recipeId + '/active')
            .patch()
            .then((response) => {
                this.recipeDetail.active = true;
                ++this.productDetail.activeRecipeCount
                this.productDetail.recipes.forEach(r => {
                    if (r.recipeId === recipeId){
                        r.active = !r.active
                    }
                })
            })
            .catch(function () {
            });
    }

    setRecipePassive(recipeId) {
        this.API.all('recipe/' + recipeId + '/passive')
            .patch()
            .then((response) => {
                this.recipeDetail.active = true;
                --this.productDetail.activeRecipeCount
                this.productDetail.recipes.forEach(r => {
                    if (r.recipeId === recipeId){
                        r.active = !r.active
                        r.default = false
                    }
                })
            })
            .catch(function () {
            });
    }
    setDefaultRecipe(recipeId) {
        this.API.all('recipe/' + recipeId + '/default')
            .patch()
            .then((response) => {
                this.recipeDetail.default = true;
                this.productDetail.recipes.forEach(r => {
                    r.default = r.recipeId === recipeId;
                    if (r.recipeId === recipeId){
                        r.active = !r.active
                    }
                })
            })
            .catch(function () {
            });
    }

    deleteRecipe(){
        this.API.all('recipe/' + this.recipeDetail.recipeId)
            .customDELETE()
            .then((response) => {
            })
            .catch(function () {
            });
    }

    deleteRecipeItem(itemId){
        this.API.all('recipe/' + this.recipeDetail.recipeId + '/'+itemId)
            .customDELETE()
            .then((response) => {
                this.recipeDetail.items = this.recipeDetail.items.filter(i => i.itemId !== itemId)
                --this.recipeDetail.itemCount
            })
            .catch(function () {
            });
    }

    back(){
        this.$scope.$emit('recipe',{tab:0})
    }
    copyRecipe(recipeId){
        this.API.all('recipe/'+recipeId+'/copy')
            .customPOST()
            .then((response) => {
                this.getProduct(response.data.list.productId).finally((x) => {
                    this.prepareRecipeData(response.data.list)
                })
            })
            .catch(function () {
            });
    }

    prepareRecipeData(data){
        this.recipeDetail = data;
        this.recipeDetail.newName = this.recipeDetail.name
        this.recipeDetail.items.forEach(i => {
            i.newQuantity = i.quantity
        })
        this.setRecipeScreen('update')
    }
}


RecipeDetailController.$inject = ["$auth", "$window", "$state", "$translate", "$location", "$rootScope", "$stateParams", 'API', '$mdBottomSheet', '$scope', '$filter', 'DialogService', 'ToastService'];

const RecipeDetailComponent = {
    templateUrl: './views/angular/app/components/recipe/partials/recipe-detail.component.html',
    controller: RecipeDetailController,
    controllerAs: 'vm',
    bindings: {
        selectedTabIndex: '<',
        wrapperTabIndex: '@',
        productId: '<',
        barcode: '<'
    }
};


export {RecipeDetailComponent, RecipeDetailController}
