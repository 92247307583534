class IconController {
  name;
  constructor($window, $state) {
    'ngInject';
    this.$window = $window;
    this.state = $state;
    this.name = '';
  }

  $onInit() {
  }

}

IconController.$inject = ["$window", "$state"]
export const IconComponent = {
  templateUrl: './views/angular/app/components/icon/icon.component.html',
  controller: IconController,
  controllerAs: 'ic',
  bindings: {
    name:"@",
  },
}
