import {openIntroductionVideo} from "../../../utils/introduction-videos";
import {LOCATION_TYPES} from "../../../utils/constants";
import "./location-management.scss";
import {elephant} from "glyphicons";
import {DialogService} from "../../../services/dialog.service";

class LocationManagementController {
  constructor(
    API,
    ToastService,
    $auth,
    DialogService,
    $rootScope,
    $window,
    $q,
    $translate,
    $filter,
    $state,
    $location,
    UserService,
    $sce,
    $mdBottomSheet,
    $stateParams,
    $scope
  ) {
    "ngInject";
    this.API = API;
    this.ToastService = ToastService;
    this.disabled = [];
    this.responsibleUsers = UserService.getResponsibleUsers();
    this.editLocationScreen = false;
    this.$auth = $auth;
    this.$stateParams = $stateParams;
    this.$scope = $scope;
    this.editSubLocationScreen = false;
    this.subLocationDisabled = [];
    this.stockProductId = [];
    this.filterStockProductId = [];
    this.changeShelfDetail = false;
    this.changeProductsDetail = false;
    this.selectedStockProducts = [];
    this.filterSelectedStockProducts = [];
    this.locationId = 0;
    this.subLocationId = 0;
    this.subLocationForRelation = {};
    this.relationSubLocationId = [];
    this.relationSubLocationName = '';
    this.filterRelationSubLocationId = [];
    this.filterSubLocationForRelation = {};
    this.DialogService = DialogService;
    this.$rootScope = $rootScope;
    this.collapsed = true;
    this.$window = $window;
    this.$q = $q;
    this.sce = $sce;
    this.filterRelationDisabled = true;
    this.relationSubProductIdDisabled = true;
    this.$translate = $translate;
    this.$filter = $filter;
    this.$state = $state;
    this.detailOfStocktaking = false; //stocktaking.component
    this.shelfProductList = false;
    this.$location = $location;
    this.video = undefined;
    this.reservedShelfQuantity = 0;
    this.shelfQuantity = 0;
    this.shelfPreStock = 0;
    this.seeShelfs = false
    this.shelfQuantityStyle = 0;
    this.shelfPreStockStyle = 0;
    this.reservedShelfQuantityStyle = 0;
    this.autoBarcode = false;
    this.editAutoBarcode = false;
    this.shelfItemsInsertType = 0;
    this.fillingDoughnutOptions = {
      options: {
        responsive: true,
            plugins: {legend: {position: 'top'}},
        maintainAspectRatio: false,
      }
    }
    this.fillingRadarData= [];
        this.fillingRadarLabel= [];
    this.stocktakingShelfCode = null;//stocktaking.component
    this.shelfOperations = false;
    this.createShelfProductId = [];
    this.stocktakings = [];//stocktaking.component
    this.createShelfItemSubProductShow = false;
    this.createShelfItemLotShow = false;
    this.createShelfItems = [];
    this.getLastTabIndex = function () {
      document.documentElement.scrollTop = 0
      return document.querySelectorAll("#tabs .md-tab.md-ink-ripple").length;
    };
    this.emptyShelfItems = 0;
    this.shelfEditFormShow = false
    this.dropdownSubLocationSettings = {enableSearch: true};
    this.dropdownSubLocationTranslationsText = {
      buttonDefaultText: this.$filter('translate')('SHELF_SELECT'),
      checkAll: this.$filter('translate')('SELECT_ALL'),
      uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
      searchPlaceholder: this.$filter('translate')('SEARCH'),
      dynamicButtonTextSuffix: this.$filter('translate')('SHELF_SELECTED'),
    };

    this.channelForCustomerIssue;
    this.subLocationStatus = [
      {id: '', name:this.$filter('translate')('ALL')},
      {id: 2, name:this.$filter('translate')('PRODUCT_DEFINED_FULL_SHELF')},
      {id: 1, name:this.$filter('translate')('PRODUCT_DEFINED_EMPTY_SHELF')},
      {id: 0, name:this.$filter('translate')('PRODUCT_UNDEFINED_SHELF')},
    ];
    this.subLocationStatu = null;
    this.stckPageNumber = 1;
    this.stckPerPage = 10;
    this.$mdBottomSheet = $mdBottomSheet;
    this.inputSearchTerm = '';
  }

  $onInit() {

    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;

    let parent = this;

    this.$scope.$on('shelfChangeEvent', function (event, obj) {
      if (obj.tab === 4) {
        if (obj?.locationId) {
          parent.changeShelfLocationId = obj?.locationId
        }
        if (obj?.barcode) {
          parent.shelfChangeBarcode = obj?.barcode
        }

        if (obj?.locationId || obj?.barcode) {
          parent.shelfChangeRead()
        }
      }
    })

    this.getLocation();


    this.API.all("support/customer-issue")
      .doGET()
      .then((response) => {
        this.customerIssues = response.data.customerIssues;
      });

    this.API.all("settings/get-channels")
      .post()
      .then((response) => {
        this.channels = response.data.channels;
        if (this.channels.length > 0) {
          this.defaultChannelId = this.channels[0].id;

          // kanal selectlerinin model initleri
          this.channelForCustomerIssue = this.defaultChannelId;
        } else {
          throw new Error("Kanal yok");
        }
      });

    this.subLocations = {};
    this.per_page = 10;
    this.page = 1;
    this.filterArr = {};
    this.pageNumber = 1;

    this.productOnLocationMap = {};
    this.PLRper_page = 10;
    this.PLRfilterArr = {};
    this.PLRPageNumber = 1;

    this.sections = {};
    this.section_per_page = 10;
    this.section_filterArr = {};
    this.section_page_number = 1;

    this.transporters = {};
    this.transporter_per_page = 10;
    this.transporter_filterArr = {};
    this.transporter_page_number = 1;

    this.changeShelfLocationId = null;
    this.fromStyle = null;
    this.toStyle = null;
    this.shelfChangeBarcode = null;
    this.fromShelfCode = null;
    this.toShelfCode = null;
    this.fromShelfId = null;
    this.toShelfId = null;
    this.shelfChangeQuantity = null;
    this.searchShelfName = null;
    this.searchShelfNameV2 = null;

    this.getTransportersForOrderPrepare(1, this.transporter_per_page);
  }

  getLocation(){
    var parent = this;
    this.API.all("location")
        .customGET()
      .then((response) => {
        if (response.errors === false) {
          this.locations = response.data.location;
          this.locationTypes = response.data.locationTypes;
          this.removalStrategy = response.data.removalStrategy;
          this.createShelfLocationId = this.locations[0].id;
          this.changeShelfLocationId = this.locations[0].id;
          this.locationId = this.locations[0].id;
          let shelf_code = parent.$window.document.getElementById('shelf_code');
          shelf_code.focus();

        }
      });
  }

  onSelectTab(obj) {
    this.focus_shelf_change_barcode = false;

    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 0) {
      this.video = 'location-management';
    }
    if (obj.tab == 1) {
      this.video = 'locationShelf';
    }
    if (obj.tab == 2) {
      this.video = 'locationProductShelf';
    }
    if (obj.tab == 4) {
      this.focus_shelf_change_barcode = true;
    }
  }

  onSelectLocation(obj) {
    this.$location.search({tab:obj.tab});
    this.$state.params.tab = obj.tab;

    this.createShelfLocationId = obj.location;
    this.shelfFilter()
  }

  onSelectSubLocation(obj) {
    this.$location.search({tab:obj.tab});
    this.$state.params.tab = obj.tab;

    this.locationId = obj.location_id;
    this.locationId = obj.location_id;
    this.filterRelationLocationId = obj.location_id;
    this.filterRelationSubLocationId = obj.sub_location_id;
    this.relationSubLocationId = obj.sub_location_id;
    this.relationSubLocationName = obj.sub_location_name;
    //this.getSubLocationsByLocationId("");
    //this.filterPLR();
  }
  onSelectTransporter(transporter_id) {
    this.transporter_id_for_order_prepare = transporter_id;
    this.getSectionForOrderPrepare(
      this.section_page_number,
      this.section_per_page
    );
  }

  getSubLocationStatus(status) {
    return this.subLocationStatus.find(e => e.id === status);
  }

  createLocation() {
    var reqData = {
      name: this.createLocationName,
      type: this.createLocationType,
      removal_strategy: this.createLocationRemovalStrategy,
      use_shelf: this.createUseShelf
    };

    this.API.all("location")
      .post(reqData)
      .then((response) => {

        if (response.response.status === 207) {
          this.locations.push({
            id                : response.data.location.id,
            name              : response.data.location.name,
            removal_strategy  : response.data.location.removal_strategy,
            location_type     : response.data.location.location_type,
            use_shelf         : response.data.location.use_shelf,
            order             : 1,
          });
          this.ToastService.success(this.$translate.instant('WAREHOUSE_CREATED_SUCCESSFULLY'));
          this.clearCreateLocationForm();
        }
      });
  }

  clearCreateLocationForm() {
    this.createLocationName = null;
    this.createLocationType = null;
    this.createLocationRemovalStrategy = null;
    this.createUseShelf = null;
  }

  deleteLocation(locationId) {
    this.DialogService.confirm(
      this.$translate.instant('CONFIRM_MESSAGE'),
      this.$translate.instant('CONFIRM_MESSAGE_BODY')
    ).then(
      () => {
        this.API.all("location/"+locationId)
          .customDELETE()
          .then((response) => {
            if (response.response.status === 204) {
              this.locations = this.locations.filter(location => location.id !== locationId);
              this.ToastService.success(this.$translate.instant('WAREHOUSE_DELETED'));
            }
          });
      },
      () => {
      }
    );
  }


  checkShelf_old(locationId, shelf) {
    var parent = this;

    this.API.all('shelf/' + shelf + '/location/' + locationId)
        .customGET()
        .then((response) => {
          if (response.response.status === 200) {
            this.readBarcode = true;
            this.ToastService.success(this.$translate.instant('SHELF_VERIFIED'));
            this.stocktakingShelfId = response.list.shelfId
          }
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
            this.shelfOperations = false;
          }
          let shelf_barcode = parent.$window.document.getElementById('shelf_barcode');
          shelf_barcode.focus();

        })
      .then((response) => {
      })
  }

  /* stocktaking.component */
  checkShelf(locationId, shelf) {
    var parent = this;
    console.log(this.stocktakingDetail);
    this.API.all('stocktaking/' + this.stocktakingDetail.stocktakingId+'/search-shelf?barcode=' +shelf+ '&location=' + locationId)
      .customGET()
      .then((response) => {
        if (response.response.status === 200) {
          this.shelfProductList = true;
          this.readBarcode = true;
          this.ToastService.success(this.$translate.instant('SHELF_VERIFIED'));
          this.stocktakingShelfId = response.shelfId
          console.log(response.data.items);
          parent.shelf.items = response.data.items;
        }
        if (response.response.status === 204) {
          this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
          this.shelfOperations = false;
        }
        let shelf_barcode = parent.$window.document.getElementById('shelf_barcode');
        shelf_barcode.focus();

      })
      .then((response) => {
      })
  }


  /* stocktaking.component */
  stocktakingSelect(item) {
    let barcode = item.productCode;
    if (item.subProductId>0) {
      barcode = item.subProductBarcode;
    }
    this.stocktakingBarcode = barcode;
    this.stocktakingRead();
  }

  /* stocktaking.compenent */
  stocktakingRead() {
    this.focus_shelf_barcode = true;
    if (!this.readBarcode) {
      this.checkShelf(this.locationId, this.stocktakingShelfCode)
    } else {
      this.checkProductForCounting(this.stocktakingBarcode)
    }

  }


  /* stocktaking.compenent */
  clearStocktakingRead() {
    this.readBarcode = false;
    this.stocktakingShelfCode = null;
    this.focus_shelf_code = true;

    //this.locationId = false;
    //this.locationId = false;
    //this.stocktakingShelfCode = null;
    this.stocktakingBarcode = this.shelfChangeProduct = null;
  }
  /* stocktaking.compenent */
  removeStocktakingTab() {
    this.detailOfStocktaking = false
    this.stocktakingDetail = {};
    this.$state.go('app.location_management',{tab: 5}, {reload:false});
  }

  /* stocktaking.compenent */
  stocktakingComplete() {
    this.API.all('stocktaking/' + this.stocktakingDetail.stocktakingId + '/complete')
        .customPOST()
        .then((response) => {
          if (response.response.status === 204) {
            this.ToastService.success(this.$translate.instant('COUNTING_LIST_INFO'));
            this.removeStocktakingTab()
          }

        })
  }



  editLocation(locationId) {
    var parent = this;
    this.disabled[locationId] = true;

    this.API.all("location/"+locationId)
      .customGET()
      .then((response) => {
        this.edit_location_name = response.data.location.name;
        this.edit_location_type = response.data.location.location_type;
        this.edit_location_removal_strategy = response.data.location.removal_strategy;
        this.edit_location_order = response.data.location.order;
        this.edit_use_shelf = response.data.location.use_shelf;
        this.locationId = locationId;

        parent.editLocationScreen = true;

        this.disabled[locationId] = false;
        //this.removeSubLocationTab();
      });
  }


  editSubLocation(subLocationId) {
    let reqObject = {
      id: subLocationId,
    };

    this.subLocationDisabled[subLocationId] = true;
    this.API.all("subLocation/getById")
      .post(reqObject)
      .then((response) => {
        this.locationId = response.data.subLocation.location_id;
        this.createSubLocationName = response.data.subLocation.name;
        this.createSubLocationOrder = response.data.subLocation.order;
        this.subLocationId = response.data.subLocation.id;

        this.editSubLocationScreen = true;
        this.subLocationDisabled[subLocationId] = false;
      });
  }

  removeLocationTab() {
    this.editLocationScreen = false;
  }

  removeSubLocationTab() {
    this.editSubLocationScreen = false;
  }

  updateLocation(locationId) {
    let reqObject = {
      id: this.locationId,
      name: this.edit_location_name,
      type: this.edit_location_type,
      removal_strategy: this.edit_location_removal_strategy,
      use_shelf: this.edit_use_shelf,
      order: this.edit_location_order
    };

    var parent = this;

    this.API.all("location/"+this.locationId)
      .customPUT(reqObject)
      .then((response) => {
        if (response.response.status === 204) {
          parent.editLocationScreen = false;
          parent.ToastService.success(
            parent.$translate.instant("WAREHOUSE_UPDATED")
          );
          this.getLocation();
        }
      });
  }

  saveSubLocation() {
    if (this.subLocationId == 0) {
      this.createSubLocation();
    } else {
      this.updateSubLocation();
    }
  }

  updateSubLocation() {
    let reqObject = {
      id: this.subLocationId,
      location_id: this.locationId,
      name: this.createSubLocationName,
      per_page: this.per_page,
      page: this.pageNumber,
      order: this.createSubLocationOrder,
    };

    var parent = this;

    this.API.all("subLocation/save")
      .post(reqObject)
      .then((response) => {
        if (response.response.status === 204) {
          parent.editSubLocationScreen = {};
          parent.ToastService.success(
            parent.$translate.instant("SHELF_UPDATED")
          );
          //parent.subLocationId = 0;
          this.subLocationId = null;
          this.clearCreateSubLocationForm();
          this.getSubLocations();
        }
      });
  }

  createSubLocation() {
    let reqObject = {
      location_id: this.locationId,
      name: this.createSubLocationName,
      per_page: this.per_page,
      page: this.pageNumber,
      order: this.createSubLocationOrder,
    };

    var parent = this;

    this.API.all("subLocation/save")
      .post(reqObject)
      .then((response) => {
        if (response.errors === false) {
          parent.ToastService.success(
            parent.$translate.instant("SHELF_CREATED")
          );
          this.clearCreateSubLocationForm();
          this.filter();
        }
      });
  }

  clearCreateSubLocationForm() {
    this.createSubLocationName = null;
    this.createSubLocationOrder = null;
  }

  STCKpaginationDataGenerator(parent, response){
    parent.stocktakings = response.data.list.data.data;
    parent.STCKtotal = response.data.list.data.total;
    parent.stckPageNumber = response.data.list.data.current_page;
    parent.stckPerPage = response.data.list.data.per_page;
    parent.STCKlast_page = response.data.list.data.last_page;
    parent.STCKprev_page_url = response.data.list.data.prev_page_url;
    parent.STCKnext_page_url = response.data.list.data.next_page_url;

    parent.STCKpagesArray = response.data.list.dataPagesArray;

    parent.STCKprev_index = response.data.list.prevIndex;
    parent.STCKnext_index = response.data.list.nextIndex;
  }

  paginationDataGenerator(parent, response) {
    parent.subLocations = response.data.subLocations;
    parent.total = response.data.subLocations.total;
    parent.current_page = response.data.subLocations.current_page;
    parent.per_page = response.data.subLocations.per_page;
    parent.last_page = response.data.subLocations.last_page;
    parent.prev_page_url = response.data.subLocations.prev_page_url;
    parent.next_page_url = response.data.subLocations.next_page_url;

    parent.subLocationsPagesArray = response.data.subLocationsPagesArray;

    parent.prev_index = response.data.prevIndex;
    parent.next_index = response.data.nextIndex;
  }

  PLRpaginationDataGenerator(parent, response) {
    parent.productOnLocationMap = response.data.productOnLocationMap;
    parent.PLRtotal = response.data.productOnLocationMap.total;
    parent.PLRcurrent_page = response.data.productOnLocationMap.current_page;
    parent.PLRper_page = response.data.productOnLocationMap.per_page;
    parent.PLRlast_page = response.data.productOnLocationMap.last_page;
    parent.PLRprev_page_url = response.data.productOnLocationMap.prev_page_url;
    parent.PLRnext_page_url = response.data.productOnLocationMap.next_page_url;

    parent.productOnLocationMapPagesArray =
      response.data.productOnLocationMapPagesArray;

    parent.PLRprev_index = response.data.prevIndex;
    parent.PLRnext_index = response.data.nextIndex;
  }

  getSubLocations(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page
  ) {
    var parent = this;

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let customGet = {
      per_page: per_page,
      page: this.pageNumber,
      location_id: this.locationId,
      sub_location_name: this.searchSubLocationName,
      sub_location_statu: this.subLocationStatu
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;
    reqObject.page = this.pageNumber;

    this.API.all("subLocations")
      .customGET("list", reqObject)
      .then((response) => {
        parent.paginationDataGenerator(parent, response);
      });
  }

  filter() {
    let filter = {
      per_page: this.per_page,
      //page: this.pageNumber,
      location_id: this.locationId,
      sub_location_name: this.searchSubLocationName,
      sub_location_statu: this.subLocationStatu
    };

    var parent = this;
    this.filterArr = filter;

    this.API.all("subLocations/filter")
      .post(filter)
      .then((response) => {
        parent.paginationDataGenerator(parent, response);
      });
  }

  deleteSubLocation(subLocationId) {
    this.DialogService.confirm(
      this.$translate.instant("CONFIRM_MESSAGE"),
      this.$translate.instant("CONFIRM_MESSAGE_BODY")
    ).then(
      () => {
        var reqData = {
          id: subLocationId,
          per_page: this.per_page,
          page: this.pageNumber,
        };
        var parent = this;
        this.API.all("subLocation/delete")
          .post(reqData)
          .then((response) => {
            if (response.errors === false) {
              parent.paginationDataGenerator(parent, response);
              parent.ToastService.success(
                parent.$translate.instant("SHELF_DELETED_MESSAGE")
              );
            }
          });
      },
      () => {
      }
    );
  }

  getMatches(query = "") {
    var self_ = this;
    var productProm = this.$q.defer();
    this.API.all("stock/search")
      .post({
        stockName: query,
      })
      .then((response) => {
        productProm.resolve((self_.searchResults = response.data.products));
      });

    return productProm.promise;
  }

  selectProduct(type = "add") {
    if (type == "add") {

      if (this.stockProductId !== null)
        this.selectedStockProducts.push(this.stockProductId);

      if (this.createShelfProductId !== null)
        this.selectedStockProducts.push(this.createShelfProductId);

    } else {
      if (
        this.filterStockProductId !== null &&
        !_.isEmpty(this.filterStockProductId)
      )
        this.filterSelectedStockProducts.push(this.filterStockProductId);
    }

  }

  getSubLocationsByLocationId(query, type = "add") {
    var parent = this;
    var locationPromise = this.$q.defer();

    var reqObject = {
      location_id: this.locationId,
      query: query,
      token: this.$auth.getToken(),
    };
    if (this.relationSubLocationId !="") {
      reqObject.sub_location_id = this.relationSubLocationId;
    }

    var subLocations = [];

    this.API.all("subLocations/getByLocationId")
      .post(reqObject)
      .then((response) => {
        if (type == "searchss") {
          this.relationSubLocationId = [];
          angular.forEach(response.data.subLocations, function (value, key) {
            subLocations.push({id: value.id, label: value.name});
          });

          locationPromise.resolve(
            (this.subLocationForRelation = subLocations)
          );
        } else {
          locationPromise.resolve(
            (this.subLocationForRelation = response.data.subLocations)
          );
        }
      });

    return locationPromise.promise;
  }

  forceOneTag(tags) {
    if (tags !== null) {
      return tags.length === 0;
    } else {
      return true;
    }
  }

  remove(itemId, type = "add") {
    if (type == "add") {
      var key = this.findElement(this.selectedStockProducts, itemId);
      this.selectedStockProducts.splice(key, 1);
    } else {
      var key = this.findElement(this.filterSelectedStockProducts, itemId);
      this.filterSelectedStockProducts.splice(key, 1);
    }
  }

  findElement(array, id) {
    var returnKey = 0;
    _.forEach(array, function (value, key) {
      if (id == value.id) {
        returnKey = parseInt(key);
      }
    });

    return returnKey;
  }

  getProductLocationRelation(
    pageNumber = this.PLRPageNumber,
    filterArr = this.PLRfilterArr,
    per_page = this.PLRper_page
  ) {
    var parent = this;

    this.per_page = per_page;
    this.pageNumber = pageNumber;

    let customGet = {
      per_page: per_page,
      page: this.pageNumber,
      token: this.$auth.getToken(),
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;

    this.API.all("subLocation/relation")
      .customGET("list", reqObject)
      .then((response) => {
        parent.PLRpaginationDataGenerator(parent, response);
      });
  }

  saveProductLocationRelation() {
    var reqData = {
      subLocationId: this.relationSubLocationId,
      locationId: this.locationId,
      selectedStockProducts: this.stockProductId,
      subProductId: this.relationSubProductId,
      per_page: this.per_page,
      page: this.pageNumber,
    };

    var parent = this;

    this.API.all("subLocation/relation/product")
      .post(reqData)
      .then((response) => {
        if (response.errors === false) {
          parent.PLRpaginationDataGenerator(parent, response);
          parent.ToastService.success(
            parent.$translate.instant("SHELF_PRODUCT_MATCH_MESSAGE")
          );
        }
      })
      .catch((response) => {
        this.ToastService.error(response.data.errors.message[0]);
      });

    this.relationSubLocationId = null;
  }

  deletePLR(PLRId) {
    this.DialogService.confirm(
      this.$translate.instant("CONFIRM_MESSAGE"),
      this.$translate.instant("CONFIRM_MESSAGE_BODY")
    ).then(
      () => {
        var reqData = {
          id: PLRId,
          per_page: this.PLRper_page,
          page: this.PLRPageNumber,
        };

        var parent = this;
        this.API.all("subLocation/relation/delete")
          .post(reqData)
          .then((response) => {
            if (response.errors === false) {
              parent.PLRpaginationDataGenerator(parent, response);
              parent.ToastService.success(
                parent.$translate.instant("SHELF_PRODUCT_RELATION_DELETE")
              );
              parent.filterPLR();
            }
          });
      },
      () => {
      }
    );
  }

  filterPLR() {
    let filter = {
      per_page: this.per_page,
      pageNumber: 1,
      subLocationId: this.relationSubLocationId,
      locationId: this.locationId,
      selectedStockProducts: this.stockProductId,
      selectedSubProductId: this.relationSubProductId,
    };

    var parent = this;

    if (
      this.relationSubLocationId == null &&
      this.locationId == null &&
      _.isEmpty(this.stockProductId)
    ) {
      parent.ToastService.error(
        parent.$translate.instant("SELECT_ONE_CRITERIA")
      );
      return false;
    }

    this.PLRfilterArr = filter;

    this.API.all("subLocation/relation/filter")
      .post(filter)
      .then((response) => {
        parent.PLRpaginationDataGenerator(parent, response);
      });
  }

  accordion(data) {

    this.collapsed = data;
  }

  parseBoolean(data) {
    if (data == "false" || !data) {
      return false;
    } else if (data == "true" || data) {
      return true;
    }
  }

  clearFilterForm() {
    this.filterRelationSubLocationId = null;
    this.filterRelationLocationId = null;
    this.filterSelectedStockProducts = [];
    this.filterStockProductId = [];
    this.PLRfilterArr = {};
    this.filterRelationDisabled = true;
    this.filter_relation_sub_products = [];

    this.getProductLocationRelation();
  }

  clearSubLocation(mode) {
    if (mode == "filterRelationSubLocationId") {
      this.filterRelationSubLocationId = null;
    } else {
      this.relationSubLocationId = null;
    }
    this.filterPLR();
  }

  clearSaveForm() {
    this.locationId = null;
    this.stockProductId = [];
    this.relationSubLocationId = null;
    this.selectedStockProducts = [];
    this.relation_sub_products = [];
    this.relationSubProductId = null;
  }

  getSectionForOrderPrepare(page_number, per_page) {
    var parent = this;

    let reqData = {
      transporterId: this.transporter_id_for_order_prepare,
      per_page: per_page,
      page: page_number,
    };

    this.section_per_page = per_page;

    this.API.all("get-section-order-prepare")
      .post(reqData)
      .then((response) => {
        parent.sections = response.data.sections;
        parent.section_total = response.data.sections.total;
        parent.section_current_page = response.data.sections.current_page;
        parent.section_per_page = response.data.sections.per_page;
        parent.section_last_page = response.data.sections.last_page;
        parent.section_prev_page_url = response.data.sections.prev_page_url;
        parent.section_next_page_url = response.data.sections.next_page_url;

        parent.sectionPagesArray = response.data.sectionsPagesArray;

        parent.section_prev_index = response.data.prevIndex;
        parent.section_next_index = response.data.nextIndex;
      });
  }

  addSectionForOrderPrepare() {
    let reqObject = {
      transporter_id: this.transporter_id_for_order_prepare,
      section_name: this.section_name_for_order_prepare,
    };

    var parent = this;

    this.API.all("section-for-order-prepare/insert")
      .post(reqObject)
      .then((response) => {
        parent.getSectionForOrderPrepare(1, parent.section_per_page);
        parent.ToastService.success(
          parent.$translate.instant("SECTION_DEFINED")
        );
        this.clearAddSectionForOrderPrepare();
      });
  }

  clearAddSectionForOrderPrepare() {
    this.transporter_id_for_order_prepare = null;
    this.section_name_for_order_prepare = null;
  }

  deleteSection(id) {
    let reqObject = {
      section_id: id,
    };

    var parent = this;

    this.API.all("section-for-order-prepare/delete")
      .post(reqObject)
      .then((response) => {
        parent.getSectionForOrderPrepare(1, parent.section_per_page);
        parent.ToastService.success(
          parent.$translate.instant("SECTION_DELETED")
        );
      });
  }

  /*
    Transporter js code start line
     */

  getTransportersForOrderPrepare(page_number, per_page) {
    var parent = this;

    let reqData = {
      per_page: per_page,
      page: page_number,
    };

    this.transporter_per_page = per_page;

    this.API.all("get-transporters-order-prepare")
      .post(reqData)
      .then((response) => {
        parent.transporters = response.data.transporters;
        parent.transporter_total = response.data.transporters.total;
        parent.transporter_current_page =
          response.data.transporters.current_page;
        parent.transporter_per_page = response.data.transporters.per_page;
        parent.transporter_last_page = response.data.transporters.last_page;
        parent.transporter_prev_page_url =
          response.data.transporters.prev_page_url;
        parent.transporter_next_page_url =
          response.data.transporters.next_page_url;

        parent.transporterPagesArray = response.data.transportersPagesArray;

        parent.transporter_prev_index = response.data.prevIndex;
        parent.transporter_next_index = response.data.nextIndex;
        parent.transporter_settings = response.data.settings;
      });
  }

  addTransporterForOrderPrepare() {
    let reqObject = {
      location_id: this.locationId,
      transporter_name: this.transporter_name_for_order_prepare,
      user_id: this.user_id_for_transporter
    };

    var parent = this;

    this.API.all("transporter-for-order-prepare/insert")
      .post(reqObject)
      .then((response) => {
        parent.getTransportersForOrderPrepare(1, parent.transporter_per_page);
        parent.ToastService.success(
          parent.$translate.instant("TRANSPORTER_DEFINED")
        );
        this.clearAddTransporterForOrderPrepare();
      });
  }

  clearAddTransporterForOrderPrepare() {
    this.locationId = null;
    this.transporter_name_for_order_prepare = null;
    this.user_id_for_transporter = null
  }

  deleteTransporter(id) {
    let reqObject = {
      transporter_id: id,
    };

    var parent = this;

    this.API.all("transporter-for-order-prepare/delete")
      .post(reqObject)
      .then((response) => {
        parent.getTransportersForOrderPrepare(1, parent.transporter_per_page);
        parent.ToastService.success(
          parent.$translate.instant("TRANSPORTER_DELETED")
        );
      });
  }

  onTagAdded(product_object, mode = "filter") {
    this.filterRelationDisabled = false;

    var reqObject = {
      product_id: product_object[0].id,
    };

    var parent = this;
    this.API.all("sub-products/get-by-product-id")
      .post(reqObject)
      .then((response) => {
        if (mode == "filter") {
          if (response.data.subProducts.length > 0) {
            parent.filterRelationDisabled = false;
            parent.filter_relation_sub_products = response.data.subProducts;
          } else {
            parent.filterRelationDisabled = true;
            parent.filter_relation_sub_products = [];
            parent.filterRelationSubProductId = null;
          }

          if (response.data.lot.length > 0) {
            parent.filter_relation_lot = response.data.lot;
            parent.filterLotRelationDisabled = false;

          } else {
            parent.filterLotRelationDisabled = true;
            parent.filter_relation_lot = [];
          }

        } else {
          if (response.data.subProducts.length > 0) {
            parent.relationSubProductIdDisabled = false;
            parent.relation_sub_products = response.data.subProducts;

            parent.createShelfItemSubProductShow = true;
          } else {
            parent.relationSubProductIdDisabled = true;
            parent.relation_sub_products = [];
            parent.relationSubProductId = null;

            parent.createShelfItemSubProductShow = false;
          }

          if (response.data.lot.length > 0 && response.data.subProducts.length === 0) {
            parent.relation_lot = response.data.lot;
            parent.createShelfItemLotShow = true;

          } else {
            parent.createShelfItemLotShow = false;
            parent.relation_lot = [];
          }

        }
      });
  }

  onTagAdding(product_object) {
    if (product_object.length > 0) {
      return false;
    }
  }

  clearVariations(mode = "filter") {
    if (mode == "filter") {
      this.filterRelationDisabled = true;
      this.filter_relation_sub_products = [];
      this.filterRelationSubProductId = null;
    } else {
      this.relationSubProductIdDisabled = true;
      this.relation_sub_products = [];
      this.relationSubProductId = null;

      this.relation_lot = [];
      this.createShelfItemLotShow = false;
      this.createShelfItemSubProductShow = false;
      this.createShelfItemsBarcode = null;
    }
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  saveRecommendedLocations() {
    const locations = [
      {name: "Rezerv Depo", type: LOCATION_TYPES.PRODUCT},
      {name: "Ürün Depo", type: LOCATION_TYPES.PRODUCT},
      {name: "Arızalı Depo", type: LOCATION_TYPES.DEFECTIVE},
    ];

    const reserveLocation = this.API.all("location").post(locations[0]);
    const productLocation = this.API.all("location").post(locations[1]);
    const defectiveLocation = this.API.all("location").post(locations[2]);

    Promise.all([reserveLocation, productLocation, defectiveLocation]).then(
      (responses) => {
        if (responses.every((response) => !response.errors)) {
          let vm = this;
          responses.forEach((response) => {
            vm.locations.push(response.data.location);
          });
          this.ToastService.success(
              this.$translate.instant('ALL_WAREHOUSES_CREATED')
          );
        }
      }
    );
  }

  createCustomerIssue() {
    var reqData = {
      issue_type: 'warehouse-guied',
      issue_subject: 'Depo Yerleşim Kılavuzu Kanal: '+this.channels[this.channelForCustomerIssue].name,
    };
    this.API.all("support/customer-issue")
      .post(reqData)
      .then((response) => {
        if (response.errors === false) {
          this.ToastService.success(this.$translate.instant('GUIDE_REQUEST_CREATED'));
          //this.clearCreateLocation();
          this.API.all("support/customer-issue")
            .doGET()
            .then((response) => {
              this.customerIssues = response.data.customerIssues;
            });
        }
      });
  }

  issueResultDownload(issue_id) {
    this.API.all("support/customer-issue/result-download")
      .doGET({issue_id: issue_id})
      .then((response) => {
        if (response.errors === false) {
          this.ToastService.success(this.$translate.instant('GUIDE_DOWNLOADED'));
        }
      });
  }

  createShelf() {
    if (0 > this.createShelfOrder) {
      this.ToastService.error(this.$translate.instant('RANK_LEAST_0'))
      this.createShelfOrder = 0
      return false
    }
    if (0 > this.createShelfWidth) {
      this.ToastService.error(this.$translate.instant('WIDTH_LEAST_0'))
      this.createShelfWidth = 0
      return false
    }
    if (0 > this.createShelfHeight) {
      this.ToastService.error(this.$translate.instant('HEIGHT_LEAST_0'))
      this.createShelfHeight = 0
      return false
    }
    if (0 > this.createShelfDepth) {
      this.ToastService.error(this.$translate.instant('DEPTH_LEAST_0'))
      this.createShelfDepth = 0
      return false
    }

    let data = {
      'locationId'  : this.createShelfLocationId,
      'type'        : this.createShelfType,
      'name'        : this.createShelfName,
      'barcode'     : this.autoBarcode ? this.createShelfName : this.createShelfBarcode,
      'seq'         : this.createShelfOrder,
      'width'       : this.createShelfWidth,
      'height'      : this.createShelfHeight,
      'depth'       : this.createShelfDepth
    }
    if ('location' === this.createShelfType && this.createShelfItems.length > 0) {
      data['items'] = this.createShelfItems;
    }
    this.API.all("shelf")
      .post(data)
      .then((response) => {
        if (201 === response.response.status){
          this.ToastService.success(this.$translate.instant('SHELF_ADDED_SUCC'))
          this.clearShelfForm()
          this.shelfFilter()

        }
      });
  }

  clearShelfForm() {
    //this.createShelfLocationId = null;
    //this.createShelfType = null;
    this.createShelfName = null;
    this.createShelfBarcode = null;
    this.createShelfOrder = null;
    this.createShelfWidth = null;
    this.createShelfHeight = null;
    this.createShelfDepth = null;
    this.createShelfSubProductId = null;
    this.createShelfLotSerialNumber = undefined;
    this.createShelfItemsBarcode = null;
    this.createShelfBarcodeSuccessData = null;
    this.createShelfBarcodeSuccessDataType =null;
    this.createProductsDetail = false;
  }
  clearShelfItemForm(){
    this.shelfItemsInsertType = parseInt(this.shelfItemsInsertType)

    this.relationSubProductIdDisabled = true;
    this.relation_sub_products = [];
    this.relationSubProductId = null;

    this.relation_lot = [];
    this.createShelfProductId = []
    this.createShelfSubProductId = []
    this.createShelfItemLotShow = false;
    this.createShelfItemSubProductShow = false;
    this.createShelfItemsBarcode = null;
    this.createShelfBarcodeSuccessData = null;
    this.createShelfBarcodeSuccessDataType = null;
    this.createShelfLotSerialNumber=undefined;
  }

  createShelfSubProductLotDetect() {
    this.API.all("sub-products/get-lot-by-sub-product-id")
      .post({sub_product_id: this.createShelfSubProductId.id})
      .then((response) => {
        if (response.data.lot.length > 0) {
          this.relation_lot = response.data.lot;
          this.createShelfItemLotShow = true;

        } else {
          this.createShelfItemLotShow = false;
          this.relation_lot = [];
        }
      });
  }


  deleteCreateShelfItems(index) {
    this.createShelfItems.splice(index,1)
  }

  shelfFilter(
      pageNumber = this.page,
      filterArr = this.filterArr,
      per_page = this.per_page,
  ) {
    this.seeShelfs = true;

    this.per_page = per_page;
    this.page = pageNumber;

    let filter = {
      'locationId': this.createShelfLocationId,
      'empty'     : this.emptyShelfItems,
      'name'      : this.searchShelfName,
      'type'      : this.createShelfType,
      'per_page'  : this.per_page,
      'page'      : this.page
    }

    this.API.all('shelf')
      .customGET(null, filter)
      .then((response) => {
        if (204 === response.response.status) {
          this.ToastService.error(this.$translate.instant('NO_SUITABLE_SHELF_FOUND'));
          this.packingLocationList =[]
          this.shelfOperations = false;
          return false;
        }
        this.packingLocationList = response['data']['list'];


        var parent = this;
        parent.shelves = response.data.shelves;
        parent.s_total = response.data.shelves.total;
        parent.s_current_page = response.data.shelves.current_page;
        parent.s_per_page = response.data.shelves.per_page;
        parent.s_last_page = response.data.shelves.last_page;
        parent.s_prev_page_url = response.data.shelves.prev_page_url;
        parent.s_next_page_url = response.data.shelves.next_page_url;

        parent.s_pagesArray = response.data.shelvesPagesArray;

        parent.s_prev_index = response.data.prevIndex;
        parent.s_next_index = response.data.nextIndex;
      });
  }

  /**
   * @deprecated Geriye dönük uyumlulul
   */
  shelfEdit(){
    let editItem = this.packingLocationList.list.filter(item => item.shelfId === shelfId && item?.sub_location_id === subProductId);
    if (0 === editItem.length){
      this.ToastService.error(this.$translate.instant('AN_ERROR'))
      return false
    }
    editItem = editItem[0];

    this.editId               = shelfId ? shelfId : subProductId;
    this.editShelfLocationId  = editItem.locationId;
    this.editShelfType        = editItem.type;
    this.editShelfName        = editItem.name;
    this.editShelfOrder       = editItem.seq;
    this.editShelfBarcode     = this.editAutoBarcode ? editItem.name: editItem.barcode;
    this.editShelfWidth       = parseInt(editItem.width);
    this.editShelfHeight      = parseInt(editItem.height);
    this.editShelfDepth       = parseInt(editItem.depth);
    this.editService          = editItem.service;
    this.shelfEditFormShow    = true
    document.getElementById('shelf-code').scrollIntoView();
  }

  /**
   * @deprecated Geriye dönük uyumlulul
   */
  shelfEditSend(shelfId,subLocationsId) {
    let editItem = this.packingLocationList.list.filter(item => item.shelfId === shelfId && item?.sub_location_id === subLocationsId);
    if (0 === editItem.length){
      this.ToastService.error(this.$translate.instant('AN_ERROR'))
      return false
    }
    this.editService = editItem[0].service;
    let filter = {
      locationId          : this.editShelfLocationId,
      type                : this.editShelfType,
      name                : this.editShelfName,
      seq                 : this.editShelfOrder,
      barcode             : this.editAutoBarcode ? this.editShelfName : this.editShelfBarcode,
      width               : this.editShelfWidth,
      height              : this.editShelfHeight,
      depth               : this.editShelfDepth,
      service             : this.editService,
    }
    this.API.all('shelf/'+this.editId)
      .customPUT(filter)
      .then((response) => {
        if (204 === response.response.status) {
          this.ToastService.success(this.$translate.instant('UPDATED_SUCC'))
          this.editId               = null;
          this.editShelfLocationId  = null;
          this.editShelfType        = null;
          this.editShelfName        = null;
          this.editShelfOrder       = null;
          this.editShelfBarcode     = null;
          this.editShelfWidth       = null;
          this.editShelfHeight      = null;
          this.editShelfDepth       = null;
          this.subProductRelation   = null;
          this.subProduct           = null;
          this.shelfEditFormShow    = false;
          this.shelfFilter()
          return true
        }
        this.ToastService.error(this.$translate.instant('AN_ERROR_UPDATING'))
      });
  }
  changeShelfDetails(){
    this.changeShelfDetail = !this.changeShelfDetail;
  }
  shelfEditV2(){
    this.shelf.width  = parseInt(this.shelf.width);
    this.shelf.height = parseInt(this.shelf.height);
    this.shelf.depth  = parseInt(this.shelf.depth);
    this.shelf.autoBarcode = false;

    this.isEditMode = true;
  }

  shelfEditSendV2() {
    let filter = {
      name                : this.editShelfName,
      seq                 : this.editShelfOrder,
      barcode             : this.editAutoBarcode ? this.editShelfName : this.editShelfBarcode,
      width               : this.editShelfWidth,
      height              : this.editShelfHeight,
      depth               : this.editShelfDepth,
      service             : 'shelf',
    }
    this.API.all('shelf/'+this.editId)
        .customPUT(filter)
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.success(this.$translate.instant('UPDATED_SUCC'))
            //this.getShelfDetail(this.editId)
            this.isEditMode = false;
            return
          }
          this.ToastService.error(this.$translate.instant('AN_ERROR_UPDATING'))
        });
  }


  shelfDelete(shelfId,subProductId) {
    this.DialogService.confirm(
        this.$translate.instant('CONFIRM_MESSAGE'),
        this.$translate.instant('WANT_TO_DELETE_SHELF')
    ).then(()=>{
      let service = shelfId ? 'shelf' : 'sub'
      let id  = shelfId ? shelfId : subProductId
      this.API.all('shelf/'+service)
          .customDELETE(id)
          .then((response) => {
            if (204 === response.response.status) {
              this.shelfFilter()
              return true
            }
            this.ToastService.error(this.$translate.instant('AN_ERROR_DELETING'))
          });
    })

  }

  getShelfDetail(locationId,shelfId){
    this.shelfId= shelfId;
    this.shelf = [];
    this.assignItems = [];
    this.shelfQuantity = 0;
    this.reservedShelfQuantity = 0;
    this.shelfPreStock = 0;
    this.shelfAllQuantity = 0;
    this.subProduct = {};
    this.lot = {};
    this.fillingDoughnutOptions = {
      options: {
        responsive: false,
        plugins: {legend: {position: 'top'}},
        maintainAspectRatio: true,
      }
    }
    this.fillingRadarData= [];
    this.fillingRadarLabel= [];
    let parent = this;
    this.API.all('')
        .customGET('shelf/'+shelfId,{
          shelfName: this.searchShelfName,
          locationId:locationId
        })
        .then((response) => {
         parent.shelfDetailParse(response.data.list);
        })
        .catch((response)=>{
          if(response.status === 422){
            this.ToastService.error(response.data.errors.message[0])
          }
        });
  }

  shelfDetailParse(responseDataList)
  {
    this.shelfOperations = true;

    this.shelf = responseDataList;
    this.fillingRadarLabel=['Stok','Beklemede','Rezerve']
    responseDataList.items.map(list=>{
      this.fillingRadarData.push([list.quantity, list.preStock, list.reservedStock])
    })
    if(this.productAssignDialog){
      this.shelfOperations = true
      this.detailOfStocktaking = false;
      this.$state.params.tab = 2;
    }
    responseDataList.items.map(item => {

      item.reservedStock = parseInt(item?.reservedStock);
      item.preStock = parseInt(item?.preStock);
      this.shelfQuantity += item?.quantity;
      this.reservedShelfQuantity += item?.reservedStock;
      this.shelfPreStock += item?.preStock;

    });
    this.shelfAllQuantity = this.shelfQuantity + this.reservedShelfQuantity
    this.shelfQuantityStyle = parseInt(this.shelfQuantity * 100 / this.shelfAllQuantity);
    this.shelfPreStockStyle = 100 - this.shelfQuantityStyle;
    this.reservedShelfQuantityStyle =  (this.reservedShelfQuantity * 100 / this.shelfAllQuantity);
  }

  shelfProductAssignDialog(locationId,shelfId, searchShelfName) {
    this.productAssignDialog = true;
    this.searchShelfNameV2 = searchShelfName;
    this.getShelfDetail(locationId,shelfId);
  }

  shelfSearch(searchShelfName, excelReport) {
    if (undefined === searchShelfName || null == searchShelfName) {
      return false;
    }

    let filter = {
      'locationId': this.createShelfLocationId,
      'shelfName'      : searchShelfName,
      'excelReport'    : excelReport
    }
    var parent = this;

    this.API.all('shelf/0')
        .customGET(null,filter)
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.error(this.$translate.instant('NO_SUITABLE_SHELF_FOUND'));
            this.shelfOperations = false;
            return false;
          }
          if (response.data.list.fileName){
            window.location.assign(window.__env.apiUrl+"/api/stock/exportExcel/"+response.data.list.fileName+"?token="+window.localStorage.satellizer_token);
          }
          parent.shelfDetailParse(response.data.list);
        });
  }

  shelfAssignProductSearch() {
    this.notMatch = false
    if (2 >= this.searchWord.length) return;
    this.API.all("shelf/assign/product?q="+this.searchWord)
        .customGET()
        .then((response) => {
          if (response.response.status === 204){
            this.notMatch = true
          }
          this.searchResults = response.data.list
        });
  }
  printShelfBarcode(barcode){
     window.print();
  }

  selectedAssignItem(item){
    this.subProduct={};
    this.lot= {}

    if (0 < item.subProduct.length) {
      this.subProduct = {
        productId   : item.productId,
        productName : item.productName,
        list        : item.subProduct
      };
      return
    }

    if (0 < item.lot.length) {
      this.lot = {
        productId   : item.productId,
        productName : item.productName,
        list        : item.lot
      };
      return
    }

    let exists = this.assignItems.filter(as=>as.productId === item.productId)

    if (0 < exists.length) return;

    this.assignItems.push({
      productId :item.productId,
      productName : item.productName,
    })
  }

  selectedAssignSubItem({item,productId,productName}){
    this.lot= {}
    if (0 < item.lot.length) {
      this.lot = {
        productId     : productId,
        productName   : productName,
        subProductId  : item.subProductId,
        property1     : item.property1,
        property2     : item.property2,
        list          : item.lot
      };
      return
    }

    let exists = this.assignItems.filter(as=>as.productId === productId && as.subProductId === item.subProductId)
    if (0 < exists.length) return;

    this.assignItems.push({
      productId     : productId,
      productName   : productName,
      subProductId  : item.subProductId,
      property1     : item.property1,
      property2     : item.property2,
    })
  }

  selectedAssignLotItem({item,productId,productName,subProductId,property1,property2}){

    let exists = this.assignItems.filter(as=>as.productId === productId && as?.subProductId === subProductId && as.lotId === item.lotId )
    if (0 < exists.length) return;
    this.assignItems.push({
      productId     : productId,
      productName   : productName,
      subProductId  : subProductId,
      property1     : property1,
      property2     : property2,
      serialNumber  : item.serialNumber,
      lotId         : item.lotId,
    })
  }

  deSelectAssignLotItem(item){
    this.assignItems  = this.assignItems.filter(as=> as.productId !== item.productId || as?.subProductId !== item?.subProductId || as?.lotId !== item?.lotId)
  }

  changeDetails(shelf){

    this.editShelfType = shelf.type
    this.editId = shelf.shelfId;
    this.editShelfLocationId = this.createShelfLocationId

    this.editShelfName = shelf.name
    this.editShelfOrder = shelf.seq;
    this.editShelfWidth = parseInt(shelf.width)
    this.editShelfHeight = parseInt(shelf.height);
    this.editShelfDepth = parseInt(shelf.depth);
    this.shelfEditFormShow = true;
    this.createProductsDetail = false;
  }
  assignedItemSelectedStatus({productId,subProductId,lotId}) {
    return 0 < this.assignItems.filter(as => {
      if (lotId){
        return  as.productId === productId && as?.subProductId === subProductId && as?.lotId === lotId
      }
      if (subProductId){
        return  as.productId === productId && as?.subProductId === subProductId
      }

      if (productId){
        return  as.productId === productId
      }

    }).length
  }

  assignProduct(){
    let data = this.assignItems.map(as=>{
      return {
        productId: as.productId,
        subProductId: as?.subProductId,
        lotId: as?.lotId
      }
    })
    this.API.all('shelf/assign/product')
        .post({
          shelfId:this.shelf.shelfId,
          items:data,
        })
        .then((response) => {
          if (response.response.status === 207){
            let li =''
            response.data.list.map(list =>{
              li += '<li>'+list+'</li>'
            })
            if (response.data.list.length > 0 ) {
            this.ToastService.showHtml('warn','<ul>'+li+'</ul>')
                }
            else {
              this.assignItems.map(item => {
             //   this.shelf.items.unshift(item)
                this.ToastService.showHtml('success',item.productId + ' ' + this.$translate.instant('BARCODED_PRODUCT_ADDED_LIST'));
              })
            }
            this.getShelfDetail(this.shelf.locationId,this.shelf.shelfId)
            this.assignItems    = []
            this.searchResults  = []
            this.subProduct     = {}
            this.lot            = {}
            this.searchWord     = null
          }
        });
  }

  unAssignItem(locationId,shelfId,itemId) {
    this.DialogService.confirm(
        this.$translate.instant('CONFIRM_MESSAGE'),
        this.$translate.instant('WANT_TO_DELETE_PRODUCT')
    ).then(()=>{
      this.API.all('shelf/assign/product/'+ shelfId + '/item/' + itemId)
          .customPUT()
          .then((response) => {
            if (204 === response.response.status){
              this.getShelfDetail(locationId,shelfId)
            }
          });
    })
  }

  selectTakingShelf(shelfName){
    if (this.fromShelfCode){
      this.toShelfCode = shelfName;
      this.toShelfRead(this.changeShelfLocationId,shelfName)
    } else {
      this.fromShelfCode = shelfName
      this.fromShelfRead(this.changeShelfLocationId,shelfName)
    }
  }
  fromShelfCodeClear(){
    this.fromShelfCode = null;
    this.shelfChangeQuantity = 1;
  }
  shelfChangeRead(){
    this.checkProduct(this.shelfChangeBarcode)
  }

  fromShelfRead(locationId, shelf){
    if (!shelf) return;
    this.API.all('shelf/' + shelf + '/location/' + locationId)
        .customGET()
        .then((response) => {
          if (response.response.status === 200) {
            this.fromShelfId = response.list.shelfId;
            this.fromStyle = {'background-color':"red" , 'border-color' : "red"};
          }
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));

          }
        })
  }
  toShelfRead(locationId, shelf){
    if (!shelf) return;
    this.API.all('shelf/' + shelf + '/location/' + locationId)
        .customGET()
        .then((response) => {
          if (response.response.status === 200) {
            this.toShelfId = response.list.shelfId;
            this.fromStyle = {'background-color':"green" , 'border-color' : "green"};
          }
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
            this.shelfOperations = false;

          }
        })
  }

  shelfChange(locationId){
    if (this.fromShelfCode && this.toShelfCode && this.fromShelfCode === this.toShelfCode){
      this.ToastService.error(this.$translate.instant('CHECK_SHELF_INFORMATION'));
      return;
    }
    setTimeout(()=>{
      this.API.all('shelf/change')
          .customPUT({
            productId   : this.shelfChangeProduct.productId,
            subProductId: this.shelfChangeProduct.subProductId,
            lotId       : this.shelfChangeProduct.lotId,
            locationId  : locationId,
            fromShelfBarcode: this.fromShelfCode,
            toShelfBarcode : this.toShelfCode,
            fromShelfId : this.fromShelfId,
            quantity    : this.shelfChangeQuantity,
            toShelfId   : this.toShelfId
          })
          .then((response)=>{
            if(response.response.status === 200 || response.response.status === 204){
              this.ToastService.success(this.$translate.instant('SHELF_REPLACEMENT_SUCC'));
              setTimeout(()=>{
                this.checkProduct(this.shelfChangeBarcode);
              },300)
            }

          })
    },300)
  }

  shelfChangeAll(){
    this.shelfChangeProduct.otherShelves.forEach(shelf => {
      if(shelf.name == this.fromShelfCode){
        this.shelfChangeQuantity = shelf.quantity
      }
    })
  }



  checkProduct(barcode){
    this.API.all('stocktaking/product/' + barcode)
        .customGET(null,{
          locationId    : this.changeShelfLocationId,
          shelfId       : this.stocktakingShelfId,
          active        : 1
        })
        .then((response) => {
          if (response.response.status === 204){
            // this.getShelfDetail(this.stocktakingShelfId);
            this.ToastService.error(this.$translate.instant('NO_PRODUCT_WITH_BARCODE'));
          }
          else{
            this.shelfChangeProduct = response.data.list
          }
        })
        .catch((response)=>{
          if(response.status === 422){
            this.ToastService.error(response.data.errors.message[0]);
          }
        })
    this.fromShelfCode = null;
    this.toShelfCode = null;
    this.shelfChangeQuantity = 1;
  }

  showProduct(productId) {
    if (this.$state.is("app.stock_management")) {
      //todo:aynı state için farklı bişey gerekiyor, reload:true yerine
      this.$state.go("app.stock_management", {tab: 1, id: productId}, {reload:true});
    } else {
      // this.$state.go("app.stock_management", {tab: 1, term: this.inputSearchTerm});
      this.$mdBottomSheet.show({
        templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
        controller: "BottomViewController",
        controllerAs: "vm",
        bindToController: true,
        locals: {
          id: productId
        }
      })
    }
  }

  showCameraView() {
    var parent = this;
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
      controller: "BottomCameraController",
      controllerAs: "vm",
      bindToController: true,
      locals: {}
    }).then(response => {
      parent.searchShelfNameV2 = response;
      parent.shelfChangeBarcode = response;
    })
      .catch(function (error) {
        console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
      });
  }

  getShelfLog(shelfId) {
    this.API.all("shelf/log/"+shelfId)
        .customGET()
        .then((response) => {
          this.selectedActions = []
          this.showShelfLog(response.data.list,shelfId)
        })
  }
  showShelfLog(logProducts, shelfId) {
      let d = this.DialogService;
      let parent = this;
      let options = {
        locals: {
        },
        onShowing: function (scope, element, options, controller) {
          scope.DialogService = d
          scope.logProducts = logProducts


          scope.cancel = () => {
            scope.DialogService.$mdDialog.hide()
          }
          scope.getLog = (productId) => {
            parent.API.all("shelf/log/"+shelfId+"/"+productId)
                .customGET()
                .then((response) => {
                  scope.shelfLogs = response.data.list;
                })
          }
        },
      }
      this.DialogService.fromTemplateV2("./views/angular/app/components/location-management/partials/dialog/log-detail.html",options)
          .then((response)=>{
          })

  }
}

LocationManagementController.$inject = ["API", "ToastService", "$auth", "DialogService", "$rootScope", "$window", "$q", "$translate", "$filter", "$state", "$location", "UserService","$sce","$mdBottomSheet","$stateParams","$scope"];
const LocationManagementComponent = {
  templateUrl:
    "./views/angular/app/components/location-management/location-management.component.html",
  controller: LocationManagementController,
  controllerAs: "vm",
  bindings: {},
};

export {LocationManagementComponent, LocationManagementController}
