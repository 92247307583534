export function DateFormatFilter() {
    'ngInject';

	 return function( input ){ 
		if (undefined === input || '' === input) {
			return 'N/A';
		}
		
		var d = new Date(input) 
		return d.toLocaleString('tr-TR', { timeZone: 'Europe/Istanbul' }) ; 
	 }
}
