class AppShellController{
    constructor(){
        'ngInject';

        //
    }

    $onInit(){
    }
}

export const AppShellComponent = {
    templateUrl: './views/angular/app/components/app-shell/app-shell.component.html',
    controller: AppShellController,
    controllerAs: 'vm',
    bindings: {}
}
