export function EmptyPartitionFilter (){
    'ngInject';

    return function( input ){
        var n = input;
        if (undefined === input || '' === input) {
            return 'N/A';
        } else {
            return input;
        }

    }
}