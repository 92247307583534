import "../invoices/invoices.scss";
import {getUserInfo} from "../../../utils/users";

class ReceivingPageController {
    constructor(API, ToastService, $mdBottomSheet, $scope, DialogService,$state,$location,$stateParams) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.$state = $state;
        this.DialogService = DialogService;
        this.$location = $location;
        this.$stateParams = $stateParams;


        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

    }

    $onInit() {
        this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    }

    onSelectTab(obj) {

        this.selectedTabIndex = obj.tab

        //this.$rootScope.$broadcast('offerEvent', obj)
        this.$location.search(obj);
        this.video = undefined;
        this.offerTabScreen = 'list';
    }

}

ReceivingPageController.$inject = ["API", "ToastService", "$mdBottomSheet", "$scope", "DialogService","$state","$location","$stateParams"];
const ReceivingPageComponent = {
    templateUrl: "./views/angular/app/components/receiving/receiving-page.component.html",
    controller: ReceivingPageController,
    controllerAs: "vm",
    bindings: {
        receivingType: '<',

    },
};

export {ReceivingPageComponent, ReceivingPageController}
