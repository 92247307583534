import "./upload.scss";
import Cookies from "js-cookie";


class UploadController {

    constructor(API, $window, ToastService, $mdBottomSheet, $scope, $timeout) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.cameraPermissionDenied = false;
        this.cameraList = []
        this.cameraId = '';
        this.showCamera = false;
        this.showCamera = false;
        this.importFiles = [];
        this.gallery = [];
        this.$mdBottomSheet = $mdBottomSheet;
    }

    $onInit() {
    }

    $onChanges(data) {
    }


    handleFileSelect(element) {
        let parent = this;

        parent.$scope.$applyAsync(function () {

            parent.$scope.files = document.getElementById('filex').files;
            parent.$scope.imagePreviews = [];

            Array.from(parent.$scope.files).forEach(function (file) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    parent.$timeout(function () {
                        parent.gallery.push({
                            file: file,
                            documentType: parent.determineFileType(file.type),
                            filename: file.name,
                            size: file.size,
                            url: e.target.result,
                        });
                    })
                };
                reader.readAsDataURL(file);
            });
        });

    }


    approve() {
        this.$mdBottomSheet.hide(this.gallery)
    }


    determineFileType(fileType) {
        if (fileType.startsWith("image/")) {
            return "image";
        } else if (fileType === "application/pdf") {
            return "pdf";
        } else if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return "excel";
        }  else if (fileType === "text/csv") {
            return "csv";
        } else {
            return "unknown";
        }
    }
}

UploadController.$inject = ["API", "$window", "ToastService", "$mdBottomSheet", "$scope", "$timeout"]
const UploadComponent = {
    templateUrl: "./views/angular/app/components/upload/upload.component.html",
    controller: UploadController,
    controllerAs: "vm",
    bindings: {},
};
export {UploadComponent, UploadController}
