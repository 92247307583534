import "./pagination-v2.scss";
class PaginationV2Controller {
  parentPage;
  parentPerPage;
  parentTotal;
  parentController;
  parentFunction;

  constructor($window, $state, $scope, $timeout) {
    'ngInject';
    this.$window = $window;
    this.state = $state;
    this.$scope = $scope;
    this.$timeout = $timeout;
    //this.parentPerPage = 25;
    this.busy = false;
  }

  $onInit() {
    this.updateLastPage();
  }

  $onChanges(changesObj) {
    if (changesObj.parentPerPage || changesObj.parentPage || changesObj.parentTotal) {
      if (changesObj.parentPerPage) {
        this.parentPage = 1;
      }
      this.updateLastPage();
    }
  }

  updateLastPage() {
    this.lastPage = Math.ceil(this.parentTotal / this.parentPerPage);
  }

  callParent() {
//    this.$timeout(() => {
      const result = this.parentFunction({ page: this.parentPage, perPage: this.parentPerPage });
      // parentFunction'ın bir Promise olup olmadığını kontrol edin
      if (result && typeof result.finally === 'function') {
        result.finally(() => {
          this.busy = false; // Request tamamlandı
        });
      } else {
        // parentFunction bir Promise değilse, busy bayrağını hemen false yapın
        this.busy = false;
      }
    //});
  }

  updateParams() {
    if (this.busy) return;
    this.$timeout(() => {
      this.callParent();
    });
  }

  goPage(direction = null) {
    if (this.busy) return;
    this.$timeout(() => {
      if (direction !== null) {
        if (direction === -1) {
          this.parentPage--;
        } else if (direction === 1) {
          this.parentPage++;
        }
        if (this.parentPage < 1) {
          this.parentPage = 1;
        }
        if (this.parentPage > this.lastPage) {
          this.parentPage = this.lastPage;
        }
      }
      this.updateParams();
    });
  }

}

PaginationV2Controller.$inject = ["$window", "$state", "$scope", "$timeout"]
export const PaginationV2Component = {
  templateUrl: './views/angular/app/components/pagination-v2/pagination-v2.component.html',
  controller: PaginationV2Controller,
  controllerAs: 'pg',
  bindings: {
    parentPage: "=",
    parentPerPage: "=",
    parentTotal: "<",
    parentFunction: "&",
  },
}
