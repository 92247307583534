function ThemeConfig($mdThemingProvider) {
  'ngInject';
  /* For more info, visit https://material.angularjs.org/#/Theming/01_introduction */
  $mdThemingProvider.theme('default')
    .primaryPalette('grey', {
      default: '800'
    })
    .accentPalette('blue')
    .warnPalette('red');

  $mdThemingProvider.theme('dark')
    .primaryPalette('grey', {
      default: '800'
    })
    .accentPalette('blue')
    .warnPalette('red')
    .dark();

  $mdThemingProvider.alwaysWatchTheme(true);
}

ThemeConfig.$inject = ["$mdThemingProvider", "$provide"];
export {ThemeConfig};
