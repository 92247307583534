import "./reports.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class ReportLocationsController {
    constructor(
        $auth, ToastService, $translate, API, TimeRangeService, $window, $state, $location, $filter) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$filter = $filter;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.API = API;
        this.TimeRangeService = TimeRangeService;
        this.users = {};
        this.$state = $state;
        this.$location = $location;
        this.video = undefined;
        this.reportList = [];
        this.filterData = {};
        this.reportFileType = {};
        this.token = getToken();
        this.permissionAlert = false;
        this.locations = [];
        this.timeRange = 'thisDay';
        this.changeTimeRange();
    }

    $onInit() {
        let parent = this;
        this.getReportList();
        this.search_selected_categories_1 = [];
        this.search_selected_categories_2 = [];
        this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};
        this.dropdownCategoriesTranslationsText = {
            buttonDefaultText: this.$filter('translate')('CATEGORY_SELECT'),
            checkAll: this.$filter('translate')('SELECT_ALL'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            dynamicButtonTextSuffix: this.$filter('translate')('CATEGORY_SELECTED'),
        };

        this.API.all("location")
          .customGET()
          .then((response) => {
              if (response.errors === false) {
                  parent.locations = response.data.location;
              }
          });
    }

    onSelectTab(obj) {
        this.$location.search(obj);
        this.video = undefined;
    }

    changeTimeRange() {
        const range = this.TimeRangeService.calculateTimeRange(this.timeRange);
        this.startedAt = range.startDate;
        this.finishedAt = range.endDate;
    };

    getReportList() {
        this.API.all('report')
            .doGET()
            .then((response) => {
                if (204 === response.response.status) {
                    this.permissionAlert = true;
                    return;
                }
                this.reportList = response.data.list;
            });
    }

    reportStatus(reportName) {
        this.API.all('report/'+reportName+'/status')
            .doGET()
            .then((response) => {
                this.reportList.map(item=>{
                    if (item.service !== reportName) return;
                    item.status = response.data.result.status
                    item.download = response.data.result.download
                    this.ToastService.showHtml('success',this.$translate.instant('STATUS_UPDATED', { label: item.label }));
                })
            });
    }

    reportRequest(reportName) {
        let parent = this;
        if (this.search_selected_categories_1.length > 0){
            let dd = [];
            this.reportList.map(item =>{
                if (item.service === reportName){
                    this.search_selected_categories_1.forEach((i, index) => {
                        dd.push(i.key);
                    })
                }
            })
            this.filterData[reportName]['category'] = dd;
        }
        if (this.search_selected_categories_2.length > 0){
            let dd = [];
            this.reportList.map(item =>{
                if (item.service === reportName){
                    this.search_selected_categories_2.forEach((i, index) => {
                        dd.push(i.key);
                    })
                }
            })
            this.filterData[reportName]['categoryIds'] = dd;
        }
        if (typeof this.filterData[reportName] === 'undefined') this.filterData[reportName] = {}
        this.filterData[reportName]['token'] = getToken();

        this.reportList.map(item=>{
            if (item.service !== reportName) return;
            item.status = 'waiting'
        });

        let customFilter = this.filterData[reportName];
        let newCustomFilter = [];
        for (let key in customFilter) {
            if (customFilter.hasOwnProperty(key)) {
                if (Array.isArray(customFilter[key])) {
                    newCustomFilter[key] =  customFilter[key].join('|');
                } else {
                    newCustomFilter[key] =  customFilter[key];
                }
            }
        }

        this.API.oneUrl('report/location-actions')
            //.customGET('location-actions', newCustomFilter, {'x-api-output': this.reportFileType[reportName]})
            .customGET(null, {locationId: parent.locationId, startedAt: parent.startedAt, finishedAt: parent.finishedAt})
            .then((response) => {
                parent.headers = response.data.headers;
                parent.shelves = response.data.shelves;
                parent.list = response.data.list;
                parent.totals = response.data.totals;
            });
    }

    setReportsFilterData(service,filter)
    {
        if (typeof this.filterData[service] === 'undefined') this.filterData[service] = {}
        Object.keys(filter).map((filterKey)=>{
            if (typeof this.filterData[service][filterKey] === 'undefined') this.filterData[service][filterKey] = null;
        })
        this.reportFileType[service]='csv'
    }
    selectAllOptionsByModel(service, key, data) {
        this.filterData[service][key] = data.map(d=>d.key)
    }
    deselectAllOptionsByModel(service, key) {
        this.filterData[service][key] = null;
    }

}

ReportLocationsController.$inject = ["$auth", "ToastService", "$translate", "API", "TimeRangeService", "$window", "$state", "$location", "$filter"];

export const ReportLocationsComponent = {
    templateUrl: './views/angular/app/components/reports/report-locations.component.html',
    controller: ReportLocationsController,
    controllerAs: 'vm',
    bindings: {}
};
