class ToastService {
  constructor($mdToast,$translate) {
    "ngInject";

    this.$mdToast = $mdToast;
    this.$translate = $translate;

    this.delay = 3000;
    this.position = "bottom right";
    this.action = this.$translate.instant('OK');
  }

  show(content) {
    if (!content) {
      return false;
    }

    return this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(content)
        .position(this.position)
        .action(this.action)
        .hideDelay(this.delay)
    );
  }
  showHtml(theme,template) {
    if (!template) {
      return false;
    }

    return this.$mdToast.show({
      template:'<md-toast md-theme="'+theme+'" ng-class="{\'md-capsule\': toast.capsule}">' +
          '<div class="md-toast-content" aria-live="polite" aria-relevant="all" >'+template+ '</div>' +
          '</md-toast>',
      hideDelay:this.delay,
      position: this.position,
      action: this.action,
      controllerAs: 'toast',
      controller: 'ToastService',
      bindToController: true
    })
  }

  success(content) {
    if (!content) {
      return false;
    }

    return this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(content)
        .position(this.position)
        .theme("success")
        .action(this.action)
        .hideDelay(this.delay)
    );
  }

  error(content) {
    if (!content) {
      return false;
    }

    return this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(content)
        .position(this.position)
        .theme("warn")
        .action(this.action)
        .hideDelay(false)
    );
  }

  precision(content) {
    if (!content) {
      return false;
    }

    return this.$mdToast.show(
        this.$mdToast
            .simple()
            .textContent(content)
            .position(this.position)
            .theme("warn")
            .action(this.$translate.instant('YES'))
            .hideDelay(false)
    );
  }

  resolve(){
    this.$mdToast.hide(true)
  }
}

ToastService.$inject = ["$mdToast","$translate"];
export {ToastService};
