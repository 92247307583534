import {UserService} from "../services/user.service";

let token = window.localStorage.satellizer_token;

const getUserInfo = () => {
    let token = window.localStorage.satellizer_token;

    let t = parseToken(token);
    return {
        uid         : t.sub,
        email       : t.email,
        permissions : t.permissions,
        companyName : t.companyName,
        settings    : t.settings,
    } ;
}

const me = () => {
    return JSON.parse(window.localStorage.getItem('polypus-me'));
}

const getUserSettings = () => {
    let t = parseToken();
    return t.settings
}
const getUserSetting = (setting) => {
   let settings = me();
   return settings[setting];
}
const getPermission = () => {
    let token = window.localStorage.satellizer_token;
    if (!token) {
        // Token yoksa, permission'ları döndürmeden çık
        return [];
    }

    let t = parseToken(token);
    return t.permissions || [];
}

const getToken = () => {
    return token;
}

const setToken = (t) => {
    window.localStorage.setItem('satellizer_token',t);
    token = window.localStorage.getItem('satellizer_token');
}

const canPermission = (...permissions) => {
    let permissionList = getPermission();
    return permissions.some(p => permissionList.includes(p));
}

const parseToken = (token) => {
    try {
        return parseJwt(token);
    } catch (e) {
        console.log("Invalid token:", e);
        return {};  // Geçersiz token ise boş döndür
    }
}
const parseJwt = (t) => {
    var base64Url = t.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export {getUserInfo, getUserSettings,getUserSetting, parseToken, getToken,setToken, getPermission,me,canPermission};
