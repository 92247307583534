
class InstallWizardController {
    constructor(
        $auth,
        $state,
        $mdSidenav,
        API
    ){
        this.$auth = $auth;
        this.state = $state;
        this.$mdSidenav = $mdSidenav;
        this.API = API;
    }
    close() {
        this.$mdSidenav("left").close();
    }

    open() {
        this.$mdSidenav("left").open();
    }
}
InstallWizardController.$inject = ["$auth", "$state", "$mdSidenav", "API"];
export const InstallWizardComponent = {
    templateUrl: "./views/angular/app/components/install-wizard/install-wizard.component.html",
    controller: InstallWizardController,
    controllerAs: "vm",
    bindings: {},
};
