import {openIntroductionVideo} from "../../../utils/introduction-videos";
import './batch-operations-byexcel.scss'
import {ToastService} from "../../../services/toast.service";

class BatchOperationsByexcelController {
    constructor(FileUploader, ToastService, $window, $state,  $translate, $location, API, DialogService) {
        "ngInject";

        this.ToastService = ToastService;
        this.$window = $window;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.API = API;
        this.DialogService = DialogService;
        this.fileUploader = FileUploader;
        this.XLSX = $window.readXlsxFile;
        this.fileUploaderDisabled = true;
        this.uploader = new FileUploader({
            url: this.$window.__env.apiUrl + "/api/batch-operations/file-upload", headers: {
                Authorization: "Bearer " + this.$window.localStorage.satellizer_token,
            },
        });
        this.video = undefined;
        this.stepList = [
            {
            step: 1, label: 'STEP_FILE_IMPORT', active: true, icon: 'touch_app',
            },
            {
                step: 2, label: 'STEP_PREPARE_TEMPLATE', active: false, icon: 'format_shapes',
            },
            {
                step: 3, label: 'STEP_UPLOAD_DATA', active: false, icon: 'ios_share',
            },
        ];

        this.activeStep = 1;
        this.previousStep = null;
        this.nextStep = 2;

        this.serviceList = [];
        this.importFiles = null
        this.importService = null
        this.importServiceSettings = null
        this.importParseData = null
        this.importParseCount = null
        this.importFileHeader = null
        this.parseTemplate = null
        this.importParseDataHeader = []
        this.importNewTemplateValue = 'new';
        this.templateMapping = {};
        this.templateMappingExtraInfo = []
        this.showUploatListStatus = 'ready';
        this.uploadDataRowFilterKey = 'all'
        this.selectedAllRows=false;
        this.selectedRows= {}
    }

    $onInit() {
        this.API.all('settings/get-channels').post().then((response) => {
            this.channels = response.data.channels;
        });
        this.getImporterServiceList()
        this.getImportList()
    }

    onSelectTab(obj) {
        this.$location.search(obj);
        this.video = undefined;
        if (obj.tab == 0) {
            this.video = 'batch-operations';
        }
        if (obj.tab == 1) {
            this.video = 'batchExcelProductsShelf';
        }
        if (obj.tab == 2) {
            this.video = 'batchExcelOrdersList';
        }
    }


    selectTab(tab) {

        if (tab == "sl") {
            var parent = this;
            this.loading = false;
            this.result = false;

            this.uploader.onErrorItem = function (fileItem, response, status, headers) {
                parent.ToastService.error(response.errors.message[0]);
            };

            this.uploader.onAfterAddingAll = function (item) {
                parent.addedFileName = item[0].file.name;
            };

            this.uploader.onBeforeUploadItem = function (item) {
                parent.loading = true;
                item.url = parent.$window.__env.apiUrl + "/api/batch-operations/file-upload";
            };

            this.uploader.onCompleteItem = function (item, response, status, headers) {
                parent.loading = false;
                //console.info('onCompleteAll', response);
                if (response.data !== undefined) {
                    parent.exportReportFilename = response.data.exportReportFilename;
                    parent.result = true;
                }

                parent.uploader.destroy();
            };
        } else if (tab == "plr") {
            var parent = this;
            this.loadingPLR = false;
            this.resultPLR = false;

            this.uploader.onErrorItem = function (fileItem, response, status, headers) {
                parent.ToastService.error(response.errors.message[0]);
            };

            this.uploader.onAfterAddingAll = function (item) {
                parent.addedFileNamePLR = item[0].file.name;
            };

            this.uploader.onBeforeUploadItem = function (item) {
                parent.loadingPLR = true;
                item.url = parent.$window.__env.apiUrl + "/api/batch-operations/plr-file-upload";
            };

            this.uploader.onCompleteItem = function (item, response, status, headers) {
                parent.loadingPLR = false;
                //console.info('onCompleteAll', response);
                if (response.data !== undefined) {
                    parent.exportReportFilenamePLR = response.data.exportReportFilename;
                    parent.resultPLR = true;
                }
                parent.uploader.destroy();
            };
        } else if (tab == "ca") {
            var parent = this;
            this.loadingCA = false;
            this.resultCA = false;

            this.uploader.onErrorItem = function (fileItem, response, status, headers) {
                parent.ToastService.error(response.errors.message[0]);
            };

            this.uploader.onAfterAddingAll = function (item) {
                parent.addedFileNameCA = item[0].file.name;
            };

            this.uploader.onBeforeUploadItem = function (item) {
                parent.loadingCA = true;
                item.url = parent.$window.__env.apiUrl + "/api/batch-operations/ca-file-upload";
            };

            this.uploader.onCompleteItem = function (item, response, status, headers) {
                parent.loadingCA = false;
                //console.info('onCompleteAll', response);
                if (response.data !== undefined) {
                    parent.exportReportFilenameCA = response.data.exportReportFilename;
                    parent.transactionIdCA = response.data.transactionId;
                    parent.resultCA = true;
                }

                if (response.status_code != 422) parent.ToastService.success(this.$translate.instant('TRANSACTION_LIST_CREATED'));

                parent.uploader.destroy();
            };
        } else if (tab == "pf") {

            if ((this.mode == 1 || this.mode == 2) && this.channelId != undefined) {
                this.fileUploaderDisabled = false;
            } else {
                this.fileUploaderDisabled = true;
            }

            var parent = this;
            this.loadingPF = false;
            this.resultPF = false;

            this.uploader.onErrorItem = function (fileItem, response, status, headers) {
                parent.ToastService.error(response.errors.message[0]);
            };

            this.uploader.onAfterAddingAll = function (item) {
                parent.addedFileNamePF = item[0].file.name;
            };

            this.uploader.onBeforeUploadItem = function (item) {
                parent.loadingPF = true;
                item.url = parent.$window.__env.apiUrl + '/api/batch-operations/product-filter-excel-for-tsoft-upload';
                item.formData = [{"mode": parent.mode, "channel_id": parent.channelId}];
            }



            this.uploader.onCompleteItem = function (item, response, status, headers) {
                parent.loadingPF = false;
                //console.info('onCompleteAll', response);
                if (response.data !== undefined) {
                    parent.exportReportFilenamePF = response.data.exportReportFilename;

                    angular.forEach(response.data.exportReportFilename, function (fileName, key) {
                        parent.$window.open(parent.$window.__env.apiUrl + "/api/action/exportExcel/" + fileName + "?token=" + parent.$window.localStorage.satellizer_token, '_blank');
                    });

                    parent.resultPF = true;
                    parent.mode = response.data.mode;
                }

                parent.uploader.destroy();
            };
        }
    }

    openIntroductionVideoModal(video) {
        openIntroductionVideo(video);
    }


    getImporterServiceList() {
        this.API.all('/importer/services')
            .customGET()
            .then(response => {
                this.serviceList = response.data.list
            })
    }

    getImportList() {
        return this.API.all('/importer/uploads')
            .customGET()
            .then(response => {
                if (204 !==response.response.status){
                    this.upladLists = response.data.list
                    this.showUploadList(this.showUploatListStatus)
                }
            })
    }


    getServiceSettings() {
        this.API.all('/importer/' + this.importService + '/setting')
            .customGET()
            .then(response => {
                this.importServiceSettings = response.data.list
            })
    }

    saveImportTemplate(){
        let parent = this;
        let request = {
            label:this.newTemplateName,
            template:this.templateMapping
        }

        this.API.all('importer/'+this.importService+'/setting')
            .post(request)
            .then((response) => {
                parent.importNewTemplateValue = response.data.list.templateId
                parent.importTemplate = response.data.list.templateId
                parent.ToastService.showHtml('success',this.$translate.instant('TEMPLATE_SUCC_REGISTERED'))
            });
    }

    csvParse() {
        let parent = this;
        let reader = new FileReader();
        reader.readAsText(this.importFiles[0], 'UTF-8');
        reader.onload = function () {
            let record = reader.result.split(/\r\n|\n/);
            let separator = record[0].split(',').length > 1 ? ',' : ';';
            let headers = record[0].split(separator);
            let lines = [];
            let json = {};

            for (let i = 0; i < record.length; i++) {
                let data = record[i].split(separator);
                if (data.length === headers.length) {
                    let tarr = [];
                    for (let j = 0; j < headers.length; j++) {
                        tarr.push(data[j]);
                    }
                    lines.push(tarr);
                }
            }
            for (let k = 0; k < lines.length; ++k) {
                json[k] = lines[k];
            }

            parent.importParseData = json;
            parent.importParseCount = record.length
            if (parent.importFileHeader) {
                parent.importParseDataHeader = parent.importParseData[0].map((header,i) => {
                    return header === null ? 'COLUMN' + i : header
                });

                delete parent.importParseData[0];
            } else {
                parent.importParseDataHeader = parent.importParseData[0].map((h, i) => {
                    return 'COLUMN' + i
                })
            }
        }
    }

    xlsxParse() {
        let parent = this
        this.XLSX(this.importFiles[0]).then(rows => {
            parent.importParseData = rows
            parent.importParseCount = Object.keys(rows).length

            if (parent.importFileHeader) {
                parent.importParseDataHeader = parent.importParseData[0].map((header,i) => {
                    return header === null ? 'COLUMN' + i : header
                });
                parent.importParseData.shift();
            } else {
                parent.importParseDataHeader = this.importParseData[0].map((h, i) => {
                    return 'COLUMN ' + i
                })
            }

        }).catch(err => {
            this.ToastService.error(err)
        })
    }

    selectStep(step,controller) {
        if (controller === 'navigasyon' || controller==='previousButton'){

            if (!this.stepList.find(s=>s.step===step).active){
                return this.selectStep(this.activeStep,'button')
            }

            if (step !== 1) this.previousStep = step - 1; else this.previousStep = null;
            if (step !== 3) this.nextStep = step + 1;
            this.activeStep = step
            return true
        }
     //   if (step === this.activeStep) return ;
        let result;

        if (this.activeStep === 1) {
            result = this.step1()
        } else if (this.activeStep === 2) {
            result = this.step2()
        }else if (this.activeStep === 3) {
            result = this.step3()
        }

        if (result.error){
            this.ToastService.showHtml('warn', '<ul style="list-style-type: none;">' + result.message.join('') + '</ul>')
            return false
        }

        if (step !== 1) this.previousStep = step - 1; else this.previousStep = null;
        if (step !== 3) this.nextStep = step + 1;
        this.activeStep = step
        this.stepList.map(list =>{
            if (list.step===step) list.active = true;
        })
    }

    step1() {
        let result = {
          error:false,
          message:[]
        };

        if (!this.importFiles){
            result.message.push('<li>' + this.$translate.instant('SELECT_FILE') + '</li>');
        }

        if (!this.importService){
            result.message.push('<li>' + this.$translate.instant('SELECT_SERVICE') + '</li>');
        }

        if (!(this.importFiles && this.importService)){
            result.error = true
            return  result;
        }

        if (this.importFiles[0].type === 'text/csv') {
            this.csvParse()
        } else if (this.importFiles[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.xlsxParse()
        }

        this.getServiceSettings()
        return result
    }

    step2() {
        let result = {
            error: false,
            message: []
        };

        let requirementList = Object.keys(this.importServiceSettings.fields).filter((field) => {
            return this.importServiceSettings.fields[field].requirement && !this[field]
        })
        if (requirementList.length > 0) {
            requirementList.map(err => {
                result.message.push('<li><b>' + this.importServiceSettings.fields[err].label + '</b> Alanı boş bırakılamaz</li>')
            })
            result.error = true;
        }
        return result;
    }

    step3(){
        this.sendImportData()
        return true
    }


    getServiceLabel(key, arr) {
        return arr.filter(f => f.serviceName === key)[0]?.label
    }

    getTemplateLabel(key, arr) {
        return arr.filter(f => f.id === key)[0]?.label
    }

    serviceSelectTemplate() {

        let parent = this;

        this.templateMapping = {}
        Object.keys(this.importServiceSettings.fields).map(field => {
            parent[field] = null;
        })

        this.importTemplateShow = false
        if (this.importNewTemplateValue === this.importTemplate) {
            if (!this.importFileHeader) {
                this.importTemplateShow = true
                return true
            }
            Object.keys(this.importServiceSettings.fields).map(field => {
                let key = field;
                field = this.importServiceSettings.fields[field]
                field.keywords.map(keyword => {
                    let header = parent.importParseDataHeader.findIndex(header => header.toLowerCase().replace(/\s/g, '') === keyword.toLowerCase())
                    if (header === -1) return;

                    parent[key] = parent.importParseDataHeader[header]
                    parent.templateMapping[key] = header
                })
            })
            this.importTemplateShow = true
            return true
        }

        let selectedTemplate = this.importServiceSettings.templates.find(template => template.id === this.importTemplate).template;
        Object.keys(selectedTemplate).map(template => {

            parent[template] = parent.importParseDataHeader[selectedTemplate[template]]
            parent.templateMapping[template] = selectedTemplate[template]
        })
    }

    templateFieldMapping(key) {
        if ('clear' === this[key]) {
            delete this.templateMapping[key]
            this[key] = null
            this.importServiceSettings.fields[key].extraFields && this.templateMappingExtraInfo.pop()
            return
        }
        this.templateMapping[key] = this.importParseDataHeader.indexOf(this[key])
        this.importServiceSettings.fields[key].extraFields && this.templateMappingExtraInfo.push(key)
    }

    sendImportData() {
        let request = {
            data            : this.importParseData,
            header          : this.importParseDataHeader,
            rowCount        : this.importParseCount,
            template        : this.templateMapping,
            templateId      : this.importTemplate,
            headerContains  : this.importFileHeader,
            name            : this.importFiles[0].name,
            importService   : this.importService
        }

        this.API.all('/importer/uploads/'+this.importService)
            .post(request)
            .then((response) => {
                if (response.response.status === 207) {
                    this.ToastService.showHtml('success', response.message.join(', '))
                }
                if (response.response.status === 200) {
                    this.ToastService.showHtml('success', response.data.message)
                }
            });
    }


    showUploadList(filter) {
        this.upladLists?.map(list =>{
            if (filter === 'all') {
                list.show = true;
                return true;
            }
            list.show = list.process === filter && (this.importService ? list.service === this.importService : true)
        })
    }

    showUploadListDetail(uploadDetail) {
        return this.API.all('/importer/uploads/'+uploadDetail.id)
            .customGET()
            .then(response => {
                this.uploadList.rows = response.data.list
                this.uploadDataRowFilter()
            })
    }

    getIndexInUsed(field) {
        return Object.values(this.uploadList.template).findIndex(key => key === parseInt(field)) !== -1;
    }

    getKeyInUsed(field) {
        let index = Object.values(this.uploadList.template).findIndex(key => key === parseInt(field))
        if (index === -1) return  false;
        return Object.keys(this.uploadList.template)[index]
    }

    uploadDataRowFilter() {
        this.uploadList.rows.list.map(list => {
            if (this.uploadDataRowFilterKey === 'all') {
                list.show = true
                return true
            }
            list.show = list.status === this.uploadDataRowFilterKey;
        })
    }

    selectedAllRowsAction(){
        this.uploadList.rows.list.map(row=>{
            if (row.show)   this.selectedRows = {...this.selectedRows, [row.id]: !this.selectedAllRows}
        })
    }

    updateSelectedRowsAction() {
         this.selectedAllRows = this.uploadList.rows.list.length === Object.values(this.selectedRows).filter(v=> v).length
    }

    showUploadListDetailDialog(detail) {
        let options = {
            controller: 'BatchOperationsByexcelController',
            controllerAs: 'vm',
            locals: {
                uploadList:detail
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.showUploadListDetail(controller.uploadList)
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/batch-operations-byexcel/partials/upload-lists-detail.component.html',
            options
        )
    }

    sendDeletedData() {
        Object.keys(this.selectedRows).map(row =>{
            if (!this.selectedRows[row]) return false;
            this.API.all('/importer/uploads/'+this.uploadList.id+'/'+row)
                .customDELETE()
                .then((response) => {
                    if (response.response.status === 204) {
                        this.ToastService.showHtml('success',this.$translate.instant('SUCC_REMOVED_LIST'))
                    }
                });
        })
        this.showUploadListDetail(this.uploadList)
    }

    sendAcceptData() {

        let rowIds = [];
        Object.keys(this.selectedRows).map(row =>{
            if (this.selectedRows[row]) rowIds.push(row)
        })

        this.API.all('/importer/uploads/'+this.uploadList.id)
            .customPUT({rowIds:rowIds})
            .then((response) => {
                if (response.response.status === 200) {
                    this.ToastService.showHtml('success', response.data.message)
                }
            });
    }

    refreshImportList(){
        this.getImportList().then(response=>{
            this.ToastService.showHtml('success',this.$translate.instant('DOWNLOAD_LIST_UPDATED'))
        })
    }

    refreshImportRowList(){
        let uploadList= this.uploadList
        this.showUploadListDetail(uploadList).then(response=>{
            this.ToastService.showHtml('success',uploadList.name+ this.$translate.instant('ROW_LIST_UPDATED'))

        })
    }
}

BatchOperationsByexcelController.$inject = ["FileUploader", "ToastService", "$window", "$state", "$translate", "$location", "API", "DialogService"];
const BatchOperationsByexcelComponent = {
    templateUrl: "./views/angular/app/components/batch-operations-byexcel/batch-operations-byexcel.component.html",
    controller: BatchOperationsByexcelController,
    controllerAs: "vm",
    bindings: {},
};

export {BatchOperationsByexcelComponent, BatchOperationsByexcelController}
