import { getUserInfo,me } from "../../../utils/users";
class ActionSummaryController {
  constructor(
    API,
    ToastService,
    i18nService,
    $auth,
    $timeout,
    $state,
    DialogService,
    UserService,
    $stateParams,
    $mdMenu,
    $rootScope,
    $filter,
    $q,
    $location,
    $scope,
    $window,
    $mdBottomSheet,
    TabActionService
  ) {
    "ngInject";
    this.UserService = UserService;
    this.API = API;
    this.$auth = $auth;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.TabActionService = TabActionService;
    this.$scope = $scope;
    this.window = $window;
    this.$mdBottomSheet = $mdBottomSheet;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$mdMenu = $mdMenu;

    this.per_page = 25;
    this.pageNumber = 1;
    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;

    this.created_at_end = new Date()
    this.created_at_start = new Date(this.created_at_end.getFullYear(), 0, 1)

  }
  cleaForm() {

  }

  $onInit() {
    //this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
    this.summary_list_type = 'marketplace_group';
  }

  getPaginationAction(
    pageNumber = this.pageNumber,
    filterArr = this.filterArr,
    per_page = this.per_page) {
    //this.getOrderLists(pageNumber, filterArr, per_page)
    this.getOrderListV2(pageNumber, filterArr, per_page)
  }

  getActionSummary(){
    let parent = this;
    this.API.all("action/summary")
      .customGET(null, {
        summary_list_type: parent.summary_list_type,
        created_at_start: parent.created_at_start,
        created_at_end: parent.created_at_end,
      })
      .then((response) => {
        if (parent.summary_list_type === 'marketplace_group') {
          this.actionSummariesMarketplace = response.data.list
        }
        if (parent.summary_list_type === 'default_group') {
          this.actionSummariesDefault = response.data.list
        }

        this.heads = [];
        this.heads2 = [];

        var parent2 = this;

        angular.forEach(this.actionSummariesMarketplace, function (val, date) {
          angular.forEach(val, function (application, key) {
            if (parent2.heads.indexOf(key) === -1) {
              parent2.heads.push(key);
              parent2.heads2.push({"key":"TK", "application":key, "column":"total"});
              parent2.heads2.push({"key":"TP", "application":key, "column":"prepared"});
              parent2.heads2.push({"key":"EL", "application":key, "column":"control"});
              parent2.heads2.push({"key":"PK", "application":key, "column":"package"});
              parent2.heads2.push({"key":"KK", "application":key, "column":"qualityControl"});
              parent2.heads2.push({"key":"TS", "application":key, "column":"shipment"});
            }
          });
        });
      });
  }

}
ActionSummaryController.$inject = ["API", "ToastService", "i18nService", "$auth", "$timeout", "$state", "DialogService", "UserService", "$stateParams", "$mdMenu", "$rootScope", "$filter", "$q", "$location","$scope","$window","$mdBottomSheet","TabActionService"];
const ActionSummaryComponent = {
  templateUrl: "./views/angular/app/components/action-summary/action-summary.component.html",
  controller: ActionSummaryController,
  controllerAs: "vm",
  bindings: {},
};
export {ActionSummaryComponent,ActionSummaryController}
