import "./forgot-password.scss"
class ForgotPasswordController {
    constructor(API, ToastService, $translate, $state) {
        'ngInject';

        this.API = API;
        this.$state = $state;
        this.ToastService = ToastService;
        this.$translate = $translate;
    }

    $onInit(){
        this.email = '';
    }

    submit() {
        this.API.all('auth/password/email').post({
            email: this.email
        }).then(() => {
            this.ToastService.success(this.$translate.instant('CHECK_YOUR_EMAIL_FOR_RESET_PASSWORD'));
            this.$state.go('app.landing');
        });
    }
}
ForgotPasswordController.$inject = ["API", "ToastService", "$translate", "$state"];
export const ForgotPasswordComponent = {
    templateUrl: './views/angular/app/components/forgot-password/forgot-password.component.html',
    controller: ForgotPasswordController,
    controllerAs: 'vm',
    bindings: {}
}
