class TimeRangeService {
  constructor() {}

  calculateTimeRange(timeRange) {
    let now = new Date();
    let startDate = null;
    let endDate = now;

    switch (timeRange) {
      case 'lastDay':
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case 'thisDay':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        break;
      case 'lastWeek':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case 'thisWeek':
        const firstDayOfWeek = now.getDate() - now.getDay();
        startDate = new Date(now.setDate(firstDayOfWeek));
        endDate = new Date();
        break;
      case 'lastMonth':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        break;
      case 'thisMonth':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case 'lastYear':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        break;
      case 'thisYear':
        startDate = new Date(now.getFullYear(), 0, 1);
        break;
      case 'all':
        startDate = null;
        endDate = null;
        break;
    }

    return { startDate, endDate };
  }
}
TimeRangeService.$inject = [];
export {TimeRangeService};
