import "./recipe.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class RecipeController {
    constructor($auth, $window, $state, $translate, $location, $rootScope, $stateParams, API, $mdBottomSheet, $scope, $filter, DialogService, ToastService) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.API = API;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.$filter = $filter;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.dropdownCategoriesTranslationsText = {
            buttonDefaultText: this.$filter('translate')('CATEGORY_SELECT'),
            checkAll: this.$filter('translate')('SELECT_ALL'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            dynamicButtonTextSuffix: this.$filter('translate')('CATEGORY_SELECTED'),
        };
        this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};

        this.categories = null;
        this.categoryTags = null;
        this.filter = {
            barcode: null,
            categoryTag: null,
            brand: null,
            tagId: null,
            status: []
        }
        this.page = 1;
        this.limit = 20;
        this.total = 0
        this.recipeList = [];
        this.selectedProduct = [];
        this.detailScreen = false
        this.productDetail = [];
        this.product_id = null;
    }

    $onInit() {
        this.get(1)
        this.API.all('/products/tags').post().then((response) => {
            this.categoryTags = response.data.categoryTags;
        });
    }

    get(page, limit = 20) {
        let filter = {
            page: page,
            limit: limit,
        }

        if (this.filter.barcode) {
            filter.barcode = this.filter.barcode
        }
        if (this.filter.categoryTag?.resourcetype?.id) {
            filter.tagId = this.filter.categoryTag?.resourcetype?.id
        }

        this.filter.brand?.forEach(b => {
            if (b.id) {
                filter.brandId.push(b.id);
            }
        })

        if (filter.brandId?.length > 0) {
            filter.brandId = filter.brandId.join('|')
        }

        if (this.filter.status?.length > 0) {
            filter.status = this.filter.status.join('|')
        }

        this.API.all('recipe')
            .customGET(null, filter)
            .then((response) => {
                if (response.response.status === 204) {
                    this.recipeList = null;
                    return;
                }
                const {total, limit, totalPage, list} = response.data;
                this.limit = limit;
                this.total = total;
                this.recipeList = list;
                this.totalPage = totalPage;
            })
            .catch(function () {
            });
    }

    showRecipe(productId){
        this.$scope.$emit('recipe',{tab:1,productId:productId});
    }

}


RecipeController.$inject = ["$auth", "$window", "$state",  "$translate", "$location", "$rootScope", "$stateParams", 'API', '$mdBottomSheet', '$scope', '$filter', 'DialogService', 'ToastService'];

const RecipeComponent = {
    templateUrl: './views/angular/app/components/recipe/partials/recipe.component.html',
    controller: RecipeController,
    controllerAs: 'vm',
    bindings: {
        selectedTabIndex: '<',
        wrapperTabIndex: '@',
    }
};


export {RecipeComponent, RecipeController}
