import "./settings.scss";

class CurrencyController {
    constructor(API, ToastService, DialogService, FileUploader, $window, $state, $location, $rootScope, $scope) {
        "ngInject";

        this.API = API;
        this.ToastService = ToastService;
        this.$window = $window;
        this.$state = $state;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$scope = $scope;

        this.availableCurrency = [];
        this.currency = [];
        this.waitingCurrency = 0;
        this.selectedCurrency = null;
    }

    $onInit() {
        let parent = this;
        this.getFilterInventory()
        this.getCurrency()
        parent.$rootScope.$on('currencyEvent', function (event, data) {
            parent.onSelectTab(data)
        })
    }

    onSelectTab(obj) {
        this.$location.search(obj);
        this.getCurrency()
    }

    $onChanges(data) {
        console.log(data)
    }

    getFilterInventory() {
        return this.API.all("settings/currency/inventory")
            .customGET(null, {filter: 1})
            .then((response) => {
                this.availableCurrency = response.data.list.currency;
            });
    }

    getCurrency() {
        this.API.all("settings/currency")
            .customGET()
            .then((response) => {
                this.currency = response?.data?.list ?? [];
                this.getFilterInventory().then(() => {
                    // currency listesi üzerinde işlem yapma
                    this.currency.forEach(currencyList => {
                        // Mevcut dövizler arasında eksik olanları ekleyin
                        currencyList.avaiablleexchangeRateList = this.availableCurrency.data
                            .filter(c => !currencyList.exchangeRate.find(f => f.currency === c.currency))
                            .map(m => {
                                m.price = 1;
                                m.newPrice = 1;
                                return m;
                            });
                    });
                    // Mevcut dövizlerin listesi üzerinde işlem yapma
                    this.availableCurrency.data.forEach(a => {
                        let currency = this.currency.find(c => c.name === a.currency);

                        if (currency) {
                            // Döviz varsa
                            a.exists = true;
                            a.exchangeRate = currency.exchangeRate.filter(c => c.currency !== a.currency).map(r=>{
                                r.newPrice = r.price
                                return r
                            });
                            a.avaiablleexchangeRateList = currency.avaiablleexchangeRateList;
                        } else {
                            // Döviz yoksa
                            a.exchangeRate = [];
                            a.avaiablleexchangeRateList = this.availableCurrency.data
                                .filter(c => c.currency !== a.currency)
                                .map(r => {
                                    r.price = 1;
                                    r.newPrice = 1;
                                    return r;
                                });
                        }
                    });
                })
            });
    }
    addNewCurrency(currency){
        this.availableCurrency.data.forEach(data =>{
            if (data.currency === this.selectedCurrency.currency){
                let x = data.avaiablleexchangeRateList.find(r => r.currency === currency)
                data.avaiablleexchangeRateList =data.avaiablleexchangeRateList.filter(r => r.currency !== currency)
                data.exchangeRate.push({
                    currency: x.currency,
                    name: x.name,
                    price: x.price,
                    newPrice: x.newPrice,
                    new:true
                })
            }
        })
        this.addCurrency =null
        return  true
    }

    priceFixed(price) {
        price = price.toString().replace(/[^0-9.]/g, '')
        return (1 / price).toFixed(4);
    }

    save() {
        let requestData = this.generateRequestData(false);

        if (!requestData) {
            return false
        }

        this.API.all('settings/currency')
            .customPOST({currencies: requestData})
            .then((response) => {

                this.getCurrency()
            })
            .catch(() => {
            });
    }

    update() {
        let requestData = this.generateRequestData(true);

        if (!requestData) {
            return false
        }
        this.API.all('settings/currency')
            .customPUT({currencies: requestData})
            .then((response) => {

                this.getCurrency()
            })
            .catch(() => {
            });
    }

    generateRequestData(onlyChanges) {
        let data = this.availableCurrency.data.find(c => c.currency === this.selectedCurrency.currency).exchangeRate;

        if (onlyChanges) {
            data = data.filter(c => c.price != c.newPrice)
        }

        if (!data) {
            return false;
        }
        return data.map(r => {
            return {
                name: this.selectedCurrency.currency,
                currency: r.currency,
                price: r.newPrice
            }
        })
    }
    deleteCurrency(currency){
        if (!currency.id){
            console.log(this.availableCurrency.data)
            this.availableCurrency.data.forEach(data =>{
                if (data.currency === this.selectedCurrency.currency){
                    data.avaiablleexchangeRateList.push(currency)
                    data.exchangeRate = data.exchangeRate.filter(c => c.currency !== currency.currency)
                }
            })
            this.addCurrency =null
            return  true
        }
        this.API.all('settings/currency')
            .customDELETE(null,{
                name : this.selectedCurrency.currency,
                currency : currency.currency,
            })
            .then((response) => {
                this.getCurrency()
            })
            .catch(() => {
            });
    }
}

CurrencyController.$inject = ["API", "ToastService", "DialogService", "FileUploader", "$window", "$state", "$location", "$rootScope", "$scope"];
const CurrencyComponent = {
    templateUrl: "./views/angular/app/components/settings/partials/currency.html",
    controller: CurrencyController,
    controllerAs: "vm",
    bindings: {},
};

export {CurrencyComponent, CurrencyController}
