import "./production-for-duvee.scss";
class ProductionForDuveeController{
    constructor(API, $interval){
        'ngInject';

        this.API=API;
        this.$interval=$interval;
        var parent = this;
        this.showLoading = true;
        this.getWaitingItems(parent);
    }

    getWaitingItems(parent) {
        this.$interval(function(parent) {
            parent.API.all('production/get-waiting-products').post(null,{}).then((response) => {
                parent.waitingItems = response.data.responseArr;
            });
            parent.showLoading = false;
        }, 5000, 0, true, parent);

    }

    $onInit(){

    }
}
ProductionForDuveeController.$inject = ["API", "$interval"];
export const ProductionForDuveeComponent = {
    templateUrl: './views/angular/app/components/production-for-duvee/production-for-duvee.component.html',
    controller: ProductionForDuveeController,
    controllerAs: 'vm',
    bindings: {}
};
