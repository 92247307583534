import "./stock-management.scss";
import { StockManagementController } from "./stock-management.component";

class StockManagementAccountingController {
  constructor(
    API,
    ToastService,
    $translate,
    $auth,
    $http,
    DialogService,
    $stateParams,
    $q,
    $state,
    $location,
    $scope,
    $rootScope
  ) {
    "ngInject";

    this.API = API;
    this.ToastService = ToastService;
    this.$translate = $translate;
    this.$auth = $auth;
    this.disabled = [];
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.create_product_tags = [];
    this.$http = $http;
    this.createFormPackageProducts = [];
    this.editFormPackageProducts = [];
    this.currencyData = [];
    this.searchBrand = [];
    this.$q = $q;
    this.$state = $state;
    this.$location = $location;

    this.DialogService = DialogService;

    this.$rootScope = $rootScope;

    var self_ = this;

    this.productId = '';
    this.barcode = '';
    this.productCode = '';
  }

  $onInit() {
  }

  getFromAccounting() {
    this.API.all('integration/accounting/quick-view?id='+this.productId+'&barcode=' + this.barcode+'&productCode=' + this.productCode)
      .customGET()
      .then((response) => {
        if (response.response.status === 200) {
          this.quickView = response.data.quickView;
        }
        if (response.response.status === 204) {
          this.ToastService.error(this.$translate.instant('NOT_FOUND_IN_ERP'));
        }
      })
  }

}

StockManagementAccountingController.$inject = ["API", "ToastService", "$translate", "$auth", "$http", "DialogService", "$stateParams", "$q", "$state", "$location", "$scope","$rootScope"];

class CreateLotSeriController{
  constructor($mdDialog) {
    "ngInject";

    this.$mdDialog = $mdDialog

    this.createLotOrSNu = null;
    this.product_id = null;
    this.createLotQty = null;
    this.createLotExpiredDate = null;
  }

  $onInit(){}

  save(){
    this.$mdDialog.hide({
      createLotOrSNu: this.createLotOrSNu,
      createLotQty: this.createLotQty,
      createLotExpiredDate: this.createLotExpiredDate
    });
  }

  cancel(){
    this.$mdDialog.cancel({close : true});
  }
}
CreateLotSeriController.$inject = ["$mdDialog"];

export {StockManagementAccountingController, CreateLotSeriController}
