import { getPermission } from "../../utils/users";

class PermissionController {
    constructor(UserService, $timeout, $compile, $scope, TokenService) {
        'ngInject';

        this.UserService = UserService;
        this.perms = {};
        this.$timeout = $timeout;
        this.$compile = $compile;
        this.$scope = $scope;
        this.TokenService = TokenService;

        // Listen for token updates
        //todo: buna gerek olmayabilir
        this.$scope.$on('tokenUpdated', () => {
            this.updatePermissions();
        });

        // Initial permission load
        this.updatePermissions();
    }

    updatePermissions() {
        // $rootScope'tan permissions'ı al
        const permissions = this.TokenService.getPermissions();
        this.$scope.visible = {};
        permissions.forEach(permission => {
            this.$scope.visible[permission] = true;
        });
    }
}
PermissionController.$inject = ["UserService","$timeout", "$compile", "$scope", "TokenService"];
export function PermissionDirective($rootScope) {
    return {
        controller: PermissionController,
        restrict: 'A',
        priority:600,
        link: function(scope, element, attrs, controllers) {
            // $rootScope'tan permissions'ı al
            const permissions = $rootScope.permissions || [];
            scope.visible = {};
            permissions.forEach(permission => {
                scope.visible[permission] = true;
            });
        }
    };
}

PermissionDirective.$inject = ['$rootScope'];

