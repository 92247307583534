import "./issue.scss";
import {TabActionService} from "../../../services/tabAction.service";

class IssuesDetailController {
    constructor(API,$state,$mdBottomSheet,ToastService,$location,$rootScope,TabActionService,$scope,DialogService,UserService,$translate) {
        'ngInject';

        this.API = API;
        this.$state = $state;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$location = $location;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ToastService = ToastService;
        this.TabActionService = TabActionService;
        this.DialogService = DialogService;
        this.UserService = UserService;
        this.$translate = $translate;

        this.listId             = null;
        this.list               = null;
        this.listPriorityColor  = {
            1:'new-action-list-normal',
            2:'new-action-list-high',
            3:'new-action-list-very-high',
            4:'new-action-list-urgent',
        }
        this.ownerUsers = this.UserService.getAllUsers();
        this.selectedActions = [];

    }
    $onInit() {
        let parent = this;
        parent.$scope.$on('issueEvent', function (event, data) {
            if ('issueDetail' === data.screen){
                parent.issueScreen = data.screen
                parent.listId = data?.listId
                if (parent.listId){
                    parent.getList()

                }
            }
        })

    }

    getList() {
        this.API.all('action-process/assignList/' + this.listId)
            .doGET(null,{
                list:1
            })
            .then((response) => {
                this.selectedActions = []
                this.list = response.data.list
                this.list.items.map(item=>{
                    item.statistic = this.list.statistic?.actions.filter(s => s.actionId === item.actionId)[0]

                })

            });
    }

    separateActionList(isNew){
        if (this.selectedActions.length == 0){

            this.ToastService.showHtml('warn',
                this.$translate.instant('EMPTY_SELECTION_ALERT')
            );
            return true;
        }

        this.DialogService.confirm(
            this.$translate.instant('CONFIRM_MESSAGE'),
            this.$translate.instant('SEPARATE_CONFIRM_ALERT')
        ).then(()=>{
            let actionIds = this.selectedActions.map(s => s.actionId);
            this.API.all("action-process/listSeparate/"+this.listId)
                .patch({
                    actionIds:actionIds,
                    isNew:isNew
                })
                .then((response) => {
                    this.selectedActions = []
                    this.bulkAddItemResult(response.data.list)
                    this.getList()
                })
        })
    }

    showCreateListModal(){

        const tabDetail = {
            picking:{tab:2,screen:'createIssue'},
            shelfUp:{tab:3,screen:'createReturnIssue'},
            faulty:{tab:4,screen:'createFaultyIssue'},
        }
        let selectedTab = tabDetail[this.list.listType]
        this.$rootScope.$broadcast('issueChildEvent', {...selectedTab, listId: this.listId, process: 'addListItem'})


    }
    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.tryErrorRows = false

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        );

    }

    selectAction(action,select){
        let exists = this.selectedActions.filter(s=> s.actionId === action.actionId)

        if (select && exists.length>0){
            this.ToastService.showHtml('warn',
                this.$translate.instant('TRANSACTION_ALREADY_ADDED', { transactionId: action.transactionId })
            );
            return true;
        }

        action.select = select
        if (select){
            this.selectedActions.push(action);
        }else {
            this.selectedActions = this.selectedActions.filter(a=>a.actionId !== action.actionId);
        }
    }
    goProduct(productId){

    }


    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesDetailController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getOrderDetail(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/invoices-detail/invoices-detail.component.html',
            options
        )
    }
    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

    downloadProcessDocument(document, processId) {
        this.API.all(`action-process/document/${processId}`)
            .customGET(null,{
                document:document
            })
            .then((response) => {
                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.document);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
            })
    }

    changePreparedUser(listId) {
        this.API.all(`action-process/assignPrepare/${listId}/assign`)
          .customPUT({
              preparedUserId: this.preparedUserId
          })
          .then((response) => {
              if (response.response.status === 200) {
                  this.ToastService.showHtml('success', response.data.message)
              }
          });
    }

}

IssuesDetailController.$inject = ['API','$state','$mdBottomSheet','ToastService', "$location","$rootScope","TabActionService","$scope","DialogService","UserService",'$translate'];

const IssuesDetailComponent = {
    templateUrl: "./views/angular/app/components/issues/partials/issues-detail.html",
    controller: IssuesDetailController,
    controllerAs: "vm",
    bindings: {
        listId :'<',
    },
};

export {IssuesDetailController, IssuesDetailComponent}
