import {PrintPreviewDialogController} from "./dialogs/printPreview/printPreview.dialog";


require("angular");

require("ngstorage");
require("satellizer");

require("restangular");
require("angular-ui-router");
require("ngstorage");
require("angular-loading-bar");
require("angular-ui-grid");
require("angularjs-dropdown-multiselect");
require("ng-tags-input");
require("angular-translate");
require("angular-file-upload");
require("chart.js");
require("angular-chart.js");
require("angular-material");
require("ng-tags-input/build/ng-tags-input.css");
require("angular-material");
require("angular-route");
require("angular-sanitize");
require("ng-sortable");
require("angular-ui-carousel");
require("markdown-it");
/* tincymce muadili ama sanitize içindeki lowercase fonksiyonu angular versiyonu ile uyumsuz
require("textangular");
require("rangy");
 */

import Echo from 'laravel-echo';

window.Echo = Echo;

window.io = require('socket.io-client')

import {langEs} from "./lang/es.js";
import {langEn} from "./lang/en.js";
import {langTr} from "./lang/tr.js";
import {langRu} from "./lang/ru.js";
import {Html5Qrcode} from "html5-qrcode"

window.BarcodeScanner = Html5Qrcode;

import readXlsxFile from 'read-excel-file'

window.readXlsxFile = readXlsxFile;

import {ShowScreenForPermissionController} from "./dialogs/showScreenForPermission/showScreenForPermission.dialog";
import {SettingsLogController} from "./app/components/settings/settings-log.component";

// Bunlar component olarak zaten index.main de import ediliyorlar.
import {StockManagementController} from "./app/components/stock-management/stock-management.component";
import {CustomersController} from "./app/components/customers/customers.component";
import {OrderListsController} from "./app/components/order-lists/order-lists.component";
import {InvoicesController} from "./app/components/invoices/invoices.component";
import {InvoicesDetailController} from "./app/components/invoices-detail/invoices-detail.component";
import {ActionDetailController} from "./app/components/order-lists/action-detail.component";
import {
    BatchOperationsByexcelController
} from "./app/components/batch-operations-byexcel/batch-operations-byexcel.component";
import {SettingsController} from "./app/components/settings/settings.component";
import {LocationManagementController} from "./app/components/location-management/location-management.component";
import {OrdersPrepareController} from "./app/components/orders-prepare/orders-prepare.component";
import {searchProductController} from "./app/components/invoices/search-product.component";

import {BottomViewController} from "./app/components/bottom-view/bottom-view.component";
import {BottomCameraController} from "./app/components/bottom-camera/bottom-camera.component";
import {BottomQuickCreateStockController} from "./app/components/bottom-quick-create-stock/bottom-quick-create-stock.component";
import {
    StockManagementAccountingController,
    CreateLotSeriController
} from "./app/components/stock-management/stock-management.helpers";
import {MultipleQuantityController, ProductDialogController, PreparePackageMultipleController} from "./app/components/orders-prepare/orders-prepare.helpers";
import {CameraController} from "./app/components/camera/camera";
import {OfferController} from "./app/components/offer/offer.component";
import {ReceivingController} from "./app/components/receiving/receiving.component";
import {RouteController} from "./app/components/app-root/app-root.component";

import {ShelfUpController} from "./app/components/location-management/shelf-up.component";
import {IssuesController} from "./app/components/issues/issues";
import {StocktakingController} from "./app/components/location-management/stocktaking.component";
import {ShippingItemCounterController} from "./app/components/cargo/shipping-item-counter.component";
import {ToastService} from "./services/toast.service";
import {PickingController} from "./app/components/orders-prepare/picking.component";
import {IssuesDetailController} from "./app/components/issues/issues-detail.component";
import {CurrencyController} from "./app/components/settings/currency.component";
import {RecipePageController} from "./app/components/offer-recipe/recipe-page.component";
import {UploadController} from "./app/components/upload/upload";
import {GalleryController} from "./app/components/upload/gallery";
import {CloneStockController} from "./app/components/clone-stock/clone-stock.component";
import {RecipeController} from "./app/components/recipe/recipe.component";


angular.module("app.run", []);
angular.module("app.routes", []);
angular.module("app.filters", []);
angular.module("app.services", []);
angular.module("app.config", []);
angular.module("app.directives", []);
angular.module("app.components", [
    "ui.router",
    "ngMaterial",
    "angular-loading-bar",
    /*"ngMask",*/
    /*"angular-mask",*/
    "chart.js",
    "as.sortable", /*bu modül taglare sıralamak için*/
    "restangular",
    "ngStorage",
    "satellizer",
    "ngQueue",
    "ui.grid.selection",
    /*'ui.tree',*/ "angularjs-dropdown-multiselect",
    "ui.grid",
    "pascalprecht.translate",
    "ui.grid.pagination",
    "ui.grid.expandable",
    "ngTagsInput",
    "angularFileUpload",
    "ngRoute",
    "ngSanitize",
    /*
    "textAngular",
     */
    "ui.carousel",
]);

// nedense buradaki sıralama önemli, değiştirince bozuluyor. dikkat!
angular.module("app", [
    "app.run",
    "app.filters",
    "app.services",
    "app.components",
    "app.directives",
    "app.routes",
    "app.config",
]);

angular.module("app").config([
    "$translateProvider",
    function ($translateProvider) {
        $translateProvider.translations("tr", langTr);
        $translateProvider.translations("en", langEn);
        $translateProvider.translations("es", langEs);
        $translateProvider.translations("ru", langRu);
        $translateProvider.preferredLanguage( window.navigator.language.split('-')[0] || "tr");
    }
]);

/*jshint undef:true*/
/*global angular*/
angular.module('ngQueue', [])
    .factory('$queueFactory', [
        '$q', '$window', '$timeout', '$rootScope', '$filter',
        function ($q, $window, $timeout, $rootScope, $filter) {

            var Queue = function Queue(config) {
                this.init(config);
            };

            var p = Queue.prototype;

            p._config = {};

            // number of simultaneously runnable tasks
            p._limit = null;

            // the queue
            p._queue = [];

            // function used for deferring
            p._deferFunc = null;

            p._list = [];

            p.queueTotal = 0;
            p.queuePending = 0;
            p.queueIdle = true;


            p.init = function (config) {
                this._config = config;
                this._limit = config.limit;
                this._queue = [];

                if (this._config.statistics) {
                    this._list = [];
                }

                if (config.deferred) {
                    if ($window.setImmediate) {
                        this._deferFunc = function (todo) {
                            $window.setImmediate(function () {
                                todo();
                                $rootScope.$apply();
                            });
                        };
                    } else {
                        this._deferFunc = function (todo) {
                            // $timeout(todo, 0, true);
                            $timeout(todo);
                        };
                    }
                }
            };

            p.enqueue = function (todo, context, args) {
                var task = [todo, context, args];

                this._queue.push([todo, context, args]);

                if (this._config.statistics) {
                    this.updateStats();
                }

                this.dequeue();
                return task;
            };

            p.remove = function (task) {
                var index = this._queue.indexOf(task),
                    item = this._queue.splice(index, 1)[0];
                if (this._config.statistics) {
                    this.updateStats();
                }
                return item;
            };

            p.dequeue = function () {
                if (this._limit <= 0 || this._queue.length === 0) {
                    return;
                }

                this._limit--;

                var that = this,
                    buf = this._queue.shift(),
                    randNum = Math.random(),
                    todo = buf[0],
                    context = buf[1],
                    args = buf[2],
                    success, error;

                if (this._config.statistics) {
                    this._list.push({id: randNum, resolved: false});
                }

                success = error = function () {
                    that._limit++;

                    if (that._config.statistics) {
                        var item = $filter('filter')(that._list, function (v) {
                            return v.id === randNum;
                        })[0];
                        item.resolved = true;

                        that.updateStats();
                    }

                    if (that._deferFunc) {
                        that._deferFunc(function () {
                            that.dequeue();
                        });
                    } else {
                        that.dequeue();
                    }
                };

                /*jshint es5: true */
                $q.when(todo.apply(context || null, args || null))
                    .then(success, error);
                /*jshint es5: false */
            };

            p.clear = function () {
                this._queue = [];
                this._list = [];
                if (this._config.statistics) {
                    this.updateStats();
                }
            };

            p.updateStats = function () {
                this.queueTotal = this._queue.length + this._list.length;
                this.queuePending = $filter('filter')(this._list, function (v) {
                    return v.resolved === false;
                }).length + this._queue.length;
                this.queueIdle = this.queuePending < 1;
            };

            return function factory(limit, deferred) {
                var config;

                if (angular.isObject(limit)) {
                    config = limit;
                } else {
                    limit = limit || 1;

                    if (angular.isObject(deferred)) {
                        config = deferred;
                        config.limit = config.limit || limit;
                    } else {
                        if (angular.isUndefined(deferred)) {
                            deferred = false;
                        }

                        config = {
                            limit: limit,
                            deferred: !!deferred
                        };
                    }
                }

                return new Queue(config);
            };
        }]);

var app = angular.module('plunker', ['ngMaterial']);

app.controller('MainCtrl', function($scope) {

    var monthFormat =  buildLocaleProvider("MMM-YYYY");

    function buildLocaleProvider(formatString) {
        return {
            formatDate: function (date) {
                if (date) return moment(date).format(formatString);
                else return null;
            },
            parseDate: function (dateString) {
                if (dateString) {
                    var m = moment(dateString, formatString, true);
                    return m.isValid() ? m.toDate() : new Date(NaN);
                }
                else return null;
            }
        };
    }


    $scope.dateFields = [

        {
            type: 'date',
            required: false,
            binding: 'applicant.expectedGraduation',
            startView: 'month',
            mode: 'month',
            locale: monthFormat
        }
    ];
});
angular
  .module("app")
  .controller(
    "KeyController",
    ["$scope", "$document", "$mdBottomSheet",'$rootScope',
      function ($scope, $document, $mdBottomSheet, $rootScope){
          //this.$mdBottomSheet = $mdBottomSheet;
          $scope.sidebarVisible = true; // Sidebar başlangıçta görünür
          $rootScope.$on('toggleSidebarEvent', function() {
              $scope.sidebarVisible = !$scope.sidebarVisible; // Sidebar'ın görünürlüğünü değiştir
          });
          function handleKeyPress(event) {
              if (event.which === 114 || event.keyCode === 114) { // 114, F3 tuşunun ASCII kodudur
                  $scope.$apply(function(){
                      $scope.openMyComponent(); // Varsayılan olarak kabul edilen fonksiyon ismi
                  });
                  event.preventDefault(); // Sayfanın yeniden yüklenmesini önlemek için
              }
          }

          // Belirli bir tuşa basıldığında fonksiyonu çağırmak için $document servisini kullanın
          $document.on('keydown', handleKeyPress);

          // Controller yok edildiğinde olay dinleyiciyi kaldırmak için $scope.$on('$destroy', ...) kullanabilirsiniz
          $scope.$on('$destroy', function() {
              $document.off('keydown', handleKeyPress);
          });

          $scope.openMyComponent = function() {
              $mdBottomSheet.show({
                  templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
                  controller: "BottomViewController",
                  controllerAs: "vm",
                  bindToController: true,
                  locals: {
                  }
              })
          };
      }
    ]);

angular
    .module("app")
    .controller(
        "GetLotOrSeriForProductsByLocationIdController",
        ["$scope", "$rootScope", "$stateParams", "DialogService", "API", "reqData", "ToastService",
            function (
                $scope,
                $rootScope,
                $stateParams,
                DialogService,
                API,
                reqData,
                ToastService
            ) {
                let page_number = 1;
                let per_page = 10;
                let requestObj = {
                    per_page: per_page,
                    page: page_number,
                    product_id: reqData.product_id,
                    location_id: reqData.location_id,
                    qty_mode: "+",
                };
                $scope.requestObj = requestObj;
                let lot_per_page = per_page;
                var parent = $scope;

                $scope.lotsForProductResponseGenerator = function (parent, response) {
                    parent.lotsForProductData = response.data.lotsForProduct.data;
                    parent.lotsForProductCurrentPage = response.data.lotsForProduct.current_page;
                    parent.lotsForProductLastPage = response.data.lotsForProduct.last_page;
                    parent.lotsForProductPerPage = response.data.lotsForProduct.per_page;
                    parent.lotsForProductTotal = response.data.lotsForProduct.total;
                    parent.lotsForProductPagesArray = response.data.lotsForProductPagesArray;

                    parent.lotsForProductPrevPageUrl =
                        response.data.lotsForProduct.prev_page_url;
                    parent.lotsForProductNextPageUrl =
                        response.data.lotsForProduct.next_page_url;

                    parent.lotsForProductNextIndex = response.data.nextIndex;
                    parent.lotsForProductPrevIndex = response.data.prevIndex;
                };

                API.all("stock/lot-snu/" + requestObj.product_id)
                    .post(requestObj)
                    .then((response) => {
                        parent.lotsForProductResponseGenerator(parent, response);
                    });

                $scope.cancel = function () {
                    DialogService.hide();
                };

                $scope.getLotForProducts = function (page_number, per_page) {
                    let requestObj2 = {
                        per_page: per_page,
                        page: page_number,
                    };
                    var req = angular.extend({}, $scope.requestObj, requestObj2);
                    API.all("stock/lot-snu/" + req.product_id)
                        .post(req)
                        .then((response) => {
                            parent.lotsForProductResponseGenerator(parent, response);
                        });
                };
            }
        ]);

angular
    .module("app")
    .controller(
        "SelectLotOrSeriController",
        ["$scope", "$rootScope", "$stateParams", "DialogService", "API", "reqData", "ToastService", "$translate",
            function (
                $scope,
                $rootScope,
                $stateParams,
                DialogService,
                API,
                reqData,
                ToastService,
                $translate
            ) {
                $scope.ts_product_name = reqData.channel_product_info[0].ts_product_name;
                $scope.selectedLots = reqData.selected_lot_data;
                $scope.selectLots = [];
                $scope.collapsedSelectedLotDiv = false;
                $scope.location_id = reqData.location_id;

                let requestData = {
                    per_page: $scope.per_page,
                    page: $scope.page_number,
                    product_id: reqData.product_id,
                };

                var parent = $scope;

                API.all("location")
                    .customGET()
                    .then((response) => {
                        if (response.errors === false) {
                            parent.locations = response.data.location;
                        }
                    });
                $scope.searchLotNuForProduct = function () {
                    let requestData = {
                        lot_serial_number: $scope.searchLotForProduct,
                        expired_date: $scope.searchLotExpiredDate,
                        location_id: reqData.location_id,
                        product_id: reqData.product_id,
                        sub_product_id: reqData.sub_product_id,
                        searchLotAllLocation: $scope.searchLotAllLocation,
                        searchLotInStock: $scope.searchLotInStock,
                    };

                    let parent = $scope;

                    API.all("search-lot-or-snu-by-product-id")
                        .post(requestData)
                        .then((response) => {
                            parent.lotsForProductData = response.lotsOrSeriNuForProduct;
                            parent.productObj = response.productObj;
                        });
                };

                $scope.selectLotForProduct = function (lotSeriId) {
                    let addObj = {
                        lot_serial_number: $scope.selectLots[lotSeriId]["lot_serial_number"],
                        id: $scope.selectLots[lotSeriId]["id"],
                        expired_date: $scope.selectLots[lotSeriId]["expired_date"],
                        selected_lot_qty: $scope.selectLots[lotSeriId]["selected_lot_qty"],
                    };
                    var findedIndex = $scope.selectedLots.indexOf(
                        _.find($scope.selectedLots, function (selectedLot) {
                            return selectedLot.id === addObj.id;
                        })
                    );

                    if (findedIndex !== -1) $scope.selectedLots.splice(findedIndex, 1);

                    $scope.selectedLots.push(addObj);
                };

                $scope.cancel = function () {
                    DialogService.setContainerData([]);
                    DialogService.hide();
                };

                $scope.saveSelectedLot = function () {
                    var selectedLotTotalQty = 0;

                    angular.forEach($scope.selectedLots, function (selectedLot, key) {
                        selectedLotTotalQty += selectedLot.selected_lot_qty;
                    });

                    if (reqData.must_qty != selectedLotTotalQty) {
                        ToastService.error(
                            $translate.instant('LOT_SERIAL_ENTERED', { must_qty: reqData.must_qty })
                        );
                        return false;
                    }

                    DialogService.setContainerData($scope.selectedLots);
                    DialogService.hide();
                };

                $scope.deleteLotForProduct = function (lotId) {
                    var findedIndex = $scope.selectedLots.indexOf(
                        _.find($scope.selectedLots, function (selectedLot) {
                            return selectedLot.id === lotId;
                        })
                    );

                    if (findedIndex !== -1) {
                        $scope.selectedLots.splice(findedIndex, 1);
                    }
                    var oldData = JSON.parse(localStorage.getItem("basketProducts"));
                    var self_ = $scope;

                    if (!_.isEmpty(oldData)) {
                        _.forEach(oldData, function (value, key) {
                            var basketFindedIndex = self_.findElement(
                                oldData,
                                value.product_id
                            );
                            var findedIndexSL = self_.selectedLots.indexOf(
                                _.find(self_.selectedLots, function (selectedLot) {
                                    return selectedLot.product_id === value.product_id;
                                })
                            );

                            if (
                                findedIndexSL !== -1 &&
                                !_.isEmpty(self_.selectedLots[findedIndexSL]["lot_data"])
                            ) {

                                oldData[basketFindedIndex].selected_lots =
                                    self_.selectedLots[findedIndexSL];
                            } else {
                                oldData[basketFindedIndex].selected_lots = [];
                            }
                        });

                        localStorage.setItem("basketProducts", JSON.stringify(oldData));
                    }
                };

                $scope.findElement = function (array, id) {
                    var returnKey = -1;
                    angular.forEach(array, function (value, key) {
                        if (id == value.product_id) {
                            returnKey = parseInt(key);
                        }
                    });

                    return returnKey;
                };
                $scope.parseBoolean = function (data) {
                    if (data == "false" || !data) {
                        return false;
                    } else if (data == "true" || data) {
                        return true;
                    }
                };

                $scope.clearSearchLotForm = function () {
                    $scope.searchLocationIdForProduct = null;
                    $scope.searchLotForProduct = null;
                    $scope.searchLotExpiredDate = null;
                };

                $scope.clearCreateLotForm = function () {
                    $scope.createLotOrSNu = null;
                    $scope.createLotExpiredDate = null;
                };

                $scope.createLot = function () {
                    var requestObj = {
                        lot_or_snu: $scope.createLotOrSNu,
                        product_id: reqData.product_id,
                        sub_product_id: reqData.sub_product_id,
                        location_id: reqData.location_id,
                        qty: 0,
                        expired_date: $scope.createLotExpiredDate,
                    };
                    var parent = $scope;

                    API.all("stock/saveLotOrSnu")
                        .post(requestObj)
                        .then((response) => {
                            parent.searchLotNuForProduct();
                        });
                };
            }
        ]);

angular
    .module("app")
    .controller(
        "SelectCustomersController",
        ["$scope", "$rootScope", "$stateParams", "DialogService", "API",
            function ($scope, $rootScope, $stateParams, DialogService, API) {
                var selectCustomer = [];

                $scope.selectCustomer = function (
                    customerCode,
                    customerName,
                    customerId
                ) {
                    selectCustomer = {};

                    if (customerCode !== undefined) {
                        selectCustomer = {
                            customerCode: customerCode,
                            customerName: customerName,
                            customerId: customerId,
                        };
                        DialogService.setContainerData(selectCustomer);
                    }

                    DialogService.hide();
                };

                $scope.cancel = function () {
                    DialogService.hide();
                };

                $scope.filter = function () {
                    let filter = {
                        per_page: 20,
                        pageNumber: 1,
                        ts_customer_id: $scope.search_ts_customer_id,
                        ts_customer_username: $scope.search_ts_customer_username,
                        ts_customer_name: $scope.search_ts_customer_name,
                    };
                    var parent = $scope;
                    API.all("customer/filter")
                        .post(filter)
                        .then((response) => {
                            parent.customers = response.data.customers;
                            parent.total = response.data.customers.total;
                            parent.current_page = response.data.customers.current_page;
                            parent.per_page = response.data.customers.per_page;
                            parent.last_page = response.data.customers.last_page;
                            parent.prev_page_url = response.data.customers.prev_page_url;
                            parent.next_page_url = response.data.customers.next_page_url;

                            parent.customerPagesArray = response.data.customerPagesArray;

                            parent.prev_index = response.data.prevIndex;
                            parent.next_index = response.data.nextIndex;
                        });
                };
            }
        ]);
/*
angular.module('myApp', [])
    .controller('BodyOverflow',['$scope',function ($scope) {
      $scope.setBodyOverflowHidden = function () {
        angular.element('body').css('overflow', 'hidden');
      }
      $scope.setBodyOverflowVisible = function () {
        angular.element('body').css('overflow', 'visible');
      }
    }])
*/
angular
    .module("app")
    .controller("PrepareActionLineController", ["$scope", "$rootScope", "$stateParams", "DialogService", "API", "preparingVariables", "ToastService", "$translate",
        function (
            $scope,
            $rootScope,
            $stateParams,
            DialogService,
            API,
            preparingVariables,
            ToastService,
            $translate
        ) {
            $scope.ts_product_name = preparingVariables.ts_product_name;
            $scope.transaction_id = preparingVariables.transaction_id;
            $scope.qty_for_preparing = preparingVariables.qty;
            $scope.section_name_should_be = preparingVariables.section_name_should_be;
            $scope.cancel = function () {
                DialogService.hide();
            };


            $scope.prepareActionLine = function () {
                $scope.error_msg = false;
                if (
                    $scope.selected_section_name != preparingVariables.section_name_should_be
                ) {
                    $scope.error_msg =
                        $translate.instant('PRODUCT_SHOULD_BE_PLACED_SECTION', { section_name: preparingVariables.section_name_should_be });
                    return false;
                }

                if ($scope.qty_for_preparing != preparingVariables.qty) {
                    $scope.error_msg =
                    $translate.instant('PRODUCT_SHOULD_BE_PIECES', { qty: preparingVariables.qty });
                    return false;
                }

                let reqData = {
                    selected_section_name: $scope.selected_section_name,
                    qty_for_preparing: $scope.qty_for_preparing,
                    qty_should_be: preparingVariables.qty,
                    section_name_should_be: preparingVariables.section_name_should_be,
                    action_line_id: preparingVariables.action_line_id,
                    ts_supplier_product_code: preparingVariables.ts_supplier_product_code,
                    bom_parent_ts_supplier_product_code:
                    preparingVariables.bom_parent_ts_supplier_product_code,
                    remaining_count: preparingVariables.remaining_count,
                    transaction_id: preparingVariables.transaction_id,
                };
                var parent = $rootScope;
                var productsPreparingData = {};
                API.all("prepare-action-line/save")
                    .post(reqData)
                    .then((response) => {
                        DialogService.hide();
                        ToastService.success(
                        $translate.instant('PRODUCT_PLACED_IN_SECTION', { product_code: preparingVariables.ts_supplier_product_code })
                        );
                        productsPreparingData = {
                            supplierProductCode: response.data.supplier_product_code,
                            productforOrderPreparing: response.data.productForOrderPreparing,
                        };
                        DialogService.setContainerData(productsPreparingData);
                    });
            };
        }
    ]);

angular.module("app")
  .controller("ShowScreenForPermissionController", ShowScreenForPermissionController)
  .controller("PrintPreviewDialogController", PrintPreviewDialogController)
  .controller("SettingsLogController", SettingsLogController)
  .controller("StockManagementController", StockManagementController)
  .controller("CustomersController", CustomersController)
  .controller("OrderListsController", OrderListsController)
  .controller("InvoicesController", InvoicesController)
  .controller("InvoicesDetailController", InvoicesDetailController)
  .controller("ActionDetailController", ActionDetailController)
  .controller("BatchOperationsByexcelController", BatchOperationsByexcelController)
  .controller("SettingsController", SettingsController)
  .controller("LocationManagementController", LocationManagementController)
  .controller("OrdersPrepareController", OrdersPrepareController)
  .controller("StockManagementAccountingController", StockManagementAccountingController)
  .controller("BottomViewController", BottomViewController)
  .controller("BottomCameraController", BottomCameraController)
  .controller("BottomQuickCreateStockController", BottomQuickCreateStockController)
  .controller("CreateLotSeriController", CreateLotSeriController)
  .controller("MultipleQuantityController", MultipleQuantityController)
  .controller("ProductDialogController", ProductDialogController)
  .controller("PreparePackageMultipleController", PreparePackageMultipleController)
  .controller('CameraController', CameraController)
  .controller('searchProductController', searchProductController)
  .controller('offerListController', OfferController)
  .controller('receivingController', ReceivingController)
  .controller('RouteController', RouteController)
  //.controller('OfferSearchItemListController', OfferSearchItemListController)
  .controller('ShelfUpController', ShelfUpController)
  .controller('IssuesController', IssuesController)
  .controller('IssuesDetailController', IssuesDetailController)
  .controller('StocktakingController', StocktakingController)
  .controller('ShippingItemCounterController', ShippingItemCounterController)
  .controller('ToastService', ToastService)
  .controller('PickingController', PickingController)
  .controller('Currency', CurrencyController)
  .controller('OfferRecipeController', RecipePageController)
  .controller('UploadController', UploadController)
  .controller('GalleryController', GalleryController)
  .controller('CloneStockController', CloneStockController)
  .controller('RecipeController', RecipeController)

;
