import {BatchOperationsForTsoftByexcelComponent} from "./app/components/batch-operations-for-tsoft-byexcel/batch-operations-for-tsoft-byexcel.component";
import {OrdersPrepareComponent} from "./app/components/orders-prepare/orders-prepare.component";
import {CreateProductionActionComponent} from "./app/components/create-production-action/create-production-action.component";
import {ProductionForDuveeComponent} from "./app/components/production-for-duvee/production-for-duvee.component";
import {BatchOperationsByexcelComponent} from "./app/components/batch-operations-byexcel/batch-operations-byexcel.component";
import {AccountSettingsComponent} from "./app/components/account-settings/account-settings.component";
import {ShortcutComponent} from "./app/components/shortcut/shortcut.component";
import {SettingsComponent} from "./app/components/settings/settings.component";
import {SettingsLogComponent} from "./app/components/settings/settings-log.component";
import {StockManagementComponent} from "./app/components/stock-management/stock-management.component";
import {LocationManagementComponent} from "./app/components/location-management/location-management.component";
import {StockOrganizationComponent} from "./app/components/stock-organization/stock-organization.component";
import {CustomersComponent} from "./app/components/customers/customers.component";
import {OrderListsComponent} from "./app/components/order-lists/order-lists.component";
import {CargoComponent} from "./app/components/cargo/cargo.component";
import {IntegrationComponent} from "./app/components/integration/integration.component";
import {InvoicesComponent} from "./app/components/invoices/invoices.component";
import {InvoicesDetailComponent} from "./app/components/invoices-detail/invoices-detail.component";
import {EditComponent} from "./app/components/invoices/edit.component";
import {BalanceComponent} from "./app/components/invoices/balance.component";
import {SidebarComponent} from "./app/components/sidebar/sidebar.component";
import {RightbarComponent} from "./app/components/rightbar/rightbar.component";
import {DashboardIndexComponent} from "./app/components/dashboard_index/dashboard_index.component";
import {AppRootComponent} from "./app/components/app-root/app-root.component";
import {AppShellComponent} from "./app/components/app-shell/app-shell.component";
import {ResetPasswordComponent} from "./app/components/reset-password/reset-password.component";
import {ForgotPasswordComponent} from "./app/components/forgot-password/forgot-password.component";
import {LoginFormComponent} from "./app/components/login-form/login-form.component";
import {ActionDetailComponent} from "./app/components/order-lists/action-detail.component";
import {BreadcrumbComponent} from "./app/components/breadcrumb/breadcrumb";
import {InstallWizardComponent} from "./app/components/install-wizard/install-wizard.component";
import {UsersComponent} from "./app/components/users/users.component";
import {PaginationComponent} from "./app/components/pagination/pagination.component";
import {PaginationV2Component} from "./app/components/pagination-v2/pagination-v2.component";
import {AttachTransporterComponent} from "./app/components/attach-transporter/attach-transporter.component";
import {TestComponent} from "./app/components/test/test.component";
import {ReportsComponent} from "./app/components/reports/reports.component";
import {NotificationComponent} from "./app/components/notification/notification.component";
import {AboutComponent} from "./app/components/about/about.component";
import {OrdersPackagePrepareComponent} from "./app/components/orders-prepare/orders-package.componenet";
import {OrdersQualityControlPrepareComponent} from "./app/components/orders-prepare/orders-quality-control.componenet";
import {CameraComponent} from "./app/components/camera/camera";
import {searchProductComponent} from "./app/components/invoices/search-product.component";
import {OfferComponent} from "./app/components/offer/offer.component";
import {IconComponent} from "./app/components/icon/icon.component";
import {IssuesListComponent} from "./app/components/issues/issues-list.component";
import {IssuesCreateComponent} from "./app/components/issues/issues-create.component";
import {IssuesDetailComponent} from "./app/components/issues/issues-detail.component";
import {OfferPageComponent} from "./app/components/offer/offer-page.component";
import {ReceivingComponent} from "./app/components/receiving/receiving.component";
import {ShelfUpComponent} from "./app/components/location-management/shelf-up.component";
import {StocktakingComponent} from "./app/components/location-management/stocktaking.component";
import {IssuesComponent} from "./app/components/issues/issues";
import {ShippingItemCounterComponent} from "./app/components/cargo/shipping-item-counter.component";
import {BottomViewComponent} from "./app/components/bottom-view/bottom-view.component";
import {BottomCameraComponent} from "./app/components/bottom-camera/bottom-camera.component";
import {ReturnManagementComponent} from "./app/components/return-management/return-management.component";
import {
  BottomQuickCreateStockComponent
} from "./app/components/bottom-quick-create-stock/bottom-quick-create-stock.component";
import {ActionSummaryComponent} from "./app/components/action-summary/action-summary.component";
import {ActionProductSummaryComponent} from "./app/components/action-product-summary/action-product-summary.component";
import {ActionSummaryOverTimeComponent} from "./app/components/action-summary-over-time/action-summary-over-time.component";
import {AuthorizeComponent} from "./app/components/authorize/authorize.component";
import {PickingComponent} from "./app/components/orders-prepare/picking.component";
import {CurrencyComponent} from "./app/components/settings/currency.component";
import {RecipePageComponent as OfferRecipePageComponent} from "./app/components/offer-recipe/recipe-page.component";
import {ActionListComponent} from "./app/components/order-lists/order-lists.component";
import {ReceivingPageComponent} from "./app/components/receiving/receiving-page.component";
import {AdminComponent} from "./app/components/admin/admin.component";
import {CloneStockComponent} from "./app/components/clone-stock/clone-stock.component";
import {PurchaseRequestComponent} from "./app/components/offer/purchase-request.component";
import {RecipeComponent} from "./app/components/recipe/recipe.component";
import {RecipeDetailComponent} from "./app/components/recipe/recipe-detail.component";
import {RecipePageComponent} from "./app/components/recipe/recipe-page.component";
import {CombineStockEntryPageComponent} from "./app/components/combine-stock-entry/combine-stock-entry-page.component";
import {ProductEntryComponent} from "./app/components/combine-stock-entry/product-entry.component";
import {BundleEntryComponent} from "./app/components/combine-stock-entry/bundle-entry.component";
import {RawMaterialEntryComponent} from "./app/components/combine-stock-entry/raw-material-entry.component";

import {ReportLocationsComponent} from "./app/components/reports/report-locations.component";

angular.module("app.components")
  .component("batchOperationsForTsoftByexcel", BatchOperationsForTsoftByexcelComponent)
  .component("ordersPrepare", OrdersPrepareComponent)
  .component("createProductionAction", CreateProductionActionComponent)
  .component("productionForDuvee", ProductionForDuveeComponent)
  .component("batchOperationsByexcel", BatchOperationsByexcelComponent)
  .component("accountSettings", AccountSettingsComponent)
  .component("shortcut", ShortcutComponent)
  .component("settings", SettingsComponent)
  .component("settingsLog", SettingsLogComponent)
  .component("stockManagement", StockManagementComponent)
  .component("locationManagement", LocationManagementComponent)
  .component("stockOrganization", StockOrganizationComponent)
  .component("customers", CustomersComponent)
  .component("orderLists", OrderListsComponent)
  .component("cargo", CargoComponent)
  .component("integration", IntegrationComponent)
  .component("invoices", InvoicesComponent)
  .component("invoicesDetail", InvoicesDetailComponent)
  .component("edit", EditComponent)
  .component("balance", BalanceComponent)
  .component("sidebar", SidebarComponent)
  .component("rightbar", RightbarComponent)
  .component("dashboardIndex", DashboardIndexComponent)
  .component("appRoot", AppRootComponent)
  .component("appShell", AppShellComponent)
  .component("resetPassword", ResetPasswordComponent)
  .component("forgotPassword", ForgotPasswordComponent)
  .component("loginForm", LoginFormComponent)
  .component("users", UsersComponent)
  .component("actionDetail", ActionDetailComponent)
  .component("breadcrumb", BreadcrumbComponent)
  .component("attachTransporter", AttachTransporterComponent)
  .component("pagination", PaginationComponent)
  .component("paginationV2", PaginationV2Component)
  .component("test", TestComponent)
  .component("about", AboutComponent)
  .component("notification", NotificationComponent)
  .component("reports", ReportsComponent)
  .component("searchProducts", searchProductComponent)
  .component('ordersPackagePrepare', OrdersPackagePrepareComponent)
  .component('ordersPackageQualityControl', OrdersQualityControlPrepareComponent)
  .component('camera', CameraComponent)
  .component('offerService', OfferComponent)
  .component('receiving', ReceivingPageComponent)
  .component('receivingService', ReceivingComponent)
  .component('icon', IconComponent)
  .component('issuesList', IssuesListComponent)
  .component('issuesCreate', IssuesCreateComponent)
  .component('issuesDetail', IssuesDetailComponent)
  .component('offer', OfferPageComponent)
  .component('shelfUp', ShelfUpComponent)
  .component('issues', IssuesComponent)
  .component('stocktaking', StocktakingComponent)
  .component('shippingItemCounter', ShippingItemCounterComponent)
  .component('bottomView', BottomViewComponent)
  .component('bottomCamera', BottomCameraComponent)
  .component('bottomQuickCreateStock', BottomQuickCreateStockComponent)
  .component('returnManagement', ReturnManagementComponent)
  .component('actionSummary', ActionSummaryComponent)
  .component('actionProductSummary', ActionProductSummaryComponent)
  .component('actionSummaryOverTime', ActionSummaryOverTimeComponent)
  .component('authorize', AuthorizeComponent)
  .component('picking', PickingComponent)
  .component('currency', CurrencyComponent)
//  .component('offerRecipe', RecipePageComponent)
  .component('actionList', ActionListComponent)
  .component('admin', AdminComponent)
  .component('cloneStock', CloneStockComponent)
  .component('purchaseRequest', PurchaseRequestComponent)
  .component('recipe', RecipePageComponent)
  .component('recipeList', RecipeComponent)
  .component('recipeDetail', RecipeDetailComponent)
  .component('offerRecipe', OfferRecipePageComponent)
  .component('combineStockEntry', CombineStockEntryPageComponent)
  .component('productEntry', ProductEntryComponent)
  .component('bundleEntry', BundleEntryComponent)
  .component('rawMaterialEntry', RawMaterialEntryComponent)
  .component('reportLocations', ReportLocationsComponent)
;
//.component("install-wizard", InstallWizardComponent);
