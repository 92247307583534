import {introductionVideos, openIntroductionVideo} from "../../../utils/introduction-videos";
import "./orders-prepare.scss";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {list} from "postcss";
import {getUserInfo} from "../../../utils/users";
class OrdersPrepareController {
  constructor(API, ToastService, $translate, DialogService, $state, $filter, $location, $window, $sce, $timeout, $scope, $rootScope, $mdBottomSheet) {
    "ngInject";

    this.API = API;
    this.window = $window;
    this.$scope = $scope;
    this.$mdBottomSheet = $mdBottomSheet;
    this.ToastService = ToastService;
    this.$translate = $translate;
    this.osRelationFilterArr = {};
    this.os_relation_per_page = 10;
    this.page_number = 1;
    this.orderAndSection_current_page = 1;
    this.DialogService = DialogService;
    this.orderLinePrepared = [];
    this.reduceButtonDisabled = false;
    this.accountingTransfers = null;
    this.selectedOrders = [];
    this.sections = [];
    this.searchingList = null;
    this.selectedIndexForRemove = 0 ;
    this.takingShelfCode = null ;
    this.takingShelfQuantity = null;
    this.takeOffSelectedShelf = null ;
    this.inputChangedPromise = "";
    this.$state = $state;
    this.$filter = $filter;
    this.removeTheTableCount = null
    this.$location = $location;
    this.removeTheTableProducts = null ;
    this.searchingListDeliver = null;
    this.removeTheSelectedProduct = null ;
    this.video = undefined;
     this.firmalar = {};
     this.deliveryFirmalar = {};
     this.deliveryFirms = [];
    this.firms = [];
    this.collapseDelivery = []
    this.collapseCargo = [] ;
    this.cargoActions = null;
    this.cargoDelivery = null;
    this.barcodeScanner = $window.BarcodeScanner;
    this.cameraDevices = [];

    this.scannedProductQuantity =1;
    this.sce =$sce
    this.$timeout = $timeout
    this.transporterSelectedSection = {};
    this.accordionRoot = {};
    this.controllerTransporterSelectSectionsSelected = null;
    this.controllerStepPermission = false;
    this.transporterPackingComplete = false;
    this.activeTab = null;
    this.tabDetail = {
      0: {
        name: 'prepareTransporter',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-order-prepare-scan-type',
        scannerIdCookieName: 'polypus-order-prepare-scan-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState: 'stop',
        cameraTemplateId: 'order-prepare-barcode',
        scanModalSuccess: '#placement-message-success',
        scanModalError: '#placement-message-error',
        scanModalState: false,
        service: 'prepare',
        shelfRequire : true,
      },
      2: {
        name: 'preparePacking',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-order-control-scan-type',
        scannerIdCookieName: 'polypus-order-control-scan-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState: 'stop',
        cameraTemplateId: 'order-control-barcode',
        scanModalSuccess: '#placement-message-success',
        scanModalError: '#placement-message-error',
        scanModalState: false,
        service: 'control',
        shelfRequire : false,

      },
      4: {
        name: 'preparePacking',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-order-control-scan-type',
        scannerIdCookieName: 'polypus-order-control-scan-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState: 'stop',
        cameraTemplateId: 'order-control-barcode',
        scanModalSuccess: '#placement-message-success',
        scanModalError: '#placement-message-error',
        scanModalState: false,
        service: 'control',
        shelfRequire : false,

      },
      3: {
        name: 'preparePacking',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-order-control-scan-type',
        scannerIdCookieName: 'polypus-order-control-scan-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState: 'stop',
        cameraTemplateId: 'order-control-barcode',
        scanModalSuccess: '#placement-message-success',
        scanModalError: '#placement-message-error',
        scanModalState: false,
        service: 'control',
        shelfRequire : false,

      },
      1: {
        name: 'preparePacking',
        scanType: 'manuel',
        scannerCameraId: null,
        scannerCookieName: 'polypus-order-control-scan-type',
        scannerIdCookieName: 'polypus-order-control-scan-camera-id',
        terminalEvent: null,
        cameraEvent: null,
        cameraState: 'stop',
        cameraTemplateId: 'order-control-barcode',
        scanModalSuccess: '#placement-message-success',
        scanModalError: '#placement-message-error',
        scanModalState: false,
        service: 'control',
        shelfRequire : false,

      }
    }
    this.nextImageIndexProductDefault = 0;
    this.nextImageIndexProduct = [];

    this.receivingCount       = '~';
    this.pickingCount         = '~';
    this.controlCount         = '~';
    this.packageCount         = '~';
    this.qualityControlCount  = '~';
    this.accountingCount      = '~';
    this.deliveryCount        = '~';
    this.shipmentListCount    = '~';
    this.shelfUpCount         = '~';
    this.shippingCounterCount = '~';

    this.wmsSettings = null
    this.actionReceivingPrepareListSection ='select'
    this.actionPickingPrepareListSection ='select'

    this.actionPrepareListShowType='product'
    this.actionControlType = 'list'
    this.priority ={
      1:'normal',
      2:'high',
      3:'very-high',
      4:'urgent',
    }

    this.wmsSettings = null;
    this.$rootScope = $rootScope;
    this.actionPrepareProductList = [];

    this.stockType = null;
    this.orderListType = null;
    this.falseBeep = new Audio('./files/error.wav');
    this.trueBeep = new Audio('./files/success.wav');
    this.beepSound = new Audio('./files/success.wav');

    this.productQuantity = 1;
    this.stocktakingShelfCode = null;
    this.productOpen = false;
    this.quantityOpen = false;

    this.assignMeList = [];
    this.startNextIssueButton = false;

    this.scannedProductBarcode = null;

    this.userInfo = getUserInfo();
    this.currentUserId = this.userInfo.uid;

    this.shipmentList = [];
}

  $onInit() {

    let parent = this;
    parent.$scope.$on('prepareChildEvent', function(event, data) {
      document.querySelector('md-toolbar').hidden = false;
      document.querySelector('md-tabs-wrapper').hidden = false;
      if (data.count){
        Object.keys(data.count).map(c =>{
          parent[c + 'Count'] = data.count[c]

        })
      }
    });


    this.dropdownStatsTranslationsText = {
      buttonDefaultText: this.$filter('translate')('ORDER_STATUS_SELECT'),
      searchPlaceholder:this.$filter('translate')('SEARCH'),
      uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
      checkAll: this.$filter('translate')('SELECT_ALL'),
      dynamicButtonTextSuffix: this.$filter('translate')('ORDER_STATUS_SELECTED')
    };
    this.dropdownStatsSettings = { enableSearch: true, selectionLimit: 1 };
    this.getSectionRelationForOrder();
    this.selectAllPreparedActions = [];
    this.selectAllPackageProcessActions = [];
    this.upPerPage = 10;


    //this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;


    this.API.all("location")
        .customGET()
      .then((response) => {
        if (response.errors === false) {
          this.locations = response.data.location;
        }
      });

    this.API.all("settings/get-channels")
      .post(null, {})
      .then((response) => {
        this.channels = response.data.channels;
      });


    this.API.all("settings/wms-settings")
        .customGET()
        .then((response) => {
          if (response) {
            this.wmsSettings = response.data.list;
            if (this.wmsSettings['controller-order']){
              this.controllerStepPermission = true
            }
          }
          if (this?.$rootScope?.companyInfo){
            this.preferredProductCode = this.$rootScope.companyInfo['preferred-product-code'];
          }
        });
  }


  /** Scan Operations  **/

  setScanType() {
    Cookies.set(this.activeTab.scannerCookieName, this.activeTab.scanType)

    if ('camera' === this.activeTab.scanType) {
      this.getCameraInfo()
    } else if ('terminal' === this.activeTab.scanType) {
      if (!this.activeTab.terminalEvent) {
        this.activeTab.terminalEvent = true;
        this.getTerminalInfo()
      }
    }

  }
  collapseCargoButton(firm){
  if (this.collapseCargo[firm] === undefined) {
    this.collapseCargo[firm] = true
  }
    else {
      this.collapseCargo[firm] =  !this.collapseCargo[firm]
      }
  }
  collapseDeliveryButton(firm){
    if (this.collapseDelivery[firm] === undefined) {
      this.collapseDelivery[firm] = true
    }
    else {
      this.collapseDelivery[firm] =  !this.collapseDelivery[firm]
    }
  }

  getCameraInfo() {
    let parent = this
    this.barcodeScanner.getCameras().then(devices => {
      if (0 === devices.length){
        parent.ToastService.error(this.$translate.instant('NO_AVAILABLE_CAMERA_FOUND'))
      }
      parent.cameraDevices = devices
    }).catch(err => {
      parent.ToastService.show(this.$translate.instant('PLEASE_ENABLE_BROWSER_PERMISSION'))
    })
  }

  getTerminalInfo() {
    this.ToastService.success(this.$translate.instant('POLYPUS_READY_HANDHELD_TERMINAL'))
    let parent = this;
    let barcode = '';
    let interval;
    this.activeTab.terminalEvent = (e) => {
      if (interval) {
        clearInterval(interval)
      }
      if (e.code == 'Enter' && barcode) {
        parent.scanCompleteCallback(barcode)
        barcode = '';
        return true;
      }
      if ('Shift' !== e.key) {
        barcode += e.key
      }
      interval = setInterval(() => barcode = '', 20)

    }
    this.window.document.addEventListener('keydown',this.activeTab.terminalEvent  ,true)

  }

  setSelectedCamera() {
    Cookies.set(this.activeTab.scannerIdCookieName, this.activeTab.scannerCameraId)
  }

  sendToAccounting(action){
    this.ToastService.success( this.$translate.instant('ORDER_SENT_TO_ACCOUNTING', { transactionId: action.transactionId }));
  }

  scanCameraStart() {
    let parent = this;
    this.activeTab.cameraState = 'resume'
    this.activeTab.cameraEvent = new parent.barcodeScanner(this.activeTab.cameraTemplateId);
    // telefon için {width: 200, height: 150} ideal
    // pc için {width: 250, height: 250}
    //ekrana göre ayar yapmak lazım
    const config = {fps: 10, qrbox: {width: 200, height: 150}, experimentalFeatures: {useBarCodeDetectorIfSupported: true}}
    this.activeTab.cameraEvent.start(
        parent.activeTab.scannerCameraId,
        config,
        parent.scanCompleteCallback,
    ).catch((err) => {
    });
    this.beepSound.play().then().catch()
  }

  scanCameraStop(){
    this.activeTab.cameraEvent.stop()
    this.activeTab.cameraState = 'stop'

    this.activeTab.scanType = 'terminal'
    this.setScanType()
  }
  sendFromTable(shelfCode,shelfQuantity){
    if (shelfCode === null || shelfQuantity === null) {
      this.ToastService.error(this.$translate.instant('INFORMATION_COMPLETELY'));
      return;
    }
   this.removeTheSelectedProduct.takenShelves.map(item => {
     if (shelfCode === item.shelfName || parseInt(shelfCode) === item.shelfId){
       this.takeOffSelectedShelf = shelfCode ;
       return;
     }
    })
    if (this.takeOffSelectedShelf != null){
        // todo: backend ucu bekleniyor
    }
    else {
      this.ToastService.error(this.$translate.instant('WRONG_SHELF_CODE'))
    }
  }
  nextProductForTaking(){
    if (this.selectedIndexForRemove != this.removeTheTableProducts.length - 1) this.selectedIndexForRemove += 1;
    else this.selectedIndexForRemove = 0 ;
  }
  exProductForTaking(){
    if (this.selectedIndexForRemove != 0) this.selectedIndexForRemove -= 1;
    else this.selectedIndexForRemove = this.removeTheTableProducts.length - 1;
  }
  scanCameraPause() {
    this.activeTab.cameraEvent.pause()
    this.activeTab.cameraState = 'pause'
  }

  scanCameraResume() {
    this.activeTab.cameraEvent.resume()
    this.activeTab.cameraState = 'resume'
  }

  setProductQuantityForPlacement(q){
    if ('max' ===q){
      this.scannedProductQuantity =this.productRequirementQuantity;
      return true
    }
    if ('min' ===q){
      this.scannedProductQuantity =1;
      return true
    }

    if (0 >= this.scannedProductQuantity + q) {
      this.ToastService.error(this.$translate.instant('LEAST_1'))
      return false
    }
    if (this.productRequirementQuantity && this.scannedProductQuantity + q > this.productRequirementQuantity) {
      this.ToastService.error(this.$translate.instant('MAX_OF_PIECES', { quantity: this.productRequirementQuantity }))
      return false
    }
    this.scannedProductQuantity = this.scannedProductQuantity + q;
  }


  /** Scan Operations  **/


  onSelectTab(obj){
    this.$rootScope.$broadcast('prepareEvent', obj)

    this.$location.search(obj);
    this.activeTab = obj.tab;
    this.orderListType = obj.list;
    this.video = undefined;
    if (obj.tab == 5) {
      this.video = 'ordersPrepareUpdateOrderStatus';
    }

    this.activeTab = this.tabDetail[obj.tab];
    if (this.activeTab) {
      this.activeTab.scanType = Cookies.get(this.activeTab.scannerCookieName)
      this.activeTab.scannerCameraId = Cookies.get(this.activeTab.scannerIdCookieName)
      if ('camera' === this.activeTab.scanType) {
        this.getCameraInfo()
      }
      if ('terminal' === this.activeTab.scanType && !this.activeTab.terminalEvent) {
        this.getTerminalInfo()
      }
    }

    if (this.orderListType == 'picking') {
      this.$rootScope.$broadcast('pickingEvent', obj)

    } else if (this.orderListType == 'receiving') {
      this.getScreenFromListType('picking','select')
      this.barcodeFocus = true
      this.activeTab = this.tabDetail[obj.tab];
      if(!this.activeTab.terminalEvent){
        this.getTerminalInfo()
      }
      this.getPrepareProcess();
    }
  }


  readInput(val, elId) {
    document.getElementById(elId).focus();
  }


  clearSectionTransactionRelation() {
    this.orderNuForRelationCreate = null;
    this.sectionForRelationCreate = null;
    this.readInput(null, "orderNu");
  }

  getSectionRelationForOrder(
    page_number = 1,
    osRelationFilterArr = this.osRelationFilterArr,
    os_relation_per_page = 10
  ) {
    var parent = this;

    let reqData = {
      per_page: os_relation_per_page,
      page: page_number,
      filter: false,
    };

    var reqObject = angular.extend(reqData, osRelationFilterArr);

    this.os_relation_per_page = os_relation_per_page;
    this.page_number = page_number;

    reqObject.page = page_number;
    reqObject.per_page = os_relation_per_page;

  }

  sectionRelationPaginator(response) {
    this.orderAndSectionRelations = response.data.orderAndSectionRelations;
    this.orderAndSectionTotal = response.data.orderAndSectionRelations.total;
    this.orderAndSection_current_page =
      response.data.orderAndSectionRelations.current_page;
    this.orderAndSection_per_page =
      response.data.orderAndSectionRelations.per_page;
    this.orderAndSection_last_page =
      response.data.orderAndSectionRelations.last_page;
    this.orderAndSection_prev_page_url =
      response.data.orderAndSectionRelations.prev_page_url;
    this.orderAndSection_next_page_url =
      response.data.orderAndSectionRelations.next_page_url;

    this.orderAndSectionPagesArray = response.data.orderAndSectionPagesArray;

    this.orderAndSection_prev_index = response.data.orderAndSectionPrevIndex;
    this.orderAndSection_next_index = response.data.orderAndSectionNextIndex;
  }

  deleteOrderSectionRelation(relationId) {
    var parent = this;

    let reqData = {
      id: relationId,
      per_page: this.os_relation_per_page,
      page: this.page_number,
    };

  }

  filterRelation() {
    let filter = {
      per_page: this.os_relation_per_page,
      page: 1,
      empty_section: this.emptySectionFilter,
      full_section: this.fullSectionFilter,
      order_nu: this.orderNuForRelationFilter,
      section_name: this.transporterSelectedSection['transporterIdForRelationFilterSections']['sectionName'],
      transporter_id: this.transporterIdForRelationFilter.id,
      filter: true,
    };

    var parent = this;
    this.osRelationFilterArr = filter;
  }

  clearForm(formName) {
    if (formName == "filterForm") {
      this.sectionNameForRelationFilter = null;
      this.orderNuForRelationFilter = null;
      this.transporterIdForRelationFilter = null;
      this.emptySectionFilter = false;
      this.fullSectionFilter = false;
    } else if (formName == "createForm") {
      this.orderNuForRelationCreate = null;
      this.sectionForRelationCreate = null;
    }

    this.osRelationFilterArr = {};

    this.getSectionRelationForOrder(1, {}, this.os_relation_per_page);
  }

  searchProductForPreparing() {
    let reqData = {
      supplier_product_code: this.supplierProductCodeForPrepareOrder,
    };
    var parent = this;

    this.API.all("search-product-in-preparing-transaction/get")
      .post(reqData)
      .then(
        (response) => {
          parent.productForOrderPreparing =
            response.data.productForOrderPreparing;
          parent.supplier_product_code = response.data.supplier_product_code;

          var section_name_order_preparing_arr = [];
          var remaining_line_count = [];

          for (
            var i = 0;
            i < response.data.productForOrderPreparing.length;
            i++
          ) {
            section_name_order_preparing_arr.push(
              response.data.productForOrderPreparing[i].section_name
            );

            remaining_line_count[i] = {};
            remaining_line_count[i]["remaining_line_count"] =
              response.data.productForOrderPreparing[i].remaining_count;
            remaining_line_count[i]["transaction_id"] =
              response.data.productForOrderPreparing[i].transaction_id;

            remaining_line_count[i]["action_line_product_quantity"] =
              response.data.productForOrderPreparing[i].quantity;

            /*if(response.data.productForOrderPreparing[i].bom_total_qty) {
                    remaining_line_count[i]['action_line_product_quantity'] = response.data.productForOrderPreparing[i].bom_total_qty;
                }*/
          }

          parent.remaining_line_count = remaining_line_count;
          parent.section_name_order_preparing =
            section_name_order_preparing_arr;

          if (response.data.productForOrderPreparing.length > 0) {
            var myEl = document.getElementById("selected_section_name");
            var angularEl = angular.element(myEl);
            angularEl.focus();
            parent.selected_section_name = null;
          }
        },
        function (response) {
          parent.section_name_order_preparing = null;
          parent.remaining_line_count = null;
          parent.selected_section_name = null;
          parent.productForOrderPreparing = null;
          parent.supplier_product_code = null;
          var myEl = document.getElementById("product_code");
          var angularEl = angular.element(myEl);
          angularEl.focus();
        }
      );
  }
  getPreparedOrders() {
    let data = {};
    if (this.transporter_id_for_complate_order_prepare){
      data['transporterId'] = this.transporter_id_for_complate_order_prepare.id
    }
    this.ordersPrepared =null
    let parent = this;
    this.API.all("prepared-action/get")
      .post(data)
      .then((response) => {
        if (204 === response.response.status){
          parent.ToastService.error(this.$translate.instant('NO_ORDERS_WERE_FOUND'))
          return true;
        }
        parent.ordersPrepared = response.data.ordersPrepared;
        parent.ToastService.success(this.$translate.instant('MAX_OF_PIECES', { ordersPrepared: parent.ordersPrepared.length }))

      });
  }

  toggleAllPreparedAction() {
    let newArr = [];
    let parent = this;

    if (!parent.selectAllPreparedAction == false) {
      this.selectAllPreparedActions = [];
      this.showPreparedToolbar = false;
    } else {
      this.selectAllPreparedActions = [];
      this.showPreparedToolbar = true;
      angular.forEach(this.ordersPrepared, function (orderPrepared, key) {
        parent.selectAllPreparedActions[orderPrepared.transaction_id] =
          !parent.selectAllPreparedAction;
      });
    }
  }

  toggleCheckPreparedOrders(transaction_id, status) {
    if (!status) {
      delete this.selectAllPreparedActions[transaction_id];
      if (this.selectAllPreparedActions.length == 0) {
        this.selectAllPreparedAction = false;
        this.showPreparedToolbar = false;
      }
    } else {
      this.showPreparedToolbar = true;
    }
  }

  sendActionToPackageProcess(transactionId) {
    if (transactionId) {
      var transactionIds = transactionId;
    } else {
      var preparedActionsKeys = Object.keys(this.selectAllPreparedActions);
      var reqPreparedActions = [];
      for (var i = 0; i < preparedActionsKeys.length; i++) {
        if (this.selectAllPreparedActions[preparedActionsKeys[i]]) {
          reqPreparedActions.push(preparedActionsKeys[i]);
        }
      }
      var transactionIds = JSON.stringify(reqPreparedActions);
    }

    let reqData = {
      transactionIds: transactionIds,
    };

    let parent = this;

    this.API.all("send-action-package-process")
      .post(reqData)
      .then((response) => {
        parent.getPreparedOrders();
        parent.selectAllPreparedActions = [];
        parent.selectAllPreparedAction = false;
        parent.showPreparedToolbar = false;
      });
  }



  toggleAllPackageProcessAction() {
    let newArr = [];
    let parent = this;

    this.selectAllPackageProcessActions = [];

    if (!parent.selectAllPackageProcessAction == false) {
      this.showPackageProcessToolbar = false;
    } else {
      this.showPackageProcessToolbar = true;

      angular.forEach(
        this.ordersPackageProcess,
        function (orderPackageProcess, key) {
          parent.selectAllPackageProcessActions[
            orderPackageProcess.transaction_id
          ] = !parent.selectAllPackageProcessAction;
        }
      );
    }
  }

  toggleCheckPackageProcessOrders(transaction_id, status) {
    if (!status) {
      delete this.selectAllPackageProcessActions[transaction_id];
      if (this.selectAllPackageProcessActions.length == 0) {
        this.selectAllPackageProcessAction = false;
        this.showPackageProcessToolbar = false;
      }
    } else {
      this.showPackageProcessToolbar = true;
    }
  }

  clearSearchProductForPreparingForm() {
    this.supplierProductCodeForPrepareOrder = null;
    this.remaining_line_count = null;
    this.section_name_order_preparing = null;
    this.productForOrderPreparing = null;
    this.supplier_product_code = null;

    this.pressTab("supplierProductCodeForPrepareOrder");
  }

  pressTab(inputModel) {
    document.getElementById(inputModel).focus();
  }

  getUnpreparedProductsForOrders(
    current_page = 1,
    per_page = this.upPerPage,
    upFilterArr = this.upFilterArr
  ) {
    let reqData = {
      supplier_product_code: this.searchProductCodeForOrders,
      per_page: this.upPerPage,
      page: current_page,
    };
    var parent = this;

    this.upFilterArr = {
      supplier_product_code: this.searchProductCodeForOrders,
    };

    this.API.all("get-unprepared-products-for-orders")
      .post(reqData)
      .then((response) => {
        if (204 === response.response.status){
          this.ToastService.success(this.$translate.instant('ORDERS_SECTION_INFOMSG6'))
        }
        parent.up_total = response.data.unpreparedProducts.total;
        parent.up_last_page = response.data.unpreparedProducts.last_page;
        parent.up_current_page = response.data.unpreparedProducts.current_page;
        parent.productForOrders = response.data.unpreparedProducts.data;
        parent.up_next_index = response.data.nextIndex;
        parent.up_prev_index = response.data.prevIndex;
        parent.up_next_page_url =
          response.data.unpreparedProducts.next_page_url;
        parent.up_prev_page_url =
          response.data.unpreparedProducts.prev_page_url;
        parent.unpreparedProductsPagesArray =
          response.data.unpreparedProductsPagesArray;
      });
  }

  clearSearchProductForOrders() {
    this.searchProductCodeForOrders = null;
    this.getUnpreparedProductsForOrders(1, this.upPerPage, this.upFilterArr);
  }

  quickSearch(orderNu, channelId) {
    if (orderNu) {
      if (this.inputChangedPromise) {
        this.$timeout.cancel(this.inputChangedPromise);
      }

      var edited = orderNu.replace("?", "_");
      var edited = edited.replace("*", "-");

      this.searchOrderNumber = edited;
      this.getOrder(edited, channelId);

      this.searchOrderNumber = null;
    }
  }

  findElement(array, id) {
    var returnKey = -1;
    _.forEach(array, function (value, key) {
      if (id == value.id) {
        returnKey = parseInt(key);
      }
    });

    return returnKey;
  }

  selectAllContent($event) {
    $event.target.select();
  }

  getOrder(orderNu, channelId) {
    /*if(orderNu.search("-")===-1) {
        orderNu+="-"+channelId;
    }*/

    var filter = {
      searchTransactionId: orderNu,
      per_page: 10,
    };
    this.selectedOrders = this.selectedOrders.filter(sO => !sO.action)

    this.API.all("action")
        .customGET('filter',filter)
      .then((response) => {
        if (response.data.actions.data.length > 0) {
          response.data.actions.data[0].message = this.$translate.instant('NO_ACTION_TAKEN_YET')
          response.data.actions.data[0].action = false;
          response.data.actions.data[0].actionStatus = 'pending';

          if (0 == this.selectedOrders.filter(sO => sO.id == response.data.actions.data[0].id).length) {
            this.selectedOrders = [...this.selectedOrders, response.data.actions.data[0]];
          }
        }
      });
  }

  getStatsByChannelId(channelId) {
    let reqObject = {
      channelId: channelId,
    };
    this.setOrderStatusSelectedStats = [];
    this.API.all("settings/get-stats")
      .post(reqObject)
      .then((response) => {
        this.stats = response.data.statsArray;
      });
  }

  clearSelectedOrders() {
    if (confirm(this.$translate.instant('SELECTION_LIST_WILL_EMPTIED'))) {
      this.selectedOrders = [];
    }
  }


  deSelectOrder(orderId) {
    var findedId = this.findElement(this.selectedOrders, orderId);

    if (findedId != -1) this.selectedOrders.splice(findedId, 1);
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  clearOrderPrepareByTransporter() {
    this.transporter_id_for_order_prepare = null;
    this.productsForOrderPreparingWithTransporter = null;
    this.transporter_id_for_complate_order_prepare =null;
  }

  clearOrderSearch() {
    this.searchChannelId = null;
    this.searchOrderNumber = null;
  }

  /** control Orders **/

  getPackingLocation() {
    this.API.all('shelf')
        .customGET(null, {
          type: 'packing'
        })
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.error(this.$translate.instant('NO_PACKAGING_SHELF'))
            this.accordionRoot['packingShelf'] = false
            return false;
          }
          this.packingLocationList = response['data']['list'];
        });
  }
  getSelectedTransporterSections(transporterId,model) {
    this.API.all('transporter/' + transporterId + '/section')
        .doGET()
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.error(this.$translate.instant('CART_SECTION_NOT_FOUND'))
          }
          this[model] = response['data']['list']
        });
  }
  getSelectedTransporterActions(){
    this.controllerGetPrepareList()
  }

  /**
   * todo burası trasnporter kurgusana uygun geldiği için böyle. uygun bir zamanda ayırlmak iyi olur
   */
  controllerGetPrepareList(){
    let assignedIndex = this.assignMeList.findIndex(f => f.listId ===this.controllerActionPrepareList)
    if (assignedIndex === -1){
      this.listAssignMe('assignControl',this.controllerActionPrepareList)
    }

    this.API.all('action-process/control/' + this.controllerActionPrepareList)
        .doGET()
        .then((response) => {
          if (204 === response.response.status) {
            this.ToastService.error(this.$translate.instant('ORDER_PREPARED_NOT_FOUND'))
            return false;
          }

          this.transporterActionList = response['data']['list']
          this.transporterActionFilterList = this.transporterActionList;

          // todo: sebebi anguların arrayin içini izlediği için toplama işlemlerinden etkilenmemesi için böyle gerekti
          let transporterActionListClone = JSON.parse(JSON.stringify(response['data']['list']));
          this.transporterActionListBulkList = []
          transporterActionListClone.map(list => {
            list.products.map(product => {
              product.list.map(pList => {
                let exist = this.transporterActionListBulkList.filter(bulkList => bulkList.productId === pList.productId && bulkList.subProductId === pList.subProductId)
                if (0 === exist.length) {
                  this.transporterActionListBulkList = [...this.transporterActionListBulkList, pList]
                  return true;
                }
                this.transporterActionListBulkList.map(bulkList=>{
                  if (bulkList.productId === pList.productId && bulkList.subProductId === pList.subProductId){
                    bulkList.quantity += pList.quantity;
                    bulkList.remainingQuantity += pList.remainingQuantity;
                  }
                })
              })
            })
          })
        });
  }
  transporterActionListFilter() {
    this.trasnporterActionFilterList = this.transporterActionList.filter(list => {
      if ('all' === this.controllerTransporterSelectSectionsSelected) {
        return true
      }
      return list.sectionId === this.controllerTransporterSelectSectionsSelected.sectionId
    })
  }

  setControllerPlacementPacking(data) {

    this.setControlProcess(data)

  }
  setControlProcess(data){
    this.API.all("action-process/control/"+this.controllerActionPrepareList)
        .post(data)
        .then(response => {
          if (!response) {
            this.ToastService.success(this.$translate.instant('PRODUCT_NOT_MATCH'))
            return true
          }
          this.closeModal('#placement-message-success')
          if (response.data.list?.process === 'complete'){
            let report = response.data.list;
            var options = {
              controllerAs      : 'vm',
              bindToController  : true,
              onShowing         : function (scope, element, options, controller){
                scope.reportName          = 'Kontrol';
                scope.report              = report;
                scope.collapsed           = false;
              },
            };

            let OPDialogPromise = this.DialogService.fromTemplateV2(
                './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                options
            ).then((status) => {
              this.transporterActionList = null
              this.transporterActionFilterList = null
              this.transporterActionListBulkList = []
            }).catch(()=>{
              this.transporterActionList = null
              this.transporterActionFilterList = null
              this.transporterActionListBulkList = []
            })
            return false
          }

          this.transporterPackingComplete       = response.data.list.transporterComplete;
          this.productRequirementTransactionId  = response.data.list.transactionId
          this.productRequirementQuantity       = response.data.list.remainingQuantity
          this.productRequirementBarcode        = response.data.list.barcode
          this.productRequirementProductId      = response.data.list.productId
          this.productRequirementSubProductId   = response.data.list.subProductId
          this.scannedProductQuantity           = 1
          this.placementSuccessMessage          = response.data.list.message
          this.controllerGetPrepareList()

        })
        .catch(err => {
          this.placementErrorMessage = err.data.errors.message;
        })
  }
  shelfItemDelete(prepareId){
    this.API.all('shelf/package/' + prepareId+'/undo')
        .customDELETE()
        .then((response) => {
          this.getSelectedTransporterActions()
          this.ToastService.success(response.data.result.message.join(', '))
        });
  }
  accordion(key) {
    this.accordionRoot[key] = !this.accordionRoot[key];
  }

  prevImage(id, length) {
    let index = this.nextImageIndexProduct[id] - 1;
    this.changeImageIndex(id, index, length);
  }

  nextImage(id, length) {
    let index = this.nextImageIndexProduct[id] + 1;
    this.changeImageIndex(id, index, length);
  }

  changeImageIndex(id, index, length) {
    if (index < 0) {
      this.nextImageIndexProduct[id] = 0;
    }else if (index > length-1) {
      this.nextImageIndexProduct[id] = length-1;
    } else {
      this.nextImageIndexProduct[id] = index;
    }
  }

  openModal = (id) => {
    const modalEl = document.querySelector(id);
    modalEl.style.cssText = "display: block";
    this.activeTab['scanModalState'] = true;
  };

  closeModal = (id) => {
    if (this.transporterPackingComplete) {
      this.window.location.reload()
    }
    const modalEl = document.querySelector(id);
    modalEl.style.cssText = "display: none";
    this.activeTab['scanModalState'] = false;

    if ('#placement-message-error' === id){
      this.placementErrorMessage = []
    }
    if ('#placement-message-success' === id){
      this.placementSuccessMessage = []
    }

    this.scannedProductQuantity = 1;

    if ('camera' === this.activeTab['scanType'] && this.activeTab['cameraState'] === 'pause') {
      this.scanCameraResume()
    }
  };



  bulkPlacement = ({service, barcode, productId, subProductId}) => {
    if (!this.activeTab['scanModalState']) {
      return false;
    }

    const data = {
      quantity    : this.scannedProductQuantity,
      barcode     : barcode,
      productId   : productId,
      subProductId: '110100000111111' !== subProductId ? subProductId : null,
    }

    if ('control' === service) {
      data['transactionId'] = this.productRequirementTransactionId
      data['sectionId'] = 'all' !== this.controllerTransporterSelectSectionsSelected ? this.controllerTransporterSelectSectionsSelected : null;
      this.setControllerPlacementPacking(data)
    }
  }

  // todo : shelfCheck ile birşebiliri formlar ve uçlar farklı diye böyle yapılmıştır. Bende Hatırlamıyorum :)
  checkShelf() {
    this.API.all('shelf/' + this.shelfCode + '/location/' + this.productRequirementlocationId)
        .customGET()
        .then((response) => {
          if (response.response.status === 200) {
            this.shelfCodeCheck = true;
            this.ToastService.success(this.$translate.instant('SHELF_VERIFIED'));
            this.selectedShelfId = response.data.list.shelfId
          }
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));

          }
        })
  }

  controlTransporterPlacementData = ({transactionId, barcode, productId, subProductId, transporterId}) => {
    this.controlPrepareListPlacementData({transactionId, barcode, productId, subProductId, transporterId})
  }
  controlPrepareListPlacementData({transactionId, barcode, productId, subProductId, transporterId}){
    this.API.all('action-process/control/' + this.controllerActionPrepareList)
        .doGET(transporterId,{
          transactionId : transactionId,
          transporterId : transporterId,
          barcode       : barcode,
          productId     : productId,
          subProductId  : subProductId
        })
        .then((resp) => {
          this.transporterPackingComplete       = false;
          this.productRequirementTransactionId  = resp.data.filterData.transactionId
          this.productRequirementQuantity       = resp.data.filterData.remainingQuantity
          this.productRequirementBarcode        = resp.data.filterData.barcode
          this.productRequirementProductId      = resp.data.filterData.productId
          this.productRequirementSubProductId   = resp.data.filterData.subProductId
          this.scannedProductQuantity           = 1
          this.transporterSectionList           = resp.data.filterData.distribution
          this.placementSuccessMessage          = resp.data.filterData.message
          this.shelfCodeCheck=true
          this.openModal(this.activeTab['scanModalSuccess'])
        }).catch(err => {
          this.placementErrorMessage = err.data.errors.message;
          this.openModal(this.activeTab['scanModalError'])
        });
  }

  goIssue(){
    this.$state.go('app.issues',{tab: 2}, {reload:true});
  }

  getPrepareProcess() {
    this.productBarcode = null
    let listType = this.orderListType;
    if (listType === 'picking'){
      listType += '|quickPicking'
    }
    this.API.all("action-process/prepare").doGET(null, {
      listType: listType
    })
        .then((response) => {
          if (204 === response.response.status) {
            this.pendingPrepareList = []
            this.ownPrepareList = []
            this[this.orderListType + 'Count'] = 0;
            return;
          }

          this[this.orderListType + 'Count'] = response.data.count
          this.pendingPrepareList = response.data.list.filter(list=> !list.prepareUserId)
          this.ownPrepareList =  response.data.list.filter(list=> list.prepareUserId === this.currentUserId)

        });
    this.focusInput('productBarcode');
  }
  actionDetail(listId,roadStartShelf = null){
    this.API.all('action-process/prepare/'+listId)
        .doGET(null,{
          grouped:'product',
          startingShelf:roadStartShelf
        })
        .then((response) => {
          this.prepareScreenData(listId,response.data.list)

        });

      this.$rootScope.$broadcast('toggleSidebarEvent', {});

    document.querySelector('md-toolbar').hidden=true;
    document.querySelector('md-tabs-wrapper').hidden=true;
  }
  listAssignMe(process,listId = null){
    let params = {
      listType:this.orderListType,
    }
    this.API.all(`action-process/${process}/${listId ?? '_'}/assign`)
        .customPUT(params)
        .then((response) => {
          this.ToastService.showHtml('success',this.$translate.instant('APPOINTMENT_PROCESS'))
          if (process === 'assignPrepare'){
            this.actionDetail(response.data.listId)
          }
        });
  }
  quantityMove() {
    this.DialogService.hide(true);
  }


  listCheck(){
    let sum = 0;
    this.actionPrepareListV2.lines.map(item => {
      if (item.preparedQuantity === item.quantity){
        sum += 1;
      }
    })
    if (sum === this.actionPrepareListV2.lines.length){
      this.ToastService.success(this.$translate.instant('PRODUCTS_SUCC_COMPLETED'));
      this.getScreenFromListType(this.orderListType,'select')
      this.getPrepareProcess();
    }
  }

  quantityCheck() {

    this.API.all(`action-process/prepare/${this.listId}/barcode-check`)
        .customGET(null,{
          barcode         : this.productBarcode,
          productBarcode  : this.activeProduct.barcode
        })
        .then((response) => {
          let list = response.data.list

          if (list.usable !== true){
            this.falseBeep.play()
            this.ToastService.showHtml('error',this.$translate.instant('BARCODE_NOT_DEFINED_SYSTEM'))
            return
          }

          if ('shelf' === list.barcodeType){
            this.activeProduct.selectedShelf = this.activeProduct.shelves.filter(shelf=>shelf.barcode ===this.productBarcode)[0]
            this.ToastService.showHtml('success',this.$translate.instant('SHELF_REPLACED'))
            this.stocktakingShelfCode = this.productBarcode
            this.trueBeep.play()
            return;
          }

          if (this.productBarcode !== this.activeProduct.barcode) {
            this.changeProduct(this.productBarcode)
            return;
          }

          this.productQuantity = 1;
           if (list.showQuantityBox ===false){
             this.preparedListItem();
             return;
           }
          this.multipleQuantity()

        }).catch(err => {
          this.falseBeep.play()
          this.productBarcode = null;
          this.focusInput('productBarcode');

          console.log(err);
    });
  }

  manuelQuantityCheck(){
    if (this.activeProduct.quantity === 1 || this.activeProduct.remainingQuantity === 1) {
      this.productQuantity = 1;
      this.preparedListItem();

      return;
    }
    this.multipleQuantity()
  }
  multipleQuantityCheck(){
    if (this.productQuantity === undefined || this.productQuantity === '') {
      this.ToastService.error(this.$translate.instant('INCORRECT_PRODUCT_QUANTITY_ENTRY'));
      return
    }
    if (this.productQuantity > 1) {
      let parent = this;
      var options = {
        controller: "OrdersPrepareController",
        controllerAs: 'vm',
        locals: {
          dialog: true,
          productQuantity: this.productQuantity
        },
        bindToController: true,
      };
      let OPDialogPromise = this.DialogService.fromTemplateV2(
          './views/angular/app/components/orders-prepare/partials/orders-prepare-for-list-barcode.component.html',
          options
      ).then((status) => {
        if (status) {
          this.preparedListItem();
        }
        this.productBarcode = null;
      }).catch(()=>{
        this.productBarcode = null;
        this.barcodeFocus = true;
      })
      this.productBarcode = null;
      this.productOpen = false
      return;
    }else{
      this.productQuantity = 1;
      this.preparedListItem();
      this.productOpen = false
      this.productBarcode = null;
    }
    this.productBarcode = null;
    this.listCheck();
  }



  preparedListItem() {
    let _this = this
    let activeShelf;
    let data = {
      shelf     : this.stocktakingShelfCode,
      barcode   : this.productBarcode,
      quantity  : this.productQuantity,
      productId     : this.activeProduct.productId,
      subProductId  : this.activeProduct.subProductId,
    }

    if (this.stockType === 'preStock') {
      data.serialNumber = this.serialNumber;
      data.expireDate = this.expireDate;
    }
    this.API.all('action-process/prepare/' + this.listId)
        .customPOST(data)
        .then((response) => {
          if (response.response.status === 200) {
            this.trueBeep.pause();
            this.trueBeep.play();

            this.focusInput('productBarcode');

            if (response.data.list?.processComplete){

              let stockType = this.stockType;

              var options = {
                controllerAs      : 'vm',
                bindToController  : true,
                onShowing         : function (scope, element, options, controller){
                  scope.reportName          = stockType === 'preStock' ? 'Mal Kabul':'Sipariş Hazırlama';
                  scope.report              = response.data.list.report;
                  scope.collapsed           = false;
                },
              };

              let OPDialogPromise = this.DialogService.fromTemplateV2(
                  './views/angular/app/components/orders-prepare/partials/dialogs/prepare-report.html',
                  options
              ).then((status) => {
                this.getScreenFromListType(this.orderListType,'select')
                _this.getPrepareProcess()
                _this.closePrepareScreen()
              }).catch(()=>{
                this.getScreenFromListType(this.orderListType,'select')
                _this.getPrepareProcess()
                _this.closePrepareScreen()
              })
              return false
            }

            this.prepareScreenData(this.actionPrepareListV2.listId,response.data.list);
            this.acceptableShelf = response.data.list
                    if (!this.activeProduct.takenShelves){
                      this.activeProduct.takenShelves = []
                    }

                    let exists = this.activeProduct.takenShelves.findIndex(shelf => shelf.shelfId ===  _this.acceptableShelf.shelfId && shelf.lotId === _this.acceptableShelf.lotId)
                    if (exists === -1){
                      this.activeProduct.takenShelves.push({
                        shelfId: _this.acceptableShelf.shelfId,
                        shelfName: _this.acceptableShelf.shelfName,
                        lotId: _this.acceptableShelf.lotId,
                        quantity: _this.productQuantity
                      })
                    } else {
                      this.activeProduct['takenShelves'][exists].quantity += _this.productQuantity;
                    }

                    this.actionPrepareProductList.map(list => {
                      if (list.index === _this.activeProduct.index){
                        list.preparedQuantity += _this.productQuantity;
                        list.remainingQuantity -= _this.productQuantity;
                        list.takenShelves = _this.activeProduct.takenShelves
                    //    _this.productQuantity = 1
                      }
                    })
            if (0 === this.activeProduct.remainingQuantity) {
              this.listCheck();
              this.nextProduct();
             // this.ToastService.success('Bu ürünün listedeki toplama süreci tamamlanmıştır.');
            }
          }
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('NO_SHELF_FOUND'));
            this.falseBeep.play()
            this.productQuantity = 1
          }
          this.productBarcode = null;
          this.barcodeFocus = true;
        })
        .catch((response)=>{
          this.falseBeep.play()
          this.productBarcode = null;
          this.barcodeFocus = true;
          this.productQuantity = 1
        })
    //this.productQuantity = 1;
  }
  getControlProcess(){
    this.API.all("action-process/control").doGET()
        .then((response) => {
          if (204 === response.response.status){
            this.controllerActionPrepareLists = [];
            this.ToastService.showHtml('success',this.$translate.instant('CHECKING_STAGE_YET'))
          }
          this.controllerActionPrepareLists = response.data.list
          this.controlCount = response.data.count
          this.assignMeList = this.controllerActionPrepareLists.filter(f=>f.controlAssignedMe)
        });
  }

  selectChange() {
    this.actionPrepareListV2.lines.map(item => {
      item.select = false;
      if (this.activeProduct.index === item.index) {
        item.select = true;
      }
    })
    this.barcodeFocus = true

  }
  prepareListTargetProduct(target) {
    this.shelffill = null;
    if (target.remainingQuantity > 0) {
      this.activeProduct = target;
      this.stocktakingShelfCode = this.activeProduct.shelves[0].barcode

      this.productBarcode = this.activeProduct.barcode;
      this.productQuantity = 1;

      this.stockType = this.activeProduct.stockType
      this.selectChange()
      this.productBarcode = null;
      this.focusInput('productBarcode');

    }

  }

  pasteBarcode(event){
    this.changeProduct(event)
  }

  changeProduct(barcode) {
    if (barcode === this.activeProduct.barcode){
      this.productBarcode = null;
      return true;
    }

    let index = this.actionPrepareListV2.lines.filter(item => item.barcode === barcode);
    if (index.length === 1){
      if (index[0].remainingQuantity === 0){
        this.ToastService.showHtml('success',this.$translate.instant('PRODUCT_PREPARATION_COMPLETED'))
      }
      this.prepareListTargetProduct(index[0]);
      this.productBarcode = null;
    }
  }

    messageModal(notes){
    let options = {
      controller: 'OrdersPrepareController',
      controllerAs: 'vm',
      locals: {
        notes : notes,
      },
      bindToController: true,
    }
    var msgModal = this.DialogService.fromTemplateV2(
        './views/angular/app/components/orders-prepare/partials/dialogs/action-message-dialog.html',
        options
    );
    msgModal.catch((response)=>{
      this.barcodeFocus = true
    })
  }
  changePrepareDefaultParams(){
    this.activeProduct.changePrepareDefault = !this.activeProduct.changePrepareDefault;
    this.activeProduct.shelves.map((shelf, index) => {
      if (0 === index) return;
      shelf.show = this.activeProduct.changePrepareDefault
    })
    if (this.activeProduct.changePrepareDefault){
      this.stocktakingShelfCode = null
      if (this.activeProduct.stockType !== 'preStock'){
        this.productBarcode       = null
      }
    }else{
      if (this.activeProduct.stockType === 'reservedStock'){
        this.stocktakingShelfCode = this.activeProduct.shelves[0].barcode
      }
      if (this.activeProduct.stockType === 'preStock'){
        this.productBarcode = this.activeProduct['barcode']
      }
      this.productQuantity      = this.activeProduct['remainingQuantity']
      this.stockType            = this.activeProduct['stockType']
    }
  }

  multipleQuantity(){
    let acceptAllQuantity = 0;
    if (this.activeProduct.selectedShelf.items[0].quantity <= this.activeProduct.remainingQuantity){
      acceptAllQuantity = this.activeProduct.selectedShelf.items[0].quantity;
    } else {
      acceptAllQuantity = this.activeProduct.remainingQuantity;
    }
    this.quantityOpen = true
    var options = {
      controller: "MultipleQuantityController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        stocktakingShelfCode            : this.stocktakingShelfCode,
        actionPrepareProductListSelect  : this.activeProduct,
        quantity                        : this.activeProduct.quantity,
        remainingQuantity               : acceptAllQuantity,
        productQuantity                 : this.productQuantity,
        productBarcode                  : this.productBarcode,
        orderListType                   : this.orderListType
      }
    }
    if (this.activeProduct.shelves[0].items[0].quantity === 0 ){
      this.ToastService.error(this.$translate.instant('NO_PRODUCT_SHELF'));
      return;
    }
    if (this.activeProduct.selectedShelf.items[0].quantity === 0 ){
      this.ToastService.error(this.$translate.instant('NO_PRODUCT_SHELF'));
      return;
    }
    this.DialogService.fromTemplateV2("./views/angular/app/components/orders-prepare/partials/multiple-quantity-modal.html",options)
        .then((response)=>{
          this.focusInput('productBarcode');
          if(response.message){
            this.ToastService.error(response.message)
          }
          if(response.productQuantity){
            this.productQuantity = response.productQuantity
            this.stocktakingShelfCode = response.stocktakingShelfCode
            this.multipleQuantityCheck();
          }
          this.focusInput('productBarcode');
          this.quantityOpen = false
        })
  }

  shelfFill(shelf){
    this.shelffill = shelf
  }


  missingQuantity() {
    let parent = this;
    let missingItem = [];

    this.actionPrepareListV2.lines.map(item => {

      if (item.remainingQuantity === 0 ){
        return;
      }

      let shelvesQuantity = 0;
      if (item.shelves.length> 0) {
        shelvesQuantity = item.shelves.map(shelf => {
          return shelf?.items[0]?.quantity;
        }).reduce((sum, shelfQuantity) => sum + shelfQuantity)
      }

      missingItem.push({
        remainingQuantity: item.remainingQuantity,
        shelvesQuantity: shelvesQuantity,
        productName: item.productName,
      })

    })

    var options = {
      controllerAs      : 'vm',
      bindToController  : true,
      onShowing         : function (scope, element, options, controller){
        scope.API                 = parent.API
        scope.DialogService       = parent.DialogService
        scope.listId              = parent.listId;
        scope.missingItem         = missingItem;
        scope.sendMissingQuantity= () =>{
          scope.API.all(`action-process/missing/${scope.listId}`)
              .customPUT()
              .then((response) => {
                scope.DialogService.$mdDialog.hide(response)
              });
        }

      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/app/components/orders-prepare/partials/dialogs/missing-quantity-dialog.html',
        options
    );
    customerDialogPromise.then(response=>{
      if (response.response.status === 207){
        this.ToastService.show(this.$translate.instant('INCOMPLETE_COMPLETED'))
        this.closePrepareScreen()
      }
    }).catch(err=>{
      console.log(err)
    })
  }

  getScreenFromListType(listType,screen='select'){

    if (listType === 'picking') {
      this.actionPickingPrepareListSection = screen
    }

    if (listType === 'quickPicking') {
      this.actionPickingPrepareListSection = screen
    }

    if (listType === 'receiving') {
      this.actionReceivingPrepareListSection = screen
    }

  }

  closePrepareScreen(){
    this.actionPickingPrepareListSection = 'select';
    this.actionReceivingPrepareListSection = 'select';
    this.getPrepareProcess();

    this.$rootScope.$broadcast('toggleSidebarEvent', {});

    document.querySelector('md-toolbar').hidden=false;
    document.querySelector('md-tabs-wrapper').hidden=false;
  }

  showOtherShelves() {
    let shelves = this.activeProduct.shelves;
    let current = this.activeProduct.selectedShelf.shelfId;
    let dialog = this.DialogService
    var options = {
      controllerAs      : 'vm',
      bindToController  : true,
      onShowing         : function (scope, element, options, controller){
        scope.dialog = dialog;
        scope.shelves = shelves;
        scope.current = current;
        scope.selectShelf = (shelf) =>{
          scope.dialog.$mdDialog.hide(shelf)
        }

      },
    };

    var dialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/app/components/orders-prepare/partials/dialogs/other-shelves-dialog.html',
        options
    );
    dialogPromise.then(shelf=>{
      this.activeProduct.selectedShelf = shelf
      this.stocktakingShelfCode = shelf.barcode
      this.trueBeep.play()
      this.focusInput('productBarcode');

    })
    dialogPromise.catch(err=>{
      console.log(err)
      this.focusInput('productBarcode');

    })

  }
  shipmentProcessList(approve =null,transactionId= null){
    this.shipmentList = [];
    this.shipmentListCount = 0;

    let query = {};

    if (approve !== null) {
      query['approve'] = approve
    }

    if (transactionId){
      query['transactionId'] = transactionId
    }

    this.API.all("action-process/shipment")
        .doGET(null,query)
        .then((response) => {
          if (response.response.status !== 200) return;
          this.scanText = null
          this.shipmentList = response.data.list.shipment.map(s=>{

            s.show = s.provider === 'polypus';
            s.actions.map(i=>{i.selected = true})

            return s;
          })
          this.shipmentListCount = response.data.list.total
        });
  }

  accountingProcessList(transactionId = null) {
    let query = {};

    if (transactionId){
      query['transactionId'] = transactionId
    }
    this.API.all("action-process/accounting")
        .doGET(null,query)
        .then((response) => {
          if (response.response.status === 204) {
            this.ToastService.error(this.$translate.instant('ERP_FAILED_AGAIN_LATER'));
            this.scanText = null
            return;
          }
          this.scanText = null
          this.accountingList = response.data.list;
          this.accountingCount = response.data.list.total

        });
  }

  approveShipment(provider, sendSelected = false) {
    let data = {
      provider: provider,
    };

    if (sendSelected) {
      const selectedActions = this.shipmentList
          .find(s => s.provider === provider)
          ?.actions
          .filter(a => a.selected)
          .map(a => a.actionId)
          .filter(f => f);

      if (selectedActions.length === 0) {
        this.ToastService.showHtml('error', this.$translate.instant('DIDNT_CHOOSE'))
        return;
      }
      data['processIds'] = selectedActions
    }


    this.API.all(`action-process/shipment/_/approve`)
        .customPUT(data)
        .then(response => {
          this.shipmentProcessList(0)
        })
  }


  tryAgain(process, processId) {
    this.API.all(`action-process/${process}/${processId}/try`)
        .customPUT()
        .then(response => {
          if (process ===  'shipment'){
            this.shipmentProcessList()
          }else if (process === 'accounting'){
            this.accountingProcessList()
          }
        })
  }

  previousProduct() {
    let currentIndex = this.actionPrepareListV2.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
    currentIndex = currentIndex === 0 ? this.actionPrepareListV2.lines.length - 1 : currentIndex - 1;
    this.activeProduct = this.actionPrepareListV2.lines[currentIndex]
    this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;
    this.productBarcode = null;
    if (this.activeProduct.remainingQuantity === 0){
      this.previousProduct()
      return
    }
    this.focusInput('productBarcode');

  };

  nextProduct() {
    let currentIndex = this.actionPrepareListV2.lines.findIndex(s=>s.barcode === this.activeProduct.barcode);
    currentIndex = (currentIndex === this.actionPrepareListV2.lines.length - 1) ? 0 : currentIndex + 1;
    this.activeProduct = this.actionPrepareListV2.lines[currentIndex]
    this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;
    this.productBarcode = null;
    if (this.activeProduct.remainingQuantity === 0){
      this.nextProduct()
      return
    }
    this.focusInput('productBarcode');
  };

  prepareScreenData(listId,detail){
    this.actionPrepareListV2 = detail;

    this.listId = listId
    this.actionPrepareListV2.lines.forEach(l=>{
      l.selectedShelf = l.shelves[0]
    })

    this.activeProduct = this.actionPrepareListV2.lines.find(line =>{
      return line.remainingQuantity>0;
    })
    this.stocktakingShelfCode = this.activeProduct?.selectedShelf?.barcode;

    this.getScreenFromListType(this.actionPrepareListV2.listType,'prepare')
    this.focusInput('productBarcode');

  }

  printLabel(product){
    var options = {
      controller: 'PrintPreviewDialogController',
      controllerAs: 'vm',
      locals: {
        productId: product.productId,
        productName: product.productName,
      },
      bindToController: true,
      multiple: true,
      onShowing: function (scope, element, options, controller) {
        controller.printLabel(product)
      },
    };
    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/dialogs/printPreview/printPreview.dialog.html',
        options
    );
    customerDialogPromise.catch(err => {
      console.log(err)
    })
  }

  focusInput(id){
    document.getElementById(id)?.focus();

  }
  showCameraView() {
    var parent = this;
    this.$mdBottomSheet.show({
      templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
      controller: "BottomCameraController",
      controllerAs: "vm",
      bindToController: true,
      locals: {}
    }).then(response => {
      parent.scanText = response;
      parent.productBarcode = response;
    })
        .catch(function (error) {
          console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
        });
  }
}

OrdersPrepareController.$inject = ["API", "ToastService", "$translate", "DialogService", "$state", "$filter", "$location", "$window", "$sce", "$timeout", "$scope", "$rootScope", "$mdBottomSheet"];
const OrdersPrepareComponent = {
  templateUrl:
    "./views/angular/app/components/orders-prepare/orders-prepare.component.html",
  controller: OrdersPrepareController,
  controllerAs: "vm",
  bindings: {},
};
export {OrdersPrepareComponent,OrdersPrepareController}
