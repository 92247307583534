import "./camera.scss";
import Cookies from "js-cookie";

const CAMERA_COOKIE_KEY = 'camera-id'


class CameraController {

    constructor(API,$window, ToastService,$translate, $mdBottomSheet,$scope) {
        this.randId = (Math.random() + 1).toString(36).substring(7)
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.barcodeScanner = $window.BarcodeScanner;
        this.cameraId = null;
        this.cameraState = 'resume'
        this.cameraEvent = null
        this.searchCallBack = this.$scope.searchCallBack;
    }

    $onInit() {
        let parent = this;
        this.startScreen()
    }

    $onDestroy() {
        this.barcodeScanner = null
        this.cameraStop()
    }

    startScreen() {
        this.cameraId = Cookies.get(CAMERA_COOKIE_KEY)
        this.getCameraInfo()
    }

    completeCallback = (decodedText, decodedResult) => {
        this.cameraPause()
        this.$scope.$emit('childEvent', decodedText);
        this.isShowCamera = false;
        return true;
    };

    setSelectedCamera() {
        Cookies.set(CAMERA_COOKIE_KEY, this.cameraId)
        this.cameraEvent?.stop()
        this.cameraStart()
    }

    cameraStart() {
        this.cameraState = 'resume'
        this.cameraEvent = new this.barcodeScanner(this.randId);
        // telefon için {width: 200, height: 150} ideal
        // pc için {width: 250, height: 250}
        //ekrana göre ayar yapmak lazım
        const config = {
            fps: 10,
            qrbox: {width: 200, height: 150},
            experimentalFeatures: {useBarCodeDetectorIfSupported: true}
        }
        this.cameraEvent.start(
            this.cameraId,
            config,
            this.completeCallback,
        ).catch((err) => {
            console.log(err);
        });
    }

    cameraStop() {
        this.cameraEvent?.stop()
        //this.$mdBottomSheet.hide()
    }

    cameraPause() {
        this.cameraEvent.pause()
        this.cameraState = 'pause'
    }

    cameraResume() {
        this.cameraEvent.resume()
        this.cameraState = 'resume'
    }

    getCameraInfo() {
        let parent = this
        this.barcodeScanner.getCameras().then(devices => {
            if (0 === devices.length) {
                parent.ToastService.error(this.$translate.instant('NO_AVAILABLE_CAMERA_FOUND'))
                //parent.$mdBottomSheet.hide(false)
            }
            if (1 === devices.length) {
                parent.cameraId = devices[0].id
            }
            parent.cameraDevices = devices

        }).then(() => {
            parent.cameraStart()
        }).catch(err => {
            parent.ToastService.error(this.$translate.instant('PLEASE_ENABLE_BROWSER_PERMISSION')+err)
            //parent.$mdBottomSheet.hide(false)
        })
    }
}

CameraController.$inject = ["API","$window", "ToastService", "$translate", "$mdBottomSheet","$scope"]
const CameraComponent = {
    templateUrl: "./views/angular/app/components/camera/camera.component.html",
    controller: CameraController,
    controllerAs: "vm",
    bindings: {
        isShowCamera: '='
    },
};
export {CameraComponent, CameraController}
