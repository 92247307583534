import "./offer.scss";
import {getUserInfo, getToken} from "../../../utils/users";

class OfferPageController {
    constructor($auth,$window, $state, $location,$rootScope,$stateParams) {
        'ngInject';

        this.$auth = $auth;
        this.$window = $window;
        this.$state = $state;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;

        this.offetId = null;
        this.selectBarcode = null;
        this.offerTab ={
            sales:0,
            purchase:1,
        }
        this.missingProductList = [];
    }

    $onInit() {
        this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;
      //  this.getMissingProduct()
    }

    onSelectTab(obj) {

        if (this.$stateParams.offerId) {
            this.offerId =  this.$stateParams.offerId;

            if (this.$stateParams.barcode){
                this.selectBarcode = this.$stateParams.barcode
            }
        }
        if (this.$stateParams.offerType) {
            this.selectedTabIndex = this.offerTab[this.$stateParams.offerType]
        }

        //this.$rootScope.$broadcast('offerEvent', obj)
        this.$location.search(obj);
        this.video = undefined;
        this.offerTabScreen = 'list';
    }

    getMissingProduct() {
        this.API.all("offer/product/missing")
            .customGET()
            .then((response) => {
                if (response.response.status === 204) {
                    return
                }
                this.missingProductList = response.data.list;
            })
    }
}



OfferPageController.$inject = ["$auth", "$window", "$state", "$location","$rootScope","$stateParams"];

const OfferPageComponent = {
    templateUrl: './views/angular/app/components/offer/offer-page.component.html',
    controller: OfferPageController,
    controllerAs: 'vm',
    bindings: {}
};


export {OfferPageComponent, OfferPageController}
