import "./upload.scss";
import Cookies from "js-cookie";

class GalleryController {

    constructor($mdBottomSheet, $scope) {
        this.$scope = $scope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.galleryLength = 0
        this.currentGalleryIndex = 0;
        this.gallery = [];
    }

    $onInit() {
        this.galleryLength = this.gallery.length - 1
    }

    next() {
        ++this.currentGalleryIndex
        if (this.currentGalleryIndex > this.galleryLength) {
            this.currentGalleryIndex = 0;
        }
    }

    prev() {
        --this.currentGalleryIndex
        if (this.currentGalleryIndex <0) {
            this.currentGalleryIndex = this.galleryLength;
        }
    }
}

GalleryController.$inject = ["$mdBottomSheet", "$scope"]
const GalleryComponent = {
    templateUrl: "./views/angular/app/components/upload/gallery.component.html",
    controller: GalleryController,
    controllerAs: "vm",
    bindings: {
        gallery: '<'
    },
};
export {GalleryComponent, GalleryController}
